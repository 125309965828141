import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography, IconButton, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddColumnDropdown from "./AddColumnDropdown";

import RuleCheckCard from "./RuleCheckCard";
import { ReactSortable } from "react-sortablejs";
import { SnackbarContext } from "../../App";
import ViewTableData from "./ViewTableData";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
import SelectColumnsCount from "../FileWatcher/SelectColumnsCount";
import { useLocation } from "react-router-dom";

const ECAddColumnsContainer = ({
  tableData,
  columnsData,
  columnValidationData,
  setColumnValidationData,
  loadingCreateOrPreview,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setSnack } = useContext(SnackbarContext);
  const handleDelete = (columnIndex, columnName) => {
    setColumnValidationData((prevState) =>
      prevState.map((each, i) =>
        i === columnIndex
          ? {
              ...each,
              columns: each.columns.filter((col) => col !== columnName),
            }
          : each,
      ),
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, columnIndex) => {
    event.preventDefault();
    const stringData = event.dataTransfer.getData("ruleData");
    const draggedItem = stringData && JSON?.parse?.(stringData);

    const isExistsDuplicateCheck = columnValidationData?.[columnIndex]?.rules?.some(
      (rule) => rule?.Name === "DuplicateCheck",
    );

    if (draggedItem?.Name === "DuplicateCheck" && isExistsDuplicateCheck) {
      setSnack({
        message: "DuplicateCheck Exists",
        open: true,
        colour: "warning",
      });
      return;
    }

    if (
      isExistsDuplicateCheck ||
      (draggedItem?.Name === "DuplicateCheck" && columnValidationData?.[columnIndex]?.rules?.length !== 0)
    ) {
      setSnack({
        message: "DuplicateCheck not Allowed",
        open: true,
        colour: "warning",
      });
      return;
    }

    draggedItem &&
      setColumnValidationData((prevState) =>
        prevState.map((each, i) => {
          if (i === columnIndex) {
            const isItemAlreadyPresent = each.rules.some((rule) => rule.DisplayName === draggedItem.DisplayName);

            if (!isItemAlreadyPresent || (draggedItem?.Category === "Value" && draggedItem?.Id !== 1)) {
              return { ...each, rules: [...each.rules, draggedItem] };
            }
            setSnack({
              message: "Rule Check Exists",
              open: true,
              colour: "warning",
            });
          }
          return each;
        }),
      );
  };

  const onSelectColumns = (columnIndex, selectedColumn) => {
    setColumnValidationData((prevState) =>
      prevState.map((each, i) =>
        i === columnIndex
          ? {
              ...each,
              columns: each.columns.some((col) => col === selectedColumn.COLUMN_NAME)
                ? each.columns.filter((col) => col !== selectedColumn.COLUMN_NAME)
                : [...each.columns, selectedColumn.COLUMN_NAME],
            }
          : each,
      ),
    );
  };

  const onClickDeleteRow = (columnIndex) => {
    setColumnValidationData((prevState) => prevState?.filter((each, index) => index !== columnIndex));
  };

  const inputsHandler = useCallback((e, columnIndex, ruleIndex) => {
    setColumnValidationData((prevData) => {
      const updatedColumns = [...prevData];
      const targetColumn = updatedColumns[columnIndex];

      if (targetColumn) {
        targetColumn.rules = targetColumn.rules.map((check, i) => {
          if (i === ruleIndex) {
            check.NestedControls = (check.NestedControls || []).map((nestedControl) => {
              if (nestedControl?.StateName === e.target.name) {
                nestedControl.SelectedValue = e.target.value;
              }
              return nestedControl;
            });
          }
          return check;
        });
      }

      return updatedColumns;
    });
  }, []);

  const checkBoxHandler = useCallback((e, columnIndex, keyName, ruleIndex) => {
    setColumnValidationData((prevData) => {
      const updatedColumns = [...prevData];
      const targetColumn = updatedColumns[columnIndex];

      if (targetColumn) {
        updatedColumns[columnIndex] = {
          ...targetColumn,
          rules: targetColumn.rules.map((check, i) => {
            if (i === ruleIndex) {
              return {
                ...check,
                ControlProperties: {
                  ...check.ControlProperties,
                  IsChecked: e.target.checked,
                },

                NestedControls: (check.NestedControls || []).map((nestedControl) => {
                  if (nestedControl?.StateName === e.target.name) {
                    return {
                      ...nestedControl,
                      ControlProperties: {
                        IsChecked: e.target.checked,
                      },
                    };
                  }
                  return nestedControl;
                }),
              };
            }
            return check;
          }),
        };
      }

      return updatedColumns;
    });
  }, []);
  const onChangeAndOrSwitch = useCallback((e, columnIndex, ruleIndex) => {
    setColumnValidationData((prevData) => {
      return prevData.map((column, colIndex) => {
        if (colIndex !== columnIndex) {
          return column;
        }
        return {
          ...column,
          rules: column.rules.map((rule, idx) => {
            if (idx !== ruleIndex) {
              return rule;
            }
            return { ...rule, Operator: e.target.checked ? "OR" : "AND" };
          }),
        };
      });
    });
  }, []);

  const onClickAddRow = () => {
    setColumnValidationData((prevState) => [...prevState, { columns: [], rules: [] }]);
  };

  const handleRemoveRuleCheck = (columnIndex, ruleIndex) => {
    setColumnValidationData((prevState) =>
      prevState.map((each, i) =>
        i === columnIndex
          ? {
              ...each,
              rules: each.rules?.filter((rule, i) => i !== ruleIndex),
            }
          : each,
      ),
    );
  };

  const onReArrangeRuleChecks = (columnIndex, newRuleList) => {
    setColumnValidationData((prevState) =>
      prevState.map((each, i) => {
        if (i === columnIndex) {
          return { ...each, rules: newRuleList };
        }
        return each;
      }),
    );
  };

  const disableAddRow = columnValidationData?.every((each) => each?.columns?.length && each?.rules?.length);

  const isFileWatcher = location.pathname?.includes("file-watcher");

  return (
    <Grid item md={10}>
      <Box
        className="space-between"
        sx={{ background: "#2971e6", color: "white", p: isFileWatcher ? 0.6 : 1, opacity: 0.8 }}>
        <Typography>Add Columns & Rules</Typography>
        {isFileWatcher && <SelectColumnsCount />}
      </Box>

      <Box sx={{ px: 1 }}>
        <Box className="space-between" sx={{ height: "40px", boxSizing: "border-box" }}>
          <IconButton size="small" sx={{ cursor: "default" }}>
            <InfoOutlinedIcon fontSize="small" />
            <Typography sx={{ pl: "2px" }}>
              You can Click Add Row to add Specific Columns with Selected Rules to be applied
            </Typography>
          </IconButton>
          <Box className="v-center" gap={1}>
            {!isFileWatcher && <ViewTableData tableData={tableData} />}
            <CustomAddButton onClick={onClickAddRow} label={t("Add Row")} disabled={!disableAddRow} />
          </Box>
        </Box>

        <Grid
          container
          sx={{
            maxHeight: "calc(75vh - 40px)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
          className="container">
          <Grid item md={4} borderRight={"1px solid #cfcfcf"}>
            <Typography className="ecTitleHead" sx={{ borderTopLeftRadius: "12px" }}>
              Columns
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography className="ecTitleHead" sx={{ borderTopRightRadius: "12px" }}>
              Rules
            </Typography>
          </Grid>
          {columnValidationData.map((each, columnIndex) => (
            <React.Fragment key={columnIndex}>
              <Grid item md={4} borderRight={"1px solid #cfcfcf"} borderBottom={"1px solid #cfcfcf"}>
                <Box sx={{ overflowY: "auto", p: 1 }}>
                  <Grid container spacing={1}>
                    {columnsData
                      ?.filter((eachColumn) => each.columns?.includes(eachColumn?.COLUMN_NAME))
                      ?.map((col, i) => (
                        <Grid item key={i} md="auto">
                          <Chip
                            size="small"
                            label={
                              <Typography>{`${col?.COLUMN_NAME} ${
                                col?.DATA_TYPE ? `(${col?.DATA_TYPE})` : ""
                              }`}</Typography>
                            }
                            onDelete={() => handleDelete(columnIndex, col?.COLUMN_NAME)}
                          />
                        </Grid>
                      ))}
                  </Grid>
                  <AddColumnDropdown
                    onSelectColumns={(colData) => onSelectColumns(columnIndex, colData)}
                    columnsData={columnsData}
                    defaultColumns={each.columns}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={8}
                onDrop={(e) => handleDrop(e, columnIndex)}
                onDragOver={handleDragOver}
                p={1}
                overflow={"auto"}
                borderBottom={"1px solid #cfcfcf"}>
                <ReactSortable
                  list={each.rules || []}
                  setList={(newList) => onReArrangeRuleChecks(columnIndex, newList)}
                  animation={200}
                  delayOnTouchStart={true}
                  easing="ease-out"
                  className="dragItemsList">
                  {each.rules?.map((fromInput, i) => (
                    <RuleCheckCard
                      key={i}
                      columnIndex={columnIndex}
                      ruleIndex={i}
                      fromInput={fromInput}
                      inputsHandler={inputsHandler}
                      checkBoxHandler={checkBoxHandler}
                      handleRemoveRuleCheck={handleRemoveRuleCheck}
                      onChangeAndOrSwitch={onChangeAndOrSwitch}
                      disableAndSwitch={each.rules?.length - 1 === i}
                      loadingCreateOrPreview={loadingCreateOrPreview}
                    />
                  ))}
                </ReactSortable>
                <Grid item md={12} p={1}>
                  <Box className="v-center">
                    <Box className="v-center h-center" sx={{ p: 1, border: "1px dashed #ccc", flexGrow: 1 }}>
                      Drop here
                    </Box>
                    <IconButton
                      sx={{ ml: 0.5, mr: -0.5 }}
                      size="small"
                      onClick={() => onClickDeleteRow(columnIndex)}
                      disabled={loadingCreateOrPreview}
                      color="error">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default ECAddColumnsContainer;
