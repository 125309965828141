/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Paper,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import Draggable from "react-draggable";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import ApiService from "../../../../services/app.service";
import AbortModal from "../ResultTile/AbortModal";
import Progress from "./Progress";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../../App";

const ExecutionsView = React.memo(({ fetchDataValidations }) => {
  const { setSnack } = useContext(SnackbarContext);

  const draggableRef = useRef(null);
  const { t } = useTranslation();
  const [isExecutionsVisible, setIsExecutionsVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [abortModalData, setAbortModalData] = useState({});
  const [selectedTab, setSelectedTab] = useState(1);
  const [executionsLoading, setExecutionLoading] = useState(false);

  const fetchExecutions = async () => {
    setExecutionLoading(true);
    try {
      const response = await ApiService.CurrentRuleExecutions();
      setRows(response.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setExecutionLoading(false);
  };

  const handleToggleExecutions = () => {
    setIsExecutionsVisible((prev) => !prev);
  };
  const ruleExecuteRequestAbort = async () => {
    try {
      let reqBody = { executionId: abortModalData._id, isAborted: true };
      await ApiService.RuleExecuteRequestUpdate(reqBody);
      fetchExecutions();
      setAbortModalData({});
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      fetchDataValidations();
    }
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const inProgressCount = rows?.filter(
    (e) => e.status === 1 || e.status === 0
  )?.length;
  const statusClassMap = {
    2: "completed",
    3: "aborted",
  };
  useEffect(() => {
    if (isExecutionsVisible) {
      fetchExecutions();
    }
  }, [isExecutionsVisible]);
  const filteredData = rows?.filter((each) => each.status === selectedTab);
  return (
    <>
      <Draggable
        handle="#draggable-dialog-title"
        // cancel={'[class*="MuiDialogContent-root"]'}
        nodeRef={draggableRef}
      >
        <Box
          ref={draggableRef}
          className={`exePop ${isExecutionsVisible ? "bg-color" : ""}`}
          id="draggable-dialog-title"
        >
          <Box
            pl={2}
            className={`${isExecutionsVisible ? "" : "exeHeaderIcon"} ${
              inProgressCount > 0 &&
              !isExecutionsVisible &&
              "exeHeaderIconRotate"
            }`}
          >
            <Grid container alignItems={"center"}>
              <Grid item sm>
                {isExecutionsVisible && (
                  <Typography variant="h6">
                    {t("Executions List")} :{" "}
                  </Typography>
                )}
              </Grid>
              <Grid item sm textAlign={"right"}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={handleToggleExecutions}
                >
                  {isExecutionsVisible ? (
                    <CancelOutlinedIcon
                      sx={{ width: 32, height: 32, color: "#c62828" }}
                    />
                  ) : (
                    <>
                      <RotateRightOutlinedIcon sx={{ width: 32, height: 32 }} />
                      {inProgressCount > 0 && (
                        <Box component={"span"}>{inProgressCount}</Box>
                      )}
                    </>
                  )}
                </IconButton>
              </Grid>
              {isExecutionsVisible && (
                <Grid item xs={12}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      className="exepopTabs"
                      value={selectedTab}
                      onChange={handleTabChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={t("Todo")} value={0} />
                      <Tab label={t("In Progress")} value={1} />
                      <Tab label={t("Completed")} value={2} />
                      <Tab label={t("Aborted")} value={3} />
                    </Tabs>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          {isExecutionsVisible &&
            (executionsLoading ? (
              <CircularProgress sx={{ mx: "50%", my: 2 }} size={20} />
            ) : (
              <Box px={2} py={1} className="exeListPop">
                {!filteredData?.length > 0 ? (
                  <Typography textAlign="center" variant="h6">
                    No Data Available
                  </Typography>
                ) : (
                  filteredData?.map((row) => {
                    const percentage =
                      (row?.completedBatches / row?.totalBatchCount) * 100;
                    const rowStatus = row?.status;
                    const statusClassName = statusClassMap[rowStatus] || "";
                    return (
                      <Paper
                        key={row?._id}
                        className={`exList ${statusClassName}`}
                      >
                        <Progress
                          value={percentage}
                          totalbatches={row?.totalBatchCount}
                          completed={row?.completedBatches}
                          row={row}
                          setAbortModalData={setAbortModalData}
                          handleToggleExecutions={handleToggleExecutions}
                        />
                      </Paper>
                    );
                  })
                )}
              </Box>
            ))}
        </Box>
      </Draggable>

      {Object.keys(abortModalData)?.length > 0 && (
        <AbortModal
          ruleExecuteRequestAbort={ruleExecuteRequestAbort}
          showModal={Object.keys(abortModalData)?.length}
          setAbortModalData={setAbortModalData}
        />
      )}
    </>
  );
});
ExecutionsView.displayName = "ExecutionsView";
export default ExecutionsView;
