import AdjustIcon from "@mui/icons-material/Adjust";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { formatNumber } from "../../_helpers/utils";
import { useStyles } from "../Styles";
const DashBoardOverview = ({ tests }) => {
  const renderProgressBar = (data, color) => {
    return (
      <Tooltip
        title={`${data.SourceDatabaseCount} DB | ${data.SourceFileCount} Files`}
        placement="top"
        arrow
      >
        <Box backgroundColor={color} width={`${data.percentage}%`}>
          {data.no}
        </Box>
      </Tooltip>
    );
  };

  const renderDataQualityBars = () => {
    const goodData = tests?.OverallDataQuality?.graph.good;
    const averageData = tests?.OverallDataQuality?.graph.average;
    const poorData = tests?.OverallDataQuality?.graph.poor;
    if (tests?.OverallDataQuality?.dataSource === 0) {
      return <Box className="noData">No Connections</Box>;
    }
    return (
      <>
        {goodData && renderProgressBar(goodData, "#00ba37")}
        {averageData && renderProgressBar(averageData, "#ffc107")}
        {poorData && renderProgressBar(poorData, "#f86368")}
      </>
    );
  };

  return (
    <Grid container className="overallDQ container">
      <Grid item md={12}>
        <Box className="DQhead">
          <Typography variant="h6">Overall Data Quality</Typography>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box className="DQ">
          <Typography variant="h6">Connections</Typography>
          <Typography>
            No of Connections: {tests?.OverallDataQuality?.dataSource}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} alignItems="center">
        <Box className="DQ">
          <Box sx={useStyles.progress}>{renderDataQualityBars()}</Box>
          <Box className="colorInfo">
            <Box>
              <Typography>
                <AdjustIcon /> Good
              </Typography>
              <Typography>
                <AdjustIcon /> Average
              </Typography>
              <Typography>
                <AdjustIcon /> Poor
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box className="DQ">
          <Typography variant="h6">Rules</Typography>
          <Typography>
            No of Rules: {tests?.OverallDataQuality?.ValidatedRecords?.Tests}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box className="DQ">
          <Typography variant="h6">DQ Checks</Typography>
          <Typography>
            Data Quality Checks:{" "}
            {tests?.OverallDataQuality?.ValidatedRecords?.TotalValidations}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3}>
        <Box className="DQ">
          <Typography variant="h6">
            No of Records Validated :{" "}
            {tests?.OverallDataQuality?.ValidatedRecords.ValidatedRecords}
          </Typography>
          <Box>
            <Grid container className="PassFail" mt="0!important">
              <Grid item sm={6}>
                <Box className="passed" sx={{ ml: "-5px" }}>
                  <CheckCircleRoundedIcon sx={{ color: "#50cd89" }} />
                  <Typography>
                    {formatNumber(
                      tests?.OverallDataQuality?.ValidatedRecords?.Passed
                    )}{" "}
                    Passed
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box className="failed">
                  <CancelRoundedIcon color="error" />
                  <Typography>
                    {formatNumber(
                      tests?.OverallDataQuality?.ValidatedRecords?.Failed
                    )}{" "}
                    Failed
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashBoardOverview;
