import { Autocomplete, CircularProgress, TextField } from "@mui/material";

export default function TableViewsSearch({
  placeholder,
  optionsList,
  value,
  setValue,
  loader,
}) {
  const handleChange = (e, val) => {
    setValue(val);
  };

  return (
    <Autocomplete
      disablePortal
      disableClearable={!value}
      id="combo-box-demo"
      options={optionsList}
      value={value}
      getOptionLabel={(option) => (option ? option : "")}
      onChange={(e, val) => {
        handleChange(e, val);
      }}
      groupBy={(option) => option.table_type}
      loading={loader}
      // onOpen={() => {
      //   setLoading(true);
      //   setTimeout(() => {
      //     setLoading(false);
      //   }, 1000);
      // }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          placeholder={placeholder}
          sx={{
            "& .MuiChip-root": {
              my: "1px",
              fontSize: "10px",
              height: "18px",
              "& .MuiChip-deleteIcon": {
                fontSize: "12px",
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loader && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ mr: "25px" }}
                  />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
