/* eslint-disable react-hooks/exhaustive-deps */
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, Paper, Typography, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import { CustomAgGrid } from "../AgGrid";
import InnerHeader from "../Layout/InnerHeader";
import PreviewSchedulerData from "../TestScheduler/PreviewSchedulerData";
import { headCellss } from "./Model";
import PipeLinePopUpScheduler from "./PipeLinePopUpScheduler";
import EditPopUpPipeLine from "./EditPopUpPipeLine";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import { useTranslation } from "react-i18next";

export default function PipeLineList() {
  const { t } = useTranslation();
  const gridRef = useRef();
  const ScrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const [responseData, setresponseData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [previewId, setPreviewId] = useState();
  const [selectedObj, setSelectedObj] = useState({});
  const [selected, setSelected] = useState([]);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const closePreview = () => {
    setPreviewData([]);
    setPreviewId();
  };

  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  async function fetchList() {
    setPreviewData([]);
    setPreviewId();
    try {
      let response = await ApiService.getPipeLinesList();
      setSelected([]);
      setresponseData(response?.data);
    } catch (error) {
      setSnack({ message: error?.message, open: true, colour: "error" });
    }
  }

  useEffect(() => {
    fetchList();
  }, []);

  const deleteScheduled = async () => {
    setDeleteLoading(true);
    try {
      await ApiService.deletePipeLine({ ids: selected });
      setOpenDialog(false);
      fetchList();
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
    } catch (error) {
      setSnack({ message: error?.message, open: true, colour: "error" });
    }
    setDeleteLoading(false);
  };

  const previewTests = async (obj) => {
    gridRef.current.api.deselectAll();
    setPreviewId(obj._id);
    setSelectedObj(obj);
    try {
      let response = await ApiService.GetDataValidations({
        pipelineId: obj._id,
      });
      setPreviewData(response?.data?.data);
      autoScroll();
    } catch (error) {
      setSnack({ message: error?.message, open: true, colour: "error" });
    }
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    fetchList: fetchList,
    previewTests: previewTests,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  useMemo(() => {
    headCellss[headCellss.length - 1] = action;
  }, []);

  function ActionsCell(props) {
    const row = props.data;
    return (
      <React.Fragment>
        <EditPopUpPipeLine obj={row} fetchList={props.colDef.fetchList} />

        <IconButton
          title="Preview"
          onClick={() => props.colDef.previewTests(row)}
          size="small"
          color="secondary"
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </React.Fragment>
    );
  }
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader name={"CI/CD Pipelines"} />
      <Box sx={{ width: "100%", mb: 2 }}>
        <Box component="form" noValidate autoComplete="off">
          <Box className="createBtn">
            {selected.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} {t("Selected")}
                </Typography>
                <Tooltip title={t("Delete")}>
                  <IconButton size="small" onClick={handleClickOpenDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {selected.length === 0 && (
              <PipeLinePopUpScheduler
                icon={
                  <Box size="small" variant="outlined">
                    Create New DevOps Connection for DQ Rules
                  </Box>
                }
                fetchList={fetchList}
              />
            )}
          </Box>
          <CustomAgGrid
            previewId={previewId}
            closePreview={closePreview}
            gridRef={gridRef}
            headCells={headCellss}
            setSelected={setSelected}
            rows={responseData?.data}
          />
        </Box>
      </Box>
      {previewData.length !== 0 && (
        <Paper>
          <PreviewSchedulerData
            data={previewData}
            setPreviewData={setPreviewData}
            setPreviewId={setPreviewId}
            closePreview={closePreview}
            ScrollRef={ScrollRef}
            autoScroll={autoScroll}
            obj={selectedObj}
          />
        </Paper>
      )}

      {openDialog && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={deleteScheduled}
          handleClose={handleCloseDialog}
        />
      )}
    </Box>
  );
}
