/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormHelperText,
  IconButton,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useMemo, useRef, useState } from "react";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import { masterdataheadCells } from "./MasterDataheadcells";
import MasterDataPreview from "./MasterDataPreview";
import MasterdataUpload from "./MasterDataUpload";
import { useTranslation } from "react-i18next";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import { CustomPreviewIcon } from "../CustomComponents/IconButtons";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";

function ActionsCell({ data: row, colDef: { previewMasterdata } }) {
  const { t } = useTranslation();
  const { checkAccess } = useContext(UserDetailsContext);

  return (
    <>
      {checkAccess("MasterData", "Preview") && (
        <CustomPreviewIcon
          title={t("Preview")}
          onClick={() => previewMasterdata(row)}
        />
      )}
    </>
  );
}

export default function MasterData() {
  const { t } = useTranslation();
  const gridRef = useRef();
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useContext(UserDetailsContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selected, setSelected] = useState([]);
  const [previewId, setPreviewId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [state, setState] = useState(false);

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const refreshData = () => {
    gridRef?.current?.api?.refreshServerSide({ purge: true });
  };

  async function deleteMasterdata() {
    setDeleteLoading(true);
    try {
      await ApiService.deleteMasterdata({ ids: selected });
      setOpenDialog(false);
      refreshData();
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setDeleteLoading(false);
  }

  const previewMasterdata = (obj) => {
    gridRef.current.api.deselectAll();
    setPreviewId(obj._id);
  };

  const action = useMemo(
    () => ({
      headerName: "Actions",
      sortable: false,
      cellRenderer: ActionsCell,
      fetchList: refreshData,
      previewMasterdata,
      lockPosition: "right",
      cellClass: "locked-col",
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
    }),
    []
  );

  masterdataheadCells[masterdataheadCells.length - 1] = action;

  const toggleDrawer = () => {
    setState(false);
  };

  const closePreview = () => {
    setPreviewId();
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Typography variant="h6" className="upper-case">
            {t("Master Data")}
          </Typography>
          {checkAccess("MasterData", "Create") && selected.length === 0 && (
            <CustomAddButton
              onClick={() => setState(true)}
              label={t("Upload Master Data")}
            />
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74">
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box component="" noValidate autoComplete="off">
            <Box className="createBtn">
              {checkAccess("MasterData", "Delete") && selected.length !== 0 && (
                <Box className="v-center">
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                  >
                    {selected.length} {t("Selected")}
                  </Typography>
                  <Tooltip title={t("Delete")}>
                    <IconButton size="small" onClick={handleClickOpenDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <AgGridSSRM
              gridRef={gridRef}
              closePreview={closePreview}
              setSelected={setSelected}
              apiMethod="Masterdatalist"
              headCells={masterdataheadCells}
            />
          </Box>
          <FormHelperText>
            {t("Note")} : {t("note8")}
          </FormHelperText>
        </Box>
        {previewId && (
          <Paper ref={scrollRef}>
            <MasterDataPreview
              connection={previewId}
              autoScroll={autoScroll}
              returnVal={closePreview}
            />
          </Paper>
        )}
      </Box>
      {state && (
        <MasterdataUpload toggleDrawer={toggleDrawer} fetchList={refreshData} />
      )}

      {openDialog && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={deleteMasterdata}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
}
