import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const CustomExpandableTableRow = ({
  children,
  showData,
  showAccordian,
  expandComponent,
  onClickArrow,
  data,
  ...otherProps
}) => {
  return (
    <React.Fragment>
      <TableRow {...otherProps}>
        {children}
        <TableCell align="center" sx={{}}>
          <Box
            className="v-center"
            onClick={() => onClickArrow(data)}
            sx={{ gap: 2 }}
          >
            <span style={{ visibility: "hidden" }} className="accordionArrow">
              <KeyboardArrowUpOutlinedIcon />
            </span>
            <Typography sx={{ flexGrow: 1 }}>{showData}</Typography>
            <span className="accordionArrow">
              {showAccordian ? (
                <KeyboardArrowUpOutlinedIcon />
              ) : (
                <KeyboardArrowDownOutlinedIcon />
              )}
            </span>
          </Box>
        </TableCell>
      </TableRow>
      {showAccordian && <TableRow>{expandComponent}</TableRow>}
    </React.Fragment>
  );
};
export default CustomExpandableTableRow;
