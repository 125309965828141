import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ConnectionDetails({ connectionDetails }) {
  const { t } = useTranslation();
  return (
    <Box className="connectionDetails">
      <Grid container>
        <Grid item md={1}>
          <Typography variant="sectionHeading">Validate</Typography>
        </Grid>
        <Grid item md={11}>
          <Box className="v-center" gap={2}>
            <Typography variant="detailTitle">
              {t("Data Source Name")} :{" "}
              <Typography variant="detailValue" component="span">
                {connectionDetails?.connectionName}
              </Typography>
            </Typography>

            <Typography variant="detailTitle">
              {t("Database Type")} :{" "}
              <Typography variant="detailValue" component="span">
                {connectionDetails?.connectionType}
              </Typography>
            </Typography>
            {connectionDetails?.server && (
              <Typography variant="detailTitle">
                {t("Server")} :{" "}
                <Typography variant="detailValue" component="span">
                  {connectionDetails?.server}
                </Typography>
              </Typography>
            )}
            {connectionDetails?.serverHostname && (
              <Typography variant="detailTitle">
                {t("Server Host Name")} :{" "}
                <Typography variant="detailValue" component="span">
                  {connectionDetails?.serverHostname}
                </Typography>
              </Typography>
            )}
            {connectionDetails?.catalogName && (
              <Typography variant="detailTitle">
                {t("Catalog Name")} :{" "}
                <Typography variant="detailValue" component="span">
                  {connectionDetails?.catalogName}
                </Typography>
              </Typography>
            )}
            {connectionDetails?.dataBase && (
              <Typography variant="detailTitle">
                {t("Database")} :{" "}
                <Typography variant="detailValue" component="span">
                  {connectionDetails?.dataBase}
                </Typography>
              </Typography>
            )}
            {connectionDetails?.schema && (
              <Typography variant="detailTitle">
                {t("Schema")} :{" "}
                <Typography variant="detailValue" component="span">
                  {connectionDetails?.schema}
                </Typography>
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
