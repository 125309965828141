import { FormHelperText, Grid, Box } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import CreateDqRuleCard from "./CreateDqRuleCard";
import { useTranslation } from "react-i18next";
import apiImage from "../../../../images/CreateDqRules/api.svg";
import compare from "../../../../images/CreateDqRules/compare.svg";
import dataBaseImage from "../../../../images/CreateDqRules/database.svg";
import fileImage from "../../../../images/CreateDqRules/file.svg";
import JsonFileReaderForm from "../../JsonFileReaderForm";
import CustomDrawer from "../../../CustomComponents/CustomDrawer";

const CreateNewDqRule = ({ refreshData }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const createRule = JSON.parse(searchParams.get("createRule"));
  const importRule = searchParams.get("importRule");

  const handleDrawerClose = () => {
    setSearchParams();
  };

  const defaultDqTypes = [
    {
      id: 1,
      title: t("API"),
      image: apiImage,
      navLink: "/DataQualityRule/singleapi",
    },
    {
      id: 2,
      title: t("File"),
      image: fileImage,
      navLink: "/DataQualityRule/create?testType=Files",
    },
    {
      id: 3,
      title: t("Database"),
      image: dataBaseImage,
      navLink: "/DataQualityRule/create?testType=database",
    },
    {
      id: 4,
      title: t("Comparison"),
      image: compare,
      navLink: "/DataQualityRule/row-comparison/create?firstDataSourceType=database&secondDataSourceType=database",
    },
  ];
  return (
    <>
      {createRule && (
        <Grid
          container
          justifyContent={"center"}
          className="container"
          sx={{
            alignItems: "center",
            padding: "1rem",
            mb: 2,
          }}>
          <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
            {defaultDqTypes?.map((each, i) => (
              <Box key={i} mr={1}>
                {each?.title !== "API" ? (
                  <Link style={{ textDecoration: "none" }} to={each?.navLink}>
                    <CreateDqRuleCard item={each} />
                  </Link>
                ) : (
                  <CreateDqRuleCard item={each} />
                )}
              </Box>
            ))}
          </Grid>
          <Grid item sm={12}>
            <FormHelperText sx={{ color: "#a8a5a5", textAlign: "center", mt: 2 }}>{t("note3")}</FormHelperText>
          </Grid>
        </Grid>
      )}
      {importRule && (
        <CustomDrawer title={"Import DQRule"} onClose={handleDrawerClose}>
          <JsonFileReaderForm toggleDrawer={handleDrawerClose} refreshData={refreshData} />
        </CustomDrawer>
      )}
    </>
  );
};

export default CreateNewDqRule;
