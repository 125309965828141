import { useContext } from "react";
import { Box } from "@mui/material";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import DataSources from "../DataSources";
import DashBoard from "../DashBoard";
import DataValidationsList from "../Validations/DataValidationsList";
import CollectionList from "../RuleCollections/CollectionList";
import ExecutionConfigurations from "../EC";
// import ConnectionDataValidations from "../Connection/List/ConnectionDataValidations";
import EditConnectionDataValidations from "../Connection/List/EditConnectionDataValidations";
import Validations from "../Validations";
import RowComparison from "../Validations/RowComparison";
import DataCleansing from "../Data Cleansing";
import EditCompareValidation from "../Validations/EditCompareValidation";
import SingleFile from "../Validations/SingleFile";
import SingleDatabase from "../Validations/SingleDatabase";
import FFileValidations from "../Files/FFileValidations";
import EditFileValidations from "../Files/EditFileValidations";
import ImageComparison from "../ImageComparison/ImageComparison";
import Chart from "../Chart/Chart";
import ScheduledList from "../TestScheduler/ScheduledList";
import PipeLineList from "../PipeLines/PipeLineList";
import Settings from "../Settings/Settings";
import DataProfiling from "../DataProfiling";
import VisualTest from "../VisualTest";
import MasterData from "../MasterData";
import License from "../License";
import SingleAPI from "../Validations/SingleApi";
import DQGUsers from "../DQGUsers";
import UserLogsList from "../DQGUsers/UserLogsList";
import SignIn from "../Login";
import DataSourcesInsights from "../DataSourcesInsights";
import Layout from "../Layout";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import FileWatcher from "../FileWatcher";
import CreateFileWatcher from "../FileWatcher/CreateFileWatcher";
import AllNotifications from "../Notifications";
import Subscribe from "../Notifications/Subscribe";

const PrivateRoute = ({ children }) => {
  const { userDetails } = useContext(UserDetailsContext);
  const token = userDetails?.Token;
  return token ? children : <Navigate to="/login" />;
};

const SidebarLayout = () => {
  return (
    <PrivateRoute>
      <Box sx={{ display: "flex", marginTop: "52px" }}>
        <Layout />
        <Box className="outlet">
          <Outlet />
        </Box>
      </Box>
    </PrivateRoute>
  );
};

const RouteManager = () => {
  const { checkAccess } = useContext(UserDetailsContext);

  const ROUTES = [
    { path: "/", element: <DataSources /> },
    { path: "DataSource", element: <DataSources /> },
    { path: "Dashboard", element: <DashBoard /> },
    { path: "DataQualityRule", element: <DataValidationsList /> },
    { path: "RuleCollection", element: <CollectionList /> },
    checkAccess("DataQualityRule", "Create") && {
      path: "DataQualityRule/create",
      element: <ExecutionConfigurations />,
    },
    // { path: "DataQualityRule/connection/data-validations/:connectionId", element: <ConnectionDataValidations /> },
    checkAccess("DataQualityRule", "Create") && {
      path: "DataQualityRule/edit/connection/data-validation/:connectionId/:testId",
      element: <EditConnectionDataValidations />,
    },
    { path: "DataQualityRule/validations", element: <Validations /> },
    checkAccess("DataQualityRule", "Create") && {
      path: "DataQualityRule/row-comparison/create",
      element: <RowComparison />,
    },
    { path: "data-cleansing", element: <DataCleansing /> },
    checkAccess("DataQualityRule", "Create") && {
      path: "DataQualityRule/edit/validation/:testId",
      element: <EditCompareValidation />,
    },
    { path: "DataQualityRule/singlefile", element: <SingleFile /> },
    { path: "DataQualityRule/singledatabase", element: <SingleDatabase /> },
    { path: "DataQualityRule/files/validations/:fileId", element: <FFileValidations /> },
    checkAccess("DataQualityRule", "Create") && {
      path: "DataQualityRule/edit/files/validations/:fileId/:testId",
      element: <EditFileValidations />,
    },
    { path: "image", element: <ImageComparison /> },
    { path: "chart", element: <Chart /> },
    { path: "ScheduledTest/list", element: <ScheduledList /> },
    { path: "pipeline/list", element: <PipeLineList /> },
    { path: "Settings", element: <Settings /> },
    { path: "DataProfiler", element: <DataProfiling /> },
    { path: "visual/test", element: <VisualTest /> },
    { path: "DataQualityRule/singleapi", element: <SingleAPI /> },
    { path: "MasterData", element: <MasterData /> },
    { path: "license/:info", element: <License /> },
    { path: "dqgusers", element: <DQGUsers /> },
    { path: "dqgusers/userlogs/:id", element: <UserLogsList /> },
    { path: "datasources/insights", element: <DataSourcesInsights /> },
    { path: "file-watcher", element: <FileWatcher /> },
    { path: "file-watcher/create", element: <CreateFileWatcher /> },
    { path: "all-notifications", element: <AllNotifications /> },
    { path: "subscribe", element: <Subscribe /> },

    // Add other routes here as needed
  ];
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route element={<SidebarLayout />}>
        {ROUTES.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default RouteManager;
