import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CustomSwitchButton from "../CustomComponents/CustomSwitchButton";

const CreateModel = ({
  defaultRuleName,
  createNew,
  createLoading,
  onClickCancel,
  createValidation,
}) => {
  const { t } = useTranslation();

  const [ruleNameInput, setRuleNameInput] = useState(defaultRuleName);
  const [searchParams, setSearchParams] = useSearchParams();

  const isPrivate = JSON.parse(searchParams.get("isPrivate"));
  const handleSwitchChange = (e) => {
    setSearchParams((params) => {
      params.set("isPrivate", e.target.checked);
      return params;
    });
  };

  const isDisableRuleName = !(ruleNameInput?.length >= 4) || createLoading;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={() => onClickCancel()}
    >
      <DialogTitle sx={{ pb: 2 }}>{`${t("Provide")} ${t(
        "Data Quality Rule Name"
      )}`}</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          name="rulename"
          required
          label={t("DQ Rule Name")}
          placeholder={t("Data Quality Rule Name")}
          value={ruleNameInput}
          onChange={(e) => setRuleNameInput(e.target.value)}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent:
            !defaultRuleName || createNew ? "space-between" : "flex-end",
        }}
      >
        {(!defaultRuleName || createNew) && (
          <Box sx={{ pl: 1, float: "left" }}>
            <CustomSwitchButton
              labels={[t("public"), t("private")]}
              checked={isPrivate}
              onChange={handleSwitchChange}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            color="error"
            size="small"
            variant="outlined"
            onClick={() => onClickCancel()}
          >
            {t("Cancel")}
          </Button>
          {(!defaultRuleName || createNew) && (
            <LoadingButton
              disabled={isDisableRuleName}
              ssize="small"
              variant="contained"
              onClick={() => createValidation(ruleNameInput, createNew)}
              className="loader-button"
              loading={createLoading}
              startIcon={<></>}
              loadingPosition="start"
            >
              {t("Create")}
            </LoadingButton>
          )}
          {defaultRuleName && !createNew && (
            <LoadingButton
              disabled={ruleNameInput?.length < 5 || createLoading}
              size="small"
              variant="contained"
              onClick={() => createValidation(ruleNameInput)}
              className="loader-button"
              loading={createLoading}
              startIcon={<></>}
              loadingPosition="start"
            >
              {t("Update")}
            </LoadingButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const CreateOrUpdateDQRule = ({
  createLoading,
  validateLoading,
  createValidation,
}) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [searchParams] = useSearchParams();
  const defaultRuleName = searchParams.get("ruleName");
  const handleCreateNew = () => {
    setCreateNew(true);
    setOpenModal(true);
  };

  const onClickCancel = () => {
    setCreateNew(false);
    setOpenModal(false);
  };
  return (
    <React.Fragment>
      <Button
        onClick={() => setOpenModal(true)}
        size="small"
        variant="contained"
        disabled={validateLoading}
        className="loader-button"
      >
        {t(defaultRuleName ? "Update" : "Create DQ Rule")}
      </Button>
      {defaultRuleName && (
        <Button
          className="loader-button"
          sx={{ marginLeft: 1 }}
          onClick={handleCreateNew}
          size="small"
          variant="contained"
          disabled={validateLoading}
        >
          {t("Create As New DQ Rule")}
        </Button>
      )}

      {openModal && (
        <CreateModel
          defaultRuleName={defaultRuleName}
          createNew={createNew}
          createLoading={createLoading}
          onClickCancel={onClickCancel}
          createValidation={createValidation}
        />
      )}
    </React.Fragment>
  );
};

export default CreateOrUpdateDQRule;
