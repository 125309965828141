// import AnalyticsIcon from "@mui/icons-material/Analytics";
// import NotStartedSharpIcon from "@mui/icons-material/NotStartedSharp";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import { IconButton } from "@mui/material";
// import { EditTest } from "./EditTest";
import { formatDate } from "../../_helpers/utils";

export const headCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    maxWidth: 50,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  },
  {
    field: "TestName",
    headerName: "Test Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    headerTooltip: "Test Name",
  },
  {
    field: "ApplicationName",
    headerName: "App Name",
    sortable: true,
    filter: "agTextColumnFilter",
    headerTooltip: "App Name",
  },
  {
    field: "ApplicationUrl",
    headerName: "App Url",
    sortable: true,
    filter: "agTextColumnFilter",
    headerTooltip: "App Url",
  },
  {
    field: "Threshold",
    headerName: "Threshold",
    sortable: true,
    filter: "agNumberColumnFilter",
    headerTooltip: "Threshold",
  },
  {
    field: "MaxDifferences",
    headerName: "Max Differences",
    sortable: true,
    filter: "agNumberColumnFilter",
    headerTooltip: "Max Differences",
  },
  {
    field: "UpdatedDate",
    headerName: "Created Date",
    cellRenderer: CreatedDate,
    sortable: true,
    filter: "agDateColumnFilter",
    headerTooltip: "Created Date",
  },
  {},
];

function CreatedDate(props) {
  return <>{formatDate(props.data?.CreatedDate)}</>;
}

// function ActionsCell(props) {
//   const row = props.data;
//   return (
//     <>
//       <EditTest row={row} returnValue={props.context.fetchList} />
//       <IconButton
//         title="Preview"
//         onClick={() => {
//           props.context.ShowPreview(row);
//         }}
//         size="small"
//         color="primary"
//       >
//         <VisibilityOutlinedIcon fontSize="12px" />
//       </IconButton>
//       <IconButton
//         title="Execute"
//         onClick={() => {
//           props.context.excuteTest(row._id);
//         }}
//         size="small"
//         color="primary"
//       >
//         <NotStartedSharpIcon fontSize="12px" />
//       </IconButton>
//       <IconButton
//         title="Results"
//         onClick={() => {
//           props.context.viewResults(row._id);
//         }}
//         size="small"
//         color="warning"
//       >
//         <AnalyticsIcon fontSize="12px" />
//       </IconButton>
//     </>
//   );
// }
