import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  MenuItem,
  Checkbox,
  TextField,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogActions,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import CreateDQRule from "./CreateDQRule";
import APIServices from "../../../services/app.service";

const GetControllerFromInput = React.memo(({ input, inputsHandler, checkBoxHandler, keyName, columnIndex }) => {
  const { t } = useTranslation();

  if (input?.ControlType === "Dropdown") {
    return (
      <TextField
        name={input.StateName}
        select
        sx={{ width: "100px" }}
        size="small"
        value={input.ControlProperties.SelectedValue}
        onChange={(e) => inputsHandler(e, columnIndex, keyName)}>
        {input.ControlProperties.Values.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (input?.ControlType === "CheckBox") {
    return (
      <FormControlLabel
        name={input.StateName}
        onChange={(e) => checkBoxHandler(e, columnIndex, keyName)}
        control={
          <Checkbox
            sx={{
              height: "24px",
              "& input": {
                p: 0.5,
              },
              "& label": {
                m: 0,
              },
            }}
            checked={input.ControlProperties.IsChecked}
            size="small"
          />
        }
        label={t(input.DisplayName)}
      />
    );
  }

  if (["Text", "Integer"].includes(input?.ControlType)) {
    const isNumberInput = input.ControlType === "Integer";

    return (
      <TextField
        sx={{
          maxWidth: "130px",
          ml: 2,
          ...(isNumberInput && { mt: 0.4 }),
          "& input": {
            p: 0.5,
          },
          "& label": {
            transform: "translate(14px, 4px) scale(1)",
          },
        }}
        type={isNumberInput ? "number" : "text"}
        name={input.StateName}
        value={input?.ControlProperties?.Value}
        onChange={(e) => inputsHandler(e, columnIndex, keyName)}
        size="small"
        placeholder={t(input.DisplayName)}
        variant="outlined"
        InputProps={isNumberInput && { inputProps: { min: "1", step: "1" } }}
      />
    );
  }

  return null;
});
GetControllerFromInput.displayName = "GetControllerFromInput";

const SuggestedChecks = React.memo(({ previewConData }) => {
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [columns, setColumns] = useState(previewConData?.columns);
  const [showCreateRule, setShowCreateRule] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);

  const inputsHandler = useCallback((e, columnIndex, keyName) => {
    setColumns((prevData) => {
      const updatedColumns = [...prevData];
      const targetColumn = updatedColumns[columnIndex];

      if (targetColumn) {
        targetColumn.SuggestedChecks = targetColumn.SuggestedChecks.map((check) => {
          if (check?.StateName === keyName) {
            check.NestedControls = (check.NestedControls || []).map((nestedControl) => {
              if (nestedControl?.StateName === e.target.name) {
                nestedControl.SelectedValue = e.target.value;
              }
              return nestedControl;
            });
          }
          return check;
        });
      }

      return updatedColumns;
    });
  }, []);

  const checkBoxHandler = useCallback((e, columnIndex, keyName) => {
    setColumns((prevData) => {
      const updatedColumns = [...prevData];
      const targetColumn = updatedColumns[columnIndex];

      if (targetColumn) {
        updatedColumns[columnIndex] = {
          ...targetColumn,
          SuggestedChecks: targetColumn.SuggestedChecks.map((check) => {
            if (e.target.name === keyName) {
              return {
                ...check,
                ControlProperties: {
                  ...check.ControlProperties,
                  IsChecked: check.StateName === keyName ? e.target.checked : check.ControlProperties.IsChecked,
                },
                ...(e.target.checked && {
                  NestedControls: (check.NestedControls || []).map((nestedControl) => ({
                    ...nestedControl,
                    ControlProperties: {
                      IsChecked: false,
                    },
                  })),
                }),
              };
            } else {
              return {
                ...check,
                NestedControls: (check.NestedControls || []).map((nestedControl) => {
                  if (nestedControl?.StateName === e.target.name && check?.StateName === keyName) {
                    return {
                      ...nestedControl,
                      ControlProperties: {
                        IsChecked: e.target.checked,
                      },
                    };
                  }
                  return nestedControl;
                }),
              };
            }
          }),
        };
      }

      return updatedColumns;
    });
  }, []);

  const onClickCreateRule = () => {
    const validateInputs = columns?.some((item) =>
      item?.SuggestedChecks?.some(
        (each) =>
          each?.ControlProperties?.IsChecked &&
          (each?.NestedControls?.length
            ? ["Text", "Integer"].includes(each?.NestedControls?.[0]?.ControlType)
              ? !each?.NestedControls?.[0]?.SelectedValue
              : false
            : false),
      ),
    );

    if (validateInputs) {
      setSnack({
        message: "Fill in all required text inputs",
        open: true,
        colour: "warning",
      });
      return;
    } else setShowCreateRule(true);
  };

  const createValidation = async (testName) => {
    setCreateLoading(true);
    try {
      const finalValidationsColumns = columns
        ?.map((each) => {
          if (each.SuggestedChecks?.some((eachCol) => eachCol.ControlProperties?.IsChecked)) {
            return {
              tablename: previewConData?.Name,
              CustomQuery: "",
              IsCustomQuery: false,
              columns: [
                {
                  ColumnName: each?.Name,
                  validation: each.SuggestedChecks?.filter((eachCheck) => eachCheck.ControlProperties?.IsChecked),
                },
              ],
              ValidationProperties: [],
            };
          }
          return null;
        })
        .filter(Boolean);

      const payload = {
        TestName: testName,
        TestType: "Single Database",
        Tables: finalValidationsColumns,
        ConnectionId: previewConData?.connectionId,
        isPrivate: true,
      };

      await APIServices.createValidation(payload);
      setShowCreateRule(false);
      setSnack({
        message: "Created Successfully",
        open: true,
        colour: "success",
      });
      navigate("/DataQualityRule");
    } catch (e) {
      setSnack({
        message: e?.response?.data?.TestName?.message || e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const isDisabled = columns?.find((column) =>
    column?.SuggestedChecks?.some((check) => check?.ControlProperties?.IsChecked),
  );

  return (
    <>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S. No</TableCell>
              <TableCell>Column Name </TableCell>
              <TableCell>Suggested Checks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columns?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.Name}</TableCell>
                <TableCell>
                  <Grid container spacing={1}>
                    {row?.SuggestedChecks?.map((input, i) => (
                      <Grid
                        item
                        xs={input.ControlProperties.IsChecked && input?.NestedControls?.length ? 4 : 3}
                        key={i}>
                        <Box
                          key={index}
                          className={`dataInsightsInput ${
                            input.ControlProperties.IsChecked && input?.NestedControls?.length && "selectedCheck"
                          }`}>
                          <GetControllerFromInput
                            input={input}
                            inputsHandler={inputsHandler}
                            checkBoxHandler={checkBoxHandler}
                            keyName={input.StateName}
                            columnIndex={index}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}>
                            {input.ControlProperties.IsChecked &&
                              input?.NestedControls?.map((nestedInput, i) => (
                                <GetControllerFromInput
                                  key={i + "2"}
                                  input={nestedInput}
                                  inputsHandler={inputsHandler}
                                  checkBoxHandler={checkBoxHandler}
                                  keyName={input.StateName}
                                  columnIndex={index}
                                />
                              ))}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogActions sx={{ position: "sticky", bottom: 0 }}>
        <Button disabled={!isDisabled} onClick={onClickCreateRule} variant="contained" color="primary">
          Create Rule
        </Button>
      </DialogActions>

      {showCreateRule && (
        <CreateDQRule
          onClickCancel={() => setShowCreateRule(false)}
          createLoading={createLoading}
          createValidation={createValidation}
        />
      )}
    </>
  );
});

SuggestedChecks.displayName = "SuggestedChecks";

export default SuggestedChecks;
