import { useContext } from "react";
import {
  Box,
  IconButton,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import { useSearchParams } from "react-router-dom";

const DataSourcesList = ({ sources, setOpenFileData, onClickAddConnection, onClickConnectionDb }) => {
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get("view");
  const { t } = useTranslation();
  const { checkAccess } = useContext(UserDetailsContext);

  const handleAddConnection = (e, row) => {
    e.stopPropagation();
    row.type === "Files" ? setOpenFileData(row) : onClickAddConnection(row);
  };

  const renderGridViewItem = (obj, index) => (
    <Grid item md={3} lg={2} key={index}>
      <Box className={`DSGridItem ${!obj.enable ? "DisabledDSGridItem" : ""}`} onClick={() => onClickConnectionDb(obj)}>
        <Box height="100%" width="49%">
          <Box className="center" height="50%">
            <img src={obj.imageUrl} alt="" />
          </Box>
          <Box height="50%" className="center">
            {checkAccess("DataSource", "Create") && (
              <IconButton
                className="DBAdd"
                sx={{ "&:hover": { cursor: "pointer" }, mx: "auto" }}
                onClick={(e) => handleAddConnection(e, obj)}
                disabled={!obj.enable}>
                <Tooltip title="Add">
                  <AddIcon />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box height="100%" width="49%">
          <Box className="center column" height="50%">
            <Typography textAlign="center" variant="h5">
              {obj.count}
            </Typography>
            <Typography textAlign="center" sx={{ fontSize: "0.62rem" }}>
              {t("Data Sources")}
            </Typography>
          </Box>
          <Box className="center column" height="50%">
            <Typography textAlign="center" variant="h5">
              {obj?.DQGRulesCount}
            </Typography>
            <Typography textAlign="center" sx={{ fontSize: "0.62rem" }}>
              {t("DQ Rules")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  const renderListViewItem = (row, i) => (
    <TableRow
      hover
      key={i}
      sx={{ cursor: "pointer" }}
      onClick={() => onClickConnectionDb(row)}
      className={!row.enable ? "DisabledDSGridItem" : ""}>
      <TableCell align="left">
        <img width={35} src={row.imageUrl} alt="" />
      </TableCell>
      <TableCell align="left">{row.count}</TableCell>
      <TableCell align="left">{row.DQGRulesCount}</TableCell>
      <TableCell align="left">
        {checkAccess("DataSource", "Create") && (
          <IconButton
            className="DBAdd"
            sx={{ "&:hover": { cursor: "pointer" }, mx: "auto" }}
            onClick={(e) => handleAddConnection(e, row)}
            disabled={!row.enable}>
            <Tooltip title="Add">
              <AddIcon />
            </Tooltip>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );

  const renderGridView = () => (
    <Grid container spacing={2}>
      {(sources || []).map(renderGridViewItem)}
      <Grid item md={3} lg={2}>
        <Box className="DSGridItem DisabledDSGridItem">
          <Box height="100%" width="49%">
            <Box className="center" height="100%">
              <img src={"/assets/uploads/custom.png"} alt="" />
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box height="100%" width="49%">
            <Box className="center column" height="100%">
              <Typography>{t("custom")}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );

  const renderListView = () => (
    <TableContainer className="DSListItem container" sx={{ maxHeight: "calc(100vh - 160px)" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">Data Source Name</TableCell>
            <TableCell align="left">No Of Connections</TableCell>
            <TableCell align="left">No Of DQ Rules</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{sources.map(renderListViewItem)}</TableBody>
      </Table>
    </TableContainer>
  );

  return viewType !== "list" ? renderGridView() : renderListView();
};

export default DataSourcesList;
