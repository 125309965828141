import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const MasterdataConnectionDetails = ({ connectionDetails }) => {
  const { t } = useTranslation();
  return (
    <Box className="conValHead">
      <Grid container alignItems="center" justify="center">
        <Grid item sm={12} align="center">
          <Grid container>
            <Grid item xs>
              <Box>
                <Typography variant="bold" sx={{ color: "#096eb6" }}>
                  {t("Data Source Name")} :
                </Typography>{" "}
                <Typography>
                  {connectionDetails && connectionDetails.connectionName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold" sx={{ color: "#096eb6" }}>
                  {t("Data Source Type")} :
                </Typography>{" "}
                <Typography>
                  {connectionDetails.ext ? "File" : "DataBase"}{" "}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold" sx={{ color: "#096eb6" }}>
                  {t("Database Type")} :
                </Typography>{" "}
                <Typography>
                  {connectionDetails && connectionDetails.connectionType}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold" sx={{ color: "#096eb6" }}>
                  {connectionDetails.ext
                    ? `${t("File Name")} : `
                    : connectionDetails?.catalogName
                    ? `${t("Catalog Name")} : `
                    : `${t("Database Name")} : `}
                </Typography>{" "}
                <Typography>
                  {connectionDetails && connectionDetails?.ext
                    ? connectionDetails?.fileName
                    : connectionDetails?.catalogName
                    ? connectionDetails?.catalogName
                    : connectionDetails?.dataBase}{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default MasterdataConnectionDetails;
