import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
const fileTypes = ["JPEG", "PNG", "GIF"];

export default function ImageUpload({ file, setFile }) {
  const { t } = useTranslation();
  const handleChange = (fileData) => {
    setFile(null);
    setTimeout(() => {
      setFile(fileData);
    }, 1);
  };

  return (
    <div className="imageUpload">
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
      <p>
        {file && file[0]
          ? `${t("File Name")}: ${file[0]?.name}`
          : t("no files uploaded yet")}
      </p>
    </div>
  );
}
