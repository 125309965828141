import { useState } from "react";
import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useLocation, useSearchParams } from "react-router-dom";

const AddColumnDropdown = ({ columnsData, defaultColumns, onSelectColumns }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [searchParams] = useSearchParams();
  const testType = searchParams.get("testType");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowPopover(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  const onChangeColumnSelection = (selectedColumn) => {
    onSelectColumns(selectedColumn);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const isDataTypeExists = columnsData?.some((each) => each?.DATA_TYPE);
  const sortedColumnData = !location?.pathname.includes("file-watcher")
    ? columnsData?.sort((a, b) => a.COLUMN_NAME.localeCompare(b.COLUMN_NAME))
    : columnsData;
  return (
    <Box>
      <Typography
        className="h-center v-center"
        aria-describedby={popoverId}
        onClick={handleClick}
        color="warning.main"
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          fontWeight: 500,
          my: 1,
          "&:hover": {
            fontWeight: 600,
          },
        }}>
        <AddOutlinedIcon fontSize="small" /> Add Column
      </Typography>

      <Popover
        id={popoverId}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        open={showPopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Box sx={{ bgcolor: "background.paper", position: "relative" }}>
          <IconButton
            sx={{
              position: "absolute",
              right: -1,
              top: -1,
              background: "#fff",
              padding: "2px",
              ":hover": { background: "#fff" },
              zIndex: 3,
            }}
            size="small"
            onClick={handlePopoverClose}>
            <CancelOutlinedIcon color="error" fontSize="small" />
          </IconButton>
          <TableContainer component={Paper} sx={{ maxHeight: "150px", overflowY: "auto" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">Name</TableCell>
                  {testType === "database" && isDataTypeExists && <TableCell align="left">Data Type</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedColumnData
                  ?.filter((each) => each?.COLUMN_NAME)
                  ?.map((row) => (
                    <TableRow
                      key={row.COLUMN_NAME}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell align="left">
                        <Checkbox
                          onChange={() => onChangeColumnSelection(row)}
                          sx={{ p: 0 }}
                          size="small"
                          checked={defaultColumns.some((column) => column === row.COLUMN_NAME)}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          maxWidth: 100,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                        {row?.COLUMN_NAME?.length > 8 ? (
                          <Tooltip title={row.COLUMN_NAME} size="small" arrow placement="top-end">
                            {row.COLUMN_NAME}
                          </Tooltip>
                        ) : (
                          row.COLUMN_NAME
                        )}
                      </TableCell>

                      {testType === "database" && isDataTypeExists && (
                        <TableCell
                          sx={{
                            maxWidth: 80,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          align="left">
                          {row.DATA_TYPE}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Popover>
    </Box>
  );
};

export default AddColumnDropdown;
