import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Paper,
} from "@mui/material";

const CustomSelect = ({
  disabled = false,
  options,
  value,
  onChange,
  renderOptionIcon,
  renderOptionText,
}) => {
  return (
    <Paper>
      <Select
        className="customSelect"
        disabled={disabled}
        size="small"
        onChange={onChange}
        value={value}
        defaultValue={value}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            sx: {
              maxHeight: 150,
              overflow: "auto",
              borderRadius: 0,
              "& .menuItem": {
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                boxSizing: "border-box",
                height: "34px",
                width: "250px",
              },
              "& .MuiMenuItem-root ": {
                gap: "0.5rem",
              },
              "& .MuiListItemIcon-root": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "40px !important",

                "& img": {
                  width: "25px",
                  height: "auto",
                  margin: "auto",
                },
              },
            },
          },
        }}
      >
        {options.map((each) => (
          <MenuItem key={each?._id} className="menuItem" value={each}>
            <ListItemIcon className="listItemIcon">
              {renderOptionIcon(each)}
            </ListItemIcon>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{ borderRightWidth: "thin" }}
            />
            <ListItemText className="v-center">
              {renderOptionText(each)}
            </ListItemText>
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

CustomSelect.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  renderOptionIcon: PropTypes.func.isRequired,
  renderOptionText: PropTypes.func.isRequired,
};

export default CustomSelect;
