/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import SingleSelect from "../../SingleSelect";
import { ModalFilePreview } from "../ModalFilePreview";
import { useSearchParams } from "react-router-dom";

//connection meta data
const databaseMetaData = {
  label: "Select Data Source",
  placeholder: "Filter Data Source list",
};

//files meta data
const fileMetaData = {
  label: "Select Data Source",
  placeholder: "Filter Data Source list",
};
export default function SingleDataSource({ type, options, selectedOption, setOptions, setselectedOption }) {
  const [, setSearchParams] = useSearchParams();
  const { setSnack } = useContext(SnackbarContext);
  const [loadingOptions, setLoadingOption] = useState(false);
  const { t } = useTranslation();
  const [selectMetaData, setSelectMetaData] = useState({});

  const handleNext = () => {
    setSearchParams((params) => {
      params.set("connectionId", selectedOption?.id);
      params.set("isPrivate", true);
      if (type === "database") {
        params.set("isCustomQuery", false);
      }
      return params;
    });
  };

  const getSourcesList = async () => {
    setLoadingOption(true);
    try {
      const response = await ApiService.getConnectionsByType({
        type: type,
      });
      setOptions(response?.data?.data);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingOption(false);
  };

  useEffect(() => {
    getSourcesList();
    if (type === "Files") {
      setSelectMetaData(fileMetaData);
    } else {
      setSelectMetaData(databaseMetaData);
    }
  }, [type]);

  return (
    <Grid container className="container" component={Paper} pb={2}>
      <Grid
        item
        xs={12}
        sx={{
          px: 2,
          py: 1,
          background: "#F4F1FF",
          borderBottom: "1px solid #D2CDCD",
          borderRadius: "12px 12px 0px 0px",
        }}>
        <Typography className="upper-case" variant="h6">
          {type === "Files" ? t("Single File") : t("Single Database")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ mt: "4px", p: 2, pb: 0.7 }}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={6} mr={1}>
            <SingleSelect
              loader={loadingOptions}
              options={options}
              metaData={selectMetaData}
              value={selectedOption}
              setValue={setselectedOption}
            />
          </Grid>

          {type !== "database" && (
            <Grid item xs={1}>
              {type === "Files" && <ModalFilePreview selectedOption={selectedOption} heading={""} />}
              {type === "APIS" && <ModalFilePreview selectedOption={selectedOption} heading={""} />}
            </Grid>
          )}

          <Grid item xs={1}>
            <Button className="loader-button" disabled={!selectedOption} variant="contained" onClick={handleNext}>
              {t("Next")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={6} ml="4px" mt="2px" pr={1}>
          {selectedOption && Object.keys(selectedOption)?.length > 0 && (
            <>
              {type === "database" ? (
                <Typography>
                  <b>{t("Database Type")} : </b>
                  {selectedOption?.server && (
                    <>
                      <b>{t("Server")} :</b> {selectedOption?.server} |{" "}
                    </>
                  )}{" "}
                  {selectedOption?.dataBase && (
                    <>
                      <b>{t("Database")} : </b>
                      {selectedOption?.dataBase} |{" "}
                    </>
                  )}
                  {selectedOption?.serverHostname && (
                    <>
                      <b>{t("Server Host Name")} : </b>
                      {selectedOption?.serverHostname} |{" "}
                    </>
                  )}
                  {selectedOption?.catalogName && (
                    <>
                      <b>{t("Catalog Name")} : </b>
                      {selectedOption?.catalogName} |{" "}
                    </>
                  )}
                  {selectedOption?.dataBase} | <b>{t("Schema")} : </b>
                  {selectedOption?.schema}
                </Typography>
              ) : (
                <Typography>
                  <b>{t("Type")} : </b> {selectedOption.connectionType}
                  {selectedOption.size && (
                    <>
                      | <b>{t("Size")} : </b>
                      {(selectedOption.size * 0.001).toFixed(1)}KB
                    </>
                  )}
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
