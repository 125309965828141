import { useRef, useState, useContext } from "react";
import { alpha, Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import AgGridSSRM from "../../AgGrid/AgGridSSRM";
import { FileWatcherHeadCells } from "./FileWatcherHeadCells";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { UserDetailsContext } from "../../../services/UserDetailsContext";
import {
  CustomEditIcon,
  CustomExecuteIcon,
  CustomPreviewIcon,
  CustomResultIcon,
} from "../../CustomComponents/IconButtons";
import moment from "moment";
import APIServices from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { dateRangeObj, formatDateString, totalExecutionsPerPage } from "../../../_helpers/utils";
import FileWatcherTestResults from "./FileWatcherTestResults";
import CustomDeleteModal from "../../CustomComponents/CustomDeleteModal";
import PreviewFileWatcher from "./PreviewFileWatcher";

const EnhancedTableToolbar = ({ fetchDataValidations, selectedRows, setSelected, gridRef }) => {
  const { t } = useTranslation();

  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const { setSnack } = useContext(SnackbarContext);

  const isAdmin = userDetails?.RoleName === "Admin";

  const rows = [];
  gridRef?.current?.api?.forEachNode((node) => {
    rows.push(node.data);
  });
  const userCreatedRules = rows.filter((each) => each?.UserId !== userDetails?.UserId);
  const schduledOrCollectionRules = rows?.filter((each) => each?.isScheduled || each?.isInCollection);
  const isSelectedScheduled = schduledOrCollectionRules.some((each) => selectedRows.includes(each?._id));

  const isDiableDelete = userCreatedRules.some((each) => selectedRows.includes(each?._id)) && !isAdmin;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const deleteFiles = async () => {
    setLoadingDelete(true);
    try {
      await APIServices.ValidationTestDelete({ ids: selectedRows });
      setSelected([]);
      setOpenDialog(false);
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingDelete(false);
      fetchDataValidations();
    }
  };
  return (
    <>
      <Toolbar
        sx={{
          minHeight: { xs: 35 },
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          py: { xs: 0 },
          ...(selectedRows?.length > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}>
        {selectedRows?.length > 0 && (
          <>
            <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
              {selectedRows?.length} {t("Selected")}
            </Typography>
            {checkAccess("DataQualityRule", "Delete") && (
              <Tooltip
                arrow
                placement="top-end"
                title={t(
                  isDiableDelete
                    ? "Can't delete this rule. Not created by you."
                    : isSelectedScheduled
                    ? "Please deselect rules that are in collection or scheduled."
                    : "Delete",
                )}>
                <Box>
                  <IconButton
                    disabled={isDiableDelete || isSelectedScheduled}
                    size="small"
                    onClick={handleClickOpenDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </Toolbar>
      {openDialog && (
        <CustomDeleteModal
          loading={loadingDelete}
          disabled={loadingDelete}
          onClickDelete={deleteFiles}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};

const FileWatcherActionCells = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDetails, checkAccess } = useContext(UserDetailsContext);

  const [loading, setLoading] = useState(false);

  const row = props.data;

  const canEdit = row.UserId === userDetails.UserId || userDetails.RoleName === "Admin";

  const handleExecuteClick = () => {
    props.node.setRowSelectable(false);
    props.context.executeTest(row._id, props.node, setLoading);
  };

  const handleEditClick = () => {
    const { sourceConnectionId, _id: ruleId, TestName: ruleName, sourceFolderName, noColumns, sourcePath } = row;
    const tableNameParts = row.Tables?.[0]?.tablename?.split(".") || [];
    const [fileName, fileType] = tableNameParts.length === 2 ? tableNameParts : ["", ""];

    navigate(
      `create?connectionId=${sourceConnectionId}&ruleId=${ruleId}&ruleName=${ruleName}&folderName=${
        sourceFolderName || "/"
      }&fileName=${fileName}&fileType=${fileType}&activeStep=3&columnsCount=${noColumns}&saveToSource=${
        sourcePath || false
      }`,
    );
  };

  return (
    <Box display="flex" alignItems="center">
      {(row?.TestType === "Single File" || row?.TestType === "Single API") && (
        <CustomEditIcon
          sx={{ visibility: canEdit ? "visible" : "hidden" }}
          title={t("Edit")}
          onClick={handleEditClick}
          disabled={loading || props.data.isExecutionRunning > 0}
        />
      )}
      {checkAccess("DataQualityRule", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => props.context.previewConnection(row)} />
      )}
      {checkAccess("DataQualityRule", "Execute") && (
        <CustomExecuteIcon
          title={t("Execute")}
          onClick={handleExecuteClick}
          disabled={loading || props.data.isExecutionRunning > 0}
        />
      )}
      <CustomResultIcon title={t("Results")} onClick={() => props.context.handleOnClickResults(row._id, true)} />
    </Box>
  );
};

const FileWatcherTestList = () => {
  const gridRef = useRef();

  const scrollRef = useRef();

  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: "DESC",
    currentPage: 1,
    startDate: dateRangeObj.startDate,
    endDate: dateRangeObj.endDate,
  });
  const sortBy = searchParams.get("sortBy");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const currentPage = searchParams.get("currentPage");
  const ruleId = searchParams.get("ruleId");

  const previewId = searchParams.get("previewId");

  const [selected, setSelected] = useState([]);
  const [executions, setExecutions] = useState([]);
  const [totalExecutionsCount, setTotalExecutionsCount] = useState(0);

  const [resultsLoading, setResultsLoading] = useState(false);

  const executeTest = async (testId, rowNode, setLoading) => {
    setLoading(true);
    gridRef.current.api.deselectAll();

    setSnack({
      message: "DQ Rule Execution Started",
      open: true,
      colour: "success",
    });

    try {
      const response = await APIServices.ExcuteTestValidation(testId);
      const formattedDate = moment.utc(response.data.DateTime).local().format("MM/DD/YYYY h:mm:ss A");
      rowNode?.setDataValue("latestRuleExecution", formattedDate);
    } catch (error) {
      const errorMessage = error.response?.data?.message ?? error.message;
      setSnack({
        message: errorMessage,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
      rowNode?.setRowSelectable(true);
    }
  };

  const previewConnection = (row) => {
    gridRef.current.api.deselectAll();
    setSelected([]);
    setSearchParams({ previewId: row._id });
    autoScroll();
  };
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const getResultsData = async (rowId) => {
    setResultsLoading(true);
    try {
      const response = await APIServices.getTestExecutions({
        ruleId: [rowId || ruleId],
        sort: sortBy,
        pageSize: totalExecutionsPerPage,
        page: currentPage,
        startDate: formatDateString(new Date(startDate)),
        endDate: formatDateString(new Date(endDate)),
        isScheduler: false,
      });

      const data = response?.data;
      setExecutions(data?.results);
      setTotalExecutionsCount(data?.recordsCount);
      autoScroll();
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setResultsLoading(false);
  };
  const handleOnClickResults = (testId) => {
    setSearchParams({
      ruleId: testId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "grid",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    getResultsData(testId);
  };
  const refreshData = () => {
    if (gridRef) {
      gridRef?.current?.api?.refreshServerSide({ purge: true });
    }
  };
  const actionCell = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: FileWatcherActionCells,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
  };

  FileWatcherHeadCells[FileWatcherHeadCells.length - 1] = actionCell;

  return (
    <Box className="pt74">
      <Box className="createBtn">
        {selected?.length !== 0 && (
          <EnhancedTableToolbar
            selectedRows={selected}
            fetchDataValidations={refreshData}
            setSelected={setSelected}
            gridRef={gridRef}
          />
        )}
      </Box>
      <AgGridSSRM
        gridRef={gridRef}
        setSelected={setSelected}
        apiMethod={"getFileWatcherTestList"}
        headCells={FileWatcherHeadCells}
        context={{
          previewConnection: previewConnection,
          handleOnClickResults: handleOnClickResults,
          executeTest: executeTest,
        }}
      />

      <Box sx={{ mt: 1 }} ref={scrollRef}>
        {ruleId && (
          <FileWatcherTestResults
            resultsLoading={resultsLoading}
            executions={executions}
            setExecutions={setExecutions}
            totalexecutions={totalExecutionsCount}
            getResultsData={getResultsData}
            fetchDataValidations={refreshData}
          />
        )}

        {previewId && (
          <PreviewFileWatcher autoScroll={autoScroll} connection={previewId} returnVal={() => setSearchParams({})} />
        )}
      </Box>
    </Box>
  );
};

export default FileWatcherTestList;
