import { Box, Tooltip } from "@mui/material";
import * as React from "react";
import {
  customCellRenderNumberComparator,
  customComparator,
} from "../AgGrid/CustomSort";
import { formatDate } from "../../_helpers/utils";

export const collectionListHeadCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    filter: false,
    width: 50,
  },
  {
    headerName: "Name",
    field: "title",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Title",
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Description",
  },

  {
    headerName: "# DQRules",
    field: "TestNames",
    sortable: false,
    filter: false,
    cellRenderer: totalTests,
    comparator: customCellRenderNumberComparator,
    headerTooltip: "Total Tests",
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    sortable: true,
    filter: false,
    // suppressColumnsToolPanel: true,
    // filter: "agDateColumnFilter",
    cellRenderer: createdAt,
    headerTooltip: "Created Date",
  },
  {},
];

function createdAt(props) {
  return <>{formatDate(props.data.createdAt)}</>;
}

function totalTests(props) {
  return (
    <Tooltip
      title={
        <React.Fragment>
          {props.data.ValidatoinDetails.map((obj, index) => {
            return (
              <div key={index}>
                {index + 1}.{obj?.TestName}
              </div>
            );
          })}
        </React.Fragment>
      }
      placement="top-end"
      arrow
    >
      <Box>{props.data?.ruleIds?.length}</Box>
    </Tooltip>
  );
}
