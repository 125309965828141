import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Box, Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import { formatDate } from "../../_helpers/utils";

const DBScheduleSources = ({ tests }) => {
  return (
    <Grid container>
      <Grid item sm={12} md={12}>
        <Grid container gap={1}>
          <Grid item sm={3.9} pb={1} className="noborder-container left-border">
            <Box className="DQhead">
              <Typography variant="h6">Scheduled Rule Sets</Typography>
            </Box>
            <Box className="STresult">
              <Grid container alignItems="center">
                <Grid md={7} item sx={{ borderRight: "1px solid #ccc" }}>
                  <Box className="rBox">
                    {tests?.scheduledTestSets?.map((item, i) => {
                      return (
                        <Typography key={i}>
                          {item.Title} - {formatDate(item.FromDate)}
                        </Typography>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid md={5} item textAlign="center">
                  <Box sx={{ py: 1, px: 2 }}>
                    <Box className="Rsec">
                      <Link to="/ScheduledTest/list" style={{ textDecoration: "none" }}>
                        <Typography variant="h5">
                          <AccessTimeOutlinedIcon />
                          <b>{tests?.scheduledTestSets?.length}</b>
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={8} className="noborder-container right-border">
            <Box className="DQhead v-center">
              <Typography variant="h6">
                Connections <b>{tests?.connections?.totalConnections}</b>
              </Typography>
            </Box>
            <Grid container>
              <Grid item md={6}>
                <Box className="STresult">
                  <Grid container alignItems="center">
                    <Grid md={12} item textAlign="center" sx={{ borderRight: "1px solid #ccc" }}>
                      <Box className="Rsec" mt="16px">
                        <Typography variant="h5">
                          <ShareOutlinedIcon /> Database
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid className="bBox" md={12} item>
                      <Typography>
                        Snowflake <br />
                        <b>{tests?.connections?.snowflake}</b>
                      </Typography>
                      <Typography>
                        SQL <br />
                        <b>{tests?.connections?.sql}</b>
                      </Typography>
                      <Typography>
                        My SQL <br />
                        <b>{tests?.connections?.mysql}</b>
                      </Typography>
                      <Typography>
                        Postgrel SQL <br />
                        <b>{tests?.connections?.PostgreSQL}</b>
                      </Typography>
                      <Typography>
                        Data Bricks <br />
                        <b>{tests?.connections?.Databricks}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className="STresult">
                  <Grid container mb="16px" alignItems="center">
                    <Grid item sm={12} md={6}></Grid>
                    <Grid item sm={12} md={6} textAlign="right"></Grid>
                  </Grid>

                  <Grid container alignItems="center">
                    <Grid md={12} item textAlign="center">
                      <Box className="Rsec">
                        <Typography variant="h5">
                          <ArticleOutlinedIcon />
                          File
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid md={12} item>
                      <Box className="bBox">
                        <Typography>
                          CSV <br />
                          <b>{tests?.connections?.csv}</b>
                        </Typography>
                        <Typography>
                          XLSX <br />
                          <b>{tests?.connections?.xlsx}</b>
                        </Typography>
                        <Typography>
                          Parquet <br />
                          <b>{tests?.connections?.parquet}</b>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DBScheduleSources;
