import { Box, Divider, Drawer, Typography } from "@mui/material";

const CustomDrawer = ({ onClose, children, title }) => {
  return (
    <Drawer anchor="right" open={true} onClose={onClose}>
      <Box sx={{ width: 500 }}>
        {title && (
          <Box style={{ position: "sticky", top: 0, zIndex: 2 }}>
            <Typography sx={{ px: 2, pt: 1 }} variant="h6">
              {title} :
            </Typography>
            <Box boxShadow={3} mt={2}>
              <Divider />
            </Box>
          </Box>
        )}
        <Box p={2}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
