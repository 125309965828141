import { useEffect, useRef } from "react";
import * as d3 from "d3";

const BarGraph = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    const margin = { top: 40, right: 20, bottom: 70, left: 70 },
      width = 960 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    const x = d3
      .scaleBand()
      .range([0, width])
      .padding(0.1)
      .domain(data?.map((d) => d?.date)); // Use the 'date' property for x-axis

    const y = d3.scaleLinear().range([height, 0]);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    data.forEach((d) => {
      d.rows = +d.rows;
    });

    y.domain([0, d3.max(data, (d) => d.rows)]);

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.date)) // Use the 'date' property for x-axis
      .attr("width", x.bandwidth())
      .attr("y", (d) => y(d.rows))
      .attr("height", (d) => height - y(d.rows))
      .attr("fill", "#7205f7") // Updated bar color
      .transition()
      .duration(1000)
      .delay((d, i) => i * 100) // Delay for a smoother transition
      .attr("height", (d) => height - y(d.rows));

    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)")
      .attr("font-size", "12px"); // Adjusted font size

    svg.append("g").call(d3.axisLeft(y));

    // X-axis label
    // svg
    //   .append("text")
    //   .attr("transform", `translate(${width / 2},${height + margin.top + 20})`)
    //   .style("text-anchor", "middle")
    //   .text("Date") // Updated x-axis label
    //   .attr("font-size", "14px"); // Adjusted font size

    // Y-axis label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Rows Count")
      .attr("font-size", "14px"); // Adjusted font size
  }, []);

  return <div className="chart-container" ref={chartRef}></div>;
};

export default BarGraph;
