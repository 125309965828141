import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import JoinInnerOutlinedIcon from "@mui/icons-material/JoinInnerOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import ApiService from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
const ExecutionDetailItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="detailTitle">
      {t(title)} :{" "}
      <Typography component="span" variant="detailValue">
        {value}
      </Typography>
    </Typography>
  );
};

const ComparisionResultTypeCard = ({ title, value, loading, icon, onClick }) => (
  <Grid className="comparisionResultType" item sm onClick={onClick}>
    <Paper sx={{ height: "100%" }} className="tile">
      {icon}
      <Typography>
        {title} <br />
        <span>{value}</span>
      </Typography>
      {loading && (
        <IconButton aria-label="loading" size="small">
          <CircularProgress size={20} color="primary" />
        </IconButton>
      )}
      <IconButton size="small">
        <ChevronRightOutlinedIcon />
      </IconButton>
    </Paper>
  </Grid>
);
export default function ComparativeValidationResult({
  resultValidation,
  item,
  loadingType = {},
  getTableData,
  validationDetailsRowComparison,
}) {
  const { setSnack } = useContext(SnackbarContext);

  const { t } = useTranslation();
  const [comparisonResults, setComparisonResults] = useState([]);
  const [loadRecord, setLoadRecord] = useState(false);

  const getChecks = async () => {
    setLoadRecord(true);
    try {
      const response = await ApiService.ValidationChecksByResultId({
        validationResultId: item?._id,
      });
      setComparisonResults(response.data.checks);
      setLoadRecord(false);
    } catch (e) {
      setLoadRecord(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    getChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?._id]);

  return (
    <TableCell sx={{ bgcolor: "#eee" }} className="comparativeValRes" colSpan="6">
      <Grid container className="dataSourceDetails">
        <Grid item md={6} sx={{ borderRight: "1px solid #fff", px: 1, py: 0.5 }}>
          <Typography className="dataSouceHead">{t("1st Data Source")}</Typography>
          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={resultValidation?.validationDetails?.FirstDatasourceType} />

            <ExecutionDetailItem title="Name" value={resultValidation?.validationDetails?.FirstDatasourceName} />
          </Box>
        </Grid>
        <Grid item md={6} px={1} py={0.5}>
          <Typography className="dataSouceHead">{t("2nd Data Source")}</Typography>

          <Box className="v-center" gap={2}>
            <ExecutionDetailItem title="Type" value={resultValidation?.validationDetails?.SecondDatasourceType} />

            <ExecutionDetailItem title="Name" value={resultValidation?.validationDetails?.SecondDatasourceName} />
          </Box>
        </Grid>
      </Grid>
      {loadRecord && <SkeletonLoader />}
      {comparisonResults?.map((itemVal, index) => {
        const indexKey = index;
        return (
          <Box key={indexKey}>
            <Grid container>
              <Grid item md={6} className="tableDetails">
                {validationDetailsRowComparison[index]?.SqlQuery ? (
                  <Typography>
                    {t("Query")} :{validationDetailsRowComparison[index]?.SqlQuery?.FirstDataSource}
                  </Typography>
                ) : (
                  <Typography>
                    <b>
                      {resultValidation?.validationDetails?.FirstDatasourceType === "File" ? t("File") : t("Table")} :{" "}
                    </b>
                    {itemVal?.ValidationProperties.length > 0 && itemVal?.ValidationProperties[0]?.FirstDataSourceTable}{" "}
                    | <b>{t("Column")} : </b>
                    {itemVal?.FirstDataSourceSelectedColumns?.join(", ")} | <b>{t("Row Count")} : </b>
                    {itemVal?.RowCountResult?.FirstDataSource}
                  </Typography>
                )}
              </Grid>

              <Grid className="tableDetails" item md={6}>
                {validationDetailsRowComparison[0]?.SqlQuery ? (
                  <Typography>
                    {t("Query")} :{validationDetailsRowComparison[index]?.SqlQuery.SecondDataSource}
                  </Typography>
                ) : (
                  <Typography>
                    <b>
                      {resultValidation?.validationDetails?.SecondDatasourceType === "File" ? t("File") : t("Table")} :{" "}
                    </b>
                    {itemVal?.ValidationProperties.length > 0 &&
                      itemVal?.ValidationProperties[0]?.SecondDataSourceTable}
                    | <b>{t("Column")} : </b>
                    {itemVal?.SecondDataSourceSelectedColumns?.join(", ")} | <b>{t("Row Count")} : </b>
                    {itemVal?.RowCountResult?.SecondDataSource}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Box sx={{ p: 1 }}>
              <Grid container spacing={1}>
                <ComparisionResultTypeCard
                  icon={<JoinInnerOutlinedIcon />}
                  title={t("Matched")}
                  value={itemVal?.RowComparisonResult?.MatchedFirstDatasourceCount}
                  loading={loadingType?.type === "Matched" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "Matched")}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 1st Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInFirstDatasourceCount}
                  loading={loadingType?.type === "OnlyInFirstDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "OnlyInFirstDatasource")}
                />

                <ComparisionResultTypeCard
                  icon={<ArticleOutlinedIcon />}
                  title={t("Only in 2nd Source")}
                  value={itemVal?.RowComparisonResult?.OnlyInSecondDatasourceCount}
                  loading={loadingType?.type === "OnlyInSecondDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "OnlyInSecondDatasource")}
                />

                <ComparisionResultTypeCard
                  icon={<ContentCopyOutlinedIcon />}
                  title={t("Duplicate in 1st Source")}
                  value={itemVal?.RowComparisonResult?.DuplicatesOfFirstDatasourceCount}
                  loading={loadingType?.type === "DuplicatesOfFirstDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "DuplicatesOfFirstDatasource")}
                />

                <ComparisionResultTypeCard
                  icon={<ContentCopyOutlinedIcon />}
                  title={t("Duplicate in 2nd Source")}
                  value={itemVal?.RowComparisonResult?.DuplicatesOfSecondDatasourceCount}
                  loading={loadingType?.type === "DuplicatesOfSecondDatasource" && loadingType?.id === itemVal?._id}
                  onClick={() => getTableData(itemVal, "DuplicatesOfSecondDatasource")}
                />
              </Grid>
            </Box>
          </Box>
        );
      })}
    </TableCell>
  );
}
