import { Grid, Typography } from "@mui/material";
import { DataProfilingListItem, roundToX } from "../../ProfillingListItems";

export function VariableStatistics({ data }) {
  let MonotonicityValue = "Not monotonic";

  if (data?.monotonic_increase && data?.monotonic_increase_strict) {
    MonotonicityValue = "Strictly increasing";
  } else if (data?.monotonic_decrease && data?.monotonic_decrease_strict) {
    MonotonicityValue = "Strictly decreasing";
  }
  return (
    <Grid item container>
      <Grid xs={12} sm={6} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>
          Quantile statistics
        </Typography>
        <DataProfilingListItem title="Minimum" value={data?.min} />
        <DataProfilingListItem
          title="5-th percentile"
          value={roundToX(data["5%"], 2)}
        />
        <DataProfilingListItem title="Q1" value={roundToX(data["25%"], 2)} />
        <DataProfilingListItem
          title="median"
          value={roundToX(data["50%"], 2)}
        />
        <DataProfilingListItem title="Q3" value={roundToX(data["75%"], 2)} />
        <DataProfilingListItem
          title="95-th percentile"
          value={roundToX(data["95%"], 2)}
        />
        <DataProfilingListItem title="Maximum" value={data?.max} />
        <DataProfilingListItem title="Range" value={roundToX(data?.range, 2)} />
        <DataProfilingListItem
          title="Interquartile range (IQR)"
          value={roundToX(data?.iqr, 2)}
        />
      </Grid>
      <Grid xs={12} sm={6} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>
          Descriptive statistics
        </Typography>
        <DataProfilingListItem title="Standard deviation" value={data?.std} />
        <DataProfilingListItem
          title="Coefficient of variation (CV)"
          value={data?.cv}
        />
        <DataProfilingListItem title="Kurtosis" value={data?.kurtosis} />
        <DataProfilingListItem title="Mean" value={data?.mean} />
        <DataProfilingListItem
          title="Median Absolute Deviation (MAD)"
          value={roundToX(data?.mad, 2)}
        />
        <DataProfilingListItem title="Skewness" value={data?.skewness} />
        <DataProfilingListItem title="Sum" value={data?.sum} />
        <DataProfilingListItem title="Variance" value={data?.variance} />
        <DataProfilingListItem title="Monotonicity" value={MonotonicityValue} />
      </Grid>
    </Grid>
  );
}

export function VariableOverview({ data }) {
  return (
    <Grid item container>
      <Grid xs={12} sm={3} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>Length</Typography>
        <DataProfilingListItem title="Max length" value={data?.max_length} />
        <DataProfilingListItem
          title="Median length"
          value={data?.median_length}
        />
        <DataProfilingListItem title="Mean length" value={data?.mean_length} />
        <DataProfilingListItem title="Min length" value={data?.min_length} />
      </Grid>
      <Grid xs={12} sm={3} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>
          Characters and Unicode
        </Typography>
        <DataProfilingListItem
          title="Total characters"
          value={data?.n_characters}
        />
        <DataProfilingListItem
          title="Distinct characters"
          value={data?.n_characters_distinct}
        />
        <DataProfilingListItem
          title="Distinct categories"
          value={data?.n_category}
        />
        <DataProfilingListItem
          title="Distinct scripts"
          value={data?.n_scripts}
        />
        <DataProfilingListItem
          title="Distinct blocks"
          value={data?.n_block_alias}
        />
        {/* <Typography sx={{marginTop: 2, color: "#7A86A1"}}>
          The Unicode Standard assigns character properties to each code point,
          which can be used to analyse textual variables.
        </Typography> */}
      </Grid>
      <Grid xs={12} sm={3} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>Unique</Typography>
        <DataProfilingListItem title="Unique" value={data?.n_unique} />
        <DataProfilingListItem
          title="Unique (%)"
          value={roundToX(data?.p_unique, 1) + "%"}
        />
      </Grid>
      <Grid xs={12} sm={3} item p={2}>
        <Typography sx={{ fontSize: "18px", mb: 1 }}>Sample</Typography>
        <DataProfilingListItem title="1st row" value={data?.first_rows?.[0]} />
        <DataProfilingListItem title="2nd row" value={data?.first_rows?.[1]} />
        <DataProfilingListItem title="3rd row" value={data?.first_rows?.[2]} />
        <DataProfilingListItem title="4th row" value={data?.first_rows?.[3]} />
        <DataProfilingListItem title="5th row" value={data?.first_rows?.[4]} />
      </Grid>
    </Grid>
  );
}
