import { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import ApiService from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { formatDateLocale } from "../../../_helpers/utils";
import { ScheduleFrequency } from "../../../_helpers/Constant";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";

const TestScheduler = ({ selectedRules, setSchduleTestOpen, scheduleData, scheduledId, onSuccessCreateScheduled }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const ScrollRef = useRef();
  const watchFields = watch();
  const { setSnack } = useContext(SnackbarContext);
  const [selectedValue, setSelectedValue] = useState([]);
  const [dropDown, setDropDown] = useState([]);
  const [visualTests, setVisualTests] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [createAsNewLoading, setCreateAsNewLoading] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState(moment(scheduleData?.FromDate).format("YYYY-MM-DDTHH:mm"));
  const [selectedToDate, setSelectedToDate] = useState(moment(scheduleData?.ToDate).format("YYYY-MM-DDTHH:mm"));
  const minFromDate = moment(scheduleData?.FromDate).format("YYYY-MM-DDTHH:mm");
  const [minToDate, setMinToDate] = useState(moment(scheduleData?.ToDate).format("YYYY-MM-DDTHH:mm"));

  useEffect(() => {
    setMinToDate(calculateMinToDate(selectedFromDate, scheduleData?.Each, scheduleData?.Frequency));
  }, [selectedFromDate, scheduleData]);

  const handleDateChange = (event, mindate, setSelected) => {
    const selectedValue = moment(event.target.value).format("YYYY-MM-DDTHH:mm");

    if (selectedValue >= mindate) {
      setSelected(selectedValue);
    } else {
      setSelected(mindate);
    }
  };

  const isRepeatType = watch("RepeatType");
  useEffect(() => {
    setSelectedValue([]);
  }, [watchFields?.TestType]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getValidationList();
        setDropDown(response.data?.validationTests);
        setVisualTests(response.data?.visualTests);
        if (selectedRules?.length > 0) {
          const selectedTests =
            scheduleData?.TestType === "Validation Test" ? response.data?.validationTests : response.data?.visualTests;
          const filteredData = selectedRules.map((element) => selectedTests.find((obj) => obj._id === element));
          setSelectedValue(filteredData);
        }
      } catch (e) {
        handleApiError(e);
      }
    };
    fetchData();
  }, [selectedRules, scheduleData?.TestType]);
  const handleApiError = (e) => {
    const errorMessage = e?.response?.data?.message ?? e.message;
    setSnack({
      message: errorMessage,
      open: true,
      colour: "error",
    });
  };
  const formatData = (data) => {
    const res = {
      Title: data.Title,
      RepeatType: isRepeatType,
      Description: data.Description,
      FromDate: moment.utc(moment(data.FromDate)).format(),
      ExecuteParallel: scheduleData?.ExecuteParallel,
      TestType: data.TestType,
    };
    if (data.TestType === "Validation Test") {
      res["TestIds"] = selectedValue;
    } else {
      res["VisuvalTestIds"] = selectedValue;
    }
    if (isRepeatType) {
      res["Frequency"] = data.Frequency;
      res["Each"] = Number(data.Each);
      res["ToDate"] = moment.utc(moment(data.ToDate)).format();
    } else {
      res["Frequency"] = "";
      res["Each"] = "";
      res["ToDate"] = "";
    }
    return res;
  };
  const scheduleTest = async (data) => {
    if (selectedValue.length === 0) {
      setSnack({
        message: "Select at least one test",
        open: true,
        colour: "error",
      });
      return;
    }
    setLoadingSubmit(true);
    const fData = formatData(data);
    try {
      if (scheduledId) {
        await ApiService.updateScheduledTestData(scheduledId, fData);
      } else {
        await ApiService.createScheduleRun(fData);
      }
      onSuccessCreateScheduled();
      setSnack({
        message: scheduledId ? "Schedule DQ Rule Updated" : "Schedule DQ Rule Created",
        open: true,
        colour: "success",
      });
    } catch (e) {
      const responseData = e?.response?.data;

      if (responseData && typeof responseData === "object") {
        const keyNames = Object.keys(responseData);
        const values = Object.values(responseData);

        if (e?.response?.status === 422) {
          keyNames.forEach((key, index) => {
            setError(key, {
              type: "custom",
              message: values[index]?.message || "An error occurred",
            });
          });
        } else {
          handleApiError(e);
        }
      } else {
        handleApiError(e);
      }

      scrollToTop();
    }
    setLoadingSubmit(false);
  };
  const createAsNewTest = async (data) => {
    if (selectedValue.length === 0) {
      setSnack({
        message: "Select at least one test",
        open: true,
        colour: "error",
      });
      return;
    }
    setCreateAsNewLoading(true);
    try {
      const fData = formatData(data);
      await ApiService.createScheduleRun(fData);
      onSuccessCreateScheduled();
      setSnack({
        message: "Schedule DQ Rule Created",
        open: true,
        colour: "success",
      });
    } catch (e) {
      const keyNames = Object.keys(e.response.data);
      const values = Object.values(e.response.data);
      e?.response?.status === 422
        ? keyNames?.map((each, index) =>
            setError(each, {
              type: "custom",
              message: values[index]?.message,
            }),
          )
        : handleApiError(e);
      scrollToTop();
    }
    setCreateAsNewLoading(false);
  };
  const scrollToTop = () => {
    setTimeout(() => {
      ScrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 400);
  };
  const onChangeSelectedValue = (newValue) => {
    newValue && setSelectedValue(newValue);
  };

  const handleInputEach = (e) => {
    const value = parseInt(e.target.value);
    if (value <= 0) {
      setValue("Each", 1);
    } else {
      setValue("Each", value);
    }
    setMinToDate(calculateMinToDate(selectedFromDate, value || 1, watch("Frequency")));
  };

  const handleSelectFrequency = (e) => {
    setValue("Frequency", e.target.value);
    setMinToDate(calculateMinToDate(selectedFromDate, watch("Each"), e.target.value));
  };

  const calculateMinToDate = (FromDate, each, frequency) => {
    const Each = parseInt(each);
    const Frequency = parseInt(frequency);

    const date = new Date(FromDate);
    const toDate = new Date(date);

    switch (Frequency) {
      case 1:
        toDate.setMinutes(toDate.getMinutes() + Each);
        break;
      case 2:
        toDate.setDate(toDate.getDate() + Each);
        break;
      case 3:
        toDate.setDate(toDate.getDate() + Each * 7);
        break;
      case 4:
        toDate.setMonth(toDate.getMonth() + Each);
        break;
      default:
        break;
    }

    const toDateISOString = moment(toDate).format("YYYY-MM-DDTHH:mm");
    if (selectedToDate < toDateISOString || selectedToDate === "Invalid date") {
      setSelectedToDate(toDateISOString);
    }

    return toDateISOString;
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid item container spacing={2} ref={ScrollRef}>
        <Grid sm={12} item>
          <Controller
            control={control}
            name="Title"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                name="Title"
                label={t("Name")}
                multiline
                variant="outlined"
                defaultValue={scheduleData?.Title}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: t("Minimum length 4 char"),
                  },
                  maxLength: {
                    value: 32,
                    message: t("Maximum length 32 char"),
                  },
                }}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
                  field.onChange(cleanedValue);
                }}
                helperText={errors?.Title?.message}
                error={errors?.Title}
              />
            )}
          />
        </Grid>
        <Grid sm={12} item>
          <Controller
            control={control}
            name="Description"
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                name="Description"
                label={t("Description")}
                minRows={2}
                maxRows={4}
                multiline
                variant="outlined"
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
                  field.onChange(cleanedValue);
                }}
                defaultValue={scheduleData?.Description}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: t("Minimum length 4 char"),
                  },
                  maxLength: {
                    value: 250,
                    message: t("Maximum length 250 char"),
                  },
                }}
                helperText={errors?.Description?.message}
                error={errors?.Description}
              />
            )}
          />
        </Grid>
        <Grid sm={12} item>
          <TextField
            select
            fullWidth
            label={t("DQ Rule Type")}
            size="small"
            defaultValue={scheduleData?.TestType || "Validation Test"}
            {...register("TestType", { required: true })}
            error={errors?.TestType}>
            <MenuItem value={"Validation Test"}>{t("Data Quality Rules")}</MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={12} sx={{ m: "auto" }}>
          <CustomAutoComplete
            multiple
            limitTags={1}
            options={watchFields?.TestType === "Validation Test" ? dropDown : visualTests}
            value={selectedValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.TestName}
            onChange={(event, newValue) => onChangeSelectedValue(newValue)}
            // loading={loadingTables}
            shouldDisableCheckbox={(option) => selectedValue.length >= 5 && !selectedValue.includes(option)}
            isOptionEqualToValue={(option, value) => option === value}
            label={
              watchFields?.TestType === "Validation Test" ? t("Select Data Quality Rules") : t("Select Visual Test")
            }
            placeholder={
              watchFields?.TestType === "Validation Test" ? t("Select Data Quality Rules") : t("Visual Test")
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            fullWidth
            size="small"
            name="FromDate"
            inputProps={{
              min: minFromDate,
            }}
            value={selectedFromDate}
            id="datetime-local"
            label={t("Schedule Date & Time")}
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            {...register("FromDate", { required: true })}
            onChange={(e) => {
              handleDateChange(e, minFromDate, setSelectedFromDate);
            }}
            error={errors?.FromDate ? true : false}
          />
        </Grid>
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Switch
                name="RepeatType"
                checked={isRepeatType || scheduleData?.RepeatType || false}
                {...register("RepeatType")}
              />
            }
            label={t("Repeat")}
          />
        </Grid>
        {isRepeatType && (
          <>
            <Grid item sm={6} className="v-center">
              <FormLabel sx={{ mr: "10px", ml: "2px" }}>Every</FormLabel>
              <TextField
                type="number"
                size="small"
                style={{ width: "120px" }}
                InputLabelProps={{
                  shrink: true,
                  min: 1,
                }}
                defaultValue={scheduleData?.Each || ""}
                {...register("Each", {
                  required: true,
                })}
                onChange={handleInputEach}
                error={errors?.Each}
              />
              <FormControl size="small" fullWidth sx={{ display: "inline-flex", ml: "10px" }}>
                <Select
                  defaultValue={scheduleData?.Frequency}
                  {...register("Frequency", { required: true })}
                  onChange={handleSelectFrequency}
                  error={errors?.Frequency}>
                  {Object.entries(ScheduleFrequency).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                size="small"
                name="ToDate"
                inputProps={{
                  min: minToDate,
                }}
                value={selectedToDate}
                id="datetime-local"
                label={t("End Date & Time")}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("ToDate", { required: true })}
                onChange={(e) => {
                  handleDateChange(e, minToDate, setSelectedToDate);
                }}
                error={errors?.FromDate ? true : false}
              />
            </Grid>

            {errors?.Each && (
              <Typography className="errorText" sx={{ ml: "20px", mt: "2px" }}>
                {errors?.Each.message}
              </Typography>
            )}

            <Grid item sm={12} className="center info-container" sx={{ mt: "18px", ml: "18px", p: "5px", pr: "10px" }}>
              <Typography className="bold-text italic-text word-spacing">
                Start on {formatDateLocale(selectedFromDate)}, repeats every {watchFields?.Each}{" "}
                {ScheduleFrequency[watchFields?.Frequency]}, and end on {formatDateLocale(selectedToDate)}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item sm={12}>
          <Typography>
            <strong> {t("Note")} : </strong>
            {t("scheduleNote")}
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 2,
          textAlign: "center",
          "& .MuiButton-root": {
            "&:nth-of-type(1)": { mr: 1 },
          },
        }}>
        <Grid container sx={{ m: 1 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4} textAlign="left">
            <Button variant="outlined" onClick={() => setSchduleTestOpen(false)} color="error" size="small">
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="center">
            {scheduledId && (
              <CustomLoadingButton
                disabled={createAsNewLoading || loadingSubmit}
                onClick={handleSubmit(createAsNewTest)}
                loading={createAsNewLoading}>
                {t("Save As New")}
              </CustomLoadingButton>
            )}
          </Grid>
          <Grid item xs={4} textAlign="right">
            <CustomLoadingButton
              disabled={createAsNewLoading || loadingSubmit}
              sx={{ mr: 1 }}
              onClick={handleSubmit(scheduleTest)}
              loading={loadingSubmit}>
              {t("Save")}
            </CustomLoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default TestScheduler;
