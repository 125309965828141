import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Tooltip,
  Typography,
  TableContainer,
  IconButton,
  TableRow,
  TableHead,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  Button,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ViewTable = ({ TableRowsColumns, indexKey }) => {
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const viewTableId = open ? "simple-popover" : undefined;
  let TableRowkeys = [];
  if (TableRowsColumns[0]?.Columns?.length > 0) {
    TableRowkeys = TableRowsColumns[0]?.Columns?.map(
      (colItem) => colItem.COLUMN_NAME
    );
  }
  const onMouseLeave = (e) => {
    e.stopPropagation();
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };
  const onMouseEnter = (event, key) => {
    event.stopPropagation();
    setOpenedPopoverId(key);
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Button
        aria-describedby={viewTableId}
        variant="contained"
        onClick={(e) => onMouseEnter(e, indexKey)}
        startIcon={<RemoveRedEyeOutlinedIcon />}
      >
        {"View Table"}
      </Button>
      <Popover
        id={viewTableId}
        anchorEl={anchorEl}
        onClose={onMouseLeave}
        open={openedPopoverId === indexKey}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <IconButton
            onClick={onMouseLeave}
            color="error"
            className="viewTableCloseIcon"
          >
            <CancelOutlinedIcon />
          </IconButton>
          <TableContainer sx={{ maxWidth: 650, maxHeight: 300 }}>
            <Table sx={{ minWidth: 650 }} onClick={(e) => e.stopPropagation()}>
              <TableHead>
                <TableRow>
                  {TableRowsColumns[0].Columns?.map((v, i) => (
                    <TableCell key={i}>{v.COLUMN_NAME}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {TableRowsColumns?.[0]?.rows?.map((itemRow, ki) => (
                  <TableRow key={ki}>
                    {TableRowkeys?.map((v, i) => (
                      <TableCell key={i}>{itemRow[v]}</TableCell>
                    ))}
                  </TableRow>
                ))}
                {TableRowsColumns[0]?.rows?.length === 0 && (
                  <TableRow>
                    <TableCell
                      sx={{ textAlign: "center" }}
                      colSpan={TableRowsColumns[0].columnCount}
                    >
                      No records to display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Popover>{" "}
    </>
  );
};

const TableDetails = ({ tableColumns, tableData, selectTableColumns }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState([]);

  const collapseExpand = (panel) => {
    let array = [...collapse];
    var index = array.findIndex((obj) => obj === panel);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(panel);
    }
    setCollapse(array);
  };
  const checkCollapse = (panel) => {
    let array = [...collapse];
    return array.includes(panel);
  };

  const isChecked = (colName) => {
    const isTrue = tableColumns?.find((each) =>
      each.columns?.includes(colName)
    );
    return isTrue ? true : false;
  };

  return (
    <>
      {tableData?.map((item, key) => {
        let TableNames = Object.keys(item);
        let TableRowsColumns = Object.values(item);

        return (
          <Accordion
            key={key}
            elevation={0}
            className="tableListContainer"
            expanded={checkCollapse("panel" + key)}
            onChange={() => collapseExpand("panel" + key)}
          >
            <AccordionSummary
              className="tableDetails"
              expandIcon={
                <Box className="accordionArrow">
                  <KeyboardArrowDownOutlinedIcon />
                </Box>
              }
            >
              <Grid container alignItems="center">
                <Grid item sm={7}>
                  <Box className="v-center" gap={2}>
                    <Tooltip
                      className="ellipsisValidation"
                      title={<b>{TableNames[0]}</b>}
                      placement="top-end"
                    >
                      <Typography variant="detailTitle">
                        {TableNames[0]}
                      </Typography>
                    </Tooltip>
                    <Typography variant="bold" color={"primary"}>
                      {t("Rows")} : {TableRowsColumns[0].rowsCount}
                    </Typography>
                    <Typography variant="bold" color="warning.main">
                      {t("Columns")} : {TableRowsColumns[0].columnCount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3}></Grid>
                <Grid item sm={2}>
                  <ViewTable
                    TableRowsColumns={TableRowsColumns}
                    indexKey={key}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 1 }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}></TableCell>
                      <TableCell>Column Name </TableCell>
                      <TableCell>Data Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TableRowsColumns[0]?.Columns?.map((row) => {
                      return (
                        <TableRow key={row.COLUMN_NAME}>
                          <TableCell>
                            <Checkbox
                              onClick={(e) =>
                                selectTableColumns(
                                  e,
                                  TableNames[0],
                                  row.COLUMN_NAME
                                )
                              }
                              checked={isChecked(row.COLUMN_NAME)}
                              sx={{ p: 0 }}
                            />
                          </TableCell>
                          <TableCell> {row.COLUMN_NAME} </TableCell>
                          <TableCell>{row.DATA_TYPE}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default TableDetails;
