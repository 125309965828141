import { Card, CardActionArea, Typography } from "@mui/material";

const CreateDqRuleCard = ({ item }) => {
  return (
    <Card
      className="subcontainer"
      sx={{
        opacity: item?.title === "API" ? 0.5 : 1,
        width: "88px",
        pointerEvents: item?.title === "API" ? "none" : "auto", // Disable pointer events for "API" title
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img height="60px" src={item.image} alt={item.title} />

        <Typography>{item.title}</Typography>
      </CardActionArea>
    </Card>
  );
};

export default CreateDqRuleCard;
