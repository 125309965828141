import { Button, IconButton, Tooltip, CircularProgress } from "@mui/material";
import {
  InfoOutlined as InfoOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  DoDisturbAltOutlined as DoDisturbAltOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
export const TileAbortIcon = ({ onClick }) => (
  <Button
    sx={{ position: "absolute", bottom: 0, right: "-0.5rem" }}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    startIcon={
      <DoDisturbAltOutlinedIcon
        fontSize="12px"
        fontWeight="bold"
        color="error"
      />
    }
    size="small"
    variant="outlined"
    color="error"
  >
    Abort
  </Button>
);

export const TileInfoIcon = ({ title }) => (
  <IconButton
    sx={{ position: "absolute", top: 0, right: "-16px" }}
    color="primary"
    size="small"
  >
    <Tooltip arrow placement="bottom-end" title={title}>
      <InfoOutlinedIcon color="primary" />
    </Tooltip>
  </IconButton>
);

export const TilePreviewIcon = ({ onClick, id }) => (
  <IconButton
    onClick={onClick}
    sx={{ p: 0 }}
    size="small"
    aria-describedby={id}
    title="preview"
  >
    <VisibilityOutlinedIcon
      fontSize="small"
      color="primary"
      sx={{ fontSize: "0.8rem", marginLeft: "4px" }}
    />
  </IconButton>
);

export const TileDownLoadIcon = ({ loading, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: "absolute",
      top: 0,
      right: 0,
    }}
    color="primary"
    size="small"
  >
    <Tooltip arrow placement="top-end" title={"Download pdf"}>
      {loading ? (
        <FileDownloadOutlinedIcon color="primary" />
      ) : (
        <CircularProgress size={20} color="primary" />
      )}
    </Tooltip>
  </IconButton>
);
