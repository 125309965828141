import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import { Box, Card, IconButton, Modal } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  bgcolor: "background.paper",
  borderTop: "10px solid #E69434",
  boxShadow: 24,
  borderRadius: "4px",
  maxHeight: "97vh",
};

export function ModalPreview({
  label,
  loader,
  disable,
  Component,
  open,
  handleClose,
  handleOpen,
}) {
  return (
    <React.Fragment key={"right"}>
      {label && (
        <LoadingButton
          className="PreviewButton"
          size="small"
          variant="contained"
          onClick={handleOpen}
          disabled={disable}
          loading={loader}
          startIcon={<></>}
          loadingPosition="start"
        >
          {label}
        </LoadingButton>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card>
          <Box sx={style}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              color="error"
              aria-label="close popover"
              sx={{
                position: "absolute",
                right: -10,
                top: -20,
                background: "#fff",
                p: "2px",
                ":hover": { background: "#f5f5f5" },
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
            {Component}
          </Box>
        </Card>
      </Modal>
    </React.Fragment>
  );
}
