import { useContext, useState } from "react";
import { Box, SwipeableDrawer, IconButton, Grid } from "@mui/material";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import CustomGradientButton from "../CustomComponents/CustomsButtons/CustomGradientButton";
import CleanDataConnectionDetails from "./CleanDataConnectionDetails";
import FixedReplace from "./FixedReplace";
import FuzzyReplace from "./FuzzyReplace";
import MultipleDropDownData from "../DataProfiling/MultipleDropDownData";
import ApiService from "../../services/app.service";
import { FuzzyAlgorithms } from "./CleaningAlgorithms";

const DataCleansingContainer = ({ closeCleanData, columnsLoading = false, Connectiondetails, columns }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams] = useSearchParams();
  const DSType = searchParams.get("DSType");
  const tableName = searchParams.get("tableName");

  const [columnTypes, setColumnTypes] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [selectedTab, setSelectedTab] = useState("fuzzyreplace");
  const [showAlgo, setShowAlgo] = useState(false);
  const [algorithms, setAlgorithms] = useState([]);

  const handleChange = (e) => {
    setSelectedTab(e.target.name);
    setShowAlgo(false);
    if (!columnTypes.length) {
      fetchColumnTypes();
    }
  };

  const toggleDrawer = (e) => {
    e.stopPropagation();
    closeCleanData();
  };

  const fetchColumnTypes = async () => {
    setLoadingColumns(true);
    try {
      const data = {
        connectionId: Connectiondetails?.id,
        TableName: tableName,
        OperationName: "dtype",
      };
      const res = await ApiService.dataCleaningdtype(data);
      const columnData = res?.data?.dtype?.Data ?? [];
      const intCols = columnData
        .filter((col) => col?.DataTypeAfter?.includes("int") || col?.DataTypeAfter?.includes("float"))
        .map((col) => col.ColumnName);

      setColumnTypes(intCols);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingColumns(false);
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={true}
      // onClose={toggleDrawer}
      onClick={(e) => e.stopPropagation()}
      onOpen={() => null}>
      <Box sx={{ width: 500 }} px={2}>
        <Box className="v-center" gap={2} py={2}>
          <CustomGradientButton
            active={selectedTab === "fuzzyreplace"}
            onClick={handleChange}
            label={t("Fuzzy Replace")}
            name="fuzzyreplace"
          />

          <CustomGradientButton
            disabled={columnsLoading}
            active={selectedTab === "fixedreplace"}
            onClick={handleChange}
            label={t("Fixed Replace")}
            name="fixedreplace"
          />
        </Box>

        <CleanDataConnectionDetails
          Connectiondetails={Connectiondetails}
          datasourcetype={DSType}
          tableName={tableName}
        />

        {selectedTab === "fuzzyreplace" && (
          <Box className="v-center" gap={1}>
            <IconButton
              color="warning"
              size="small"
              onClick={() => setShowAlgo(!showAlgo)}
              className="flex-end"
              sx={{
                gap: 0.8,
                px: 0,
                mb: 1,
                "& span": {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}>
              <SettingsSuggestOutlinedIcon fontSize="small" />
              <span>{t("Advance options")}</span>
            </IconButton>
          </Box>
        )}

        {selectedTab === "fuzzyreplace" && showAlgo && (
          <Grid item xs={12} my={1}>
            <MultipleDropDownData
              heading={`${t("String Matching Algorithms")} : `}
              placeholder={"Select Algorithms"}
              name={"Algorithms"}
              optionsList={FuzzyAlgorithms}
              Cols={algorithms}
              setCols={setAlgorithms}
            />
          </Grid>
        )}

        {selectedTab === "fuzzyreplace" ? (
          <FuzzyReplace
            columnsLoading={columnsLoading}
            Connectiondetails={Connectiondetails}
            tableName={tableName}
            columnNames={columns}
            toggleDrawer={toggleDrawer}
            algorithms={algorithms}
          />
        ) : (
          <FixedReplace
            tableName={tableName}
            columnNames={columns}
            Connectiondetails={Connectiondetails}
            columnTypes={columnTypes}
            columnsLoading={columnsLoading}
            loadingColumns={loadingColumns}
            toggleDrawer={toggleDrawer}
          />
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default DataCleansingContainer;
