import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CustomBackButton = ({ onClick, children, ...restProps }) => {
  return (
    <Button
      size="small"
      variant="contained"
      startIcon={<ArrowBackIcon />}
      className="backButton"
      onClick={onClick}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default CustomBackButton;
