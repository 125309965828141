import {
  Box,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import FailedResultTableRow from "./FailedResultTableRow";
import { useTranslation } from "react-i18next";

export default function FailedResult({ autoScroll, validations }) {
  const { t } = useTranslation();
  const [rows] = useState(validations);

  return (
    <Box sx={{ maxHeight: "75vh", overflow: "auto", width: "100%" }}>
      <Grid container sx={{ my: 0 }}>
        <Grid item xs={12}>
          <TableContainer aria-label="simple table">
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#7A86A1" }}>
                  {rows?.[0]?.TableName && (
                    <TableCell sx={{ color: "white" }} align="left">
                      {t("Table Name")}
                    </TableCell>
                  )}
                  <TableCell sx={{ color: "white" }} align="left">
                    {t("Column")}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="left">
                    {t("DQ Check")}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="left">
                    {t("Validate Records")}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="left">
                    {t("Passed")}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="left">
                    {t("Failed")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {rows?.map((item, ind) => {
                let indkey = ind + "";
                return (
                  <FailedResultTableRow
                    key={indkey}
                    autoScroll={autoScroll}
                    item={item}
                  />
                );
              })}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
