import { useEffect, useRef } from "react";
import * as d3 from "d3";

const MultiLineChart = ({ data }) => {
  const chartRef = useRef();
  const parseDate = d3.timeParse("%Y-%m-%d");
  const handleOnClickLine = () => {};
  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const width = 900;
    const height = 550;
    const margin = { top: 50, right: 150, bottom: 50, left: 50 };
    const duration = 250;

    const lineOpacity = 0.65;
    const lineOpacityHover = 0.85;
    const otherLinesOpacityHover = 0.35;
    const lineStroke = "2px";
    const lineStrokeHover = "3px";

    const circleOpacity = 0.85;
    const circleOpacityOnLineHover = 0.25;
    const circleRadius = 3;
    const circleRadiusHover = 6;

    const uniqueLineNames = data.map((d) => d.name);
    const color = d3
      .scaleOrdinal()
      .domain(uniqueLineNames)
      .range(d3.schemeCategory10);

    const formattedData = data.map((d) => ({
      name: d.name,
      values: d.values.map((item) => ({
        parsedDate: parseDate(item.date),
        rows: +item.rows,
      })),
    }));

    const xScale = d3
      .scaleTime()
      .domain([
        d3.min(formattedData, (d) =>
          d3.min(d.values, (item) => item.parsedDate)
        ),
        d3.max(formattedData, (d) =>
          d3.max(d.values, (item) => item.parsedDate)
        ),
      ])
      .range([0, width - margin.left - margin.right]);

    const yScale = d3
      .scaleLog()
      .domain([
        1,
        d3.max(formattedData, (d) => d3.max(d.values, (item) => item.rows)),
      ])
      .range([height - margin.top - margin.bottom, 0]);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Add the code for name labels

    const namesContainer = d3
      .select(chartRef.current)
      .insert("div", ":first-child")
      .attr("class", "names-container");

    const nameLabelItems = namesContainer
      .selectAll(".name-label")
      .data(uniqueLineNames)
      .enter()
      .append("div")
      .attr("class", "name-label");

    // Add a container div for each name label
    const nameLabelContainer = nameLabelItems
      .append("div")
      .attr("class", "name-label-container");

    // Add color boxes inside the container div
    nameLabelContainer
      .append("div")
      .attr("class", "color-box-container") // Adjust the class name as needed
      .append("div")
      .attr("class", "color-box")
      .style("background-color", (d) => color(d));

    // Add text alongside the container div
    nameLabelItems
      .append("div")
      .attr("class", "name-text")
      .style("color", (d) => color(d))
      .text((d) => d)
      .on("mouseover", handleNameLabelMouseOver)
      .on("mouseout", handleNameLabelMouseOut)
      .on("click", function (event, d) {
        handleOnClickLine(d);
      });

    function handleNameLabelMouseOut() {
      lines.selectAll(".name-text-group").remove();

      const lineGroup = lines.select(
        `.line-group[data-name="${d3.select(this).text()}"]`
      );
      d3.selectAll(".line").style("opacity", lineOpacity);
      d3.selectAll(".circle").style("opacity", circleOpacity);
      d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
      lineGroup
        .selectAll(".line")
        .style("opacity", lineOpacity)
        .style("stroke-width", lineStroke);
      lineGroup.selectAll(".circle").style("opacity", circleOpacity);
      d3.select(this).style("cursor", "default").classed("hovered", false);
    }

    const line = d3
      .line()
      .x((d) => xScale(d.parsedDate))
      .y((d) => yScale(d.rows));

    const lines = svg.append("g").attr("class", "lines");

    const lineGroups = lines
      .selectAll(".line-group")
      .data(formattedData)
      .join("g")
      .attr("class", "line-group")
      .attr("data-name", (d) => d.name) // Add a data-name attribute to identify the line-group
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("click", function (event, d) {
        handleOnClickLine(d.name);
      });

    lineGroups
      .append("path")
      .attr("class", "line")
      .attr("d", (d) => line(d.values))
      .style("stroke", (d) => color(d.name))
      .style("opacity", lineOpacity)
      .style("cursor", "pointer")
      .on("mouseover", function () {
        d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
        d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
        d3.select(this)
          .style("opacity", lineOpacityHover)
          .style("stroke-width", lineStrokeHover)
          .style("cursor", "pointer");
      })
      .on("mouseout", function () {
        d3.selectAll(".line").style("opacity", lineOpacity);
        d3.selectAll(".circle").style("opacity", circleOpacity);
        d3.select(this)
          .style("stroke-width", lineStroke)
          .style("cursor", "none");
      });

    // Display counts at the end of each line
    const valueLabel = lines
      .selectAll(".label")
      .data(formattedData)
      .join("g")
      .attr("class", "label");

    valueLabel
      .append("text")
      .attr("transform", (d) => {
        const lastValue = d.values[d.values.length - 1];
        return `translate(${xScale(lastValue.parsedDate)}, ${yScale(
          lastValue.rows
        )})`;
      })
      .attr("dy", 5)
      .attr("dx", 10)
      .style("font-family", "monospace")
      .style("fill", (d) => color(d.name))
      .text((d) => d.values[d.values.length - 1].rows); // Assuming rows property should be displayed

    // Add circles in the line
    const circleGroups = lines
      .selectAll(".circle-group")
      .data(formattedData)
      .join("g")
      .attr("class", "circle-group")
      .style("fill", (d) => color(d.name));

    circleGroups
      .selectAll(".circle")
      .data((d) => d.values)
      .join("g")
      .attr("class", "circle")
      .on("mouseover", handleCircleMouseOver)
      .on("mouseout", handleCircleMouseOut)
      .on("click", function (event, d) {
        const parentData = formattedData.find((data) =>
          data.values.some((item) => item === d)
        );
        if (parentData) {
          handleOnClickLine(parentData.name);
        }
      })
      .append("circle")
      .attr("cx", (d) => xScale(d.parsedDate))
      .attr("cy", (d) => yScale(d.rows))
      .attr("r", circleRadius)
      .style("opacity", circleOpacity)
      .style("cursor", "pointer")
      .on("mouseover", function () {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr("r", circleRadiusHover);
      })
      .on("mouseout", function () {
        d3.select(this).transition().duration(duration).attr("r", circleRadius);
      });

    // Add Axis into SVG
    const xAxis = d3.axisBottom(xScale).ticks(5);

    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${height - margin.top - margin.bottom})`)
      .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "start")
      .attr("dx", "-0.2em")
      .attr("dy", "0.80em")
      .attr("font-size", "14px");

    const yAxis = d3.axisLeft(yScale).ticks(5, ",.0s");

    svg
      .append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")
      .style("text-anchor", "start")
      .attr("dy", "1em")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .text("Rows Count")
      .attr("font-size", "14px")
      .attr("font-weight", "bold");

    return () => {
      svg.remove();
    };

    function handleMouseOver(event, d) {
      const lineGroup = d3.select(this);
      const lineColor = lineGroup.select(".line").style("stroke");

      lineGroup
        .transition()
        .duration(duration)
        .style("opacity", lineOpacityHover)
        .style("stroke-width", lineStrokeHover);

      const [mouseX, mouseY] = d3.pointer(event);

      svg
        .append("text")
        .attr("class", "title-text")
        .style("fill", lineColor)
        .text(d.name)
        .attr("text-anchor", "start")
        .attr("x", mouseX + 5)
        .attr("y", mouseY - 5);

      lineGroup
        .selectAll(".circle")
        .transition()
        .duration(duration)
        .style("opacity", circleOpacityOnLineHover);
    }

    function handleMouseOut() {
      const lineGroup = d3.select(this);

      lineGroup
        .transition()
        .duration(duration)
        .style("opacity", lineOpacity)
        .style("stroke-width", lineStroke);

      svg.select(".title-text").remove();

      lineGroup
        .selectAll(".circle")
        .transition()
        .duration(duration)
        .style("opacity", circleOpacity);
    }

    function handleCircleMouseOver(event, d) {
      const lineGroup = d3.select(this.parentNode);
      const lineName = lineGroup.datum().name;
      const lineColor = color(lineName);

      d3.select(this)
        .style("cursor", "pointer")
        .append("text")
        .attr("class", "text")
        .text(`${d.rows}`)
        .attr("x", (d) => xScale(d.parsedDate) + 5)
        .attr("y", (d) => yScale(d.rows) - 10)
        .style("fill", lineColor);
    }

    function handleCircleMouseOut() {
      d3.select(this)
        .style("cursor", "none")
        .transition()
        .duration(duration)
        .selectAll(".text")
        .remove();
    }

    function handleNameLabelMouseOver(event, d) {
      const lineGroup = lines.select(`.line-group[data-name="${d}"]`);

      if (lineGroup.node()) {
        const lastValue = lineGroup.datum().values.slice(-1)[0];
        const xPosition = xScale(lastValue.parsedDate);
        const yPosition = yScale(lastValue.rows);

        // Show the name near the line when hovering over the name
        lineGroup.raise(); // Bring the highlighted line to the front
        d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
        d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
        lineGroup
          .selectAll(".line")
          .style("opacity", lineOpacityHover)
          .style("stroke-width", lineStrokeHover);
        lineGroup
          .selectAll(".circle")
          .style("opacity", circleOpacityOnLineHover);
        d3.select(this).style("cursor", "pointer").classed("hovered", true);

        // Append a group for the name text to allow for easy removal on mouseout
        const nameTextGroup = lineGroup
          .append("g")
          .attr("class", "name-text-group");

        nameTextGroup
          .append("text")
          .attr("class", "name-hover-text")
          .text(d)
          .attr("text-anchor", "start")
          .attr("x", xPosition + 5)
          .attr("y", yPosition - 5)
          .style("fill", color(d));
      }
    }
  }, [data]);
  return data && data.length > 0 ? (
    <div className="chart-container" ref={chartRef}></div>
  ) : null;
};

export default MultiLineChart;
