export const CreateValidationSchema = {
  TestName: "",
  TestDescription: "Test Description comes here",
  TestType: "Comparison",
  FirstDatasourceId: "",
  FirstDatasourceType: "",
  FirstDatasourceName: "",
  SecondDatasourceId: "",
  SecondDatasourceType: "",
  SecondDatasourceName: "",
  comparissonValidations: [],
};
