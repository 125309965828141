import moment from "moment";
import ApiService from "../services/app.service";
import authService from "../services/auth.service";
import { userLocalSession } from "./Constant";

export const totalExecutionsPerPage = parseInt(process.env.REACT_APP_PAGESIZE, 10) || 100;
export const totalBatchesPerPage = parseInt(process.env.REACT_APP_PAGESIZE, 10) || 10;

export const logOut = async (userData, setUserDetails, navigate) => {
  try {
    const reqData = {
      UserId: userData?.UserId,
      SessionId: userData?.SessionId,
    };
    await authService.logout(reqData);
  } catch (error) {
    console.log("Error:", error);
  } finally {
    localStorage.removeItem(userLocalSession);
    setUserDetails({});
    navigate("/login");
  }
};

export const formatDateLocale = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};

export const formattedTime = (dateString) => {
  return dateString ? moment(dateString).format("HH:mm:ss") : "--";
};

export const isDateBefore = (dateString) => {
  const targetDate = moment("2023-09-25");
  const date = moment(dateString);
  return date.isAfter(targetDate);
};

export const formatDate = (date) => {
  return date ? moment(date)?.local()?.format("Do MMM YYYY") : "--";
};

export const formatDateWithTime = (date) => {
  return date ? moment(date)?.local()?.format("Do MMM YYYY hh:mm A") : "--";
};

export const formatDateWithTime2 = (date) => {
  return date ? moment(date)?.local()?.format("D/M/YYYY, h:mm:ss a") : "--";
};

export const formatDateString = (date) => {
  return moment.utc(date).local().format("YYYY-MM-DD");
};

export const getTimeDuration = (startTime, endTime) => {
  if (startTime === undefined || startTime === null || endTime === undefined || endTime === null) {
    return "--";
  }

  const startDate = moment(startTime);
  const endDate = moment(endTime);
  const durationInSeconds = endDate.diff(startDate, "seconds");
  let duration;

  if (durationInSeconds >= 3600) {
    const durationInHours = Math.floor(durationInSeconds / 3600);
    const unit = `hour${durationInHours > 1 ? "s" : ""}`;
    duration = `${durationInHours} ${unit}`;
  } else if (durationInSeconds >= 60) {
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const unit = `minute${durationInMinutes > 1 ? "s" : ""}`;
    duration = `${durationInMinutes} ${unit}`;
  } else if (durationInSeconds === 60) {
    duration = "1 minute";
  } else {
    const unit = `second${durationInSeconds > 1 ? "s" : ""}`;
    duration = `${durationInSeconds} ${unit}`;
  }

  return duration;
};

export const getDaysDiff = (expiryDate) => {
  if (expiryDate === undefined || expiryDate === null) {
    return "--";
  }

  const today = moment();
  const expiryMoment = moment(expiryDate);
  const duration = moment.duration(expiryMoment.diff(today));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let result = "";

  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""} `;
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? "s" : ""} `;
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? "s" : ""}`;
  }

  return result.trim();
};

export const formatNumber = (number) => {
  if (number >= 1000000) {
    const millionValue = (number / 1000000).toFixed(2);
    const millionString = millionValue.replace(/\.0+$/, ""); // Remove trailing zeros
    return `${millionString}M`;
  } else if (number >= 1000) {
    const thousandValue = (number / 1000).toFixed(2);
    const thousandString = thousandValue.replace(/\.0+$/, ""); // Remove trailing zeros
    return `${thousandString}k`;
  }
  return number?.toString();
};

export const userDetails = (keyName) => {
  const userDetails = JSON.parse(localStorage.getItem(userLocalSession));
  return userDetails?.[keyName] ?? "";
};

export const toasterMessages = {
  "Request failed with status code 401": "Session Expired Please Login Again",
  "Network Error": "Oops! Something went wrong Please contact your IT support team",
};
export const setSecretKey = async (encryptArray) => {
  try {
    const encryptKeyObj = {
      secrets: encryptArray?.map((each) => ({
        secretKey: each,
      })),
    };
    const res = await ApiService.getDecryptionkey(encryptKeyObj);
    return res?.ResponseObject?.Secrets;
  } catch (error) {
    return { error: true, message: error };
  }
};

const currentDate = new Date();
const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

const lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
export const dateRangeObj = {
  startDate: firstDayOfMonth,
  endDate: lastDate,
  key: "selection",
  isSelected: false,
};

export const dashboardDateRangeObj = {
  startDate: moment().subtract(7, "days").toDate(),
  endDate: currentDate,
  key: "selection",
  isSelected: false,
};
export const jsonTheme = {
  base00: "#6A707E",
  base01: "#6A707E",
  base02: "#6A707E",
  base03: "#6A707E",
  base04: "#6A707E",
  base05: "#6A707E",
  base06: "#6A707E",
  base07: "#6A707E",
  base08: "#6A707E",
  base09: "#6A707E",
  base0A: "#6A707E",
  base0B: "#6A707E",
  base0C: "#6A707E",
  base0D: "#E89D45",
  base0E: "#6A707E",
  base0F: "#6A707E",
};
