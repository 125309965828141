import { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import { DecryptionMultple } from "../../../_helpers/Encryption";
import { AuthenticationTypes } from "../../../_helpers/Constant";
import { useTranslation } from "react-i18next";

const AuthenticationSection = ({
  handleChnageInput,
  defaultValues,
  disabled,
  register,
  control,
  errors,
  // connectionData,
}) => {
  const { t } = useTranslation();
  const [isSqlAuthentication, setIsSqlAuthentication] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [decryptedFormData, setDecryptedFormData] = useState({});

  const handleChangeAuthenticationType = (event) => {
    setIsSqlAuthentication(event.target.value === "SQL Server Authentication");
  };

  // const handleFormatData = async () => {
  //   const [decryptedUser, decryptedPassword] = await DecryptionMultple([
  //     connectionData?.user,
  //     connectionData?.password,
  //   ]);

  //   const newData = {
  //     ...connectionData,
  //     user: decryptedUser,
  //     password: decryptedPassword,
  //   };

  //   setDecryptedFormData(newData);
  // };

  // useLayoutEffect(() => {
  //   connectionData?.user && handleFormatData();
  // }, []);

  const passwordVisibilityIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  return (
    <>
      {
        // Object.keys(decryptedFormData)?.length > 0 ||
        // !connectionData?.user) &&

        <>
          <Grid item sm={12}>
            <Typography variant="h6">{t("Authentication")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="authenticationType"
              render={() => (
                <TextField
                  fullWidth
                  select
                  label={t("Authentication Type")}
                  required
                  size="small"
                  {...register("authenticationType", {
                    required: true,
                  })}
                  defaultValue={defaultValues.authenticationType || "SQL Server Authentication"}
                  onChange={handleChangeAuthenticationType}>
                  {AuthenticationTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              control={control}
              name="user"
              defaultValue={defaultValues?.user}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={Boolean(disabled || isSqlAuthentication)}
                  label={t("user")}
                  required
                  fullWidth
                  error={Boolean(errors.user)}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  onChange={(e) => handleChnageInput(e, field)}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              control={control}
              name="password"
              defaultValue={defaultValues?.password}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{
                    autoComplete: "new-password",
                    form: { autoComplete: "off" },
                  }}
                  autoComplete="off"
                  disabled={disabled || isSqlAuthentication}
                  defaultValue={defaultValues?.password}
                  fullWidth
                  onChange={(e) => handleChnageInput(e, field)}
                  error={errors.password}
                  label={t("Password")}
                  required
                  variant="outlined"
                  size="small"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {!defaultValues?.password && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}>
                            {passwordVisibilityIcon}
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </>
      }
    </>
  );
};

export default AuthenticationSection;
