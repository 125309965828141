import { LoadingButton } from "@mui/lab";

const CustomLoadingButton = ({
  loading = false,
  disabled = false,
  variant = "contained",
  loadingPosition = "start",
  size = "small",
  onClick,
  startIcon = <></>,
  children,
  ...restProps
}) => {
  return (
    <LoadingButton
      disabled={disabled}
      variant={variant}
      className="loader-button"
      size={size}
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </LoadingButton>
  );
};

export default CustomLoadingButton;
