import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateConnection from "../../DataSources/CreateDS/CreateConnection";
import CreateNewFile from "../../DataSources/CreateFile/CreateNewFile";
import CustomAddButton from "../../CustomComponents/CustomsButtons/CustomAddButton";

export default function NewDataSource({ type, setOptions, setselectedOption }) {
  const { t } = useTranslation();
  const [showAddConnection, setShowAddConnection] = useState(false);
  const onClickAddNew = (value, bool_) => {
    if (bool_) {
      setOptions((prevState) => [...prevState, value]);
    }
  };

  const onSuccessCreate = (newData) => {
    onClickAddNew(newData, true);
    setselectedOption(newData);
  };
  const onClickAddConnection = () => {
    setShowAddConnection(
      type === "APIS"
        ? "Web App"
        : type === "file"
        ? "file"
        : type === "database"
        ? "database"
        : ""
    );
  };
  return (
    <Box>
      <CustomAddButton label={t("Create New")} onClick={onClickAddConnection} />

      {showAddConnection === "file" && (
        <CreateNewFile
          onSuccessCreate={onSuccessCreate}
          onClose={() => setShowAddConnection(false)}
        />
      )}
      {showAddConnection === "database" && (
        <CreateConnection
          type="Add"
          handleSuccessCreate={onSuccessCreate}
          onClose={() => setShowAddConnection(false)}
        />
      )}
    </Box>
  );
}
