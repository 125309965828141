import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import InnerHeader from "../../Layout/InnerHeader";
import ValidateResultModal from "../../Validations/ValidateResultModal";
import CreateOrUpdateDQRule from "../CreateOrUpdateDQRule";
import FileValidations from "./FileValidations";
import NewDataSource from "./NewDataSource";
import SingleDataSource from "./SingleDataSource";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";

export default function SingleFile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ScrollRef = useRef();

  const [searchParams] = useSearchParams();

  const { setSnack } = useContext(SnackbarContext);
  const [finalValidation, setfinalValidation] = useState([]);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [inputParams, setinputParams] = useState([{ Name: "", Value: "" }]);
  const [requestBody, setrequestBody] = useState();
  const [file, setfile] = useState();
  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setselectedOption] = useState(null);

  const connectionId = searchParams.get("connectionId");
  const isPrivate = searchParams.get("isPrivate");
  const ruleId = searchParams.get("ruleId");

  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  useEffect(() => {
    setfinalValidation([]);
  }, [selectedOption]);

  const handleBack = () => {
    setfinalValidation([]);
    if (ruleId) {
      navigate("/DataQualityRule");
    } else {
      navigate(-1);
    }
  };

  const createValidation = async (ruleName, createNew) => {
    setCreateLoading(true);
    let data = {
      TestName: ruleName,
      TestType: "Single File",
      requestBody: requestBody,
      File: file,
      Params: Object.values(inputParams[0]).toString().length === 1 ? undefined : inputParams,
      Tables: [
        {
          tablename: file,
          columns: finalValidation,
          IsCustomQuery: false,
          ValidationProperties: [],
        },
      ],
      ConnectionId: connectionId,
      isPrivate: isPrivate === "private",
    };
    try {
      if (ruleId && !createNew) {
        await ApiService.updateValidation(data, ruleId);
        setSnack({ message: "DQ Rule Updated", open: true, colour: "success" });
      } else {
        await ApiService.createValidation(data);
        setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      }
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const ValidationCheck = async () => {
    setValidateLoading(true);
    let data = {
      TestType: "Single File",
      Tables: [
        {
          TableName: selectedOption?.fileName,
          columns: finalValidation,
          IsCustomQuery: false,
        },
      ],
      fileId: connectionId,
    };
    try {
      let response = await ApiService.checkValidation(data);
      setValidateLoading(false);
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setValidateLoading(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  const getComponent = () => {
    return (
      finalValidation.length > 0 && (
        <Grid container>
          <Grid item sm={12} sx={{ textAlign: "right", mt: 1 }}>
            {validationsResultShow && (
              <ValidateResultModal
                Validations={validationsResult}
                model={true}
                returnValue={(value) => {
                  setValidationsResultShow(value);
                  setValidationsResult([]);
                }}
              />
            )}
            <Button
              sx={{ mr: 2 }}
              color="error"
              size="small"
              variant="outlined"
              onClick={() => {
                navigate("/DataQualityRule");
              }}>
              {t("Cancel")}
            </Button>
            <LoadingButton
              onClick={() => ValidationCheck()}
              sx={{ mr: 2 }}
              size="small"
              variant="contained"
              disabled={validateLoading || createLoading}
              className="PreviewButton"
              loading={validateLoading}
              startIcon={<></>}
              loadingPosition="start">
              {t("Preview")}
            </LoadingButton>
            <CreateOrUpdateDQRule
              createLoading={createLoading}
              validateLoading={validateLoading}
              createValidation={createValidation}
            />
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <React.Fragment>
      <InnerHeader>
        <Box className="DSHeaderContainer">
          <Box className="v-center">
            <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
            <Typography variant="h6" ml={2} className="upper-case">
              {t("Create Data Quality Rule")}
            </Typography>
          </Box>
          {!connectionId && <NewDataSource type="file" setOptions={setOptions} setselectedOption={setselectedOption} />}
        </Box>
      </InnerHeader>
      <Box className="pt74" ref={ScrollRef}>
        {connectionId ? (
          <FileValidations
            finalValidation={finalValidation}
            setfinalValidation={setfinalValidation}
            inputParams={inputParams}
            setinputParams={setinputParams}
            requestBody={requestBody}
            setrequestBody={setrequestBody}
            setfile={setfile}
            autoScroll={autoScroll}
          />
        ) : (
          <SingleDataSource
            type="Files"
            options={options}
            selectedOption={selectedOption}
            setOptions={setOptions}
            setselectedOption={setselectedOption}
          />
        )}

        <Box sx={{ display: "flex", flexDirection: "row", px: 2, pb: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          {getComponent()}
        </Box>
      </Box>
    </React.Fragment>
  );
}
