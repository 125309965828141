import { Paper, ToggleButtonGroup, ToggleButton } from "@mui/material";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
const CustomToggleButton = ({ viewType, onChange, sx = {} }) => {
  return (
    <Paper sx={{ display: "flex", alignItems: "center", ...sx }}>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={viewType}
        exclusive
        onChange={onChange}
      >
        <ToggleButton size="small" sx={{ p: "0.3rem 0.45rem" }} value="grid">
          <GridViewRoundedIcon fontSize="small" />
        </ToggleButton>
        <ToggleButton size="small" sx={{ p: "0.3rem 0.45rem" }} value="list">
          <FormatListBulletedRoundedIcon fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );
};

export default CustomToggleButton;
