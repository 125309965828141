import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import PieChart, {
  Connector,
  Font,
  Label,
  Legend,
  Series,
  Size,
} from "devextreme-react/pie-chart";
import CustomDrawer from "../Drawer";
import { headCells } from "../Drawer/Modal";
import { useTranslation } from "react-i18next";

function PieChartComponent({ columnData }) {
  const { t } = useTranslation();
  const [drawerData, setDrawerData] = useState([]);
  const [state, setState] = useState(false);
  const [cells, setCells] = useState([]);

  const piedataSource = [
    "NullCount",
    "NotNullCount",
    "DistinctCount",
    "DuplicateCount",
  ];

  const formatData = (columnRes) => {
    return piedataSource.map((obj) => {
      const details =
        obj === "DistinctCount"
          ? columnRes["DistinctValues"]
          : obj === "DuplicateCount"
          ? columnRes["DuplicateValues"]
          : undefined;
      return {
        key: obj,
        value: columnRes[obj],
        details: details,
      };
    });
  };

  const formatLabel = (arg) => `${arg.argumentText}: ${arg.valueText}`;

  const handlePropertyChange = (e) => {
    e.target.select();
    if (e.target.data.details) {
      const data = [...headCells];
      if (e.target.data?.details[0]?.Count) {
        data.push({
          field: "Count",
          headerName: "Count",
          sortable: true,
          filter: false,
        });
      }
      setCells(data);
      setDrawerData(e.target.data);
      setState(true);
    }
  };

  const customizeLabel = (pointInfo) => {
    if (
      pointInfo.argument === "NullCount" ||
      pointInfo.argument === "NotNullCount"
    ) {
      return {};
    }
    return pointInfo.value > 0 ? { font: { cursor: "pointer" } } : {};
  };

  const DPGraphFooterComponent = ({ title, value }) => (
    <Grid md={4} item key={1}>
      <Box className="DPGraphFooter">
        <Typography>{t(title)}</Typography>
        <Typography variant="bold">{value}</Typography>
      </Box>
    </Grid>
  );
  return (
    <>
      {columnData.map((obj, index) => {
        const formattedData = formatData(obj);
        return (
          <Grid
            key={index}
            container
            alignItems="center"
            sx={{ padding: "8px 0px", background: "#F4F1FF", mb: 0.5 }}
          >
            <Grid md={2} item>
              <Typography className="colName">{obj.Name}</Typography>
            </Grid>
            <Grid md={10} item>
              <Box>
                <Grid container spacing={0.5} alignItems="center">
                  <Grid md={7} item>
                    <Box position="relative">
                      <Grid container spacing={0.5}>
                        <DPGraphFooterComponent
                          title="Min Value"
                          value={obj?.MinValue ? obj?.MinValue : "NA"}
                        />
                        <DPGraphFooterComponent
                          title="Max Value"
                          value={obj?.MaxValue ? obj?.MaxValue : "NA"}
                        />
                        <DPGraphFooterComponent
                          title="Avg Value"
                          value={obj?.AvgValue ? obj?.AvgValue : "NA"}
                        />
                        <DPGraphFooterComponent
                          title="Min Value Length"
                          value={obj?.MinValueLength}
                        />
                        <DPGraphFooterComponent
                          title="Max Value Length"
                          value={obj?.MaxValueLength}
                        />
                        <DPGraphFooterComponent
                          title="Average Value Length"
                          value={obj?.AverageValueLength}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid md={5} item>
                    <PieChart
                      id={`pie-${index}`}
                      className="pieChart"
                      palette="Bright"
                      resolveLabelOverlapping="shift"
                      dataSource={formattedData}
                      customizeLabel={customizeLabel}
                      onPointClick={handlePropertyChange}
                    >
                      <Legend
                        orientation="horizontal"
                        itemTextPosition="right"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                        columnCount={15}
                      />
                      <Series argumentField="key" valueField="value">
                        <Label visible={true} customizeText={formatLabel}>
                          <Font size={8} />
                          <Connector visible={true} />
                        </Label>
                      </Series>
                      <Size height={100} />
                    </PieChart>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        );
      })}
      <CustomDrawer
        state={state}
        setState={setState}
        drawerData={drawerData}
        headCells={cells}
      />
    </>
  );
}

export default PieChartComponent;
