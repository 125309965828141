import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AbortModal = ({
  showModal,
  ruleExecuteRequestAbort,
  setAbortModalData,
}) => {
  const { t } = useTranslation();
  const [loadingAbort, setLoadingAbort] = useState(false);
  const handleAbort = async () => {
    setLoadingAbort(true);
    await ruleExecuteRequestAbort();
    setLoadingAbort(false);
  };
  return (
    <Dialog
      className="dialogCus"
      open={showModal}
      onClose={() => setAbortModalData({})}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h6" id="alert-dialog-title">
        {"Are you sure want to Abort?"}
      </DialogTitle>
      <DialogActions>
        <LoadingButton
          variant="outlined"
          size="small"
          onClick={() => handleAbort()}
          autoFocus
          disabled={loadingAbort}
          className="loader-button"
          loading={loadingAbort}
          startIcon={<></>}
          loadingPosition="start"
        >
          {t("Yes")}
        </LoadingButton>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => setAbortModalData({})}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AbortModal;
