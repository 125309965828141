import { Autocomplete, Checkbox, CircularProgress, TextField, Chip } from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const CustomAutoComplete = React.forwardRef(
  (
    {
      name = "",
      label = "",
      placeholder = "",
      value = "",
      disabled = false,
      loading = false,
      options = [],
      onChange = () => null,
      getOptionLabel = (option) => (option ? String(option) : ""),
      getOptionIcon = () => null,
      onOpen = () => null,
      multiple = false,
      labelChip = true,
      disableAllOptions = false,
      shouldDisableCheckbox = () => false, // Default to no disabling
      ...props
    },
    ref,
  ) => {
    const renderTags = (value, getTagProps) =>
      value.map((option, index) => (
        <Chip key={index} label={getOptionLabel(option)} {...getTagProps({ index })} style={{ display: "none" }} />
      ));

    return (
      <Autocomplete
        {...props}
        ref={ref}
        getOptionDisabled={() => disableAllOptions}
        multiple={Boolean(multiple)}
        disableCloseOnSelect={multiple}
        disablePortal
        disabled={disabled}
        loading={loading}
        size="small"
        options={options}
        value={value || null}
        renderTags={!labelChip ? renderTags : undefined}
        name={name}
        isOptionEqualToValue={(option, value) => option === value}
        onOpen={onOpen}
        onChange={(e, selectedValues) => onChange(e, selectedValues)}
        getOptionLabel={(option) => (option ? getOptionLabel(option) : "")}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {Boolean(multiple) && (
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                sx={{ mr: 1, p: 0 }}
                checked={selected}
                disabled={shouldDisableCheckbox(option)}
              />
            )}
            {getOptionIcon?.(option)}
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={20} style={{ marginLeft: 5 }} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              "& .MuiChip-root": {
                my: "1px",
                fontSize: "10px",
                height: "18px",
                "& .MuiChip-deleteIcon": {
                  fontSize: "12px",
                },
              },
            }}
          />
        )}
      />
    );
  },
);
CustomAutoComplete.displayName = "CustomAutoComplete";

export default CustomAutoComplete;
