import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  CircularProgress,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const ViewTableDatabaseToFile = ({
  viewTableData,
  viewTableDataLoading,
  id,
  anchorEl,
  setAnchorEl,
}) => {
  const columns =
    viewTableData?.length > 0 ? Object.keys(viewTableData[0]) : [];
  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  function handleDragStart(event) {
    event.preventDefault();
  }

  return (
    <Popover
      sx={{
        "& .MuiBackdrop-root": {
          display: "none !important",
        },
        maxWidth: "520px !important",
      }}
      classes={{ paper: "no-backdrop" }}
      id={id}
      anchorEl={anchorEl}
      open={true}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={{ p: 1 }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onMouseLeave();
          }}
          color="error"
          aria-label="close popover"
          sx={{
            position: "absolute",
            right: -1,
            top: -1,
            background: "#fff",
            p: "2px",
            ":hover": { background: "#fff" },
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <TableContainer sx={{ maxWidth: 650, maxHeight: 300 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell onDragStart={handleDragStart} key={index}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {viewTableData?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns?.map((column, columnIndex) => (
                    <TableCell onDragStart={handleDragStart} key={columnIndex}>
                      {row[column]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {viewTableData?.length === 0 && (
                <TableRow>
                  <TableCell
                    onDragStart={handleDragStart}
                    sx={{ textAlign: "center" }}
                    colSpan={columns?.length}
                  >
                    {viewTableDataLoading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : (
                      "No records to display"
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  );
};

export default ViewTableDatabaseToFile;
