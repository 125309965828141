/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Box, IconButton, Typography, Drawer, Tabs, CardMedia, Button } from "@mui/material";
import Tab from "@mui/material/Tab";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DataUsageIcon from "@mui/icons-material/DataUsage";
// import RefreshIcon from "@mui/icons-material/Refresh";

import { useTranslation } from "react-i18next";
import APIServices from "../../services/app.service";
import { SnackbarContext } from "../../App";
import { useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import SkeletonLoader from "../SkeletonLoader";

const DataProfileStatusIndicator = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [searchParams, setSearchParams] = useSearchParams({
    dataProfileStatus: false,
  });

  const dataProfileStatus = JSON.parse(searchParams.get("dataProfileStatus"));

  const [DSProfilingList, setDSProfilingList] = useState([]);

  const [selectedTab, setSelectedTab] = useState(1);
  const [loadingApi, setLoadingApi] = useState(false);

  const handleClickViewAll = () => {
    navigate("/datasources/insights");
  };

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseDrawer = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("dataProfileStatus");
      return newState;
    });
  };

  const handleOpenDrawer = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("dataProfileStatus", true);
      return newState;
    });
  };

  const statusByConnection = async () => {
    setLoadingApi(true);
    setDSProfilingList([]);
    try {
      const res = await APIServices.getInsightStatusByConnection();
      setDSProfilingList(res?.data?.rows);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingApi(false);
  };
  useEffect(() => {
    if (dataProfileStatus || DSProfilingList?.length === 0) {
      statusByConnection();
    }
  }, [dataProfileStatus]);

  const filteredList = DSProfilingList?.filter((eachcon) => eachcon?.status === selectedTab);

  const onClickConnection = (conData) => {
    if (conData?.status === 2) {
      const searchParams = createSearchParams({
        con_id: conData._id,
      });
      navigate({
        pathname: "datasources/insights",
        search: `?${searchParams}`,
      });
    }
  };
  return (
    <>
      {DSProfilingList.length > 0 && (
        <Box onClick={handleOpenDrawer} className="dataprofileprogress">
          <DataUsageIcon color="primary" sx={{ fontSize: 40 }} />
          {/* <Typography variant="body1" color="warning.main">
          {t("Progress")}
        </Typography> */}
          <Typography
            variant="body1"
            sx={{
              // borderRadius: "50%",
              // background: "#1976D2",
              padding: "2px 8px",
              color: "warning.main",
            }}>
            {DSProfilingList?.filter((each) => each?.status === 1)?.length}
          </Typography>
        </Box>

        // <Button
        //   sx={{ ml: "auto" }}
        //   onClick={handleOpenDrawer}
        //   size="small"
        //   variant="contained"
        //   color="warning"
        //   startIcon={<RefreshIcon />}>
        //   {t("Refresh")}
        // </Button>
      )}
      {dataProfileStatus && (
        <Drawer anchor="right" open={true} onClose={handleCloseDrawer}>
          <Box sx={{ width: 450, position: "relative" }}>
            <Box
              sx={{
                height: "2.5rem",
                position: "fixed",
                width: "inherit",
                px: 2,
                background: "white",
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}>
              <Typography variant="h6">{t("Data Insights")} :</Typography>
              <IconButton onClick={handleCloseDrawer} sx={{ ml: "auto" }} color="error">
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ pt: "3rem", px: 2 }}>
              <Tabs
                variant="fullWidth"
                className="exepopTabs"
                value={selectedTab}
                onChange={handleTabChange}
                sx={{ px: 2 }}>
                <Tab label={t("In Progress")} value={1} />
                <Tab label={t("Completed")} value={2} />
              </Tabs>

              {filteredList?.length ? (
                filteredList?.map((each, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                      my: 0.5,
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      ...(selectedTab === 2 && { cursor: "pointer" }),
                    }}
                    onClick={() => onClickConnection(each)}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {t("Connection")}: {each?.connectionName}
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                        No. of Tables: 2
                      </Typography> */}
                    </Box>

                    <CardMedia
                      component="img"
                      image={`/assets/DataInsights/${each?.connectionType.toLowerCase()}.png`}
                      alt="mysql.png"
                      sx={{ width: 50 }}
                    />
                  </Box>
                ))
              ) : loadingApi ? (
                <SkeletonLoader />
              ) : (
                <Typography textAlign="center" my={1} variant="h6">
                  {t("No Data Found")}
                </Typography>
              )}
            </Box>
            <Box sx={{ width: "10%", ml: "40%" }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ position: "fixed", bottom: "5%" }}
                onClick={handleClickViewAll}>
                {t("View All")}
              </Button>
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default DataProfileStatusIndicator;
