import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonLoader() {
  return (
    <Box width={"100%"} p={2}>
      <Skeleton style={{ background: "#abcee6" }} />
      <Skeleton style={{ background: "#b9d6ea" }} />
      <Skeleton style={{ background: "#c7deee" }} />
    </Box>
  );
}
