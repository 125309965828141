import { Box, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

import { useState } from "react";
import SkeletonLoader from "../../SkeletonLoader";
import BarGraph from "./Charts/BarGraph";
import MultiLineChart from "./Charts/MultiLineChart";

const ColumnChart = ({
  columnDataLoading,
  columnData,
  setColumnDataLoading,
}) => {
  const [selectedType, setSelectedType] = useState("Rows");

  const handleOnChangeType = (e) => {
    setColumnDataLoading(true);
    setTimeout(() => {
      setSelectedType(e.target.value);
      setColumnDataLoading(false);
    }, 1000);
  };

  return (
    <Box sx={{ position: "relative", my: 2 }}>
      {columnDataLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <FormControl
            sx={{
              minWidth: 120,
              right: "16px",
              top: "16px",
              position: "absolute",
            }}
            size="small"
          >
            <InputLabel>Select</InputLabel>
            <Select
              size="small"
              value={selectedType}
              label="Select"
              onChange={handleOnChangeType}
            >
              <MenuItem value={"Rows"}>Rows</MenuItem>
              <MenuItem value={"DuplicateRowsCount"}>Duplicate</MenuItem>
              <MenuItem value={"UniqueRowsCount"}>Distinct</MenuItem>
              <MenuItem value={"NullRowsCount"}>Null</MenuItem>
              <MenuItem value={"NotNullRowsCount"}>Not Null</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ background: "#F2F2F2", p: 2 }}>
            {columnData?.[selectedType] &&
              (selectedType === "Rows" ? (
                <BarGraph data={columnData[selectedType]} />
              ) : (
                <MultiLineChart data={columnData[selectedType]} />
              ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ColumnChart;
