/* eslint-disable react-hooks/exhaustive-deps */
import {
  max,
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  schemeCategory10,
  select,
  stack,
  stackOrderDescending,
} from "d3";
import React, { useEffect, useRef, useState } from "react";
import { roundToX } from "../ProfillingListItems";
import useResizeObserver from "./useResizeObserver";

//Legends
const Legend = ({ keys }) => {
  const colorScale = scaleOrdinal().domain(keys).range(schemeCategory10);

  return (
    <div style={{ marginTop: "8px" }}>
      {keys.map((key, index) => (
        <div key={index}>
          <span
            style={{
              display: "inline-block",
              width: "25px",
              height: "10px",
              backgroundColor: colorScale(key),
              marginRight: "10px",
              marginLeft: "18px",
            }}
          ></span>
          <span>
            <b>{key}</b>
          </span>
        </div>
      ))}
    </div>
  );
};

function StackedBarChart({ rows, allKeys, total }) {
  const svgRef = useRef();
  const wrapperRef = useRef();

  const [keys] = useState(allKeys);
  const [data] = useState(rows);

  const dimensions = useResizeObserver(wrapperRef);

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();

    // stacks / layers
    const stackGenerator = stack().keys(keys).order(stackOrderDescending);
    const layers = stackGenerator(data);
    const extent = [
      0,
      max(layers, (layer) => max(layer, (sequence) => sequence[1])),
    ];

    // scales
    const yScale = scaleBand()
      .domain(data.map((d, i) => i.toString()))
      .range([height, 0])
      .padding(0.25);

    const xScale = scaleLinear().domain(extent).range([0, width]);

    //Colour scale
    const colorScale = scaleOrdinal().domain(keys).range(schemeCategory10);

    //Xaxis Labels
    const Xlabels = svg.selectAll(".Xlabel").data(layers);

    // rendering
    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", (d) => colorScale(d.key))
      .selectAll("rect")
      .data((d) => d)
      .join("rect")
      .attr("x", (sequence) => xScale(sequence[0]))
      .transition()
      .attr("y", (d, i) => yScale(i.toString()))
      .attr("height", yScale.bandwidth())
      .attr("width", (sequence) => xScale(sequence[1]) - xScale(sequence[0]));

    Xlabels.enter()
      .append("text")
      .attr("class", "Xlabel")
      .merge(Xlabels)
      .style("fill", "#ffffff")
      .style("font-size", 13)
      .style("font-weight", "bold")
      .style("text-align", "center")
      .attr("x", (d) => (xScale(d[0][1]) + xScale(d[0][0])) / 2)
      .attr("y", () => yScale.bandwidth() / 2) // Adjust the Y-coordinate based on the band scale
      .attr("dy", "2em") // To vertically center the label within the bar
      .attr("dx", "-1.25em")
      .text((d) => {
        let percentage = roundToX((d[0].data[d.key] / total) * 100, 1);
        return percentage > 10 ? `${percentage}%` : "";
      })
      .append("tspan")
      .attr("x", (d) => (xScale(d[0][1]) + xScale(d[0][0])) / 2)
      .attr("y", () => yScale.bandwidth() / 2)
      .attr("dy", "3em")
      .attr("dx", "-1.5em")
      .text((d) => {
        let percentage = roundToX((d[0].data[d.key] / total) * 100, 1);
        let value = d[0].data[d.key];
        return percentage > 10 ? `(${value})` : "";
      });

    Xlabels.exit().remove();
  }, [data, dimensions, keys]);

  return (
    <React.Fragment>
      <div ref={wrapperRef}>
        <svg ref={svgRef} width={"100%"} height={"100%"} />
        <Legend keys={keys} />
      </div>
    </React.Fragment>
  );
}

export default StackedBarChart;
