/* eslint-disable react-hooks/exhaustive-deps */
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import CustomWidthTooltip from "../../CustomComponents/CustomWidthTooltip";
import CompareFinalValidation from "../RowComparison/CompareFinalValidation";
import OrderbyDropdown from "../RowComparison/OrderbyDropdown";
import ViewTableDatabaseToFile from "../RowComparison/ViewTableDatabaseToFile";
import Source1 from "./CreateNewDqRule/SourcesDatabaseToFile/Source1";

export default function EditDatabaseToFile({
  source1,
  source2,
  finalValidation,
  setFinalValidation,
  source1Sql,

  setFirstSourceOrderBy,
  firstSourceOrderBy,
  secondSourceOrderBy,
  setSecondSourceOrderBy,
  editResponseData,
  source1SqlData,
  setSource1SqlData,
  source2SqlData,
  setSource2SqlData,
  isFullScan,
  setIsFullScan,
}) {
  const initialize = {
    file: false,
    filedata: [],
    database: false,
    databasedata: [],
  };

  const { t } = useTranslation();

  const { setSnack } = useContext(SnackbarContext);

  const [source1Options, setsource1Options] = useState([]);
  const [source2Options, setSource2Options] = useState([]);
  const [source1Coulumns, setSource1Coulumns] = useState([]);
  const [source2Coulumns, setSource2Coulumns] = useState([]);

  const [dragItem, setdragItem] = useState({});

  const [source1Dragitems, setsource1Dragitems] = useState([]);
  const [source2Dragitems, setsource2Dragitems] = useState([]);

  const [source1SqlQueryData, setsource1SqlQueryData] = useState([]);
  const [source2SqlQueryData, setSource2SqlQueryData] = useState([]);

  const [source1Data, setSource1Data] = useState(initialize);
  const [source2Data, setSource2Data] = useState(initialize);
  const source1TableName = editResponseData?.firstDataSourceTableColumns?.tableName;
  const source2TableName = editResponseData?.secondDataSourceTableColumns?.tableName;
  const [source1DB, setsource1DB] = useState({
    table_name: source1TableName,
    table_type: "Tables",
  });
  const [source2DB, setSource2DB] = useState({
    table_name: source2TableName,
    table_type: "Tables",
  });

  const [selected, setSelected] = useState([]);
  const [finalSelected, setfinalSelected] = useState([]);

  const [loader1, Setloader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [source1TableData, setSource1TableData] = useState([]);
  const [source2TableData, setSource2TableData] = useState([]);
  const [source1TableDataLoading, setSource1TableDataLoading] = useState(false);
  const [source2TableDataLoading, setSource2TableDataLoading] = useState(false);

  const [showOrderByError, setShowOrderByError] = useState(false);

  const [validation, setValidation] = useState({
    row_count_matching: false,
    row_data_matching: true,
  });

  const openFirstDataSource = Boolean(anchorEl);
  const openSecondDataSource = Boolean(anchorEl2);
  useMemo(() => {
    const source = { ...source1Data };

    async function getData() {
      if (source1?.ext) {
        source["file"] = true;
        source["database"] = false;
        setSource1Data(source);
        try {
          const response = await ApiService.GetFilesData({ id: source1.id });
          if (response?.data?.result?.ext === "txt") {
            if (response?.data?.result?.rows[0]?.length > 20) {
              const abc = response.data.result.rows[0]?.slice(0, 20);
              response.data.result.rows[0] = abc;
            }
          }
          source["filedata"] = response?.data;
          setSource1Data(source);
        } catch (e) {
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      } else if (source1?.dataBase) {
        source["file"] = false;
        source["database"] = true;
        setSource1Data(source);
        try {
          const response = await ApiService.ConnectionDetails(source1.id);
          setsource1Options(response?.data?.tables);
        } catch (e) {
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      }
    }
    getData();
  }, [source1]);

  useMemo(() => {
    const source = { ...source2Data };
    async function getData() {
      if (source2?.ext) {
        source["file"] = true;
        source["database"] = false;
        setSource2Data(source);
        try {
          const response = await ApiService.GetFilesData({ id: source2.id });
          if (response?.data?.result?.ext === "txt") {
            if (response?.data?.result?.rows[0]?.length > 20) {
              const abc = response.data.result.rows[0].slice(0, 20);
              response.data.result.rows[0] = abc;
            }
          }
          source["filedata"] = response?.data;
          setSource2Data(source);
        } catch (e) {
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      } else if (source2?.dataBase) {
        source["file"] = false;
        source["database"] = true;
        setSource2Data(source);
        try {
          const response = await ApiService.ConnectionDetails(source2.id);
          setSource2Options(response?.data?.tables);
        } catch (e) {
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      }
    }
    getData();
  }, [source2]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [...Array(range)].map((obj, index) => {
        return index;
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const deleteSelected = () => {
    const updatedSource1 = source1Dragitems.filter((_, index) => !selected.includes(index));
    const updatedSource2 = source2Dragitems.filter((_, index) => !selected.includes(index));

    setsource1Dragitems(updatedSource1);
    setsource2Dragitems(updatedSource2);
    setSelected([]);
  };

  const getConnections1TableData = async (tablesArray) => {
    Setloader1(true);
    try {
      const response = await ApiService.ConnectionDetailsDataValidation({
        connectionId: source1.id,
        tableName: tablesArray,
      });
      setSource1Data((prevState) => ({
        ...prevState,
        databasedata: response?.data?.tablesData,
      }));
      const columns =
        tablesArray.length > 0
          ? response?.data?.tablesData[tablesArray?.length - 1][tablesArray[tablesArray?.length - 1]]?.Columns.map(
              (e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              },
            )
          : [];
      setSource1Coulumns(columns);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      Setloader1(false);
    }
  };

  const getConnections2TableData = async (tablesArray) => {
    setLoader2(true);
    const source = { ...source2Data };
    try {
      const response = await ApiService.ConnectionDetailsDataValidation({
        connectionId: source2.id,
        tableName: tablesArray,
      });
      source["databasedata"] = response?.data?.tablesData;
      setSource2Data(source);
      const columns =
        tablesArray.length > 0
          ? response?.data?.tablesData[tablesArray?.length - 1][tablesArray[tablesArray?.length - 1]]?.Columns?.map(
              (e) => {
                return { label: e?.COLUMN_NAME, value: e?.COLUMN_NAME };
              },
            )
          : [];
      setSource2Coulumns(columns);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoader2(false);
    }
  };
  const mapColumnNames = (columns) => {
    return (
      columns?.map(({ COLUMN_NAME }) => ({
        label: COLUMN_NAME,
        value: COLUMN_NAME,
      })) || []
    );
  };
  const fetchData = async () => {
    try {
      // setsource1DB([source1TableName]);
      // setSource2DB([source2TableName]);

      const updatedArray1 = mapColumnNames(editResponseData?.firstDataSourceTableColumns?.columnNames);
      const updatedArray2 = mapColumnNames(editResponseData?.secondDataSourceTableColumns?.columnNames);

      const source1DataDetails = {
        ...source1Data,
        databasedata: [
          {
            [source1TableName]: {
              rows: [],
              Columns: editResponseData?.firstDataSourceTableColumns?.columnNames,
              rowsCount: 0,
              columnCount: 0,
            },
          },
        ],
      };

      const source2DataDetails = {
        ...source2Data,
        databasedata: [
          {
            [source2TableName]: {
              rows: [],
              Columns: editResponseData?.secondDataSourceTableColumns?.columnNames,
              rowsCount: 0,
              columnCount: 0,
            },
          },
        ],
      };

      setSource1Data(source1DataDetails);
      setSource2Data(source2DataDetails);
      setSource1Coulumns(updatedArray1);
      setSource2Coulumns(updatedArray2);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [editResponseData]);

  const dragStart = (obj) => {
    setdragItem(obj);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };
  const handleSwitchChange = (e) => {
    setIsFullScan(e.target.checked);
  };
  const isPrimaryKey = finalValidation.some(
    (item) =>
      item.source1.some((source1Item) => source1Item.IsKey) || item.source2.some((source2Item) => source2Item.IsKey),
  );

  const updateOrderBy = () => {
    if (dragItem.source === "source1" && source1Dragitems.length === 0) {
      if (source1Dragitems.length === 0 || !firstSourceOrderBy) {
        !isPrimaryKey && setFirstSourceOrderBy(dragItem?.Column);
      } else {
        setFirstSourceOrderBy("");
      }
    } else if (dragItem.source === "source2" && source2Dragitems.length === 0) {
      if (source2Dragitems.length === 0 || !secondSourceOrderBy) {
        !isPrimaryKey && setSecondSourceOrderBy(dragItem?.Column);
      } else {
        setSecondSourceOrderBy("");
      }
    }
  };

  const onDrop = () => {
    const updatedSource1 = [...source1Dragitems];
    const updatedSource2 = [...source2Dragitems];
    if (dragItem.source === "source1") {
      if (source2Dragitems.length > updatedSource1.length) {
        const correspondingSource2Item = source2Dragitems[updatedSource1.length];

        const index_ = [];
        updatedSource1.forEach((obj, index) => {
          if (JSON.stringify(obj) === JSON.stringify(dragItem)) {
            if (JSON.stringify(source2Dragitems[index]) === JSON.stringify(correspondingSource2Item)) {
              index_.push(index);
            }
          }
        });
        if (index_.length > 0) {
          setSnack({
            message: "This Column Mapping Already Exist.",
            open: true,
            colour: "warning",
          });
        } else {
          updatedSource1.push(dragItem);
        }
      } else {
        updatedSource1.push(dragItem);
      }
      setsource1Dragitems(updatedSource1);
    } else if (dragItem.source === "source2") {
      if (source1Dragitems.length > updatedSource2.length) {
        const correspondingSource1Item = source1Dragitems[updatedSource2.length];
        const index_1 = [];
        updatedSource2.forEach((obj, index) => {
          if (JSON.stringify(obj) === JSON.stringify(dragItem)) {
            if (JSON.stringify(source1Dragitems[index]) === JSON.stringify(correspondingSource1Item)) {
              index_1.push(index);
            }
          }
        });
        if (index_1.length > 0) {
          setSnack({
            message: "This Column Mapping Already Exist.",
            open: true,
            colour: "warning",
          });
        } else {
          updatedSource2.push(dragItem);
        }
      } else {
        updatedSource2.push(dragItem);
      }
      setsource2Dragitems(updatedSource2);
    }
    setdragItem();
    updateOrderBy();
  };

  const range =
    source1Dragitems?.length > source2Dragitems?.length ? source1Dragitems?.length : source2Dragitems?.length;

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  const addValidation = () => {
    if (source1Dragitems?.length !== source2Dragitems?.length) {
      setSnack({
        message: "Column mapping has unmapped columns. Please fix to create validation.",
        open: true,
        colour: "warning",
      });
    } else {
      const final_validation = [...finalValidation];
      final_validation.forEach((fnlValidationObj, index1) => {
        fnlValidationObj.source1.forEach((source1Obj, index) => {
          delete source1Obj["_id"];
          delete source1Obj["source"];

          delete fnlValidationObj.source2[index]["_id"];
          delete fnlValidationObj.source2[index]["source"];
          source1Dragitems.forEach((s1Dragitem, index2) => {
            delete s1Dragitem["source"];
            delete source2Dragitems[index2]["source"];

            if (JSON.stringify({ ...source1Obj, IsKey: false }) === JSON.stringify({ ...s1Dragitem, IsKey: false })) {
              if (
                JSON.stringify({
                  ...source2Dragitems[index2],
                  IsKey: false,
                }) ===
                JSON.stringify({
                  ...fnlValidationObj.source2[index],
                  IsKey: false,
                })
              ) {
                fnlValidationObj.source1.splice(index, 1);
                fnlValidationObj.source2.splice(index, 1);
              }
            }
          });
          if (fnlValidationObj?.source1?.length === 0 && fnlValidationObj?.source2?.length === 0) {
            delete final_validation[index1];
          }
          setFinalValidation(final_validation);
        });
      });
      const final = {
        validation: validation,
        source1: source1Dragitems,
        source2: source2Dragitems,
      };
      final_validation.push(final);
      const final_res = final_validation.filter(function (element) {
        return element !== undefined;
      });

      setFinalValidation(final_res);
      setSelected([]);
      setsource1Dragitems([]);
      setsource2Dragitems([]);
      setValidation({
        row_count_matching: false,
        row_data_matching: true,
      });
    }
  };

  const checkValidationSelected =
    (source1Dragitems.length === 0 && source2Dragitems.length === 0) ||
    source1Dragitems?.length !== source2Dragitems.length;

  const resetValidation = () => {
    setsource1Dragitems([]);
    setsource2Dragitems([]);
    setValidation({
      row_count_matching: false,
      row_data_matching: true,
    });
    setsource1SqlQueryData([]);
    setSource2SqlQueryData([]);
    setSource1SqlData("");
    setSource2SqlData("");
  };

  const handleIsKey = (e, index, orderby1, orderby2) => {
    const updatedSource1Dragitems = source1Dragitems.map((each, i) => ({
      ...each,
      IsKey: index === i ? !each.IsKey : false,
    }));

    const updatedSource2Dragitems = source2Dragitems.map((item, i) => ({
      ...item,
      IsKey: index === i ? !item.IsKey : false,
    }));

    setsource1Dragitems(updatedSource1Dragitems);
    setsource2Dragitems(updatedSource2Dragitems);
    if (e.target.checked === true) {
      setFirstSourceOrderBy(orderby1);
      setSecondSourceOrderBy(orderby2);
    }
  };

  const isCheckedSource1Dragitems = source1Dragitems?.filter((each) => each.IsKey);
  const isCheckedSource2Dragitems = source2Dragitems?.filter((each) => each.IsKey);
  const isCheckedAnyDragitem = isCheckedSource1Dragitems?.length || isCheckedSource2Dragitems?.length ? true : false;

  const onClickViewTableSource1 = async (e) => {
    e.stopPropagation();
    setSource1TableDataLoading(true);
    setSource1TableData([]);
    setAnchorEl(e.currentTarget);
    const tableName = source1DB?.table_name;
    const connectionId = source1.id;
    const orderBy = firstSourceOrderBy;

    try {
      const response = await ApiService.getcomparisionViewTableData({
        connectionId: connectionId,
        tableName: tableName,
        orderBy: orderBy,
      });

      setSource1TableData(response?.data?.rows);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setSource1TableDataLoading(false);
  };

  const onClickViewTableSource2 = async (e) => {
    e.stopPropagation();
    setSource2TableDataLoading(true);
    setSource2TableData([]);
    setAnchorEl2(e.currentTarget);
    const tableName = source2DB?.table_name;
    const connectionId = source2.id;
    const orderBy = secondSourceOrderBy;

    try {
      const response = await ApiService.getcomparisionViewTableData({
        connectionId: connectionId,
        tableName: tableName,
        orderBy: orderBy,
      });

      setSource2TableData(response?.data?.rows);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setSource2TableDataLoading(false);
  };
  const onChangeSource1Column = (e, value) => {
    setsource1DB(value);
    setSource1Data((prevState) => ({
      ...prevState,
      filedata: [],
      databasedata: [],
    }));
  };
  const onChangeSource2Column = (e, value) => {
    setSource2DB(value);
    setSource2Data((prevState) => ({
      ...prevState,
      filedata: [],
      databasedata: [],
    }));
  };
  return (
    <React.Fragment>
      <Box component={Paper} className="drawerShortHead">
        <Typography variant="h6">{t("Data Source")}</Typography>
      </Box>
      <Box
        component={Paper}
        sx={{
          width: "100%",
          p: 2,
          pt: 1,
          boxShadow: "0px 3px 6px #00000029",
          borderTopLeftRadius: "0 !important",
        }}>
        <Grid container>
          <Grid sx={{ p: 1 }} item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                p: 2,
                height: "100%",
                boxShadow: "0px 3px 6px #00000029",
                borderTopLeftRadius: "0 !important",
                boxSizing: "border-box",
              }}>
              <Source1
                type="edit"
                source1={source1}
                source2={source2}
                source1SqlData={source1SqlData}
                source1Data={source1Data}
                source1Sql={source1Sql}
                source1Options={source1Options}
                source1DB={source1DB}
                source1SqlQueryData={source1SqlQueryData}
                getConnections1TableData={getConnections1TableData}
                loader1={loader1}
                Setloader1={Setloader1}
                dragStart={dragStart}
                setSource1SqlData={setSource1SqlData}
                setsource1SqlQueryData={setsource1SqlQueryData}
                finalValidation={finalValidation}
                source1Dragitems={source1Dragitems}
                onChangeSource1Column={onChangeSource1Column}
              />
            </Box>
          </Grid>

          <Grid sx={{ p: 1 }} item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                p: 2,
                height: "100%",
                boxShadow: "0px 3px 6px #00000029",
                borderTopLeftRadius: "0 !important",
                boxSizing: "border-box",
              }}>
              <Source1
                type="edit"
                source1={source1}
                source2={source2}
                source2Data={source2Data}
                source1Sql={source1Sql}
                source2SqlData={source2SqlData}
                source2SqlQueryData={source2SqlQueryData}
                source2DB={source2DB}
                source2Options={source2Options}
                loader2={loader2}
                setLoader2={setLoader2}
                dragStart={dragStart}
                setSource2SqlData={setSource2SqlData}
                setSource2SqlQueryData={setSource2SqlQueryData}
                getConnections2TableData={getConnections2TableData}
                finalValidation={finalValidation}
                source2Dragitems={source2Dragitems}
                onChangeSource2Column={onChangeSource2Column}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <>
        <Grid container sx={{ mt: 2 }} item>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box component={Paper} className="drawerShortHead">
              <Typography variant="h6">{t("Column Mapping")}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    color: !isFullScan ? "#E89D45" : "black",
                    fontWeight: !isFullScan ? "bold" : "normal",
                    marginRight: "8px",
                  }}>
                  {t("Position-Based Row Comparison")}
                </Typography>
                <Switch size="small" checked={isFullScan} onChange={handleSwitchChange} />
                <Typography
                  sx={{
                    color: isFullScan ? "#E89D45" : "black",
                    fontWeight: isFullScan ? "bold" : "normal",
                    marginRight: "8px",
                  }}>
                  {t("Intersection Row Comparison")}
                </Typography>
              </Box>

              <CustomWidthTooltip
                arrow
                placement="top-end"
                title={
                  <>
                    <Typography>
                      <b>Position-Based Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or
                      tables by comparing based on the sequential order or positions.
                    </Typography>
                    <Typography>
                      <b>Intersection Row Comparison</b> : Conducts a row-by-row comparison of data in datasets or
                      tables by comparing irrespective of their sequential order or positions.
                    </Typography>
                  </>
                }>
                <IconButton size="small">
                  <InfoOutlinedIcon />
                </IconButton>
              </CustomWidthTooltip>
            </Box>
          </Box>
          <Grid xs={12} item>
            <Card onDrop={onDrop} onDragOver={onDragOver}>
              <Box className="innerSubHead">
                <Grid container alignItems="center" item>
                  <Grid sm={4} item></Grid>
                  <Grid sm={8} item className="innersubRight">
                    <Box className="createBtn">
                      {selected.length > 0 ? (
                        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                          {selected.length} {t("Selected")}
                          <Tooltip title="Delete">
                            <IconButton size="small" onClick={deleteSelected}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th.MuiTableCell-root": {
                          backgroundColor: "#e5f6fd",
                          color: "#1976d2",
                          padding: "4px",
                          height: "54px",
                        },
                      }}>
                      <TableCell colSpan={2} align="center" sx={{ width: "50%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <Typography variant="h6">
                            {t("1st Data Source")}
                            {source1?.connectionName && source1Data?.database ? " [Database]" : " [File]"}
                          </Typography>

                          {!source1?.path && source1Data?.database && source1Sql === "No" && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}>
                              <OrderbyDropdown
                                isDisabled={isCheckedAnyDragitem || isPrimaryKey}
                                options={source1Coulumns}
                                fieldName={firstSourceOrderBy}
                                setSelectedValue={(e) => {
                                  if (e?.value) {
                                    setFirstSourceOrderBy(e?.value);
                                  }
                                }}
                                showError={showOrderByError && !firstSourceOrderBy}
                              />
                              <IconButton
                                sx={{ padding: 0 }}
                                color="primary"
                                aria-describedby={"firstDataSource"}
                                variant="contained"
                                disabled={firstSourceOrderBy ? false : true}
                                onClick={onClickViewTableSource1}>
                                <Tooltip title={t("View Table Data")} placement="top">
                                  <RemoveRedEyeOutlinedIcon size="small" />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell colSpan={2} align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}>
                          <Typography variant="h6">
                            {t("2nd Data Source")}
                            {source2?.connectionName && source2Data?.database ? " [Database]" : " [File]"}
                          </Typography>{" "}
                          {!source2?.path && source2Data?.database && source1Sql === "No" && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}>
                              <OrderbyDropdown
                                isDisabled={isCheckedAnyDragitem || isPrimaryKey}
                                options={source2Coulumns}
                                fieldName={secondSourceOrderBy}
                                setSelectedValue={(e) => {
                                  if (e?.value) {
                                    setSecondSourceOrderBy(e?.value);
                                  }
                                }}
                                showError={showOrderByError && !secondSourceOrderBy}
                              />

                              <IconButton
                                sx={{ padding: 0 }}
                                color="primary"
                                aria-describedby={"secondDataSource"}
                                variant="contained"
                                disabled={secondSourceOrderBy ? false : true}
                                onClick={onClickViewTableSource2}>
                                <Tooltip title="View Table Data" placement="top">
                                  <RemoveRedEyeOutlinedIcon size="small" />
                                </Tooltip>
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%">
                        <Checkbox
                          size="small"
                          onClick={(e) => {
                            handleSelectAllClick(e);
                          }}
                          checked={selected.length > 0 && selected.length === range}
                        />
                      </TableCell>
                      {source1?.connectionName ? (
                        <>
                          <TableCell width="41%">
                            {t("Table Name")} - {t("Column Name")}
                          </TableCell>
                          {source1Sql === "No" && !(source1Data?.file && source2Data?.file) && (
                            <TableCell align="center" width="8%">
                              {t("Is Key Column ?")}
                            </TableCell>
                          )}
                        </>
                      ) : (
                        <>
                          <TableCell width="46%">{t("File")} </TableCell>
                          {!(source1Data?.file && source2Data?.file) && (
                            <TableCell align="center" width="8%">
                              {t("Is Key Column ?")}
                            </TableCell>
                          )}
                        </>
                      )}

                      {source2?.connectionName ? (
                        <>
                          <TableCell width="46%">
                            {t("Table Name")} - {t("Column Name")}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell width="46%">{t("File")} </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(range)].map((obj, index) => {
                      const isItemSelected = isSelected(index);
                      return (
                        <TableRow key={index}>
                          <TableCell align="center">
                            <Checkbox
                              size="small"
                              onClick={(e) => {
                                handleClick(e, index);
                              }}
                              checked={isItemSelected}
                            />
                          </TableCell>
                          <TableCell>
                            {source1Dragitems[index]?.Table} {source1Dragitems[index]?.Table ? "-" : ""}{" "}
                            {source1Dragitems[index]?.Column}
                          </TableCell>
                          {source1Sql === "No" && !(source1Data?.file && source2Data?.file) && (
                            <TableCell>
                              <Radio
                                disabled={isPrimaryKey || (source1Data?.file && source2Data?.file)}
                                checked={source1Dragitems[index]?.IsKey}
                                size="small"
                                value={source1Dragitems[index]?.IsKey}
                                onClick={(e) => {
                                  handleIsKey(
                                    e,
                                    index,
                                    source1Dragitems[index]?.Column,
                                    source2Dragitems[index]?.Column,
                                  );
                                }}
                              />
                            </TableCell>
                          )}

                          <TableCell>
                            {source2Dragitems[index]?.Table} {source2Dragitems[index]?.Table ? "-" : ""}{" "}
                            {source2Dragitems[index]?.Column}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow sx={{ alignItems: "center" }}>
                      <TableCell colSpan={5} align="center">
                        <Typography sx={{ p: 1, fontSize: "14px", opacity: "0.5" }}>{t("Drop here")}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              disabled={checkValidationSelected}
              onClick={() => {
                resetValidation();
              }}
              size="small"
              sx={{ mr: 1 }}>
              {t("Reset")}
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={checkValidationSelected}
              onClick={() => {
                if (
                  ((secondSourceOrderBy || source2Data?.file) && (firstSourceOrderBy || source1Data?.file)) ||
                  source1Sql === "Yes"
                ) {
                  addValidation();
                } else {
                  setShowOrderByError(true);
                }
              }}>
              {`${t("Add")} ${t("Data Quality Checks")}`}
            </Button>
          </Grid>
        </Grid>

        {finalValidation.length > 0 && (
          <CompareFinalValidation
            finalValidation={finalValidation}
            setFinalValidation={setFinalValidation}
            setfinalSelected={setfinalSelected}
            finalSelected={finalSelected}
            source1={source1}
            source2={source2}
            source1Sql={source1Sql}
          />
        )}
      </>
      {openFirstDataSource && (
        <ViewTableDatabaseToFile
          viewTableData={source1TableData}
          viewTableDataLoading={source1TableDataLoading}
          id={"firstDataSource"}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {openSecondDataSource && (
        <ViewTableDatabaseToFile
          viewTableData={source2TableData}
          viewTableDataLoading={source2TableDataLoading}
          id={"secondDataSource"}
          anchorEl={anchorEl2}
          setAnchorEl={setAnchorEl2}
        />
      )}
    </React.Fragment>
  );
}
