import { useEffect, useState } from "react";
import CustomHeaderAgGrid from "../AgGrid/CustomHeaderAgGrid";

export default function UniqueTable({ highLightColumn, headCells }) {
  const [bodyData, setBodyData] = useState([]);
  useEffect(() => {
    setBodyData(headCells);
  }, [headCells]);
  return (
    <>
      {Array.isArray(bodyData) && bodyData?.length > 0 && (
        <CustomHeaderAgGrid
          data={bodyData}
          errorColumn={{ columns: highLightColumn, color: "red" }}
        />
      )}
    </>
  );
}
