import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const TotalBatchesPopup = ({ anchorE2, setAnchorE2 }) => {
  const id = anchorE2 ? "simple-popover2" : undefined;
  const open = Boolean(anchorE2);
  const onMouseLeave = (e) => {
    e.stopPropagation();
    setAnchorE2(null);
  };
  return (
    <Popover
      id={id}
      anchorEl={anchorE2}
      onClose={(e) => {
        onMouseLeave(e);
      }}
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        sx={{ p: 1 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton
          onClick={(e) => {
            onMouseLeave(e);
          }}
          color="error"
          aria-label="close popover"
          sx={{
            position: "absolute",
            right: -1,
            top: -1,
            background: "#fff",
            p: "2px",
            ":hover": { background: "#fff" },
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <TableContainer
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{ maxWidth: 650, maxHeight: 300 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableCell></TableCell>
            </TableHead>
            <TableBody>
              {/* {popupData?.map((itemRow, ki) => {
                return (
                  <TableRow key={ki}>
                    {[1, 2, 3].map((v, i) => {
                      return <TableCell key={i}>{v}</TableCell>;
                    })}
                  </TableRow>
                );
              })} */}
              {/* {popupData?.length === 0 && (
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    No records to display
                  </TableCell>
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Popover>
  );
};

export default TotalBatchesPopup;
