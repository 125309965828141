import { useState } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormTabs } from "./FormTabs";

const AuthenticationOptions = [
  {
    value: "UserAuthentication",
    label: "User Authentication",
  },
  {
    value: "ServiceAuthentication",
    label: "Service Authentication",
  },
];

const tabConfig = {
  RefreshToken: {
    tab1: "Refresh token",
    tab2: "Azure Key vault",
    type: "text",
  },
  ClientSecret: {
    tab1: "Client Secret",
    tab2: "Azure Key vault",
    type: "text",
  },
};

export default function BigQueryForm({
  register,
  errors,
  control,
  formData,
  defaultValues,
}) {
  const [authType, setAuthType] = useState("UserAuthentication");
  const handleChangeAuthType = (e) => {
    setAuthType(e.target.value);
  };

  return (
    <>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          label="Description"
          minRows={2}
          maxRows={4}
          multiline
          variant="outlined"
          {...register("Description")}
          defaultValue={formData?.Description}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          label="Project ID"
          {...register("projectid", { required: true })}
          error={errors.projectid}
          defaultValue={formData?.projectid}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          label="Additional Project IDs"
          {...register("additionalPIDs")}
          defaultValue={formData?.additioanalPIDs}
        />
      </Grid>
      <Grid item sm={12}>
        <Controller
          name="authenticationType"
          control={control}
          defaultValue={
            defaultValues.authenticationType || "UserAuthentication"
          }
          render={({ field }) => (
            <TextField
              fullWidth
              size="small"
              select
              label="Authentication"
              {...register("authenticationType", {
                required: true,
              })}
              {...field}
              onChange={handleChangeAuthType}
            >
              {AuthenticationOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      {authType === "UserAuthentication" ? (
        <>
          <Grid item sm={12}>
            <TextField
              fullWidth
              size="small"
              label="Client ID"
              {...register("ClientId", { required: true })}
              error={errors.ClientId}
              defaultValue={formData?.ClientId}
            />
          </Grid>
          <Grid item sm={12}>
            <FormTabs
              label={tabConfig.ClientSecret}
              register={register}
              errors={errors}
              control={control}
              formData={formData}
            />
          </Grid>
          <Grid item sm={12}>
            <FormTabs
              label={tabConfig.RefreshToken}
              register={register}
              errors={errors}
              control={control}
              formData={formData}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item sm={12}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid Email",
                },
              })}
              error={!!errors.email}
              defaultValue={formData?.email}
              helperText={errors?.email?.message ?? " "}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              fullWidth
              size="small"
              label="Key File Path"
              {...register("keyfilepath", { required: true })}
              error={errors.keyfilepath}
              defaultValue={formData?.keyfilepath}
            />
          </Grid>
        </>
      )}
    </>
  );
}
