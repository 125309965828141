import { Box, InputBase, Paper, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const SelectColumnsCount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const columnsCount = parseInt(searchParams.get("columnsCount"));

  const onChangeColumns = (event) => {
    event.target.value > columnsCount &&
      event.target.value <= 100 &&
      setSearchParams((params) => {
        params.set("columnsCount", event.target.value);
        return params;
      });
  };
  return (
    <Box className="v-center" gap={1}>
      <Typography>No. of Columns : </Typography>
      <Paper
        className="v-center"
        sx={{
          borderRadius: "4px",
          border: "0.5px solid #ccc",
          width: "72px",
          height: "24px",
          paddingLeft: "8px",
          paddingRight: "8px",
          color: "#4F4F4F",
          fontWeight: 500,
          "& input": {
            padding: "3px 0px",
          },
        }}>
        <InputBase
          inputProps={{ min: columnsCount, max: 100 }}
          type="number"
          size="small"
          placeholder="Columns"
          value={columnsCount}
          onChange={onChangeColumns}
        />
      </Paper>
    </Box>
  );
};

export default SelectColumnsCount;
