import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Fragment, useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialUserState = {
  Name: "",
  Email: "",
  Password: "Password",
  TestValidationIds: [],
  emailError: "",
};
export default function AddNewMail({
  dropDown,
  fetchData,
  edit,
  userData,
  selected,
  deleteSelected,
  openDialog,
  setOpenDialog,
}) {
  const { t } = useTranslation();

  const { setSnack } = useContext(SnackbarContext);
  const [state, setState] = useState({ right: false });
  const [SelectedValue, setSelectedValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(initialUserState);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (edit) {
      let a = {
        Name: "",
        Email: "",
        Password: "Password",
        TestValidationIds: [],
        errors: {},
      };
      a["Name"] = userData.Name;
      a["Email"] = userData.Email;
      a["Password"] = userData.Password;
      let val = [];
      userData.TestValidationIds.forEach((element) => {
        let dropDownFilter = dropDown.filter((obj) => obj._id === element._id);
        val.push(dropDownFilter[0]);
      });
      setSelectedValue(val);
      a["TestValidationIds"] = userData.TestValidationIds;
      setUsers(a);
    }
  }, [userData]);

  const handleChange = (e) => {
    let data = { ...users };
    data[e.target.name] = e.target.value;
    setUsers(data);
  };

  const addEmail = async () => {
    if (SelectedValue.length === 0) {
      setSnack({
        message: "Select atleast one test",
        open: true,
        colour: "error",
      });
      return;
    }
    setLoading(true);
    users.TestValidationIds = SelectedValue;
    try {
      await ApiService.addUser(users);
      setState({ right: false });
      fetchData();
      setUsers(initialUserState);
      setSelectedValue([]);
      setSnack({ message: "Created", open: true, colour: "success" });
    } catch (error) {
      let usersErrors = { ...users };
      usersErrors.errors = error.response.data;
      setUsers(usersErrors);
    }
    setLoading(false);
  };

  const editEmail = async () => {
    if (SelectedValue.length === 0) {
      setSnack({
        message: "Select atleast one test",
        open: true,
        colour: "error",
      });
      return;
    }
    setLoading(true);
    users.TestValidationIds = SelectedValue;
    try {
      await ApiService.editUser(users, userData._id);
      setState({ right: false });
      fetchData();
      setSnack({ message: "Updated", open: true, colour: "success" });
    } catch (error) {
      let usersErrors = { ...users };
      usersErrors.errors = error.response.data;
      setUsers(usersErrors);
    }
    setLoading(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open && edit === false) {
      setUsers(initialUserState);
      setSelectedValue([]);
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 500 }}
      role="presentation"
    >
      <Paper elevation={0}>
        <Box className="drawerHead">
          <Typography variant="h6">
            {!edit ? "Add New User:" : "Update User:"}
          </Typography>
        </Box>
        <Box component="form">
          <Grid spacing={2} container>
            <Grid sm={12} item>
              <TextField
                size="small"
                value={users.Name}
                helperText={users.errors?.Name?.message}
                error={users.errors?.Name}
                name="Name"
                onChange={handleChange}
                fullWidth
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid sm={12} item>
              <TextField
                size="small"
                value={users.Email}
                helperText={users.errors?.Email?.message}
                error={users.errors?.Email}
                name="Email"
                onChange={handleChange}
                fullWidth
                label="Email"
                variant="outlined"
              />
            </Grid>
            <Grid sm={12} item>
              <Autocomplete
                multiple
                size="small"
                limitTags={1}
                id="checkboxes-tags-demo"
                options={dropDown}
                value={SelectedValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.TestName}
                onChange={(event, newValue, reason) => {
                  if (reason === "selectOption") {
                    setSelectedValue(newValue);
                  } else if (reason === "removeOption") {
                    setSelectedValue(newValue);
                  } else if (reason === "clear") {
                    setSelectedValue([]);
                  }
                }}
                renderOption={(props, option, { isSelected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={isSelected}
                    />
                    {option.TestName}
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Data Quality Rules"
                    placeholder="Test Name"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt="16px">
          <Button
            variant="outlined"
            onClick={toggleDrawer(anchor, false)}
            size="small"
            color="error"
          >
            {t("Cancel")}
          </Button>
          {!edit ? (
            <LoadingButton
              disabled={loading}
              onClick={addEmail}
              variant="contained"
              className="loader-button"
              size="small"
              loading={loading}
              sx={{ float: "right" }}
              startIcon={<></>}
              loadingPosition="start"
            >
              {t("Save")}
            </LoadingButton>
          ) : (
            <LoadingButton
              disabled={loading}
              onClick={editEmail}
              variant="contained"
              className="loader-button"
              size="small"
              loading={loading}
              sx={{ float: "right" }}
              startIcon={<></>}
              loadingPosition="start"
            >
              {t("Update")}
            </LoadingButton>
          )}
        </Box>
      </Paper>
    </Box>
  );
  const onClickDelete = async () => {
    setDeleteLoading(true);
    await deleteSelected();
    setDeleteLoading(false);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <Fragment>
        {!edit ? (
          <>
            {selected.length !== 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} {t("Selected")}
                </Typography>
                <Tooltip title={t("Delete")}>
                  <IconButton size="small" onClick={handleClickOpenDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {selected.length <= 0 && (
              <Button
                onClick={toggleDrawer("right", true)}
                variant="outlined"
                size="small"
              >
                add user
              </Button>
            )}

            {openDialog && (
              <CustomDeleteModal
                loading={deleteLoading}
                disabled={deleteLoading}
                onClickDelete={onClickDelete}
                handleClose={handleCloseDialog}
              />
            )}
          </>
        ) : (
          <IconButton
            color="success"
            onClick={toggleDrawer("right", true)}
            title="Edit"
            size="small"
          >
            <EditOutlinedIcon />
          </IconButton>
        )}
        <Drawer anchor={"right"} open={state["right"]}>
          {list("right")}
        </Drawer>
      </Fragment>
    </div>
  );
}
