import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const GeneralInfo = ({ previewConData }) => {
  const renderInfoItem = (label, value) => (
    <Box className="infoItemBox">
      <Typography>
        {label}
        <Typography
          component="span"
          variant="bold"
          color={value ? "success.main" : "error"}
        >
          {`(${value ? "Yes" : "No"})`}
        </Typography>
      </Typography>
    </Box>
  );
  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S. No</TableCell>
            <TableCell>Column Name </TableCell>
            <TableCell>Data Type </TableCell>
            <TableCell>Constraints </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previewConData?.columns?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row?.Name}</TableCell>
              <TableCell>{row?.DataType}</TableCell>
              <TableCell>
                <Box className="infoBox">
                  {renderInfoItem("Primary Key", row?.IsPrimaryKey)}
                  {renderInfoItem("Unique Key", row?.IsUniqueKey)}
                  {renderInfoItem("Nullable", row?.IsNullable)}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GeneralInfo;
