import { customBooleanComparator, customComparator } from "../AgGrid/CustomSort";
import { formatDate } from "../../_helpers/utils";
import { Chip, Typography } from "@mui/material";
import { roleAttrs } from "./DQGUser_Constants";
import { userLocalSession } from "../../_helpers/Constant";
const userDetails = JSON.parse(localStorage.getItem(userLocalSession));

function renderRole(props) {
  const role = props?.data?.RoleName;
  return (
    <Chip
      className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}
      sx={{
        color: "#fff",
        mr: 1,
        backgroundColor: roleAttrs[role]?.color,
        borderRadius: "6px",
      }}
      size="small"
      label={role}
    />
  );
}
const renderName = (props) => (
  <Typography className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}>
    {`${props?.data?.FirstName} ${props?.data?.LastName}`}
  </Typography>
);
const renderEmail = (props) => (
  <Typography className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}>{props?.data?.Email}</Typography>
);

const renderStatus = (props) => (
  <Chip
    className={`${props?.data?.IsOnline ? "OnlineChip" : "OfflineChip"} ${
      props?.data?.Email === userDetails?.Email ? "bold-text" : ""
    }`}
    size="small"
    label={props?.data?.IsOnline ? "Online" : "Offline"}
  />
);
const renderLastLogin = (props) => (
  <Typography className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}>
    {formatDate(props?.data?.LastLogin)}
  </Typography>
);
const renderCreatedDate = (props) => (
  <Typography className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}>
    {formatDate(props?.data?.CreatedAt)}{" "}
  </Typography>
);
const renderLastModified = (props) => (
  <Typography className={props?.data?.Email === userDetails?.Email ? "bold-text" : ""}>
    {formatDate(props?.data?.ModifiedAt)}
  </Typography>
);

export const DQGUsersHeadcells = [
  {
    field: "FirstName",
    headerName: "Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    cellRenderer: renderName,
  },
  {
    field: "RoleName",
    headerName: "Role",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agSetColumnFilter",
    comparator: customComparator,
    cellRenderer: renderRole,
  },
  {
    field: "Email",
    headerName: "Email",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    cellRenderer: renderEmail,
  },
  {
    field: "IsOnline",
    headerName: "Status",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agSetColumnFilter",
    comparator: customBooleanComparator,
    cellRenderer: renderStatus,
  },
  {
    field: "LastLogin",
    headerName: "Last Logged In",
    sortable: true,
    filter: false,
    cellRenderer: renderLastLogin,
  },
  {
    field: "CreatedAt",
    headerName: "Created",
    sortable: true,
    filter: false,
    cellRenderer: renderCreatedDate,
  },
  {
    field: "ModifiedAt",
    headerName: "Last Modified",
    sortable: true,
    filter: false,
    cellRenderer: renderLastModified,
  },
  {},
];

// function renderOnlyDate(props) {
//   return <>{formatDate(props?.data?.TimeStamp)}</>;
// }

// function renderOnlyTime(props) {
//   return <>{formattedTime(props?.data?.TimeStamp)}</>;
// }
// const renderDateTime = (props) => {
//   const formattedDate = moment
//     .utc(props?.data?.TimeStamp)
//     .local()
//     .format("MM/DD/YYYY h:mm:ss A");
//   console.log(formattedDate, props?.data?.TimeStamp, "TimeStamp");
//   return formattedDate;
// };

export const DQGUserLogHeadcells = [
  {
    field: "Activity",
    headerName: "Activity",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },

  {
    field: "TimeStamp",
    headerName: "Date & Time",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    // comparator: renderOnlyTime,
    // cellRenderer: renderDateTime,
  },
  // {
  //   field: "Duration",
  //   headerName: "Duration(ms)",
  //   sortable: true,
  //   suppressColumnsToolPanel: true,
  //   filter: "agTextColumnFilter",
  //   comparator: customComparator,
  // },
  {
    field: "Status",
    headerName: "Status",
    cellStyle: (params) => {
      // Conditionally set text color based on cell data
      if (params?.value === "Success") {
        return { color: "#52BE80" };
      }
      return { color: "#DC7633" };
    },
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
];
