import { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import InnerHeader from "../Layout/InnerHeader";
import APIServices from "../../services/app.service";
import { SnackbarContext } from "../../App";
import SkeletonLoader from "../SkeletonLoader";
import CustomDialog from "../CustomComponents/CustomDialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

const CustomComponent = ({ handleOnClick, isChecked }) => {
  return (
    <IconButton size="small" onClick={handleOnClick} color={isChecked ? "success" : "error"}>
      {isChecked ? <CheckIcon fontSize="small" /> : <ClearIcon />}{" "}
    </IconButton>
  );
};

const subscriptionChanges = (rows, input) => {
  return input.map((code) => {
    const foundFeature = rows.find((item) => item.actions.some((action) => action.templeteCode === code?.templeteCode));

    if (foundFeature) {
      const action = foundFeature.actions.find((action) => action.templeteCode === code.templeteCode);
      const actionName = action.name.charAt(0).toUpperCase() + action.name.slice(1); // Capitalize first letter
      const subscriptionStatus = action.isSubscribed ? "subscribed" : "unsubscribed";
      return { isSubscribed: action.isSubscribed, text: `${foundFeature.feature} ${actionName} ${subscriptionStatus}` };
    }
    return `${code} not found`;

  });
};

const Subscribe = () => {
  const [rows, setRows] = useState([]);
  const [actions, setActions] = useState([]);
  const [allActions, setAllActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [modifiedRows, setModifiedRows] = useState([]);
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const NotificationSubscription = async () => {
    setLoading(true);
    try {
      const result = await APIServices.NotificationSubscriptionJson();
      setRows(result.data?.templeteStatus);
      setActions(result.data?.actions);
      const allOriginalCodes = [];
      for (const originalRow of result.data?.templeteStatus || []) {
        allOriginalCodes.push(...originalRow.actions);
      }
      setAllActions(allOriginalCodes);
      setModifiedRows([]);
    } catch (e) {
      setSnack({
        message: e?.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };
  const checkValue = (row, action) => {
    const value = row?.actions?.find((e) => e.name === action);
    return value?.isSubscribed;
  };
  const optionAvailable = (row, action) => {
    return row?.actions?.find((e) => e.name === action);
  };

  const handleTempleteCodeChange = (rowAction) => {
    setModifiedRows((prevRows) => {
      const existingIndex = prevRows.findIndex((row) => row.templeteCode === rowAction.templeteCode);
      let updatedTempleteCodes;
      if (existingIndex > -1) {
        updatedTempleteCodes = prevRows.map((item, index) =>
          index === existingIndex ? { ...item, ...rowAction } : item,
        );
      } else {
        updatedTempleteCodes = [...prevRows, rowAction];
      }
      return updatedTempleteCodes;
    });
  };

  const handleOnClick = (row, action) => {
    const newRows = rows.map((r) => {
      if (r.feature === row.feature) {
        return {
          ...r,
          actions: r.actions.map((a) => {
            if (a.name === action) {
              const updateRow = { ...a, isSubscribed: !a.isSubscribed };
              handleTempleteCodeChange(updateRow);
              return updateRow;
            }
            return a;
          }),
        };
      }
      return r;
    });
    setRows(newRows);
  };

  const saveUpdates = async () => {
    const finalModifiedRows = [];
    for (const mr of modifiedRows) {
      const checkRow = allActions.find((e) => e.templeteCode === mr.templeteCode);
      if (checkRow?.isSubscribed !== mr?.isSubscribed) {
        finalModifiedRows.push(mr);
      }
    }
    const requestBody = {
      UnsubscribedTempleteCodes: finalModifiedRows.filter((el) => el.isSubscribed === false).map((e) => e.templeteCode),
      SubscribedTempleteCodes: finalModifiedRows.filter((el) => el.isSubscribed === true).map((e) => e.templeteCode),
    };

    if (confirmUpdate) {
      try {
        await APIServices.NotificationSubscriptionUpdate(requestBody);
        setSnack({
          message: "upadated successfully",
          open: true,
          colour: "success",
        });
      } catch (e) {
        setSnack({
          message: "Oops Somthing went wrong..!",
          open: true,
          colour: "error",
        });
      }
      setConfirmUpdate(false);
    } else {
      setConfirmUpdate(true);
    }
  };
  useEffect(() => {
    NotificationSubscription();
  }, []);

  let content;

  if (loading) {
    content = <SkeletonLoader />;
  } else if (actions.length > 0) {
    content = (
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Feature</TableCell>
                {actions?.map((a) => (
                  <TableCell key={a?.name} align="left">
                    {a.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rows || []).map((row) => (
                <TableRow key={row.feature}>
                  <TableCell align="left">{row?.feature}</TableCell>
                  {actions?.map((a) => (
                    <TableCell key={a?.name} align="left">
                      {optionAvailable(row, a?.name) && (
                        <CustomComponent
                          handleOnClick={() => handleOnClick(row, a?.name)}
                          isChecked={checkValue(row, a?.name)}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={{ p: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4} textAlign="left">
            <Button
              variant="outlined"
              color="error"
              size="medium"
              onClick={() => {
                navigate("/");
              }}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs textAlign="right">
            <Button
              disabled={modifiedRows.length === 0}
              onClick={saveUpdates}
              variant="contained"
              color="success"
              size="small">
              {"Update"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    content = <Typography>{`${t("No records available")}`}</Typography>;
  }


  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        <Typography variant="h6">Subscriptions</Typography>
      </InnerHeader>
      <Box sx={{ width: "100%", mb: 2 }} className="pt74">
        {content}
        <CustomDialog
          open={confirmUpdate}
          title={`${t("Are you sure want to update?")}`}
          minWidth={450}
          onClose={() => setConfirmUpdate(false)}>
          <Box
            sx={{
              flexDirection: "column",
              minHeight: "auto !important",
              padding: 1,
              width: "100%",
              "& .MuiButton-root": { mt: "16px" },
            }}>
            <Typography sx={{ opacity: 0.6, fontWeight: 700 }}>You have changed these items.</Typography>
            <List>
              {(subscriptionChanges(rows, modifiedRows) || []).map((e) => (
                <ListItem key={e} disablePadding>
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      {e?.isSubscribed ? (
                        <CheckCircleIcon color="success" fontSize="small" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={e?.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={saveUpdates}
              variant="contained"
              color="success"
              mt={2}
              sx={{ float: "right" }}
              size="small">
              {"Update"}
            </Button>
          </Box>
        </CustomDialog>
      </Box>
    </Box>
  );
};
export default Subscribe;
