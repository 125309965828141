import { Box, Tooltip } from "@mui/material";
import * as React from "react";
import { customCellRenderNumberComparator, customComparator } from "../AgGrid/CustomSort";
import { formatDate, formatDateWithTime } from "../../_helpers/utils";
import { ScheduleFrequency } from "../../_helpers/Constant";

export const testSchedulerHeadCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    filter: false,
    maxWidth: 80,
  },
  {
    field: "Title",
    headerName: "Title",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Title",
  },
  {
    field: "Description",
    headerName: "Description",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
    headerTooltip: "Description",
  },
  // {
  //   field: "TestType",
  //   headerName: "Test Type",
  //   sortable: true,
  //   suppressColumnsToolPanel: true,
  //   filter: "agTextColumnFilter",
  //   comparator: customComparator,
  //   headerTooltip: "Test Type",
  // },
  {
    headerName: "Total Tests",
    field: "TestNames",
    sortable: false,
    filter: false,
    cellRenderer: totalTests,
    comparator: customCellRenderNumberComparator,
    headerTooltip: "Total Tests",
  },
  {
    field: "FromDate",
    headerName: "Schedule Start",
    sortable: true,
    // filter: false,
    // suppressColumnsToolPanel: true,
    filter: "agDateColumnFilter",
    cellRenderer: StartDate,
    headerTooltip: "Schedule Start Date & Time",
  },
  {
    field: "NoOfTimes",
    headerName: "Repeat",
    sortable: false,
    filter: false,
    cellRenderer: RepeatType,
    headerTooltip: "Repeat Type",
  },
  {
    field: "ToDate",
    headerName: "Schedule End",
    sortable: true,
    // filter: false,
    // suppressColumnsToolPanel: true,
    filter: "agDateColumnFilter",
    cellRenderer: EndDate,
    headerTooltip: "Schedule End Date & Time",
  },
  // {
  //   field: "ExecuteParallel",
  //   headerName: "Execute In Parallel",
  //   sortable: false,
  //   filter: false,
  //   cellRenderer: ExecuteParallel,
  //   headerTooltip: "Execute In Parallel",
  // },
  // {
  //   field: "Active",
  //   headerName: "Active",
  //   sortable: true,
  //   filter: false,
  //   cellRenderer: Active,
  //   headerTooltip: "Active",
  // },
  {
    field: "createdAt",
    headerName: "Created Date",
    sortable: true,
    filter: false,
    // suppressColumnsToolPanel: true,
    // filter: "agDateColumnFilter",
    cellRenderer: createdDate,
    headerTooltip: "Created Date",
  },
  {},
];

function RepeatType(props) {
  return (
    <>
      {props?.data?.RepeatType === false
        ? "None"
        : `Every ${props?.data?.Each} ${ScheduleFrequency[props?.data?.Frequency]}`}
    </>
  );
}

// function ExecuteParallel(props) {
//   return <>{props.data?.ExecuteParallel?.toString()}</>;
// }

function StartDate(props) {
  return <>{formatDateWithTime(props?.data?.FromDate)}</>;
}
function EndDate(props) {
  return <>{formatDateWithTime(props?.data?.ToDate)}</>;
}

function createdDate(props) {
  return <>{formatDate(props?.data?.createdAt)}</>;
}

function totalTests(props) {
  return (
    <Tooltip
      title={
        <React.Fragment>
          {props?.data?.TestNames?.map((obj, index) => {
            return (
              <div key={index}>
                {index + 1}.{obj?.TestName}
              </div>
            );
          })}
        </React.Fragment>
      }
      placement="top-end"
      arrow>
      <Box>{props.data.TestType === "Visual Test" ? props.data.VisuvalTestIds.length : props.data.TestIds.length}</Box>
    </Tooltip>
  );
}

// function Active(props) {
//   return <>{props.data.Active.toString()}</>;
// }
