/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { roleAttrs } from "./DQGUser_Constants";
import { useContext, useLayoutEffect, useState } from "react";
import { SnackbarContext } from "../../App";
import appService from "../../services/app.service";

const StyledButton = styled(Button)(({ isselected }) => ({
  width: "175px",
  fontWeight: isselected ? "bold" : "normal",
  transition: "background-color 0.3s ease-in-out",
}));

function CustomRadioButtons({ selected, setSelected }) {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [rolelist, setRoleList] = useState([]);

  const handleButtonClick = (value) => {
    setSelected(value.Id);
  };

  const fetchRoles = async () => {
    try {
      const roleList = await appService.getUserRoles();
      setRoleList(roleList?.ResponseObject);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
  };
  useLayoutEffect(() => {
    fetchRoles();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
      }}>
      {rolelist?.map((role, i) => {
        const isSelected = role?.Id === selected;

        return (
          <StyledButton
            key={i}
            isselected={isSelected}
            sx={{
              border: "none",
              borderRadius: 2,
              boxShadow: "none",
              backgroundColor: isSelected ? roleAttrs[role?.Name]?.color : "none",
              color: isSelected ? "#fff" : roleAttrs[role?.Name]?.color,

              "&:hover": {
                bgcolor: roleAttrs[role?.Name]?.color,
                color: "#fff",
                boxShadow: "none",
                border: "none",
              },
            }}
            startIcon={roleAttrs[role?.Name]?.icon}
            size="medium"
            variant={isSelected ? "contained" : "outlined"}
            onClick={() => handleButtonClick(role)}>
            {t(role?.Name)}
          </StyledButton>
        );
      })}
    </div>
  );
}

export default CustomRadioButtons;
