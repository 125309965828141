import { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

const PieChart = ({ usedData, totalData }) => {
  const chartRef = useRef(null);

  const prepareData = (usedData, totalData) => {
    return [
      { label: "used", value: usedData },
      { label: "remaining", value: totalData - usedData },
    ];
  };

  useEffect(() => {
    const size = 60;
    const radius = size / 2;

    // Clear previous content
    d3.select(chartRef.current).select("svg").remove();

    // Append the svg object to the div called 'my_dataviz'
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", size)
      .attr("height", size)
      .append("g")
      .attr("transform", `translate(${size / 2}, ${size / 2})`);

    // Prepare data using the provided props
    const data = prepareData(usedData, totalData);

    // Set the color scale
    const color = d3.scaleOrdinal().range(["#e68200", "#009b00"]);

    // Create a pie chart generator
    const pie = d3.pie().value((d) => d.value);

    // Generate the pie chart data
    const pieData = pie(data);

    // Create an arc generator
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    // Build the pie chart using the arc generator
    const arcs = svg
      .selectAll("path")
      .data(pieData)
      .join("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.label)) // Use label for color mapping
      .attr("stroke", "#ddd") // Add stroke for better separation
      .style("stroke-width", "1px")
      .style("opacity", 0.7);

    // Add animation
    arcs
      .transition()
      .duration(1000)
      .attrTween("d", (d) => {
        const interpolate = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = interpolate(t);
          return arc(d);
        };
      });

    // Add text labels with percentages
    // svg
    //   .selectAll("text")
    //   .data(pieData)
    //   .join("text")
    //   .attr("transform", (d) => `translate(${arc.centroid(d)})`)
    //   .attr("dy", "0.35em")
    //   .attr("text-anchor", "middle")
    //   .style("fill", "#fff") // Text color
    //   .style("font-size", "12px") // Text font size
    //   .text((d) => `${((d.data.value / totalData) * 100).toFixed(0)}%`);
  }, [usedData, totalData]); // Add usedData and totalData as dependencies

  return <Box sx={{ marginLeft: "auto" }} id="my_dataviz" ref={chartRef}></Box>;
};

export default PieChart;
