/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SnackbarContext } from "../../App";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import appService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import CreateEditDQGUser from "./CreateEditDQGUser";
import { DQGUsersHeadcells } from "./DQGUsersHeadcells";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
import { azureAppInsights, azureLogin } from "../../_helpers/Constant";

import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import CustomDialog from "../CustomComponents/CustomDialog";
import { CustomDeleteIcon, CustomEditIcon, CustomLogsIcon } from "../CustomComponents/IconButtons";
import { CustomAgGrid } from "../AgGrid";

function ActionsCell(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const { userDetails } = useContext(UserDetailsContext);
  const [showEditUser, setShowEditUser] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const row = props.data;

  const handleNavigateLogs = (path) => {
    navigate(path);
  };

  const onClickDelete = async () => {
    setDeleteLoading(true);
    try {
      const payload = {
        LoggedinUserId: userDetails?.UserId,
        DeleteUserId: deleteUserData?.Id,
      };
      const response = await appService.deleteDQGUser(payload);
      setSnack({
        message: response?.Message,
        open: true,
        colour: "success",
      });
      props.colDef.fetchList();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setDeleteUserData({});
      setDeleteLoading(false);
    }
  };

  const handleClose = () => {
    setDeleteUserData({});
  };

  return (
    <>
      {userDetails?.RoleName === "Admin" && <CustomEditIcon title={t("Edit")} onClick={() => setShowEditUser(true)} />}
      {(userDetails?.RoleName === "Admin" || userDetails?.Email === row?.Email) && (
        <>
          {azureAppInsights && (
            <CustomLogsIcon title={t("Log data")} onClick={() => handleNavigateLogs(`/dqgusers/userlogs/${row.Id}`)} />
          )}
          <CustomDialog open={showEditUser} title={`${t("Edit")} ${t("User")}`} onClose={() => setShowEditUser(false)}>
            <CreateEditDQGUser
              existingusers={props?.colDef?.existingusers}
              fetchList={props?.colDef?.fetchList}
              defaultdata={row}
              handleClose={() => setShowEditUser(false)}
            />
          </CustomDialog>
        </>
      )}

      {userDetails?.RoleName === "Admin" && userDetails?.Email !== row?.Email && (
        <CustomDeleteIcon title={t("delete")} onClick={() => setDeleteUserData(row)} />
      )}

      {Object.keys(deleteUserData)?.length > 0 && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={onClickDelete}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default function DQGUsersList() {
  const { t } = useTranslation();
  const gridRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const [existingusers, setExistingusers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const fetchList = async () => {
    setExistingusers([]);
    setLoadingUsers(true);
    const userData = {
      UserId: userDetails?.UserId,
      OrganisationId: userDetails?.OrganisationId,
      UserEmail: userDetails?.Email,
    };
    try {
      const users = await appService.fetchExistingDQGUsers(userData);
      setExistingusers(users);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingUsers(false);
    }
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    fetchList: fetchList,
    existingusers: existingusers,
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    width: 300,
  };

  const deleteSelected = async () => {
    setDeleteLoading(true);
    try {
      const response = await appService.deleteDQGUser(selected[0]);
      setSnack({
        message: response?.Message,
        open: true,
        colour: "success",
      });
      fetchList();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setOpenDialog(false);
      setSelected([]);
      setDeleteLoading(false);
    }
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchList();
    DQGUsersHeadcells[DQGUsersHeadcells.length - 1] = action;
  }, []);

  return (
    <>
      <InnerHeader>
        <Box className="DSHeaderContainer">
          <Typography variant="h6" className="upper-case">
            {t("Users")}
          </Typography>
          {checkAccess("DQGUsers", "Create") && selected.length === 0 && (
            <CustomAddButton
              onClick={() => setShowCreateForm(!showCreateForm)}
              sx={{ borderRadius: 0.4, float: "right" }}
              disabled={!azureLogin && showCreateForm}
              label={`${t("Create")} ${t("User")}`}
            />
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74">
        <CustomDialog
          open={showCreateForm}
          title={`${t("Create")} ${t("User")}`}
          onClose={() => setShowCreateForm(false)}>
          <CreateEditDQGUser
            existingusers={existingusers}
            fetchList={fetchList}
            handleClose={() => setShowCreateForm(false)}
          />
        </CustomDialog>
        {existingusers?.length !== 0 ? (
          <>
            <Box className="createBtn">
              {checkAccess("DQGUsers", "Delete") && selected.length !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
                    {selected} {t("Selected")}
                  </Typography>
                  <Tooltip title={t("Delete")}>
                    <IconButton size="small" onClick={handleClickOpenDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <CustomAgGrid
              gridRef={gridRef}
              headCells={DQGUsersHeadcells}
              setSelected={setSelected}
              rows={existingusers}
            />
          </>
        ) : (
          <Box>
            {loadingUsers ? (
              <SkeletonLoader />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  height: "60vh",
                }}
                className="v-center container">
                <Typography variant="h5" sx={{ color: "#64748B", m: "auto" }}>
                  {t("No Users Found")}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {openDialog && (
          <CustomDeleteModal
            loading={deleteLoading}
            disabled={deleteLoading}
            onClickDelete={deleteSelected}
            handleClose={handleCloseDialog}
          />
        )}
      </Box>
    </>
  );
}
