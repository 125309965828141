import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, TextField, MenuItem, Paper, Box } from "@mui/material";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import SingleDatabaseQuery from "../../Connection/List/SingleDatabaseQuery";
import { useState } from "react";
import CustomRadioGroup from "../../CustomComponents/CustomRadioGroup";

const SelectTable = ({
  queryInputData,
  tableColumns,
  loadingTableDetails,
  finalValidations,
  connectionDetails,
  tablesList,
  handleSelectTable,
  setQueryInputData,
  reset,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCustomQuery = JSON.parse(searchParams.get("isCustomQuery"));
  const tableName = searchParams.get("tableName");
  const [isQueryOpened, setIsQueryOpened] = useState(Boolean(!queryInputData));

  const onChangeIsQuery = (e) => {
    searchParams.delete("tableName");
    setSearchParams((params) => {
      params.set("isCustomQuery", e.target.value);
      return params;
    });
    reset();
  };
  return (
    <Grid container className="selectTableContainer">
      <Grid item md={4}>
        <Box className="isQueryRadioButtons">
          <CustomRadioGroup
            disabled={tableColumns.length > 0 || finalValidations?.length > 0}
            value={isCustomQuery}
            onChange={onChangeIsQuery}
            options={[
              { value: true, label: t("Yes") },
              { value: false, label: t("NO") },
            ]}
            label={t("Do you wish to enter raw SQL query ?")}
          />
        </Box>
      </Grid>

      <Grid item md={8}>
        <Box component={Paper} sx={{ p: 4, borderBottomRightRadius: "12px" }}>
          {isCustomQuery ? (
            <SingleDatabaseQuery
              queryInputData={queryInputData}
              getTableData={handleSelectTable}
              finalValidations={finalValidations}
              loadingTableDetails={loadingTableDetails}
              setQueryInputData={setQueryInputData}
              isQueryOpened={isQueryOpened}
              setIsQueryOpened={setIsQueryOpened}
            />
          ) : (
            <Box sx={{ height: "100%" }} className="conValSel v-center">
              <Grid container spacing={2}>
                <Grid md={8} item>
                  <TextField
                    fullWidth
                    select
                    label={"Select Table"}
                    size="small"
                    disabled={
                      tableColumns.length > 0 || finalValidations?.length > 0
                    }
                    value={tableName}
                    onChange={(e) => {
                      e.target.value &&
                        setSearchParams((params) => {
                          params.set("tableName", e.target.value);
                          return params;
                        });
                    }}
                  >
                    {tablesList?.map((option) => (
                      <MenuItem
                        key={option.table_name}
                        value={option.table_name}
                      >
                        {option.table_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {["Salesforce"].includes(connectionDetails?.connectionType) && (
                  <Grid md={3} textAlign="center" item>
                    {/* <FormControlLabel
                      size="small"
                      control={
                        <Switch
                          size="small"
                          checked={customObjects}
                          onChange={() => setCustomObjects(!customObjects)}
                        />
                      }
                      label="Show Custom objects only"
                    /> */}
                  </Grid>
                )}
                <Grid md={2} item>
                  <CustomLoadingButton
                    onClick={() => handleSelectTable()}
                    disabled={loadingTableDetails}
                    loading={loadingTableDetails}
                  >
                    {"GO"}
                  </CustomLoadingButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SelectTable;
