/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, FormHelperText, Grid } from "@mui/material";
import ApiService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import EditDatabaseToFile from "../Validations/Step2/EditDatabaseToFile";
import CreateOrUpdateDQRule from "./CreateOrUpdateDQRule";
import { CreateValidationSchema } from "./test.schema";
import ValidateResultModal from "./ValidateResultModal";
import { SnackbarContext } from "../../App";
import { LoadingButton } from "@mui/lab";

export default function EditCompareValidation() {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const params = useParams();

  const [createValidationSchema] = useState(CreateValidationSchema);
  const [editResponseData, setEditResponseData] = useState({});
  const [source1, setSource1] = useState({});
  const [source2, setSource2] = useState({});
  const [firstSourceOrderBy, setFirstSourceOrderBy] = useState("");
  const [secondSourceOrderBy, setSecondSourceOrderBy] = useState("");
  const [source1Sql, setSource1Sql] = useState("No");
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [finalValidation, setFinalValidation] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [loadValidate, setLoadValidate] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [source1SqlData, setSource1SqlData] = useState("");
  const [source2SqlData, setSource2SqlData] = useState("");
  const [defaultRuleName, setDefaultRuleName] = useState("");
  const [isPrivate, setIsPrivate] = useState("private");
  const [isFullScan, setIsFullScan] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.editValidation(params.testId);
        setEditResponseData(response?.data);
        setSource1(response?.data?.ConnectionDetails?.FirstDatasourceDetails);
        setSource2(response?.data?.ConnectionDetails?.SecondDatasourceDetails);
        setDefaultRuleName(response?.data?.TestName);
        setIsPrivate(response?.data?.isPrivate ? "private" : "public");
        setIsFullScan(response?.data?.isFullScan);
        setFirstSourceOrderBy(response?.data?.firstSourceOrderBy);
        setSecondSourceOrderBy(response?.data?.secondSourceOrderBy);
        if (response?.data?.comparissonValidations[0]?.SqlQuery) {
          setSource1Sql("Yes");
          setSource1SqlData(response?.data?.comparissonValidations[0]?.SqlQuery?.FirstDataSource);
          setSource2SqlData(response?.data?.comparissonValidations[0]?.SqlQuery?.SecondDataSource);
          const data2 = response?.data.comparissonValidations?.map((obj) => {
            let src1 = obj?.FirstDataSource;
            src1[0]["sqlQuery"] = obj?.SqlQuery?.FirstDataSource;
            let src2 = obj?.SecondDataSource;
            src2[0]["sqlQuery"] = obj?.SqlQuery?.SecondDataSource;
            return {
              source1: src1,
              source2: src2,
              validation: {
                row_count_matching: obj?.ValidationName === "RowCount",
                row_data_matching: obj?.ValidationName === "RowComparison",
              },
            };
          });
          setFinalValidation(data2);
        } else {
          const data1 = response?.data?.comparissonValidations?.map((obj) => {
            return {
              source1: obj?.FirstDataSource,
              source2: obj?.SecondDataSource,
              validation: {
                row_count_matching: obj?.ValidationName === "RowCount",
                row_data_matching: obj?.ValidationName === "RowComparison",
              },
            };
          });
          setTimeout(() => {
            setFinalValidation(data1);
          }, 100);
        }
      } catch (e) {
        setSnack({
          message: e?.response?.data?.message ?? e.message,
          open: true,
          colour: "error",
        });
      }
    };
    fetchData();
  }, [params.testId]);

  const dataFormat = (ruleName) => {
    const data = { ...createValidationSchema };
    data.comparissonValidations = [];
    data["TestName"] = ruleName;
    data["firstSourceOrderBy"] = firstSourceOrderBy ? firstSourceOrderBy : "";
    data["secondSourceOrderBy"] = secondSourceOrderBy ? secondSourceOrderBy : "";
    data["FirstDatasourceId"] = source1.id;
    data["FirstDatasourceName"] = source1?.ext ? source1?.fileName : source1?.dataBase;
    data["FirstDatasourceType"] = source1?.ext ? "File" : "Database";
    data["SecondDatasourceId"] = source2.id;
    data["SecondDatasourceName"] = source2?.ext ? source2?.fileName : source2?.dataBase;
    data["SecondDatasourceType"] = source2?.ext ? "File" : "Database";
    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          const data__ = {};
          data__["ValidationName"] = "RowCount";
          data__["ValidationId"] = "21";
          data__["ValidationDisplayName"] = "Row Count Match";
          data__["FirstDataSource"] = [];
          data__["SecondDataSource"] = [];
          const a = {
            Table: obj1.Table ? obj1.Table : obj1.filename,
            Column: obj1.Column,
            IsKey: obj1.IsKey,
          };
          const b = {
            Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
            Column: obj.source2[index].Column,
            IsKey: obj.source2[index].IsKey,
          };
          data__["FirstDataSource"].push(a);
          data__["SecondDataSource"].push(b);
          data["comparissonValidations"].push(data__);
        });
      }
      if (obj.validation.row_data_matching === true) {
        obj.source1.forEach((obj1, index) => {
          const data__ = {};
          data__["ValidationName"] = "RowComparison";
          data__["ValidationId"] = "22";
          data__["ValidationDisplayName"] = "Row Comparison";
          data__["FirstDataSource"] = [];
          data__["SecondDataSource"] = [];

          const a = {
            Table: obj1.Table ? obj1.Table : obj1.filename,
            Column: obj1.Column,
            IsKey: obj1.IsKey,
          };

          const b = {
            Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
            Column: obj.source2[index].Column,
            IsKey: obj.source2[index].IsKey,
          };
          data__["FirstDataSource"].push(a);
          data__["SecondDataSource"].push(b);
          data["comparissonValidations"].push(data__);
        });
      }
    });
    return data;
  };

  const sqlDataFormat = (ruleName) => {
    const data = { ...createValidationSchema };
    data.comparissonValidations = [];
    data["TestName"] = ruleName;
    data["FirstDatasourceId"] = source1?.connectionName ? source1.id : source1._id;
    data["FirstDatasourceName"] = source1?.dataBase ? source1?.dataBase : source1?.fileName;
    data["FirstDatasourceType"] = source1?.fileName ? "File" : "Database";
    data["SecondDatasourceId"] = source2.id;
    data["SecondDatasourceName"] = source2?.dataBase ? source2?.dataBase : source2?.fileName;
    data["SecondDatasourceType"] = source2?.fileName ? "File" : "Database";

    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          const data__ = {};
          data__["SqlQuery"] = {
            FirstDataSource: obj1.sqlQuery ? obj1.sqlQuery : finalValidation[0]?.SqlQuery?.FirstDataSource,
            SecondDataSource: obj.source2[index].sqlQuery
              ? obj.source2[index].sqlQuery
              : finalValidation[0]?.SqlQuery?.SecondDataSource,
          };
          data__["ValidationName"] = "RowCount";
          data__["ValidationId"] = "21";
          data__["ValidationDisplayName"] = "Row Count Match";
          data__["FirstDataSource"] = [];
          data__["SecondDataSource"] = [];
          const a = {
            Table: obj1.Table ? obj1.Table : obj1?.filename,
            Column: obj1.Column,
            IsKey: obj1.IsKey,
          };
          const b = {
            Table: obj.source2[index]?.Table ? obj.source2[index].Table : obj.source2[index]?.filename,
            Column: obj.source2[index].Column,
            IsKey: obj.source2[index].IsKey,
          };
          data__["FirstDataSource"].push(a);
          data__["SecondDataSource"].push(b);
          data["comparissonValidations"].push(data__);
        });
      }
      if (obj.validation.row_data_matching === true) {
        obj.source1 &&
          obj.source1.forEach((obj1, index) => {
            const data__ = {};
            data__["SqlQuery"] = {
              FirstDataSource: obj1.sqlQuery ? obj1.sqlQuery : finalValidation[0]?.SqlQuery?.FirstDataSource,
              SecondDataSource: obj.source2[index].sqlQuery
                ? obj.source2[index].sqlQuery
                : finalValidation[0]?.SqlQuery?.SecondDataSource,
            };
            data__["ValidationName"] = "RowComparison";
            data__["ValidationId"] = "22";
            data__["ValidationDisplayName"] = "Row Comparison";
            data__["FirstDataSource"] = [];
            data__["SecondDataSource"] = [];

            const a = {
              Table: obj1.Table ? obj1.Table : obj1.filename,
              Column: obj1.Column,
              IsKey: obj1.IsKey,
            };

            const b = {
              Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
              Column: obj.source2[index].Column,
              IsKey: obj.source2[index].IsKey,
            };
            data__["FirstDataSource"].push(a);
            data__["SecondDataSource"].push(b);
            data["comparissonValidations"].push(data__);
          });
      }
    });

    return data;
  };

  const ValidationCheck = async () => {
    setLoadValidate(true);
    let data;
    if (source1Sql === "Yes") {
      data = sqlDataFormat("");
    } else {
      data = dataFormat("");
    }
    try {
      let response = await ApiService.checkValidation({
        ...data,
        isFullScan: isFullScan,
      });
      if (response?.data?.status) {
        setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
        setValidationsResultShow(true);
      } else {
        setSnack({
          message: response.data.message,
          open: true,
          colour: "error",
        });
      }
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadValidate(false);
    }
  };

  const updateValidation = async (ruleName) => {
    setUpdateLoading(true);
    let data;
    if (source1Sql === "Yes") {
      data = sqlDataFormat(ruleName);
    } else {
      data = dataFormat(ruleName);
    }

    try {
      await ApiService.updateValidation({ ...data, isFullScan: isFullScan }, params.testId);
      setSnack({ message: "DQ Rule Updated", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message,
        open: true,
        colour: "error",
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const createValidation = async (ruleName) => {
    setCreateLoading(true);
    let data;
    if (source1Sql === "Yes") {
      data = sqlDataFormat(ruleName);
    } else {
      data = dataFormat(ruleName);
    }
    try {
      await ApiService.createValidation({
        ...data,
        isPrivate: isPrivate === "private",
        isFullScan: isFullScan,
      });
      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader name={`${t("Edit")} ${t("Data Quality Rule")} - ${t("Comparison")}`} />
      {Object.keys(editResponseData)?.length > 0 ? (
        <EditDatabaseToFile
          source1={source1}
          source2={source2}
          finalValidation={finalValidation}
          setFinalValidation={setFinalValidation}
          source1Sql={source1Sql}
          setSource1Sql={setSource1Sql}
          sqlQuery={false}
          setFirstSourceOrderBy={setFirstSourceOrderBy}
          firstSourceOrderBy={firstSourceOrderBy}
          secondSourceOrderBy={secondSourceOrderBy}
          setSecondSourceOrderBy={setSecondSourceOrderBy}
          editResponseData={editResponseData}
          source1SqlData={source1SqlData}
          setSource1SqlData={setSource1SqlData}
          source2SqlData={source2SqlData}
          setSource2SqlData={setSource2SqlData}
          isFullScan={isFullScan}
          setIsFullScan={setIsFullScan}
        />
      ) : (
        <SkeletonLoader />
      )}
      {finalValidation?.length > 0 && (
        <>
          <Grid container>
            <Grid item sm={6} sx={{ textAlign: "left", mt: 2 }}>
              <Button
                color="error"
                size="small"
                variant="outlined"
                onClick={() => {
                  navigate("/DataQualityRule");
                }}>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item sm={6} sx={{ textAlign: "right", mt: 2 }}>
              {validationsResultShow && (
                <ValidateResultModal
                  Validations={validationsResult}
                  model={true}
                  Comparative={true}
                  returnValue={(value) => {
                    setValidationsResultShow(value);
                    setValidationsResult([]);
                  }}
                />
              )}
              <LoadingButton
                onClick={() => ValidationCheck()}
                sx={{ mr: 1 }}
                size="small"
                color="success"
                variant="contained"
                disabled={loadValidate || finalValidation.length === 0}
                className="loader-button"
                loading={loadValidate}
                startIcon={<></>}
                loadingPosition="start">
                {t("Preview")}
              </LoadingButton>

              <CreateOrUpdateDQRule
                defaultRuleName={defaultRuleName}
                isPrivate={isPrivate}
                createLoading={updateLoading || createLoading}
                validateLoading={loadValidate}
                setIsPrivate={setIsPrivate}
                createValidation={createValidation}
                updateValidation={updateValidation}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <FormHelperText>{t("Note")} : </FormHelperText>
              <FormHelperText>{t("note5")}</FormHelperText>
              <FormHelperText>
                <FormHelperText>{t("note6")}</FormHelperText>
              </FormHelperText>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
