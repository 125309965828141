import { FormControl, FormLabel, Grid } from "@mui/material";
import TableViewsSearch from "../TableViewsSearch";

export function DropDownData(props) {
  const {
    placeholder,
    options,
    heading,
    value,
    setValue,
    loader = false,
  } = props;

  return (
    <Grid item xs={12} lg={12} sx={{ p: 2 }}>
      {heading && (
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>{heading}:</FormLabel>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableViewsSearch
          loader={loader}
          placeholder={placeholder}
          optionsList={options}
          value={value}
          setValue={setValue}
        />
      </Grid>
    </Grid>
  );
}
