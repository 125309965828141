import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  // Badge,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DataProfilingListItem, roundToX } from "../ProfillingListItems";

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: -16,
//     top: 7,
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: "0 6px",
//   },
// }));

const OverviewSection = ({ data }) => {
  return (
    <Grid container mt={1}>
      <Grid
        sx={{
          gap: "2rem",
        }}
        item
        xs={6}
      >
        <Typography variant="bold">Dataset statistics</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.4rem",
            mt: 1,
          }}
        >
          <DataProfilingListItem
            title="Number of Variables"
            value={data?.n_var}
          />
          <DataProfilingListItem
            title="Number of Observations"
            value={data?.n}
          />
          <DataProfilingListItem
            title="Missing Cells"
            value={data?.n_cells_missing}
          />
          <DataProfilingListItem
            title="Missing Cells (%)"
            value={roundToX(data?.p_cells_missing, 2) + "%"}
          />
          <DataProfilingListItem
            title="Duplicate Rows"
            value={data?.n_duplicates}
          />
          <DataProfilingListItem
            title="Duplicate Rows (%)"
            value={roundToX(data?.p_duplicates, 2) + "%"}
          />
          <DataProfilingListItem
            title="Total Size in Memory"
            value={data?.memory_size}
          />
          <DataProfilingListItem
            title="Average Record Size in Memory"
            value={roundToX(data?.record_size, 4)}
          />
        </Box>
      </Grid>
      <Grid gap={1} item xs={6}>
        <Typography variant="bold">Variable Types</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.4rem",
            mt: 1,
          }}
        >
          {Object.keys(data?.types).map((key) => (
            <DataProfilingListItem
              key={key}
              width="250px"
              title={key}
              value={data.types[key]}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
const AlertListItem = ({ alertItem }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className=""
    >
      <Typography>{alertItem}</Typography>
      <Button variant="contained" sx={{ paddingX: "5px", paddingY: "0px" }}>
        {alertItem.toLowerCase().split("]")[0].split("[")}
      </Button>
    </Box>
  );
};
const Alert = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        padding: "4px",
        marginLeft: "15px",
      }}
    >
      {data?.map((each) => (
        <AlertListItem key={each} alertItem={each} />
      ))}
    </Box>
  );
};
const Reproduction = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.4rem",
        padding: "4px",
        marginLeft: "15px",
        mt: 1,
      }}
    >
      <DataProfilingListItem
        width="160px"
        title="Analysis started"
        value={data?.date_start}
      />
      <DataProfilingListItem
        width="160px"
        title="Analysis finished"
        value={data?.date_end}
      />
      <DataProfilingListItem
        width="160px"
        title="Duration"
        value={data?.duration}
      />
    </Box>
  );
};
const PandasOverview = ({ data }) => {
  const [value, setValue] = useState("Overview");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ py: 1, px: 2, mt: 2 }} className="sampleContainer">
      <Typography variant="h5" color="primary">
        Overview
      </Typography>
      <TabContext value={value}>
        <TabList
          className="overviewSections"
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value="Overview" label="Overview" />
          {/* <Tab
            value="Alerts"
            label={
              <StyledBadge
                color="primary"
                badgeContent={data?.alerts?.length}
                max={99}
              >
                Alerts
              </StyledBadge>
            }
          /> */}
          <Tab value="Reproduction" label="Execution Details" />
        </TabList>

        <TabPanel sx={{ py: 1, px: 2 }} value="Overview">
          <OverviewSection data={data?.table} />
        </TabPanel>
        <TabPanel sx={{ py: 1, px: 2 }} value="Alerts">
          <Alert data={data?.alerts} />
        </TabPanel>
        <TabPanel sx={{ py: 1, px: 2 }} value="Reproduction">
          <Reproduction data={data?.analysis} />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default PandasOverview;
