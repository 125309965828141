import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Paper, Tooltip, Typography } from "@mui/material";
import { formatDateWithTime, getTimeDuration, isDateBefore } from "../../../../_helpers/utils";
import { SnackbarContext } from "../../../../App";
import { UserDetailsContext } from "../../../../services/UserDetailsContext";

import { TileAbortIcon, TileInfoIcon, TilePreviewIcon } from "./ResultIcons";

import DownloadCsvModal from "./DownloadCsvModal";
import TotalChecksPopup from "./TotalChecksPopup";
import TotalBatchesPopup from "./TotalBatchesPopup";
import AbortModal from "./AbortModal";

import ResultLabelComponent from "./ResultLabelComponent";
import ApiService from "../../../../services/app.service";
import fileNotFound from "../../../../images/file-notfound.png";
const STATUS_MESSAGES = {
  0: "Todo",
  1: "In Progress",
  2: "Completed",
  3: "Aborted",
  4: "Suspended",
};

const COLOR_VARIANTS = {
  1: "warning.main",
  2: "success.main",
  3: "error",
  4: "error",
};

const MoreDetails = ({ executionResult }) => (
  <>
    <ResultLabelComponent title="Executed By" value={executionResult?.ExecutedBy} />
    <ResultLabelComponent title="Start Time" value={formatDateWithTime(executionResult?.startDateTime)} />
    <ResultLabelComponent
      title="End Time"
      value={
        executionResult?.totalBatchCount === executionResult?.completedBatches || executionResult?.isAborted
          ? formatDateWithTime(executionResult?.finishDateTime)
          : "--"
      }
    />
    <ResultLabelComponent
      title="Duration"
      value={
        executionResult?.totalBatchCount === executionResult?.completedBatches || executionResult?.isAborted
          ? getTimeDuration(executionResult?.startDateTime, executionResult?.finishDateTime)
          : "--"
      }
    />
  </>
);

const ResultsDetails = ({ id1, executionResult, onClickTotalChecks, setAbortModalData }) => {
  const { t } = useTranslation();
  const { checkAccess } = useContext(UserDetailsContext);
  const moreDetailsTitle = <MoreDetails executionResult={executionResult} />;

  const failed_records =
    executionResult?.totalFailedRecords < executionResult?.totalRecordsProcessed
      ? executionResult?.totalFailedRecords
      : executionResult?.totalRecordsProcessed;

  return (
    <Box sx={{ position: "relative" }}>
      <Typography className="resultLabelTitle">
        <strong>{t("Result")}</strong>
      </Typography>
      <TileInfoIcon title={moreDetailsTitle} />
      {[0, 1, 4].includes(executionResult?.status) && (
        <TileAbortIcon onClick={() => setAbortModalData(executionResult)} />
      )}

      {executionResult?.validationDetails?.TestType === "Comparison" ? (
        <>
          <ResultLabelComponent
            type="MatchedInSourceA"
            title="Matched"
            value={executionResult?.summary?.MatchedFirstDatasourceCount}
            executionResult={executionResult}
          />
          <ResultLabelComponent
            type="UnMatchedInSourceA"
            title="Unique in 1st"
            value={executionResult?.summary?.OnlyInFirstDatasourceCount}
            executionResult={executionResult}
          />
          <ResultLabelComponent
            type="UnMatchedInSourceB"
            title="Unique in 2nd"
            value={executionResult?.summary?.OnlyInSecondDatasourceCount}
            executionResult={executionResult}
          />
          <ResultLabelComponent
            type="DuplicatesInSourceA"
            title="Duplicates in 1st"
            value={executionResult?.summary?.DuplicatesOfFirstDatasourceCount}
            executionResult={executionResult}
          />
          <ResultLabelComponent
            type="DuplicatesInSourceB"
            title="Duplicates in 2nd"
            value={executionResult?.summary?.DuplicatesOfSecondDatasourceCount}
            executionResult={executionResult}
          />
        </>
      ) : (
        <>
          <ResultLabelComponent
            title="Total Checks"
            value={
              <span>
                {executionResult?.Validations?.length}
                <TilePreviewIcon onClick={onClickTotalChecks} id={id1} />
              </span>
            }
            executionResult={executionResult}
          />

          <Typography className="resultLabelHightlight" color="success.main">
            {t("Passed")} :{" "}
            <Typography component="span" className="resultLabelValue">
              {executionResult?.totalRecordsProcessed - executionResult?.totalFailedRecords || 0}
              {executionResult?.totalRecordsProcessed - executionResult?.totalFailedRecords > 0 &&
                executionResult?.passedFilePath &&
                (executionResult?.status === 2 || executionResult?.status === 3) &&
                checkAccess("DataQualityRule", "Download") && (
                  <DownloadCsvModal executionResult={executionResult} type="passedFilePath" />
                )}
            </Typography>
          </Typography>
          <Typography className="resultLabelHightlight" color="error">
            {t("Failed")} :{" "}
            <Typography component="span" className="resultLabelValue">
              {failed_records}
              {failed_records > 0 &&
                isDateBefore(executionResult?.startDateTime) &&
                (executionResult?.status === 2 || executionResult?.status === 3) &&
                checkAccess("DataQualityRule", "Download") && (
                  <DownloadCsvModal
                    executionResult={executionResult}
                    type={executionResult?.failedFilePath ? "failedFilePath" : "validationRequest"}
                  />
                )}
            </Typography>
          </Typography>
        </>
      )}
    </Box>
  );
};

const ResultTile = ({ executions, executionResult, onClickCard, setExecutions, fetchDataValidations }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  // const { checkAccess } = useContext(UserDetailsContext);
  const [anchorE1, setAnchorE1] = useState(false);
  const [anchorE2, setAnchorE2] = useState(false);
  const [abortModalData, setAbortModalData] = useState({});
  const [popupData, setPopupData] = useState({});
  // const [downloadPdfData, setDownloadPdfData] = useState({});
  const id1 = anchorE1 ? "simple-popover1" : undefined;

  const isComparisonTest = executions?.[0]?.validationDetails?.TestType === "Comparison";

  // const onClickDownloadPdf = async (e) => {
  //   e.stopPropagation();
  //   if (downloadPdfData?._id !== executionResult?._id) {
  //     setDownloadPdfData(executionResult);
  //     try {
  //       const result = await ApiService.DownoladPDF({
  //         executionId: executionResult._id,
  //         testValidationId: executionResult?.testValidationId,
  //       });

  //       const linkSource = `data:application/pdf;base64,${result.data}`;
  //       const fileName = `${executionResult?.validationDetails?.TestName + new Date().toISOString()}.pdf`;

  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = linkSource;
  //       downloadLink.download = fileName;
  //       downloadLink.click();
  //     } catch (e) {
  //       setSnack({
  //         message: e?.response?.data?.message ?? e.message,
  //         open: true,
  //         colour: "error",
  //       });
  //     }
  //     setDownloadPdfData({});
  //   }
  // };

  const onClickTotalChecks = (event) => {
    event.stopPropagation();
    setAnchorE1(event.currentTarget);
    setPopupData(executionResult);
  };

  const ruleExecuteRequestAbort = async () => {
    try {
      const reqBody = {
        executionId: abortModalData._id,
        status: 3,
        isAborted: true,
      };
      const result = await ApiService.RuleExecuteRequestUpdate(reqBody);
      setExecutions((prevState) =>
        prevState.map((each) =>
          each?._id === abortModalData._id
            ? {
                ...each,
                isAborted: true,
                status: 3,
                finishDateTime: new Date(),
              }
            : each,
        ),
      );
      setAbortModalData({});
      setSnack({
        message: result?.data?.message,
        open: true,
        colour: "success",
      });
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      fetchDataValidations();
    }
  };

  return (
    <>
      {[6].includes(executionResult?.status) ? (
        <Box component={Paper} className="DQRuleResultcard disableDQRuleResultcard" gap={1}>
          <img src={fileNotFound} width="40%" alt="" />
          <Typography sx={{ opacity: 0.5 }} variant="h6" textAlign="center" color="error">
            {executionResult?.message}
          </Typography>
          <Box width="100%">
            <ResultLabelComponent
              title="Source File Name"
              value={executionResult?.validationDetails?.Tables?.[0]?.tablename}
            />
            <ResultLabelComponent title="Created At" value={formatDateWithTime(executionResult?.createdAt)} />
          </Box>
        </Box>
      ) : (
        <Box component={Paper} onClick={() => onClickCard(executionResult)} className="DQRuleResultcard">
          {/* {checkAccess("DataQualityRule", "Download") &&
            (executionResult?.status === 2 || executionResult?.status === 3) && (
              <TileDownLoadIcon
                loading={Object.keys(downloadPdfData)?.length === 0 || downloadPdfData._id !== executionResult._id}
                onClick={onClickDownloadPdf}
              />
            )} */}

          <ResultLabelComponent title="Total Records" value={executionResult?.totalRecordsCount || ""} />

          {isComparisonTest && (
            <>
              <Typography className="resultLabelTitle v-center">
                <Tooltip
                  className="ellipsis"
                  title={
                    <Typography className="ellipsisSql">
                      {executionResult?.FirstDatasourceName || executionResult?.summary?.FirstDatasourceSql}{" "}
                    </Typography>
                  }
                  placement="top-end">
                  {t("1st Data Source")} (
                  {executionResult?.FirstDatasourceName || executionResult?.summary?.FirstDatasourceSql}){" "}
                </Tooltip>
                :{" "}
                <Typography className="resultLabelValue" component="span" ml={0.5}>
                  {Number(executionResult?.ProcessedInFirstDataSource) || ""}
                </Typography>
              </Typography>
              <Typography className="resultLabelTitle v-center">
                <Tooltip
                  className="ellipsis"
                  title={
                    <Typography className="ellipsisSql">
                      {executionResult?.SecondDatasourceName || executionResult?.summary?.SecondDatasourceSql}
                    </Typography>
                  }
                  placement="top-end">
                  {t("2nd Data Source")} (
                  {executionResult?.SecondDatasourceName || executionResult?.summary?.SecondDatasourceSql})
                </Tooltip>{" "}
                :{" "}
                <Typography component="span" className="resultLabelValue">
                  {Number(executionResult?.ProcessedInSecondDataSource) || ""}
                </Typography>
              </Typography>
            </>
          )}
          <Typography>
            <Typography className="resultLabelTitle" component="span">
              {t("Processed")} :{" "}
            </Typography>
            <ResultLabelComponent
              title="Batch"
              component="span"
              value={`${executionResult?.latestValidationResult?.batchNumber} / ${executionResult?.latestValidationResult?.totalBatchCount}`}
            />
            <Divider
              sx={{ bgcolor: "#1976d2", borderRightWidth: 2, mx: 1 }}
              component="span"
              orientation="vertical"
              variant="middle"
              flexItem
              color="primary"
            />

            <ResultLabelComponent
              title="Records"
              component="span"
              value={
                isComparisonTest
                  ? executionResult?.ProcessedInFirstDataSource + executionResult?.ProcessedInSecondDataSource || ""
                  : executionResult?.totalRecordsProcessed || ""
              }
            />
          </Typography>

          <ResultLabelComponent
            title="Status"
            spanProps={{ color: COLOR_VARIANTS[executionResult?.status] }}
            value={t(STATUS_MESSAGES[executionResult?.status])}
          />
          <Divider className="tile-divider" m={0} variant="middle" />
          <ResultsDetails
            id1={id1}
            executionResult={executionResult}
            onClickTotalChecks={onClickTotalChecks}
            setAbortModalData={setAbortModalData}
          />
        </Box>
      )}
      {anchorE1 && <TotalChecksPopup popupData={popupData} anchorE1={anchorE1} setAnchorE1={setAnchorE1} />}
      {anchorE2 && <TotalBatchesPopup popupData={popupData} anchorE2={anchorE2} setAnchorE2={setAnchorE2} />}
      {Object.keys(abortModalData)?.length > 0 && (
        <AbortModal
          ruleExecuteRequestAbort={ruleExecuteRequestAbort}
          showModal={Object.keys(abortModalData)?.length}
          setAbortModalData={setAbortModalData}
        />
      )}
    </>
  );
};

export default ResultTile;
