import React, { useContext, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Box, IconButton, Tooltip, Toolbar, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import InsightsIcon from "@mui/icons-material/Insights";
import CustomDeleteModal from "../../CustomComponents/CustomDeleteModal";
import APIServices from "../../../services/app.service";
import CreateConnection from "../CreateDS/CreateConnection";
import FilesDataList from "./FilesDataList";
import AgGridSSRM from "../../AgGrid/AgGridSSRM";
import { SnackbarContext } from "../../../App";
import { UserDetailsContext } from "../../../services/UserDetailsContext";
import { CustomEditIcon, CustomPreviewIcon, CustomValidateIcon } from "../../CustomComponents/IconButtons";
import { getDSHeadCells } from "./headCellsDSDetails";
import { isAzure } from "../../../_helpers/Constant";

const DataSourcesToolbar = ({ numSelected, selectedRows, onDeleteSuccess }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  const handleDeleteConnections = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await APIServices.ConnectionDelete({
        ids: selectedRows,
      });
      onDeleteSuccess(response?.data?.result?.deletedIds);
      closeDeleteDialog();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        py: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {numSelected > 0 && (
        <>
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t("Selected")}
          </Typography>
          <Tooltip title={t("Delete")}>
            <IconButton size="small" onClick={openDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {isDeleteDialogOpen && (
        <CustomDeleteModal
          loading={isLoadingDelete}
          disabled={isLoadingDelete}
          onClickDelete={handleDeleteConnections}
          handleClose={closeDeleteDialog}
        />
      )}
    </Toolbar>
  );
};

DataSourcesToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const DataSourceActions = React.memo(({ data, context }) => {
  const { t } = useTranslation();
  const { userDetails, checkAccess } = useContext(UserDetailsContext);
  const showEdit = data?.UserId === userDetails?.UserId || userDetails?.RoleName === "Admin" ? "visible" : "hidden";

  const handlePreviewClick = () => context.previewCall(data);
  const onClickEditConnection = () => context.setSelectEditConnectionData(data);

  return (
    <>
      <Box className="v-center" sx={{ gap: 0.4 }}>
        <CustomEditIcon sx={{ visibility: showEdit }} title={t("Edit")} onClick={onClickEditConnection} />

        {checkAccess("DataSource", "Preview") && (
          <CustomPreviewIcon title={t("Preview")} onClick={handlePreviewClick} />
        )}

        {!isAzure(data?.connectionType) && (
          <Link className="custom-link" to={`/datasources/insights?con_id=${data.id}`}>
            <IconButton size="small">
              <Tooltip title={t("Data Insights")} className="v-center" placement="top" arrow>
                <InsightsIcon fontSize="small" sx={{ color: "#7205f7" }} />
              </Tooltip>
            </IconButton>
          </Link>
        )}

        {checkAccess("DataSource", "Create") && (
          <Link
            className="custom-link"
            to={`/DataQualityRule/create/?connectionId=${data.id}&testType=database&isPrivate=true&IsCustomQuery=false&connectionType=${data?.connectionType}`}>
            <CustomValidateIcon title={t("Validate")} />
          </Link>
        )}
      </Box>
    </>
  );
});
DataSourceActions.displayName = "DataSourceActions";

const ConnectionsListView = React.memo(({ gridRef, refreshData, getTablesData }) => {
  const scrollRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const DSType = searchParams.get("type");
  const DSName = searchParams.get("name");

  const [selected, setSelected] = useState([]);
  const [selectEditConnectionData, setSelectEditConnectionData] = useState(null);

  const [conndetails] = useState({ type: DSType, name: DSName });

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };
  const onSuccessCreate = () => {
    refreshData("isEdit");
    setSelected([]);
  };

  const clickPreviewConnection = (row) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("dataProfileStatus");
      newState.set("previewConId", row?.id);
      return newState;
    });
    gridRef.current?.api?.deselectAll();
    setSelected([]);
    getTablesData(row);
    autoScroll();
  };

  const onDeleteSuccess = () => {
    refreshData();
    setSelected([]);
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: DataSourceActions,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    flex: 1,
  };
  const headCellsDSDetails = [...getDSHeadCells(DSName || "")];
  headCellsDSDetails[headCellsDSDetails?.length - 1] = action;
  return (
    <>
      {DSType !== "Files" && DSType !== "API" && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box className="createBtn">
            {selected?.length > 0 && (
              <DataSourcesToolbar
                selectedRows={selected}
                numSelected={selected.length}
                onDeleteSuccess={onDeleteSuccess}
              />
            )}
          </Box>
          <AgGridSSRM
            gridRef={gridRef}
            setSelected={setSelected}
            apiMethod={"getDataSourceByType"}
            headCells={headCellsDSDetails}
            payload={conndetails}
            context={{
              refreshData: refreshData,
              setSelected: setSelected,
              previewCall: clickPreviewConnection,
              setSelectEditConnectionData: setSelectEditConnectionData,
            }}
          />
        </Box>
      )}

      {selectEditConnectionData && (
        <CreateConnection
          connectionType={selectEditConnectionData.connectionType}
          type="Edit"
          connectionData={selectEditConnectionData}
          handleSuccessCreate={onSuccessCreate}
          onClose={() => setSelectEditConnectionData(null)}
        />
      )}
      {(DSType === "Files" || DSType === "API") && conndetails && (
        <FilesDataList
          scrollRef={scrollRef}
          selected={selected}
          refreshData={refreshData}
          dataName={DSName}
          setSelected={setSelected}
          onDeleteSuccess={onDeleteSuccess}
          gridRef={gridRef}
          conndetails={conndetails}
        />
      )}
    </>
  );
});
ConnectionsListView.displayName = "ConnectionsListView";

export default ConnectionsListView;
