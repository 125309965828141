import {
  Box,
  Paper,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const FinalValidationsDetails = ({ finalValidations, setFinalValidations }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const isSelected = (indexId) => selected.indexOf(indexId) !== -1;
  const deleteFinalvalidation = (e, delId) => {
    const selectedIndex = selected.indexOf(delId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, delId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const deleteSelected = () => {
    let res = [...finalValidations];
    res = res.filter(function (value, index) {
      return selected.indexOf(index) === -1;
    });
    setFinalValidations(res);
    setSelected([]);
  };
  return (
    <Box className="validations" sx={{ mt: 2 }} component={Paper}>
      <Box className="innerSubHead">
        <Grid container alignItems="center" justify="center">
          <Grid item sm={8}>
            <Typography variant="h6">{t("Data Quality Checks")} :</Typography>
          </Grid>
          <Grid item sm={4}>
            <Box className="createBtn" sx={{ p: "0 !important" }}>
              {selected?.length > 0 && (
                <Typography color="inherit" variant="subtitle1" component="div">
                  {selected?.length} {t("selected")}
                  <Tooltip title="Delete">
                    <IconButton size="small" onClick={deleteSelected}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {finalValidations?.map((row, irow) => {
              const isItemSelected = isSelected(irow);
              const labelId = `validations-table-checkbox-${irow}`;
              return (
                <TableRow key={labelId}>
                  <TableCell width={"4%"} align="center">
                    <Checkbox
                      onClick={(e) => deleteFinalvalidation(e, irow)}
                      inputProps={{ "aria-labelledby": labelId }}
                      checked={isItemSelected}
                      sx={{ p: 0 }}
                    />
                  </TableCell>
                  <TableCell width={"48%"}>
                    <Typography>
                      <strong>{t("Table Name")} : </strong>
                      <Tooltip title={row?.tablename} placement="top">
                        {row?.tablename?.length > 30
                          ? row?.tablename.slice(0, 30) + "..."
                          : row?.tablename}
                      </Tooltip>
                    </Typography>
                  </TableCell>
                  <TableCell width={"48%"}>
                    {row?.columns?.map((data1, index) => (
                      <div key={index}>
                        <Typography className="columnName">
                          <strong>{t("Column Name")} : </strong>
                          {data1.ColumnName ||
                            data1?.validation?.[0]?.NestedControls?.[0]
                              ?.DisplayName ||
                            "NA"}
                        </Typography>
                        {data1?.validation?.map((data, i) => {
                          return (
                            <Box key={i} pl="8px">
                              {data1.ColumnName !== "" && (
                                <>
                                  <strong>{i + 1}.</strong>
                                  {data.DisplayName}
                                </>
                              )}
                              {data["NestedControls"]?.map((obj1, key) => (
                                <Box key={key}>
                                  {obj1.ControlType === "CheckBox" && (
                                    <>
                                      ({obj1.Name} -
                                      {obj1[
                                        "ControlProperties"
                                      ].IsChecked.toString()}
                                      )
                                    </>
                                  )}
                                  {obj1.ControlType === "Integer" &&
                                    obj1.SelectedValue}
                                  {obj1.ControlType === "Dropdown" &&
                                    (obj1.SelectedValue
                                      ? obj1.SelectedValue
                                      : obj1["ControlProperties"]
                                          .SelectedValue)}
                                  {obj1.ControlType === "Text" &&
                                    obj1.SelectedValue}
                                </Box>
                              ))}
                              {data1.ColumnName === "" && (
                                <>
                                  <strong>{i + 1}.</strong>
                                  {data.NestedControls[0]?.DisplayName}-
                                  {data.NestedControls[0]?.SelectedValue}
                                </>
                              )}
                            </Box>
                          );
                        })}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FinalValidationsDetails;
