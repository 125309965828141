import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../SkeletonLoader";

export default function FileDetails({
  loader,
  file,
  totalRows,
  totalColumns,
  tables,
  rows,
  setRows,
}) {
  const { t } = useTranslation();

  return (
    <Accordion key={"sadasd"} defaultExpanded={true} className="container">
      <Grid container className="center" pt={1}>
        <Grid item sm={9} gap={3} sx={{ pl: 2 }} className="display-inline">
          <Typography>
            <b>{t("File name")} : </b> {file?.fileName}
          </Typography>

          <Typography color="#611EB6">
            {t("Rows")} : {totalRows}
          </Typography>

          <Typography color="#E68200">
            {t("Columns")} : {totalColumns}
          </Typography>
        </Grid>
        <Grid item sm={2} className="float-right h-center">
          <FormControl
            fullWidth
            sx={{ maxWidth: 90, Height: 50, ml: "auto" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label">
              {t("Records Shown")}
            </InputLabel>
            <Select
              label="Records Shown"
              defaultValue={rows}
              onChange={(e) => setRows(e.target.value)}
            >
              <MenuItem key={"5"} value={5}>
                5
              </MenuItem>
              <MenuItem key={"10"} value={10}>
                10
              </MenuItem>
              <MenuItem key={"25"} value={25}>
                25
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={1} className="float-right">
          <AccordionSummary
            expandIcon={
              <Box className="createBtn">
                <IconButton size="small">
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ minHeight: "auto!important" }}
          />
        </Grid>
      </Grid>
      <AccordionDetails sx={{ borderBottom: "12px solid transparent" }}>
        {loader ? (
          <SkeletonLoader />
        ) : (
          <TableContainer sx={{ display: "grid" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow key={"tablehead1"}>
                  {tables.length > 0 &&
                    tables[0].map((td, i) => {
                      const labelId = `columnOption-${i}`;
                      let optionVal = file?.firstRowisHeader
                        ? td
                        : `Column ${i + 1}`;
                      return (
                        <TableCell key={labelId}>{t(optionVal)}</TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody sx={{ "& tr": { verticalAlign: "top" } }}>
                {tables.length > 0 &&
                  (file?.firstRowisHeader ? tables.slice(1) : tables).map(
                    (item, key) => {
                      const labelId1 = `filesrow-${key}`;
                      return (
                        <TableRow key={labelId1}>
                          {item.length > 0 &&
                            item.map((td, i) => {
                              const labelId2 = `filesrowtd-${i}`;
                              return <TableCell key={labelId2}>{td}</TableCell>;
                            })}
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
