import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatDateLocale } from "../../../../_helpers/utils";

const scheduleFrequencies = {
  1: { unit: "minutes", label: "Minute(s)" },
  2: { unit: "hours", label: "Hour(s)" },
  3: { unit: "days", label: "Day(s)" },
  4: { unit: "weeks", label: "Week(s)" },
  5: { unit: "months", label: "Month(s)" },
};

const getMinToDate = (startDate, each, frequency) => {
  if (!startDate || !scheduleFrequencies[frequency]) {
    return "";
  }
  const minDate = moment(startDate).clone();
  const { unit } = scheduleFrequencies[frequency];

  if (unit === "weeks") {
    minDate.add(each * 7, "days");
  } else {
    minDate.add(each, unit);
  }

  return minDate.isValid() ? minDate.format("YYYY-MM-DDTHH:mm") : "";
};

const formatDate = (date) => moment(date).format("YYYY-MM-DDTHH:mm");

const calculateMinToDate = (fromDate, each, frequency) => {
  if (!fromDate || !scheduleFrequencies[frequency]) {
    return "";
  }

  const toDate = moment(fromDate).clone();
  const frequencyUnit = scheduleFrequencies[frequency].unit;

  if (frequencyUnit === "weeks") {
    toDate.add(each * 7, "days");
  } else {
    toDate.add(each, frequencyUnit);
  }

  return toDate.isValid() ? toDate.format("YYYY-MM-DDTHH:mm") : "";
};

const SelectScheduledDate = ({ scheduledData = {}, setScheduledData }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const ruleId = searchParams.get("ruleId") || "";

  const minFromDate = scheduledData?.startDate ? formatDate(scheduledData.startDate) : "";
  const minToDate = getMinToDate(scheduledData.startDate, scheduledData.frequencyUnit, scheduledData.frequencyValue);

  useEffect(() => {
    if (!ruleId) {
      setScheduledData((prevState) => ({
        ...prevState,
        startDate: moment().add(5, "minutes").format("YYYY-MM-DDTHH:mm"),
        endDate: calculateMinToDate(
          moment().add(5, "minutes").format("YYYY-MM-DDTHH:mm"),
          scheduledData?.frequencyUnit,
          scheduledData?.frequencyValue,
        ),
      }));
    }
  }, []);
  const handleSelectFrequency = (e) => {
    const frequency = parseInt(e.target.value, 10);
    setScheduledData((prevState) => ({
      ...prevState,
      frequencyValue: frequency,
      endDate: calculateMinToDate(scheduledData.startDate, scheduledData?.frequencyUnit, frequency),
    }));
  };

  const onChangeStartDate = (event) => {
    const selectedDate = moment(event.target.value);
    const formattedDate = selectedDate.isValid() ? selectedDate.format("YYYY-MM-DDTHH:mm") : "";

    setScheduledData((prevState) => ({
      ...prevState,
      startDate: formattedDate,
      endDate: calculateMinToDate(formattedDate, scheduledData?.frequencyUnit, scheduledData?.frequencyValue),
    }));
  };

  const onChangeEndDate = (event) => {
    const selectedDate = moment(event.target.value);
    const formattedDate = selectedDate.isValid() ? selectedDate.format("YYYY-MM-DDTHH:mm") : "";
    setScheduledData((prevState) => ({
      ...prevState,

      endDate: formattedDate,
    }));
  };

  const onChangeEvery = (e) => {
    const value = parseInt(e.target.value, 10);
    setScheduledData((prevState) => ({
      ...prevState,
      frequencyUnit: value,
      endDate: calculateMinToDate(scheduledData.startDate, value, scheduledData.frequencyValue),
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <TextField
              fullWidth
              size="small"
              inputProps={{ min: minFromDate }}
              value={scheduledData.startDate || ""}
              label={t("Schedule Date & Time")}
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              onChange={onChangeStartDate}
            />
          </Grid>
          <Grid item sm={3}>
            <FormControlLabel
              sx={{ float: "right", mr: 0 }}
              control={
                <Switch
                  checked={scheduledData.isRepeat || false}
                  onChange={(e) =>
                    setScheduledData((prevState) => ({
                      ...prevState,
                      isRepeat: e.target.checked,
                    }))
                  }
                />
              }
              label={t("Repeat")}
            />
          </Grid>
          {scheduledData.isRepeat && (
            <>
              <Grid item sm={6} mb={1} className="v-center">
                <FormLabel sx={{ mr: "10px" }}>Every</FormLabel>
                <TextField
                  type="number"
                  size="small"
                  style={{ width: "120px" }}
                  InputLabelProps={{ shrink: true }}
                  value={scheduledData.frequencyUnit || 1}
                  onChange={onChangeEvery}
                />
                <FormControl size="small" fullWidth sx={{ display: "inline-flex", ml: "10px" }}>
                  <Select value={scheduledData.frequencyValue || ""} onChange={handleSelectFrequency}>
                    {Object.entries(scheduleFrequencies).map(([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  inputProps={{ min: minToDate }}
                  value={scheduledData.endDate || ""}
                  label={t("End Date & Time")}
                  type="datetime-local"
                  InputLabelProps={{ shrink: true }}
                  onChange={onChangeEndDate}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {scheduledData.isRepeat && (
        <Grid item sm={6} px={2}>
          <Box sx={{ border: "1px solid #ccc" }} height="100%" className="center">
            <Typography className="bold-text italic-text word-spacing">
              Start on {formatDateLocale(scheduledData.startDate)}, repeats every {scheduledData.frequencyUnit}{" "}
              {scheduleFrequencies[scheduledData.frequencyValue].label}, and ends on{" "}
              {formatDateLocale(scheduledData.endDate)}.
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectScheduledDate;
