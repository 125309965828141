import React, { useContext, useEffect, useState } from "react";
import { Paper, Box, Grid, IconButton, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import SkeletonLoader from "../SkeletonLoader";
import CompareFinalValidation from "./RowComparison/CompareFinalValidation";

const PreviewValidationData = ({ autoScroll, connection, returnVal }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const [testDetails, setTestDetails] = useState({});
  const [source1, setSource1] = useState();
  const [source2, setSource2] = useState();
  const [testType, setTestType] = useState();
  const [finalValidation, setFinalValidation] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    setFinalValidation([]);
    try {
      const { data } = await ApiService.GetDataValidationByID(connection);
      setTestDetails(data);
      setSource1(data?.ConnectionDetails?.FirstDatasourceDetails);
      setSource2(data?.ConnectionDetails?.SecondDatasourceDetails);

      if (data?.TestType === "Comparison") {
        const finalData = data?.comparissonValidations.map((obj) => ({
          source1: obj.FirstDataSource,
          source2: obj.SecondDataSource,
          validation: {
            row_count_matching: obj.ValidationName === "RowCount",
            row_data_matching: obj.ValidationName === "RowComparison",
          },
        }));
        setFinalValidation(finalData);
      }
      setTestType(data?.TestType);
      autoScroll();
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [connection]);

  return (
    <React.Fragment>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <Paper className="DQRulePreviewContainer">
          <Box mb="3px">
            <Grid container alignItems="center">
              <Grid item xs={10} sm={10} pl="15px">
                <Typography className="upper-case" style={{ color: "#403C6C" }} variant="h6">
                  {t("Preview")}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} textAlign="right" pr="8px">
                <IconButton onClick={() => returnVal(false)} size="small" color="error">
                  <CancelOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item sm={12} className="Previewbar" py="2px">
                <Grid container className="v-center">
                  <Grid item xs={12} sm>
                    <Typography>
                      <span className="upper-case bold-text" style={{ color: "#495057" }}>
                        {t("Test Name")} :{" "}
                      </span>{" "}
                      {testDetails && testDetails.TestName}{" "}
                    </Typography>
                  </Grid>
                  {testDetails?.Tables?.[0]?.tablename && (
                    <Grid item xs={12} sm>
                      <Typography>
                        <span className="bold-text" style={{ color: "#495057", padding: "3px" }}>
                          {testDetails?.ConnectionDetails?.connectionType === "Azure Blob"
                            ? t("FILE NAME")
                            : t("TABLE NAME")}{" "}
                          :
                        </span>{" "}
                        {testDetails?.Tables?.[0]?.tablename}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} sm>
                    <Typography>
                      <span className="upper-case bold-text" style={{ color: "#495057" }}>
                        {t("Data Source Name")} :{" "}
                      </span>{" "}
                      {testDetails?.ConnectionDetails && (
                        <>
                          {testDetails?.TestType === "Single File" && testDetails?.ConnectionDetails?.fileName}

                          {(testDetails?.TestType === "Single Database" ||
                            testDetails?.ConnectionDetails?.connectionType === "Azure Blob") &&
                            testDetails?.ConnectionDetails?.connectionName}
                          {testDetails?.TestType === "Comparison" && (
                            <>
                              {source1 && source1?.ext ? source1?.fileName : source1?.connectionName}
                              <> / </>
                              {source2 && source2?.ext ? source2?.fileName : source2?.connectionName}
                            </>
                          )}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={2}>
                    <Typography>
                      <span className="upper-case bold-text" style={{ color: "#495057" }}>
                        {t("Test type")} :{" "}
                      </span>{" "}
                      {testDetails && testDetails.TestType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {testType !== "Comparison" && (
            <Box px="15px" py="10px">
              <Grid item container columnGap={2}>
                {testDetails?.Tables?.map((row, index) => (
                  <Grid key={index} item md={4} lg={3} component={Paper} className="DQRulePreviewcard">
                    <Typography>
                      <span className="bold-text" style={{ color: "#495057", padding: "3px" }}>
                        {t("Column Name")} :
                      </span>{" "}
                      {row.columns.map((data1) => (data1?.ColumnName === "" ? "NA" : data1.ColumnName)).join(", ")}
                    </Typography>

                    {row.columns?.[0]?.validation.map((data, i) => {
                      return (
                        <div key={i}>
                          {row.columns?.[i]?.validation.ColumnName !== "" ? (
                            <span style={{ padding: "4px" }}>
                              {i + 1}. {data.DisplayName}
                            </span>
                          ) : (
                            ""
                          )}
                          {data["NestedControls"].map((obj1, i2) => {
                            return (
                              <React.Fragment key={i2}>
                                {obj1.ControlType === "CheckBox" && (
                                  <Box sx={{ ml: 2 }}>
                                    {obj1?.Name} - {obj1["ControlProperties"].IsChecked.toString()}
                                  </Box>
                                )}
                                {obj1.ControlType === "Integer" && `: ${obj1.SelectedValue}`}
                                {obj1.ControlType === "Dropdown" &&
                                  (`: ${obj1.SelectedValue}` || obj1["ControlProperties"].SelectedValue)}
                                {obj1.ControlType === "Text" && `: ${obj1.SelectedValue}`}
                              </React.Fragment>
                            );
                          })}
                          {row.columns?.[i]?.validation.ColumnName === "" && data.NestedControls && (
                            <>
                              <strong>{index + 1}.</strong>
                              {data.NestedControls && data.NestedControls[0]?.DisplayName} -{" "}
                              {data.NestedControls && data.NestedControls[0]?.SelectedValue}
                            </>
                          )}
                          <Typography sx={{ ml: 2 }}>Operator : {data?.Operator}</Typography>
                          <br />
                        </div>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {testType === "Comparison" && (
            <CompareFinalValidation
              finalValidation={finalValidation}
              finalSelected={[]}
              source1={source1}
              source2={source2}
              source1Sql={testDetails.comparissonValidations[0]?.SqlQuery ? "Yes" : "No"}
              checkbox={false}
            />
          )}
        </Paper>
      )}
    </React.Fragment>
  );
};

export default PreviewValidationData;
