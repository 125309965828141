/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import InnerHeader from "../../Layout/InnerHeader";
import ValidateResultModal from "../../Validations/ValidateResultModal";
import CreateOrUpdateDQRule from "../CreateOrUpdateDQRule";
import NewDataSource from "../SingleFile/NewDataSource";
import SingleDataSource from "../SingleFile/SingleDataSource";
import DatabaseValidation from "./DatabaseValidation";

const SingleDatabase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef();
  const [searchParams] = useSearchParams();
  const connectionId = searchParams.get("connectionId");
  const isPrivate = searchParams.get("isPrivate");
  const ruleId = searchParams.get("ruleId");
  const tableName = searchParams.get("tableName");
  const isCustomQuery = searchParams.get("isCustomQuery");

  const { setSnack } = useContext(SnackbarContext);
  const [finalValidations, setFinalValidations] = useState([]);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [selectedOption, setselectedOption] = useState(null);

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const handleBack = () => {
    setFinalValidations([]);
    if (ruleId) {
      navigate("/DataQualityRule");
    } else {
      navigate(-1);
    }
  };

  const createValidation = async (ruleName, createNew) => {
    setCreateLoading(true);
    let data = {
      TestName: ruleName,
      TestType: "Single Database",
      Tables: finalValidations,
      ConnectionId: connectionId,
      isPrivate: isPrivate,
    };
    try {
      if (ruleId && !createNew) {
        await ApiService.updateValidation(data, ruleId);
      } else {
        await ApiService.createValidation(data);
      }

      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    }
    setCreateLoading(false);
  };

  const ValidationCheck = async () => {
    setValidateLoading(true);
    var data = {
      TestType: "Single Database",
      Tables: finalValidations,
      ConnectionId: connectionId,
    };
    try {
      let response = await ApiService.checkValidation(data);
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setValidateLoading(false);
    }
  };

  return (
    <>
      <InnerHeader>
        <Box className="DSHeaderContainer">
          <Box className="v-center" gap={2}>
            <CustomBackButton onClick={handleBack}>{t("Back")}</CustomBackButton>
            <Typography variant="h6" className="upper-case">
              {t("Create Data Quality Rule")}
            </Typography>
          </Box>

          {!connectionId && (
            <NewDataSource type="database" setOptions={setOptions} setselectedOption={setselectedOption} />
          )}
        </Box>
      </InnerHeader>

      <Box className="pt74" ref={scrollRef}>
        {connectionId ? (
          <DatabaseValidation
            finalValidations={finalValidations}
            setFinalValidations={setFinalValidations}
            autoScroll={autoScroll}
          />
        ) : (
          <SingleDataSource
            type="database"
            options={options}
            selectedOption={selectedOption}
            setOptions={setOptions}
            setselectedOption={setselectedOption}
          />
        )}

        <Box sx={{ flex: "1 1 auto", mt: 2 }} />
        {finalValidations.length > 0 && (tableName || isCustomQuery) && (
          <Grid container>
            <Grid item sm={12} sx={{ textAlign: "right", mt: 1 }}>
              {validationsResultShow && (
                <ValidateResultModal
                  Validations={validationsResult}
                  model={true}
                  returnValue={(value) => {
                    setValidationsResultShow(value);
                    setValidationsResult([]);
                  }}
                />
              )}
              <Button
                sx={{ mr: 2 }}
                color="error"
                size="small"
                variant="outlined"
                onClick={() => navigate("/DataQualityRule")}>
                {t("Cancel")}
              </Button>
              <LoadingButton
                onClick={() => ValidationCheck()}
                sx={{ mr: 2 }}
                size="small"
                variant="contained"
                className="PreviewButton"
                disabled={validateLoading}
                loading={validateLoading}
                startIcon={<></>}
                loadingPosition="start">
                {t("Preview")}
              </LoadingButton>

              <CreateOrUpdateDQRule
                createLoading={createLoading}
                validateLoading={validateLoading}
                createValidation={createValidation}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default SingleDatabase;
