import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { JSONTree } from "react-json-tree";
import { useTranslation } from "react-i18next";
import { jsonTheme } from "../../_helpers/utils";
import SkeletonLoader from "../SkeletonLoader";
export default function CustomJsonTree(props) {
  const { t } = useTranslation();
  const { loader, response, returnVal, showCross } = props;
  return (
    <Box className="container">
      <Box component={Paper} className="drawerShortHead" variant="h6">
        {t("Preview")}
      </Box>

      <Paper
        sx={{
          borderRadius: 0,
          boxShadow: "0px 3px 6px #00000029  !important",
        }}
      >
        <Box className="flex-col bg-white&box-shadow" gap={1} p={1.5}>
          <Box display={"flex"}>
            <Box sx={{ width: "140px" }}>
              {t("Data Source Name")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">
              {response?.fileDetails?.connectionName}{" "}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ width: "140px" }}>
              {t("Total nodes")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">
              {response?.result?.totalRows}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box sx={{ width: "140px" }}>
              {response?.fileDetails?.connectionType === "Web App"
                ? t("HTTP Method")
                : t("Total Columns")}
              <Box sx={{ float: "right" }} component={"span"}>
                &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
              </Box>
            </Box>

            <Typography variant="bold">
              {response?.fileDetails?.connectionType === "Web App"
                ? response?.fileDetails?.HTTPMethod
                : t("pending")}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box className="innerSubHead">
        <Grid container alignItems="center" justify="center">
          {showCross && (
            <Grid item sm={2}>
              <IconButton
                onClick={() => {
                  returnVal(false);
                }}
                size="small"
                color="error"
                sx={{ ml: "auto", display: "flex" }}
                aria-label="add to shopping cart"
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        component={Paper}
        className="bg-white&box-shadow"
        height={"100%"}
        p="16px"
        sx={{
          boxShadow: "0px 3px 6px #00000029  !important",
        }}
      >
        <Grid container>
          <Grid xs={12} sx={{ p: 0, display: "grid" }} md={12} item>
            {loader ? (
              <SkeletonLoader />
            ) : (
              <>
                {response && (
                  <JSONTree data={response?.result?.rows} theme={jsonTheme} />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
