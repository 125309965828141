import {
  mdiAccountCheckOutline,
  mdiAccountSettings,
  mdiShieldAccount,
} from "@mdi/js";
import Icon from "@mdi/react";

export const roleAttrs = {
  "Regular user": {
    icon: <Icon path={mdiAccountSettings} size={1} />,
    color: "#d9ab2b",
  },
  "Privileged user": {
    icon: <Icon path={mdiAccountCheckOutline} size={1} />,
    color: "#4990B3",
  },
  Admin: {
    icon: <Icon path={mdiShieldAccount} size={1} />,
    color: "#6BA259",
  },
};
