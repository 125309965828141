/* eslint-disable react-hooks/exhaustive-deps */
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, IconButton, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import ApiCustomValidateForm from "../../API/ApiCustomValidateForm";
import FileDetails from "../../Files/FileDetails";
import SelectColumns from "../../Files/SelectColumns";
import SelectFileColumns from "../../Files/SelectFileColumns";
import CustomJsonTree from "../../JsonFile/CustomJsonTree";
import SkeletonLoader from "../../SkeletonLoader";
import ValidationFormInputs from "../SingleDatabase/ValidationFormInputs";

export default function FileValidations({
  setfinalValidation,
  finalValidation,
  inputParams,
  setinputParams,
  requestBody,
  setrequestBody,
  setfile,
  autoScroll,
}) {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [responseData, setResponseData] = useState([]);
  const [columnOption, setColumnOption] = useState([]);
  const [rows, setRows] = useState(5);
  const [tables, setTables] = useState([]);
  const [file, setFile] = useState({});
  const [selected, setSelected] = useState([]);
  const [fileType, setfileType] = useState();
  const [loader, setLoader] = useState(false);
  const [validationsForm, setValidationsForm] = useState([]);
  const [validationData, setvalidationData] = useState([]);
  const [JSON_SCHEMA, setJsonSchema] = useState([]);
  const [totalRows, setRotalRows] = useState(0);
  const [totalColumns, setTotalColumns] = useState(0);

  const [validationsLoading, setValidationsLoading] = useState(false);

  const fileId = searchParams.get("connectionId");
  const ruleId = searchParams.get("ruleId");

  useEffect(() => {
    (async () => {
      setColumnOption([]);
      setfileType();
      setTables([]);
      if (fileId) {
        setLoader(true);
        try {
          let response = await ApiService.GetFilesData({
            id: fileId,
            numberOfRows: rows,
          });
          setLoader(false);
          setfileType(response?.data?.result?.ext);
          if (response?.data?.result?.ext === "txt") {
            if (response.data.result.rows[0].length > 20) {
              var abc = response.data.result.rows[0].slice(0, 20);
              response.data.result.rows[0] = abc;
            }
          }
          setResponseData(response);
          if (
            response.data.result.ext === "json" ||
            response.data.result.ext === "xml"
          ) {
            let filterData = response.data.result.nodes.filter(
              (obj) => (obj.Type !== "complex") & (obj.Type !== "array")
            );
            let __d = filterData.map((obj) => obj.Path);
            setTables(__d);
          } else {
            setTables(response.data.result.rows);
          }
          setFile(response.data.fileDetails);
          setfile(response.data?.fileDetails?.fileName);
          setRotalRows(response.data.result.totalRows);
          setTotalColumns(response.data.result.totalColumns);
          setValidationsForm(response.data.ValidationsForm);
          setvalidationData(response.data.ValidationsForm);
          setJsonSchema(
            JSON.parse(JSON.stringify(response.data.ValidationsForm))
          );
        } catch (e) {
          setLoader(false);
          setSnack({
            message: e?.response?.data?.message ?? e.message,
            open: true,
            colour: "error",
          });
        }
      }
    })();
  }, [fileId, rows]);

  const getEditValidationData = async () => {
    setValidationsLoading(true);
    try {
      let response = await ApiService.editValidation(ruleId);
      setfinalValidation(response?.data?.Tables[0]?.columns);
      setSearchParams((params) => {
        params.set("isPrivate", response?.data?.isPrivate);
        return params;
      });
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setValidationsLoading(false);
  };

  const refresh = () => {
    let a = JSON.parse(JSON.stringify(JSON_SCHEMA));
    setValidationsForm(a);
    setvalidationData(a);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setColumnOption(typeof value === "string" ? value.split(",") : value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const deleteFinalvalidation = (e, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const deleteSelected = () => {
    let res = [...finalValidation];
    res = res.filter(function (value, index) {
      return selected.indexOf(index) === -1;
    });
    setfinalValidation(res);
    setSelected([]);
  };

  const addValidation = () => {
    const hasRequiredTextInputs = validationsForm?.some(
      (each) =>
        each?.ControlProperties?.IsChecked &&
        ["ValueCheck", "Min Length", "Max Length", "CustomPattern"].includes(
          each?.Name
        ) &&
        each?.NestedControls?.length &&
        !each?.NestedControls?.[0]?.SelectedValue
    );

    if (hasRequiredTextInputs) {
      setSnack({
        message: "Fill all required text inputs",
        open: true,
        colour: "warning",
      });
      return;
    }

    setValidationsForm([]);
    setvalidationData([]);

    const merge_col_validation = columnOption.map((column) => ({
      ColumnName: column,
      validation: validationData.filter(
        (obj) => obj["ControlProperties"]["IsChecked"] === true
      ),
    }));

    const findColumn = (columnName) =>
      finalValidation.find((item) => item.ColumnName === columnName);

    const findValidation = (validation, id) =>
      validation.find((el) => parseInt(el.Id) === parseInt(id));

    if (finalValidation.length === 0) {
      setfinalValidation(merge_col_validation);
    } else {
      merge_col_validation.forEach((val) => {
        const foundColumn = findColumn(val.ColumnName);
        if (foundColumn) {
          val.validation.forEach((validate) => {
            const foundValidation = findValidation(
              foundColumn.validation,
              validate.Id
            );
            if (foundValidation) {
              const indexVal = foundColumn.validation.indexOf(foundValidation);
              foundColumn.validation.splice(indexVal, 1);
              foundColumn.validation.push(validate);
            } else {
              foundColumn.validation.push(validate);
            }
          });
        } else {
          finalValidation.push(val);
        }
      });
      setfinalValidation(finalValidation);
    }
    setColumnOption([]);
    setTimeout(() => {
      refresh();
    }, 1);
    autoScroll();
  };

  const resetPage = () => {
    setValidationsForm([]);
    setvalidationData([]);
    setColumnOption([]);
    setTimeout(() => {
      refresh();
    }, 1);
  };

  const isDisabledbutton = () => {
    let list = validationData.filter(
      (obj) => obj.ControlProperties.IsChecked === true
    );
    return !columnOption.length > 0 || !list.length > 0;
  };

  const selectColumnsComponent = () => {
    return (
      <React.Fragment>
        {tables.length > 0 && (
          <Box>
            {fileType === "json" || fileType === "xml" ? (
              <SelectFileColumns
                columnOption={columnOption}
                handleChange={handleChange}
                tables={tables}
              />
            ) : (
              <SelectColumns
                columnOption={columnOption}
                handleChange={handleChange}
                tables={tables}
                file={file}
              />
            )}
          </Box>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (ruleId) getEditValidationData();
  }, []);

  if (validationsLoading) {
    return <SkeletonLoader />;
  }

  return (
    <React.Fragment>
      <>
        {fileType === "json" || fileType === "xml" ? (
          <CustomJsonTree
            loader={loader}
            response={responseData.data}
            showCross={false}
          />
        ) : (
          <FileDetails
            loader={loader}
            file={file}
            tables={tables}
            rows={rows}
            totalRows={totalRows}
            totalColumns={totalColumns}
            setRows={setRows}
          />
        )}
      </>
      {responseData?.data?.fileDetails?.connectionType === "Web App" && (
        <ApiCustomValidateForm
          inputParams={inputParams}
          setinputParams={setinputParams}
          requestBody={requestBody}
          setrequestBody={setrequestBody}
          setFile={setfile}
        />
      )}
      {validationsLoading || loader ? (
        <SkeletonLoader />
      ) : (
        <ValidationFormInputs
          validationsFormData={validationsForm}
          setValidationsFormData={setValidationsForm}
          headerComponent={selectColumnsComponent}
          reset={resetPage}
          isDisabledButton={isDisabledbutton}
          addValidationFun={addValidation}
        />
      )}

      {finalValidation.length > 0 && (
        <>
          <Box className="validations" sx={{ mt: 2 }} component={Paper}>
            <Box className="innerSubHead">
              <Grid container alignItems="center" justify="center">
                <Grid item sm={8}>
                  <Typography variant="h6">
                    {t("Data Quality Checks")} :
                  </Typography>
                </Grid>
                <Grid item sm={4} className="innerSubRight">
                  <Box className="createBtn" sx={{ p: "0 !important" }}>
                    {selected.length > 0 ? (
                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                      >
                        {selected.length} {t("Selected")}
                        <Tooltip title="Delete">
                          <IconButton size="small" onClick={deleteSelected}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {finalValidation.map((row, irow) => {
                    const isItemSelected = isSelected(irow);
                    const labelId = `validations-table-checkbox-${irow}`;
                    return (
                      <TableRow key={labelId}>
                        <TableCell width={"40px"} align="center">
                          <Checkbox
                            onClick={(e) => {
                              deleteFinalvalidation(e, irow);
                            }}
                            inputProps={{ "aria-labelledby": labelId }}
                            checked={isItemSelected}
                            sx={{ p: 0 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>
                            <strong>
                              {responseData?.data?.fileDetails
                                ?.connectionType === "Web App"
                                ? t("Property")
                                : t("Column")}
                              {t("Name")} :
                            </strong>{" "}
                            {row.ColumnName === "" ? "NA" : row.ColumnName}{" "}
                          </Typography>
                        </TableCell>
                        {row.ColumnName === "" && (
                          <TableCell>
                            <strong>1.</strong>{" "}
                            {row.validation[0].NestedControls[0].DisplayName} -{" "}
                            {row.validation[0].NestedControls[0].SelectedValue}
                          </TableCell>
                        )}
                        {row.ColumnName !== "" && (
                          <TableCell>
                            {row.validation.map((data, index) => {
                              return (
                                <>
                                  <strong>{index + 1}.</strong>{" "}
                                  {data.DisplayName}
                                  {data["NestedControls"]?.map((obj1) => {
                                    return (
                                      <>
                                        (
                                        {obj1.ControlType === "CheckBox" && (
                                          <>
                                            {obj1.Name} -{" "}
                                            {obj1[
                                              "ControlProperties"
                                            ].IsChecked.toString()}
                                          </>
                                        )}
                                        {obj1.ControlType === "Integer" && (
                                          <>{obj1.SelectedValue}</>
                                        )}
                                        {obj1.ControlType === "Dropdown" && (
                                          <>
                                            {obj1.SelectedValue
                                              ? obj1.SelectedValue
                                              : obj1["ControlProperties"]
                                                  .SelectedValue}
                                            {""}
                                          </>
                                        )}
                                        {obj1.ControlType === "Text" && (
                                          <>{obj1.SelectedValue}</>
                                        )}
                                        )
                                      </>
                                    );
                                  })}
                                  <br />
                                </>
                              );
                            })}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </React.Fragment>
  );
}
