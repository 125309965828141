import { Box, Button, FormHelperText, TextField, Typography } from "@mui/material";
import { useState } from "react";

const FolderPath = ({ defaultValue = "", handleSubmitFolderPath }) => {
  const [folderPath, setFolderPath] = useState(defaultValue === true ? "" : defaultValue);
  const [error, setError] = useState(false);

  const handlePathChange = (event) => {
    setFolderPath(event.target.value);
    if (error) {
      setError(false); // Reset error state when the user starts typing again
    }
  };

  const validatePath = () => {
    const windowsPathRegex =
      /^(?:[a-zA-Z]:\\(?:[^<>:"/\\|?*\r\n]+\\)*[^<>:"/\\|?*\r\n]*|\\\\[a-zA-Z0-9._-]+(\\[a-zA-Z0-9._-]+)+\\[^<>:"/\\|?*\r\n]*)$/;
    const unixPathRegex = /^\/(?:[^<>:"/\\|?*\r\n]+\/)*[^<>:"/\\|?*\r\n]*$/;

    const isValidPath = windowsPathRegex.test(folderPath) || unixPathRegex.test(folderPath);

    if (isValidPath) {
      handleSubmitFolderPath(folderPath);
    } else {
      setError(true);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: "0 auto", padding: 1 }}>
      <Typography variant="h6" color="primary" mb={2}>
        Enter the full path of the folder
      </Typography>
      <Box className="v-center" sx={{ gap: 1 }}>
        <TextField
          size="small"
          value={folderPath}
          onChange={handlePathChange}
          placeholder="Enter full folder path"
          label="Folder Path"
          fullWidth
          variant="outlined"
          sx={{ backgroundColor: "background.paper", borderRadius: 1 }}
          error={error} // Apply error style if there's an error
        />
        <Button
          sx={{ fontSize: "10px", px: 0.2, textTransform: "none" }}
          size="small"
          disabled={!folderPath}
          onClick={validatePath}
          variant="contained"
          color="primary">
          Validate
        </Button>
      </Box>
      {error && (
        <Typography color="error" sx={{ fontSize: "10px" }}>
          Invalid path.
        </Typography>
      )}
      <FormHelperText sx={{ margin: "8px 0", color: "text.secondary" }}>
        Example: &apos;C:\\Users\\YourName\\Documents&apos; or &apos;/home/username/Documents&apos;
      </FormHelperText>
    </Box>
  );
};

export default FolderPath;
