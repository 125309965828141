import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const AwsS3 = ({ defaultValues, register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label={t("Description")}
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description")}
          defaultValue={defaultValues?.Description}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          defaultValue={defaultValues?.accessKeyId}
          {...register("accessKeyId", { required: true })}
          label={t("Access Key ID")}
          error={!!errors.accessKeyId}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          defaultValue={defaultValues?.secretAccessKey}
          {...register("secretAccessKey", { required: true })}
          label={t("Secret Access Key")}
          error={!!errors.secretAccessKey}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          fullWidth
          defaultValue={defaultValues.regionName}
          {...register("regionName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={!!errors.regionName}
          helperText={errors?.regionName?.message || null}
          label={t("Region Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) =>
            (e.target.value = e.target.value.replace(/\s{2,}/g, " "))
          }
        />
      </Grid>

      <Grid item sm={12}>
        <TextField
          fullWidth
          defaultValue={defaultValues.bucketName}
          {...register("bucketName", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={!!errors.bucketName}
          helperText={errors?.bucketName?.message || null}
          label={t("Bucket Name")}
          variant="outlined"
          required
          size="small"
          onChange={(e) =>
            (e.target.value = e.target.value.replace(/\s{2,}/g, " "))
          }
        />
      </Grid>
    </>
  );
};

export default AwsS3;
