/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo, useRef, useState } from "react";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NotStartedSharpIcon from "@mui/icons-material/NotStartedSharp";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import AnalyticsIcon from "@mui/icons-material/Analytics";
import { SnackbarContext } from "../../App";
import appService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import { CreateTest } from "./CreateTest";
import { EditTest } from "./EditTest";

import { headCells } from "./HeadCells";
import TestPreview from "./TestPreview";
import VisualTestResults from "./VisualTestResults";
import { useTranslation } from "react-i18next";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";

function ActionsCell({ data, context }) {
  const { t } = useTranslation();
  const { checkAccess } = useContext(UserDetailsContext);

  const [loading, setLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const row = data;

  const handleExecuteResults = async () => {
    setLoading(true);
    await context.excuteTest(row._id, setLoading);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        title={t("Edit")}
        className="accordianActionIcon"
        size="small"
        onClick={() => setIsEditOpen(true)}
        disabled={loading}
      >
        <EditOutlinedIcon fontSize="small" />
      </IconButton>
      {checkAccess("VisualTest", "Preview") && (
        <IconButton
          title={t("Preview")}
          onClick={() => {
            context.ShowPreview(row);
          }}
          className="accordianActionIcon"
          size="small"
        >
          <VisibilityOutlinedIcon fontSize="12px" />
        </IconButton>
      )}
      {checkAccess("VisualTest", "Execute") && (
        <IconButton
          title={t("Execute")}
          onClick={() => handleExecuteResults()}
          className="accordianActionIcon"
          size="small"
          disabled={loading}
        >
          <NotStartedSharpIcon fontSize="12px" />
        </IconButton>
      )}
      <IconButton
        title={t("Results")}
        onClick={() => {
          context.viewResults(row._id);
        }}
        className="accordianActionIcon"
        size="small"
      >
        <AnalyticsIcon fontSize="12px" />
      </IconButton>
      {isEditOpen && (
        <EditTest
          row={row}
          returnValue={context.fetchList}
          state={isEditOpen}
          setState={setIsEditOpen}
        />
      )}
    </Box>
  );
}

export default function VisualTest() {
  const gridRef = useRef();
  const ScrollRef = useRef();
  const { t } = useTranslation();

  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useContext(UserDetailsContext);
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [previewObj, setPreviewObj] = useState(null);
  const [outPut, setOutPut] = useState(null);
  const [loader, setLoader] = useState(false);
  const [result, setResult] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const closePreview = () => {
    setPreviewObj();
    CloseResults();
  };

  const CloseResults = () => {
    setResult([]);
    setOutPut();
  };

  const ShowPreview = (obj) => {
    CloseResults();
    setPreviewObj(obj);
    autoScroll();
  };

  const refreshData = () => {
    if (gridRef) gridRef?.current?.api?.refreshServerSide({ purge: true });
  };

  const deleteTest = async () => {
    closePreview();
    setDeleteLoading(true);
    try {
      await appService.deleteVisualTest({ ids: selected });
      setOpenDialog(false);
      refreshData();
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setDeleteLoading(false);
  };

  const excuteTest = async (id, setLoading) => {
    closePreview();
    setLoader(true);
    try {
      let res = await appService.executeTest(id);
      setLoader(false);
      setOutPut(res);
    } catch (e) {
      setLoader(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };

  const viewResults = async (id) => {
    closePreview();

    setResultsLoading(true);
    try {
      let res = await appService.viewVisualTestResults(id);
      setResult(res);
      setLoader(false);
      res?.data && autoScroll();
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
      setLoader(false);
    }
    setResultsLoading(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    headerTooltip: "Actions",
  };

  useMemo(() => {
    headCells[headCells.length - 1] = action;
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        <Box sx={{ width: "100%" }} className="space-between">
          <Typography variant="h6" className="upper-case">
            {t("Visual Test")}
          </Typography>
          {checkAccess("VisualTest", "Create") && selected?.length === 0 && (
            <CreateTest returnValue={refreshData} closePreview={closePreview} />
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74" sx={{ width: "100%", mb: 2 }}>
        <Box component="form" noValidate autoComplete="off">
          <Box className="createBtn">
            {checkAccess("VisualTest", "Delete") && selected?.length !== 0 && (
              <Box className="v-center">
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected?.length} {t("Selected")}
                </Typography>
                <Tooltip title={t("Delete")}>
                  <IconButton size="small" onClick={handleClickOpenDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <AgGridSSRM
            // previewId={selectedId}
            gridRef={gridRef}
            closePreview={closePreview}
            setSelected={setSelected}
            apiMethod={"getVisualTestList"}
            headCells={headCells}
            context={{
              viewResults: viewResults,
              ShowPreview: ShowPreview,
              fetchList: refreshData,
              excuteTest: excuteTest,
            }}
          />
        </Box>
      </Box>
      {previewObj && (
        <TestPreview
          previewObj={previewObj}
          closePreview={closePreview}
          setOutPut={setOutPut}
          setResult={setResult}
          ScrollRef={ScrollRef}
        />
      )}
      {(loader || resultsLoading) && <SkeletonLoader />}
      {result?.length > 0 && (
        <VisualTestResults
          result={result}
          outPut={outPut}
          setOutPut={setOutPut}
          CloseResults={CloseResults}
          ScrollRef={ScrollRef}
        />
      )}

      {openDialog && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={deleteTest}
          handleClose={handleCloseDialog}
        />
      )}
    </Box>
  );
}
