import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DateRangePicker, defaultInputRanges } from "react-date-range";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { SnackbarContext } from "../../App";

const DateRangeComponent = ({
  selectedDateRange,
  setSelectedDateRange,
  onClickSubmit,
  setShowModal,
}) => {
  const { setSnack } = useContext(SnackbarContext);
  const [maxDate] = useState(new Date());
  const [prevStateDate] = useState(selectedDateRange);

  const handleDateChange = (item) => {
    const { startDate, endDate } = item.selection;
    const daysDifference = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    if (daysDifference <= 31) {
      setSelectedDateRange({ ...item.selection });
    } else {
      setSnack({
        message:
          "Please select a date range of up to 31 days from the start date.",
        open: true,
        colour: "error",
      });
    }
  };

  const handleOnSubmit = () => {
    onClickSubmit();
    setShowModal(false);
  };

  const handleClose = () => {
    setSelectedDateRange(prevStateDate);
    setShowModal(false);
  };

  return (
    <Dialog fullWidth open={true} onClose={handleClose}>
      <DialogTitle variant="h6" className="space-between" gap={2}>
        <Typography>
          <strong>Select Date Range</strong>
        </Typography>
        <Box variant="h6" className="space-between" gap={2}>
          <Typography variant="body1">
            <strong>Start Date : </strong>
            {new Date(selectedDateRange?.startDate).toDateString()}
          </Typography>
          <Typography variant="body1">
            <strong>End Date : </strong>
            {new Date(selectedDateRange?.endDate).toDateString()}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: 0, minHeight: 340 }}>
        <DateRangePicker
          style={{ height: "100%", fontSize: "12px" }}
          maxDate={maxDate}
          ranges={[selectedDateRange]}
          onChange={handleDateChange}
          inputRanges={[defaultInputRanges[0]]}
        />
        <FormHelperText sx={{ mt: 1 }}>
          Note: Please select a date range of up to 30 days from the start date.
        </FormHelperText>
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOnSubmit}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SelectDateRange = ({
  selectedDateRange,
  setSelectedDateRange,
  onClickSubmit,
  size = "small",
}) => {
  const [showModal, setShowModal] = useState(false);
  const maxDate = new Date();

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        startIcon={<CalendarMonthRoundedIcon />}
        variant="outlined"
        color="primary"
        size={size}
      >
        Date Range
      </Button>
      {showModal && (
        <DateRangeComponent
          setSelectedDateRange={setSelectedDateRange}
          selectedDateRange={selectedDateRange}
          onClickSubmit={onClickSubmit}
          setShowModal={setShowModal}
          maxDate={maxDate}
        />
      )}
    </>
  );
};

export default SelectDateRange;
