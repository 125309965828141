import { Box } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
const fileTypes = ["JPG", "PNG", "GIF"];

export default function ImageUpload({ file, setFile }) {
  const { t } = useTranslation();
  const handleChange = (fileData) => {
    if (fileData[0]) {
      setFile(fileData);
    }
  };

  return (
    <Box
      sx={{
        minWidth: "100px",
        "& .guZdik": {
          minWidth: "100px",
        },
      }}
      className="imageUpload"
    >
      <FileUploader
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
      />
      {file ? (
        <p>
          {t("File Name")} : {file?.[0]?.name || file?.originalname}
        </p>
      ) : (
        <p>{t("no files uploaded yet")}</p>
      )}
    </Box>
  );
}
