import { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CustomAutoComplete from "../../../CustomComponents/CustomAutoComplete.js";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EastIcon from "@mui/icons-material/East";
import APIServices from "../../../../services/app.service.js";
import CustomFolderIcon from "../../../CustomComponents/Icons/CustomFolderIcon.js";
import { useSearchParams } from "react-router-dom";
import FolderPath from "../FileWatcherStepper/FolderPath.js";

const iconColors = {
  "Processed Location": "#e1e7ff",
  "Passed Record Location": "#dcfce7",
  "Failed Records Location": "#fae6e7",
  "Error Records Location": "#fef3c6",
};

const icons = {
  "Processed Location": <ChangeCircleOutlinedIcon sx={{ color: "#6466ef" }} />,
  "Passed Record Location": <PlaylistAddCheckOutlinedIcon sx={{ color: "#23c45d" }} />,
  "Failed Records Location": <PlaylistRemoveOutlinedIcon sx={{ color: "#ff2b2a" }} />,
  "Error Records Location": <ErrorOutlineOutlinedIcon sx={{ color: "#f6a925" }} />,
};

const LocationItem = ({ title, description, locationData, data, connectionsList, setLocationDetails }) => {
  const [searchParams] = useSearchParams();

  const saveToSource = JSON.parse(searchParams.get("saveToSource"));

  const { folderKeyName, connectionKeyName, folderListName } = data;
  const [showSelectConnection, setShowSelectConnection] = useState(false);
  const [loadingFolderList, setLoadingFolderList] = useState(false);

  const onChangeConnection = async (con) => {
    if (con?.id === "localdrive") {
      setLocationDetails((prevState) => ({
        ...prevState,
        [connectionKeyName]: con?.id,
        [folderListName]: [],
        [folderKeyName]: "",
      }));
      return;
    }
    setLoadingFolderList(true);
    try {
      const response = await APIServices.getFoldersList({
        connectionId: con?.id,
      });

      setLocationDetails((prevState) => ({
        ...prevState,
        [connectionKeyName]: con?.id,
        [folderListName]: ["/", ...(response?.data || [])],
        [folderKeyName]: "",
      }));
    } catch (error) {
      console.log(error);
    }

    setLoadingFolderList(false);
  };

  const onChangeFolder = async (newValue) => {
    setLocationDetails((prevState) => ({ ...prevState, [folderKeyName]: newValue }));

    setShowSelectConnection(false);
  };

  const toggleSelectConnection = () => {
    setShowSelectConnection((prev) => !prev);
  };

  const selectedConnection = connectionsList?.find((each) => each.id === locationData[connectionKeyName]);

  const getTitleComponent = () => {
    if (selectedConnection) {
      return (
        <Box className="v-center">
          <Box className="v-center" sx={{ gap: 1 }}>
            <img
              width={25}
              src={`/assets/DataInsights/${selectedConnection?.connectionType.toLowerCase()}.png`}
              alt=""
            />
            <Typography>{selectedConnection?.connectionName}</Typography>

            {locationData[folderKeyName] && (
              <>
                <EastIcon color="primary" />
                <CustomFolderIcon height="16" width="16" />
                <Typography>{locationData[folderKeyName]}</Typography>
              </>
            )}
          </Box>
        </Box>
      );
    }

    return <Typography variant="body1">{description}</Typography>;
  };

  const handleOnOpenFolderList = () => {
    !loadingFolderList && !locationData[folderListName]?.length && onChangeConnection(selectedConnection);
  };

  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ backgroundColor: "#f4f8fb", padding: 2, borderRadius: 1 }}>
        <Box className="v-center">
          <Box className="center" sx={{ backgroundColor: iconColors[title], padding: 1, borderRadius: "50%" }}>
            {icons[title]}
          </Box>
          <Box sx={{ marginLeft: 1 }}>
            <Typography variant="h6">{title}</Typography>
            {getTitleComponent()}
          </Box>
          <IconButton onClick={toggleSelectConnection} sx={{ ml: "auto" }}>
            {locationData[folderKeyName] ? <EditNoteOutlinedIcon /> : <AddBoxOutlinedIcon fontSize="large" />}
          </IconButton>
        </Box>
        {showSelectConnection && (
          <Box className="flex column" sx={{ gap: 2, my: 2 }}>
            <CustomAutoComplete
              disabled={saveToSource || loadingFolderList}
              options={connectionsList}
              value={selectedConnection}
              getOptionIcon={(opt) => (
                <img
                  src={`/assets/DataInsights/${opt?.connectionType?.toLowerCase()}.png`}
                  alt={opt.connectionType}
                  style={{ width: 25, height: 25, marginRight: 5 }}
                />
              )}
              getOptionLabel={(option) => option?.connectionName}
              label="Select Connection"
              onChange={(e, newValue) => onChangeConnection(newValue)}
            />

            {selectedConnection?.id === "localdrive" && !loadingFolderList ? (
              <FolderPath defaultValue={locationData[folderKeyName]} handleSubmitFolderPath={onChangeFolder} />
            ) : (
              <CustomAutoComplete
                onOpen={handleOnOpenFolderList}
                loading={loadingFolderList}
                options={locationData[folderListName]}
                label="Select Folder"
                value={locationData[folderKeyName]}
                getOptionIcon={() => (
                  <Box mr={1}>
                    <CustomFolderIcon />
                  </Box>
                )}
                // getOptionLabel={(option) => option?.connectionName}
                onChange={(e, newValue) => onChangeFolder(newValue)}
              />
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default LocationItem;
