import { Box, TableBody, TableCell, Tooltip } from "@mui/material";
import CustomHeaderAgGrid from "../AgGrid/CustomHeaderAgGrid";
// import DataCleaning from "../DataCleaning";
import ExpandableTableRow from "../Tables/ExpandableTableRow";

export default function FailedResultTableRow({ autoScroll, item }) {
  const Results = item?.ApiMultiResult ? item?.ApiMultiResult : [item?.Result];

  return (
    <>
      {Results.map((Result, indx) => (
        <TableBody key={indx}>
          <ExpandableTableRow
            disable={!Result?.TotalFailed}
            autoScroll={autoScroll}
            showData={
              <span style={{ color: "red", float: "left" }}>
                {Result?.TotalFailed}
              </span>
            }
            expandComponent={
              <TableCell
                className="expandableRow"
                sx={{ width: "12%" }}
                colSpan="7"
              >
                <Box>
                  {/* {!validatemodal && <DataCleaning ValidationData={item} />} */}
                  {Result?.FailedRecordsWithPath?.length > 0 ? (
                    <CustomHeaderAgGrid
                      data={Result?.FailedRecordsWithPath}
                      errorColumn={{
                        columns: "FailedValue",
                        color: "red",
                      }}
                    />
                  ) : (
                    <CustomHeaderAgGrid
                      data={Result?.FailedRecords}
                      errorColumn={{
                        columns: item.ColumnName?.split(","),
                        color: "red",
                      }}
                    />
                  )}
                </Box>
              </TableCell>
            }
          >
            {item?.TableName && (
              <TableCell sx={{ width: "20%" }}>
                <Tooltip title={item?.TableName} placement="top">
                  {item?.TableName.length > 20
                    ? item?.TableName.slice(0, 20) + "..."
                    : item?.TableName}
                </Tooltip>
              </TableCell>
            )}
            <TableCell sx={{ width: "20%" }}>{item.ColumnName}</TableCell>
            <TableCell sx={{ width: "20%" }}>
              {item?.CombinedDQCheckName}
            </TableCell>
            <TableCell sx={{ width: "16%" }}>
              {Result?.TotalPassed + Result?.TotalFailed}
            </TableCell>
            <TableCell sx={{ width: "12%", color: "green" }}>
              {Result?.TotalPassed}
            </TableCell>
          </ExpandableTableRow>
        </TableBody>
      ))}
    </>
  );
}
