import { Box, Typography, IconButton, Stack } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import GetControllerFromInput from "./GetControllerFromInput";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AntSwitch from "../CustomComponents/CustomSwitchButton/AntSwitch";

const RuleCheckCard = ({
  columnIndex,
  ruleIndex,
  fromInput,
  inputsHandler,
  checkBoxHandler,
  // moveCard,
  handleRemoveRuleCheck,
  onChangeAndOrSwitch,
  disableAndSwitch,
  loadingCreateOrPreview,
}) => {
  return (
    <Box sx={{ height: "100%" }}>
      <Box className="ecRuleCheckCard">
        <IconButton
          sx={{
            position: "absolute",
            top: -6,
            right: -6,
            "& svg": { fontSize: "16px" },
          }}
          color="error"
          disabled={loadingCreateOrPreview}
          size="small"
          onClick={() => handleRemoveRuleCheck(columnIndex, ruleIndex)}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <Box className="space-between" sx={{ width: "100%" }}>
          <Box className="v-center">
            <DragIndicatorIcon color="primary" fontSize="small" />
            <Typography>{fromInput.DisplayName}</Typography>
          </Box>
          {!disableAndSwitch && (
            <Stack direction="row" spacing={1} alignItems="center" mr={2}>
              <Typography>And</Typography>
              <AntSwitch
                onChange={(e) => onChangeAndOrSwitch(e, columnIndex, ruleIndex)}
                checked={fromInput?.Operator === "OR"}
              />
              <Typography>Or</Typography>
            </Stack>
          )}
        </Box>
        {fromInput?.NestedControls?.map((nestedInput, i) => (
          <GetControllerFromInput
            key={i}
            input={nestedInput}
            inputsHandler={(e) => inputsHandler(e, columnIndex, ruleIndex)}
            checkBoxHandler={(e) =>
              checkBoxHandler(e, columnIndex, fromInput.StateName, ruleIndex)
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default RuleCheckCard;
