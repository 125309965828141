import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

function GetControlerFromInput({
  fromInput,
  inputsHandler,
  checkBoxHandler,
  keyName,
}) {
  const { t } = useTranslation();
  if (fromInput?.ControlType === "Dropdown") {
    return (
      <TextField
        name={fromInput?.StateName}
        select
        sx={{ width: "150px" }}
        size="small"
        defaultValue={fromInput?.ControlProperties?.SelectedValue}
        onChange={(e) => inputsHandler(e, keyName)}
      >
        {fromInput?.ControlProperties?.Values?.map((item, indx) => {
          let indexKey = indx + "key";
          return (
            <MenuItem key={indexKey} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }

  if (fromInput?.ControlType === "CheckBox") {
    return (
      <FormControlLabel
        // sx={{ color: "#AAAAAA" }}
        name={fromInput?.StateName}
        onChange={(e) => checkBoxHandler(e, keyName)}
        control={
          <Checkbox
            // defaultChecked={fromInput?.ControlProperties?.IsChecked}
            size="small"
            checked={fromInput?.ControlProperties?.IsChecked}
          />
        }
        label={t(fromInput?.DisplayName)}
      />
    );
  }
  if (fromInput?.ControlType === "Text") {
    return (
      <TextField
        required
        size="small"
        defaultValue={fromInput?.SelectedValue || ""}
        name={fromInput?.StateName}
        onChange={(e) => inputsHandler(e, keyName)}
        sx={{ ml: 2 }}
        label={t(fromInput?.DisplayName)}
        variant="outlined"
      />
    );
  }
  if (fromInput?.ControlType === "Integer") {
    return (
      <TextField
        type="number"
        defaultValue={fromInput?.SelectedValue || ""}
        required
        InputProps={{ inputProps: { min: "1", step: "1" } }}
        name={fromInput?.StateName}
        onChange={(e) => {
          inputsHandler(e, keyName);
        }}
        size="small"
        sx={{ ml: 2, mt: 0.4, width: "80px" }}
        label={t(fromInput?.DisplayName)}
        variant="outlined"
      />
    );
  }
  if (fromInput?.ControlType === "Heading") {
    return (
      <Box>
        <h2>{t(fromInput?.StateName)}</h2>
      </Box>
    );
  }
}

const ValidationFormInputs = ({
  validationsFormData,
  setValidationsFormData,
  headerComponent = () => {},
  reset = () => {},
  isDisabledButton = () => {},
  addValidationFun,
}) => {
  const { t } = useTranslation();
  const checkBoxHandler = (e, keyname) => {
    const prev_validation = [...validationsFormData];
    if (keyname === e.target.name) {
      prev_validation?.forEach((obj) => {
        if (obj?.StateName === keyname) {
          if (e.target.checked) {
            obj["ControlProperties"]["IsChecked"] = e.target.checked;
            setValidationsFormData(prev_validation);
          } else {
            obj["ControlProperties"]["IsChecked"] = e.target.checked;
            obj?.NestedControls?.forEach((obj1) => {
              if (obj1?.ControlProperties?.IsChecked) {
                obj1["ControlProperties"]["IsChecked"] = false;
              }
            });
            setValidationsFormData(prev_validation);
          }
        }
      });
    } else {
      prev_validation?.forEach((obj) => {
        if (obj?.StateName === keyname) {
          obj["NestedControls"]?.forEach((obj1) => {
            if (obj1?.StateName === e.target.name) {
              obj1["ControlProperties"]["IsChecked"] = e.target.checked;
            }
          });
        }
      });
      setValidationsFormData(prev_validation);
    }
  };

  const inputsHandler = (e, keyname) => {
    const prev_validation = [...validationsFormData];
    prev_validation?.forEach((obj) => {
      if (obj?.StateName === keyname) {
        obj["NestedControls"]?.forEach((obj1) => {
          if (obj1?.StateName === e.target.name) {
            obj1["SelectedValue"] = e.target.value;
            setValidationsFormData(prev_validation);
          }
        });
      }
    });
  };

  return (
    <>
      <Grid className="validationFormInputsContainer" mt={1.2} container>
        <Grid
          item
          container
          className="center"
          px="0.5rem"
          pt="0.5rem"
          pb="0.2rem"
        >
          <Grid item md={6}>
            <Typography my={1} variant="h6">
              {t("Select Data Quality Checks")}
              <CustomWidthTooltip title={t("note4")} placement="right">
                <IconButton size="small">
                  <InfoOutlinedIcon fontSize="12px" />
                </IconButton>
              </CustomWidthTooltip>
            </Typography>
          </Grid>
          <Grid item md={6}>
            {headerComponent()}
          </Grid>
        </Grid>
        <Grid item md={6} className="datatypeValueHead">
          <Typography variant="h6">{t("Value")}</Typography>
        </Grid>
        <Grid item md={6} className="dataTypeMatchHead">
          <Typography variant="h6">{t("Match Datatype")}</Typography>
        </Grid>
        <Grid item md={6}>
          <Box component={Paper} className="dataTypeValue">
            {validationsFormData
              ?.filter((e) => e?.Category === "Value")
              ?.map((fromInput, index) => {
                let indexKey = index + "key";
                return (
                  <Box
                    key={indexKey}
                    className={`${fromInput?.Class} ${
                      fromInput?.ControlProperties?.IsChecked &&
                      fromInput?.NestedControls?.length &&
                      "ValueClassChecked"
                    }`}
                  >
                    <GetControlerFromInput
                      key={fromInput.StateName + "1"}
                      fromInput={fromInput}
                      inputsHandler={inputsHandler}
                      checkBoxHandler={checkBoxHandler}
                      keyName={fromInput.StateName}
                    />

                    {fromInput.ControlProperties.IsChecked &&
                      fromInput?.NestedControls?.map((nestedInput, i) => (
                        <GetControlerFromInput
                          key={i}
                          fromInput={nestedInput}
                          inputsHandler={inputsHandler}
                          checkBoxHandler={checkBoxHandler}
                          keyName={fromInput.StateName}
                        />
                      ))}
                  </Box>
                );
              })}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box component={Paper} className="dataTypeMatch">
            {validationsFormData
              ?.filter((e) => e?.Category === "Data Type")
              ?.map((fromInput, index) => (
                <Box
                  key={index}
                  className={fromInput.Class ? fromInput.Class : ""}
                >
                  <GetControlerFromInput
                    key={fromInput.StateName + "1"}
                    fromInput={fromInput}
                    inputsHandler={inputsHandler}
                    checkBoxHandler={checkBoxHandler}
                    keyName={fromInput.StateName}
                  />
                  {fromInput.ControlProperties.IsChecked &&
                    fromInput?.NestedControls?.map((nestedInput, i) => (
                      <GetControlerFromInput
                        key={i}
                        fromInput={nestedInput}
                        inputsHandler={inputsHandler}
                        checkBoxHandler={checkBoxHandler}
                        keyName={fromInput.StateName}
                      />
                    ))}
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right", mt: 2 }}>
        <Button
          size="small"
          fontSize="small"
          color="error"
          onClick={reset}
          disabled={isDisabledButton()}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          {t("Reset")}
        </Button>
        <Button
          className="loader-button"
          size="small"
          onClick={addValidationFun}
          variant="contained"
          disabled={isDisabledButton()}
        >
          {`${t("Add")} ${t("Data Quality Checks")}`}
        </Button>
      </Box>
    </>
  );
};

export default ValidationFormInputs;
