/* eslint-disable react-hooks/exhaustive-deps */
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Autocomplete, Checkbox, FormControl, Grid, IconButton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useEffect, useState, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import CheckboxesAutoComplete from "../../../components/SelectDropdown";
import ApiService from "../../../services/app.service";
// import { ExpectedRowColumn } from "../../Files/ValidationsForm";
import InnerHeader from "../../Layout/InnerHeader.js";
import SkeletonLoader from "../../SkeletonLoader";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { SnackbarContext } from "../../../App";
import { useTranslation } from "react-i18next";
import SingleDatabaseQuery from "./SingleDatabaseQuery";
import { LoadingButton } from "@mui/lab";
import ConnectionDetails from "../../Validations/SingleDatabase/ConnectionDetails";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import CustomRadioGroup from "../../CustomComponents/CustomRadioGroup";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton";

function GetControlerFromInput({ fromInput, inputsHandler, checkBoxHandler, keyName }) {
  const { t } = useTranslation();
  if (fromInput?.ControlType === "Dropdown") {
    return (
      <TextField
        name={fromInput?.StateName}
        select
        sx={{ width: "150px" }}
        size="small"
        defaultValue={fromInput?.ControlProperties?.SelectedValue}
        onChange={(e) => inputsHandler(e, keyName)}>
        {fromInput?.ControlProperties?.Values?.map((item, indx) => {
          let indexKey = indx + "key";
          return (
            <MenuItem key={indexKey} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
    );
  }

  if (fromInput?.ControlType === "CheckBox") {
    return (
      <FormControlLabel
        // sx={{ color: "#AAAAAA" }}
        name={fromInput?.StateName}
        onChange={(e) => checkBoxHandler(e, keyName)}
        control={
          <Checkbox
            // defaultChecked={fromInput?.ControlProperties?.IsChecked}
            size="small"
            checked={fromInput?.ControlProperties?.IsChecked}
          />
        }
        label={t(fromInput?.DisplayName)}
      />
    );
  }
  if (fromInput?.ControlType === "Text") {
    return (
      <TextField
        required
        size="small"
        defaultValue={fromInput?.SelectedValue || ""}
        name={fromInput?.StateName}
        onChange={(e) => inputsHandler(e, keyName)}
        sx={{ ml: 2 }}
        label={t(fromInput?.DisplayName)}
        variant="outlined"
      />
    );
  }
  if (fromInput?.ControlType === "Integer") {
    return (
      <TextField
        type="number"
        defaultValue={fromInput?.SelectedValue || ""}
        required
        InputProps={{ inputProps: { min: "1", step: "1" } }}
        name={fromInput?.StateName}
        onChange={(e) => {
          inputsHandler(e, keyName);
        }}
        size="small"
        sx={{ ml: 2, mt: 0.4, width: "80px" }}
        label={t(fromInput?.DisplayName)}
        variant="outlined"
      />
    );
  }
  if (fromInput?.ControlType === "Heading") {
    return (
      <Box>
        <h2>{t(fromInput?.StateName)}</h2>
      </Box>
    );
  }
}
const SelectIsQuery = ({ isSqlQuery, onChangeIsQuery }) => {
  const { t } = useTranslation();
  return (
    <FormControl className="customRadioGroup">
      <CustomRadioGroup
        value={isSqlQuery}
        onChange={onChangeIsQuery}
        options={[
          { value: true, label: t("Yes") },
          { value: false, label: t("NO") },
        ]}
        label={t("Do you wish to enter raw SQL query ?")}
      />
    </FormControl>
  );
};

export default function DataValidations({ connectionId, finalValidations, setfinalValidations, autoScroll }) {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const location = useLocation();
  const params = useParams();
  const [connection, setConnection] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [validationsForm, setValidationsForm] = useState([]);
  const [tablesData, setTablesData] = useState([]);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [validationData, setValidationData] = useState([]);
  const [resetData, setResetData] = useState();
  const [selected, setSelected] = useState([]);
  const [collapse, setCollapse] = useState([]);
  const [rowsColumns, setrowsColumns] = useState([]);
  const [tables, setTables] = useState([]);
  const [tableName, setTableName] = useState("");
  const [connectionDetails, setConnectionDetails] = useState({});
  const [JSON_SCHEMA, setJsonSchema] = useState([]);
  const [loader, setLoading] = useState(false);
  const [sobjects, setSobjects] = useState([]);
  const [customObjects, setCustomObjects] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);

  const [isSqlQuery, setIsSqlQuery] = useState(false);
  const [queryInput, setQueryInput] = useState("");
  // const [showExpectedRowsInput, setShowExpectedRowsInput] = useState("");

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 800,
    },
  });

  const checkBoxHandler = (e, keyname) => {
    let prev_validation = [...validationData];
    if (keyname === e.target.name) {
      prev_validation?.forEach((obj) => {
        if (obj?.StateName === keyname) {
          if (e.target.checked) {
            obj["ControlProperties"]["IsChecked"] = e.target.checked;
            setValidationData(prev_validation);
          } else {
            obj["ControlProperties"]["IsChecked"] = e.target.checked;
            obj?.NestedControls?.forEach((obj1) => {
              if (obj1?.ControlProperties?.IsChecked) {
                obj1["ControlProperties"]["IsChecked"] = false;
              }
            });
            setValidationData(prev_validation);
          }
        }
      });
    } else {
      prev_validation?.forEach((obj) => {
        if (obj?.StateName === keyname) {
          obj["NestedControls"]?.forEach((obj1) => {
            if (obj1?.StateName === e.target.name) {
              obj1["ControlProperties"]["IsChecked"] = e.target.checked;
            }
          });
        }
      });
      setValidationData(prev_validation);
    }
  };

  const inputsHandler = (e, keyname) => {
    let prev_validation = [...validationData];
    prev_validation?.forEach((obj) => {
      if (obj?.StateName === keyname) {
        obj["NestedControls"]?.forEach((obj1) => {
          if (obj1?.StateName === e.target.name) {
            obj1["SelectedValue"] = e.target.value;
            setValidationData(prev_validation);
          }
        });
      }
    });
  };

  const selectTableColumns = (event, tablename, column_name) => {
    let table_columns = [...tableColumns];
    if (event.target.checked) {
      if (table_columns?.length === 0) {
        table_columns?.push({
          tablename: tablename,
          CustomQuery: isSqlQuery ? queryInput : "",
          IsCustomQuery: isSqlQuery,
          columns: [
            {
              ColumnName: column_name,
              validation: [],
            },
          ],
        });
        setTableColumns(table_columns);
      } else {
        var table_index = table_columns?.findIndex((obj) => obj?.tablename === tablename);
        if (table_index !== -1) {
          var column_index = table_columns[table_index]?.columns?.findIndex((obj) => obj?.ColumnName === column_name);
          if (column_index === -1) {
            table_columns[table_index]?.columns?.push({
              ColumnName: column_name,
              validation: [],
            });
            setTableColumns(table_columns);
          }
        } else {
          table_columns?.push({
            tablename: tablename,
            CustomQuery: isSqlQuery ? queryInput : "",
            IsCustomQuery: isSqlQuery,
            columns: [
              {
                ColumnName: column_name,
                validation: [],
              },
            ],
          });
          setTableColumns(table_columns);
        }
      }
    } else {
      table_columns?.forEach((obj, index) => {
        if (obj?.tablename === tablename) {
          var col_index = obj?.columns?.findIndex((obj1) => obj1?.ColumnName === column_name);
          obj?.columns?.splice(col_index, 1);
        }
        if (obj?.columns?.length === 0) {
          table_columns?.splice(index, 1);
        }
      });
      setTableColumns(table_columns);
    }
  };

  // const addExpectedColRows = (e, type, tablename) => {
  //   let previous_validation = [...rowsColumns];
  //   var index = previous_validation?.findIndex(
  //     (obj) => obj?.tablename === tablename
  //   );
  //   if (previous_validation?.length === 0 || index === -1) {
  //     var validation_obj = JSON?.parse(JSON.stringify(ExpectedRowColumn[0]));
  //     validation_obj["NestedControls"][0]["SelectedValue"] = e.target.value;
  //     var validation = {
  //       tablename: tablename,
  //   CustomQuery: isSqlQuery ? queryInput : "",
  //  IsCustomQuery: isSqlQuery,
  //       columns: [
  //         {
  //           ColumnName: "",
  //           validation: [validation_obj],
  //         },
  //       ],
  //     };
  //     previous_validation.push(validation);
  //     setrowsColumns(previous_validation);
  //   } else {
  //     previous_validation[
  //       index
  //     ].columns[0].validation[0].NestedControls[0].SelectedValue =
  //       e.target.value?.length > 0 ? e.target.value : 0;
  //     setrowsColumns(previous_validation);
  //   }
  // };

  // const addFinalRow = (tablename) => {
  //   setTablesData([]);
  //   let final_validation = [...finalValidations];
  //   var list1 = final_validation.filter((obj) => obj.tablename === tablename);
  //   var prev = rowsColumns.filter((obj2) => obj2.tablename === tablename);
  //   if (list1?.length > 0) {
  //     var list2 = list1[0].columns.filter((obj1) => obj1.ColumnName === "");
  //     if (list2?.length > 0) {
  //       var validation = list2[0].validation.filter(
  //         (obj3) => obj3.StateName === "expected_rows_columns"
  //       );
  //       validation[0].NestedControls[0].SelectedValue =
  //         prev[0].columns[0].validation[0].NestedControls[0].SelectedValue;
  //     } else {
  //       final_validation.push(prev[0]);
  //     }
  //   } else {
  //     final_validation.push(prev[0]);
  //   }
  //   setfinalValidations(final_validation);
  //   setrowsColumns([]);
  // };
  const addValidationFun = () => {
    const validateInputs = validationData?.some(
      (each) =>
        each?.ControlProperties?.IsChecked &&
        (each?.NestedControls?.length
          ? each?.NestedControls?.[0]?.ControlType === "Text"
            ? !each?.NestedControls?.[0]?.SelectedValue
            : false
          : false),
    );

    if (validateInputs) {
      setSnack({
        message: "Fill the all required text inputs",
        open: true,
        colour: "warning",
      });
      return;
    }
    setValidationsForm([]);
    setValidationData([]);
    setTablesData([]);
    setTableColumns([]);
    setrowsColumns([]);
    const previousCol = [...tableColumns];
    let previousValidation = [...finalValidations];

    // Helper functions to find objects in arrays based on properties
    const findTable = (tablName, validations) => validations.find((val) => val.tablename === tablName);
    const findColumn = (columns, columnName) => columns.find((col) => col.ColumnName === columnName);
    const findValidation = (validations, valId) => validations.find((val) => parseInt(val.Id) === parseInt(valId));

    // Loop through each table column and add selected validations
    previousCol?.forEach((table) =>
      table.columns.forEach((column) => {
        const addedIds = new Set(); // Track added ids for each column
        validationData
          .filter((data) => data["ControlProperties"]["IsChecked"])
          .forEach((data) => {
            // Check if the id is not already added for this column
            if (!addedIds.has(data.Id)) {
              column.validation.push(data);
              addedIds.add(parseInt(data.Id)); // Add the id to the set
            }
          });
      }),
    );

    if (previousValidation.length === 0) {
      previousValidation = previousCol?.map((table) => ({
        ...table,
        columns: table?.columns?.map((column) => ({ ...column })),
      }));
    } else {
      previousCol.forEach((table) => {
        const existingTable = findTable(table.tablename, previousValidation);
        if (existingTable) {
          table.columns.forEach((column) => {
            const existingColumn = findColumn(existingTable.columns, column.ColumnName);
            if (existingColumn) {
              column.validation.forEach((validate) => {
                const existingValidation = findValidation(existingColumn.validation, validate.Id);
                existingValidation
                  ? existingColumn.validation.splice(existingColumn.validation.indexOf(existingValidation), 1, validate)
                  : existingColumn.validation.push(validate);
              });
            } else {
              existingTable.columns.push(column);
            }
          });
        } else {
          previousValidation.push(table);
        }
      });
    }
    // Update the ValidationProperties for rowsColumns
    rowsColumns.forEach((rowColumn) => {
      const table = findTable(rowColumn.table_name, previousValidation);
      if (table) {
        const [rowsValidation, columnsValidation] = table.ValidationProperties;
        [rowsValidation, columnsValidation].forEach((prop, idx) => (prop.Value = rowColumn.rows_columns[idx]));
      }
    });
    setfinalValidations(previousValidation);
    autoScroll();
  };

  const onMouseEnter = (event, key) => {
    setOpenedPopoverId(key);
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    setOpenedPopoverId(null);
    setAnchorEl(null);
  };

  const getTableData = async () => {
    setTablesData([]);
    setLoading(true);
    const payload = {
      connectionId: connectionId,
      tableName: tableName || [],
      CustomQuery: isSqlQuery ? queryInput : "",
      IsCustomQuery: isSqlQuery,
    };
    try {
      let response = await ApiService.ConnectionDetailsDataValidation(payload);
      setCollapse([]);
      setValidationsForm(response?.data?.ValidationsForm);
      setConnection(response?.data);
      setJsonSchema(JSON.parse(JSON.stringify(response?.data?.ValidationsForm)));
      setValidationData(response?.data?.ValidationsForm);
      setTablesData(response?.data?.tablesData);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setValidationsForm([]);
    setValidationData([]);
    setTablesData([]);
    setTableColumns([]);
    setResetData(1);
    setrowsColumns([]);
  };

  const isSelected = (indexId) => selected.indexOf(indexId) !== -1;
  const deleteFinalvalidation = (e, delId) => {
    const selectedIndex = selected.indexOf(delId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, delId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const deleteSelected = () => {
    let res = [...finalValidations];
    res = res.filter(function (value, index) {
      return selected.indexOf(index) === -1;
    });
    setfinalValidations(res);
    setSelected([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const isDisabled = (table_name, rowCount) => {
  //   var list = rowsColumns.filter((obj) => obj.tablename === table_name);
  //   if (list?.length > 0) {
  //     if (
  //       list[0].columns[0].validation[0].NestedControls[0].SelectedValue
  //         ?.length > 0 &&
  //       parseInt(
  //         list[0].columns[0].validation[0].NestedControls[0].SelectedValue
  //       ) > 0
  //     ) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  // };

  const isDisabledButton = () => {
    let list = validationData.filter((obj) => obj.ControlProperties.IsChecked === true);
    return !(tableColumns?.length > 0 && list?.length > 0);
  };

  // const addCollapse = () => {
  //   if (collapse?.length === 0) {
  //     var a = tablesData.map((obj, index) => {
  //       return "panel" + index;
  //     });
  //     setCollapse(a);
  //   } else {
  //     setCollapse([]);
  //   }
  // };

  const checkCollapse = (panel) => {
    let array = [...collapse];
    return array.includes(panel);
  };

  const collapseExpand = (panel) => {
    let array = [...collapse];
    var index = array.findIndex((obj) => obj === panel);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(panel);
    }
    setCollapse(array);
  };

  const onChangeIsQuery = (e) => {
    setIsSqlQuery(e.target.value === "true");
    setTableName("");
    setConnection({});
    setQueryInput("");
    reset();
  };
  const initialSchema = () => {
    let a = JSON.parse(JSON.stringify(JSON_SCHEMA));
    setValidationsForm(a);
    setValidationData(a);
  };
  useEffect(() => {
    if (params.testId && Object.keys(connectionDetails)?.length > 0) {
      getTableData();
    }
  }, [connectionDetails]);

  useEffect(() => {
    (async () => {
      setLoadingTables(true);
      try {
        let response = await ApiService.ConnectionDetails(connectionId);
        setTablesData([]);
        setConnection([]);

        if (response?.data) {
          setConnectionDetails(response?.data?.ConnectionDetails);
          setLoadingTables(false);
          response?.data?.ConnectionDetails?.connectionType === "Salesforce"
            ? setSobjects(response.data?.results)
            : setTables(response?.data?.tables);
        }
      } catch (e) {
        setLoadingTables(false);
        setSnack({
          message: e?.response?.data?.message ?? e.message,
          open: true,
          colour: "error",
        });
      }
    })();

    if (params.testId) {
      if (finalValidations?.[0]?.IsCustomQuery) {
        setIsSqlQuery(true);
        setQueryInput(finalValidations?.[0]?.CustomQuery);
      } else {
        setTableName([finalValidations?.[0]?.tablename]);
      }
    }
  }, []);

  useEffect(() => {
    setTablesData(connection.tablesData);
  }, [finalValidations, resetData]);

  useEffect(() => {
    setTablesData(connection.tablesData);
    initialSchema();
  }, [rowsColumns]);
  const getIsQueryComponent = () => {
    if (!isSqlQuery) {
      return (
        <Box sx={{ height: "100%" }} className="conValSel v-center">
          <Grid container>
            {tables?.length > 0 && (
              <Grid xs={12} md={8} item>
                {/* <CheckboxesAutoComplete
            lable="Select Tables"
            placeholder="Select Tables"
            optionsList={tables}
            returnBack={(val) => {
              setTableName(val);
              if (val?.length === 0) {
                setConnection([]);
              }
            }}
          /> */}
                <TextField
                  fullWidth
                  select
                  label={t("Select Table")}
                  size="small"
                  disabled={tableColumns.length > 0 || finalValidations?.length > 0}
                  value={tableName}
                  name="sourceOneTables"
                  onChange={(e) => {
                    setTablesData([]);
                    if (e.target.value) {
                      setTableName([e.target.value]);
                    } else {
                      setTableName([]);
                    }
                  }}>
                  {tables?.map((option) => {
                    return (
                      <MenuItem key={option.table_name} value={option.table_name}>
                        {option.table_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            )}
            {sobjects?.length > 0 && (
              <Grid xs={12} md={4} item>
                <Autocomplete
                  disablePortal
                  onChange={(e, value) => {
                    setTableName([value]);
                  }}
                  size="small"
                  options={sobjects}
                  renderInput={(paramsData) => <TextField {...paramsData} label="Select objects" />}
                />
              </Grid>
            )}
            {["Salesforce"].includes(connectionDetails?.connectionType) && (
              <Grid
                xs={12}
                md={3}
                textAlign="center"
                item
                sx={{
                  "& .MuiSwitch-root": { top: 0 },
                }}>
                <FormControlLabel
                  size="small"
                  control={
                    <CustomSwitchButton checked={customObjects} onChange={() => setCustomObjects(!customObjects)} />
                  }
                  label="Show Custom objects only"
                />
              </Grid>
            )}
            <Grid xs={12} md={2} item>
              {tableName?.length > 0 && (
                <LoadingButton
                  size="small"
                  onClick={() => getTableData()}
                  variant="contained"
                  color="primary"
                  disabled={loader}
                  loading={loader}
                  startIcon={<ArrowForwardOutlinedIcon />}
                  className="loader-button"
                  loadingPosition="start">
                  {t("GO")}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Box>
      );
    } else {
      return (
        <SingleDatabaseQuery
          isEdit={params.testId}
          queryInput={queryInput}
          setQueryInput={setQueryInput}
          getTableData={getTableData}
          finalValidations={finalValidations}
        />
      );
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      {location?.pathname !== "/DataQualityRule/singledatabase" && (
        <InnerHeader
          name={
            params.testId
              ? `${t("Edit")} ${t("Data Quality Rule")} - ${t("Single Database")}`
              : `${t("Create")} ${t("Data Quality Rule")} - ${t("Single Database")}`
          }
        />
      )}

      <Box
        sx={{
          "& .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
            m: 0,
          },
        }}>
        {location?.pathname?.includes("/connection/data-validations/") && (
          <Link style={{ marginTop: "-0.6rem" }} to="/" className="custom-link">
            <CustomBackButton>{t("Back")}</CustomBackButton>
          </Link>
        )}
        <Typography component={Paper} className="drawerShortHead" variant="h6">
          {t("Validation")}
        </Typography>
        <Box className="conValHead" sx={{ py: 1 }}>
          <Grid container>
            <Grid md={4} item alignItems="center">
              {!params.testId && <SelectIsQuery isSqlQuery={isSqlQuery} onChangeIsQuery={onChangeIsQuery} />}
              <ConnectionDetails connectionDetails={connectionDetails} />
              <Box display={"flex"} mt={0.5}>
                <Box sx={{ width: "140px" }}>
                  <Typography variant="bold"> {t("Schema")} </Typography>
                  <Box sx={{ float: "right" }} component={"span"}>
                    &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
                  </Box>
                </Box>

                {connectionDetails?.schema}
              </Box>
            </Grid>
            <Grid md={8} item>
              {loadingTables && <SkeletonLoader />}
              {getIsQueryComponent()}
            </Grid>
          </Grid>
          {loader ? (
            <SkeletonLoader />
          ) : (
            (tableName?.length > 0 || queryInput) &&
            connection?.tablesData && (
              <Paper sx={{ boxShadow: "none !important" }}>
                {/* <Box className="innerSubHead">
                    <Grid alignItems="center" container>
                      <Grid xs={6} md={6} item>
                        <Typography variant="h6">
                          Select Table Columns:
                        </Typography>
                      </Grid>
                      <Grid xs={6} md={6} item>
                        <Box className="innerSubRight">
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: "110px", textTransform: "none" }}
                            onClick={addCollapse}
                          >
                            {collapse.length === 0 ? (
                              <>
                                Expand all <ExpandMoreIcon />
                              </>
                            ) : (
                              <>
                                Collapse all <ExpandLessIcon />
                              </>
                            )}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box> */}
                {tablesData?.map((item, key) => {
                  let TableNames = Object.keys(item);
                  let TableRowsColumns = Object.values(item);

                  let TableRowkeys = [];
                  if (TableRowsColumns[0]?.Columns?.length > 0) {
                    TableRowkeys = TableRowsColumns[0]?.Columns?.map((colItem) => colItem.COLUMN_NAME);
                  }
                  return (
                    <Accordion
                      key={key}
                      elevation={0}
                      sx={{
                        my: 1,
                        p: 0,
                        background: "#5F64E51A",
                        borderRadius: 1,
                      }}
                      expanded={checkCollapse("panel" + key)}
                      onChange={() => {
                        collapseExpand("panel" + key);
                      }}>
                      <AccordionSummary
                        sx={{
                          minHeight: "auto!important",
                          py: 0,
                        }}
                        expandIcon={
                          <Box className="accordionArrow">
                            <KeyboardArrowDownOutlinedIcon />
                          </Box>
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid container alignItems="center">
                          <Grid item sm={7}>
                            <Tooltip className="ellipsisValidation" title={<b>{TableNames[0]}</b>} placement="top-end">
                              <b>{TableNames[0]}</b>
                            </Tooltip>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "ceneter",
                                gap: 2,
                              }}>
                              <Typography color={"primary"}>
                                {t("Rows")} : {TableRowsColumns[0].rowsCount}
                              </Typography>
                              <Typography color={"error"}>
                                {t("Columns")} : {TableRowsColumns[0].columnCount}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item sm={3}>
                            {/* {showExpectedRowsInput === TableNames[key] ? (
                              <>
                                <TextField
                                  type="number"
                                  // className="expRow"
                                  InputProps={{
                                    inputProps: { min: "0", step: "1" },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onKeyPress={(e) => {
                                    e.stopPropagation();
                                    if (e.code === "Minus") {
                                      e.preventDefault();
                                    }
                                  }}
                                  size="small"
                                  sx={{ mr: 1 }}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    addExpectedColRows(
                                      e,
                                      "rows",
                                      TableNames[0]
                                    );
                                  }}
                                  label="Expected rows"
                                  variant="outlined"
                                />
                                <Button
                                  size="small"
                                  // className="expBtn"
                                  variant="contained"
                                  startIcon={<AddOutlinedIcon />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addFinalRow(TableNames[0]);
                                  }}
                                  disabled={isDisabled(
                                    TableNames[0],
                                    TableRowsColumns[0].rowsCount
                                  )}
                                >
                                  Add row count validation
                                </Button>
                              </>
                            ) : (
                              <Button
                                aria-label="delete"
                                color="primary"
                                aria-describedby={id}
                                variant="contained"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowExpectedRowsInput(TableNames[0]);
                                }}
                                startIcon={<AddOutlinedIcon />}
                              >
                                Expected Rows
                              </Button>
                            )} */}
                          </Grid>
                          <Grid item sm={2}>
                            <Button
                              aria-label="delete"
                              color="primary"
                              aria-describedby={id}
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                onMouseEnter(e, key);
                              }}
                              startIcon={<RemoveRedEyeOutlinedIcon />}>
                              {t("View Table")}
                            </Button>

                            <Popover
                              id={id}
                              anchorEl={anchorEl}
                              onClose={onMouseLeave}
                              open={openedPopoverId === key}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}>
                              <Box sx={{ p: 1 }}>
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onMouseLeave();
                                  }}
                                  color="error"
                                  aria-label="close popover"
                                  sx={{
                                    position: "absolute",
                                    right: -1,
                                    top: -1,
                                    background: "#fff",
                                    p: "2px",
                                    ":hover": { background: "#fff" },
                                  }}>
                                  <CancelOutlinedIcon />
                                </IconButton>
                                <TableContainer sx={{ maxWidth: 650, maxHeight: 300 }}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                    onClick={(e) => e.stopPropagation()}>
                                    <TableHead>
                                      <TableRow>
                                        {TableRowsColumns[0].Columns?.map((v, i) => (
                                          <TableCell key={i}>{v.COLUMN_NAME}</TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {TableRowsColumns?.[0]?.rows?.map((itemRow, ki) => (
                                        <TableRow key={ki}>
                                          {TableRowkeys?.map((v, i) => (
                                            <TableCell key={i}>{itemRow[v]}</TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                      {TableRowsColumns[0]?.rows?.length === 0 && (
                                        <TableRow>
                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                            colSpan={TableRowsColumns[0].columnCount}>
                                            No records to display
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                            </Popover>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          p: 1,
                        }}>
                        <Box>
                          <TableContainer sx={{ background: "white", p: 1 }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ width: "50px" }}></TableCell>
                                  <TableCell>Column Name </TableCell>
                                  <TableCell>Data Type</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {TableRowsColumns[0]?.Columns?.map((row) => {
                                  return (
                                    <TableRow key={row.COLUMN_NAME}>
                                      <TableCell>
                                        <Checkbox
                                          onClick={(event) => selectTableColumns(event, TableNames[0], row.COLUMN_NAME)}
                                          sx={{ p: 0 }}
                                        />
                                      </TableCell>
                                      <TableCell> {row.COLUMN_NAME} </TableCell>
                                      <TableCell>{row.DATA_TYPE}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Paper>
            )
          )}
        </Box>
      </Box>
      {(tableName?.length > 0 || queryInput) && connection?.tablesData && (
        <>
          <Box className="selectVal">
            <Paper>
              <Box className="innerSubHead">
                <Grid container alignItems="center" justify="center">
                  <Grid item sm={8}>
                    <Typography color={"primary"} variant="h6">
                      {t("Select Data Quality Checks")}
                      <CustomWidthTooltip color="primary" title={t("note4")} placement="right">
                        <IconButton size="small">
                          <InfoOutlinedIcon fontSize="12px" />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className="selValBody">
                <Grid container spacing={4}>
                  <Grid sm={12} item md={6} sx={{ mb: 0.5 }}>
                    <Box className="">
                      <Box className="drawerShortHead" component={Paper}>
                        <Typography variant="h6">{t("Value")}</Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          borderTopLeftRadius: 0,
                          boxShadow: "0px 3px 6px #00000029  !important",
                        }}>
                        {validationsForm
                          ?.filter((e) => e?.Category === "Value")
                          ?.map((fromInput, index) => {
                            let indexKey = index + "key";
                            return (
                              <Box
                                key={indexKey}
                                className={`${fromInput?.Class} ${
                                  fromInput?.ControlProperties?.IsChecked &&
                                  fromInput?.NestedControls?.length &&
                                  "ValueClassChecked"
                                }`}>
                                <GetControlerFromInput
                                  key={fromInput.StateName + "1"}
                                  fromInput={fromInput}
                                  inputsHandler={inputsHandler}
                                  checkBoxHandler={checkBoxHandler}
                                  keyName={fromInput.StateName}
                                />

                                {fromInput.ControlProperties.IsChecked &&
                                  fromInput?.NestedControls?.map((nestedInput, i) => (
                                    <GetControlerFromInput
                                      key={i}
                                      fromInput={nestedInput}
                                      inputsHandler={inputsHandler}
                                      checkBoxHandler={checkBoxHandler}
                                      keyName={fromInput.StateName}
                                    />
                                  ))}
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid sm={12} item md={6} sx={{ mb: 0.5 }}>
                    <Box>
                      <Box className="drawerShortHead" component={Paper}>
                        <Typography variant="h6">{t("Match Datatype")}</Typography>
                      </Box>
                      <Box
                        sx={{
                          p: 1.5,
                          borderTopLeftRadius: 0,
                          boxShadow: "0px 3px 6px #00000029  !important",
                        }}>
                        {validationsForm
                          ?.filter((e) => e?.Category === "Data Type")
                          ?.map((fromInput, index) => (
                            <Box key={index} className={fromInput.Class ? fromInput.Class : ""}>
                              <GetControlerFromInput
                                key={fromInput.StateName + "1"}
                                fromInput={fromInput}
                                inputsHandler={inputsHandler}
                                checkBoxHandler={checkBoxHandler}
                                keyName={fromInput.StateName}
                              />
                              {fromInput.ControlProperties.IsChecked &&
                                fromInput?.NestedControls?.map((nestedInput, i) => (
                                  <GetControlerFromInput
                                    key={i}
                                    fromInput={nestedInput}
                                    inputsHandler={inputsHandler}
                                    checkBoxHandler={checkBoxHandler}
                                    keyName={fromInput.StateName}
                                  />
                                ))}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <Box sx={{ textAlign: "right", mt: 1 }}>
            <Button
              size="small"
              fontSize="small"
              color="error"
              onClick={reset}
              disabled={isDisabledButton()}
              variant="contained"
              sx={{ mr: 1 }}>
              {t("Reset")}
            </Button>
            <Button size="small" onClick={addValidationFun} variant="contained" disabled={isDisabledButton()}>
              {`${t("Add")} ${t("Data Quality Checks")}`}
            </Button>
          </Box>
        </>
      )}
      {finalValidations?.length > 0 && (
        <Box className="validations" sx={{ mt: 2 }} component={Paper}>
          <Box className="innerSubHead">
            <Grid container alignItems="center" justify="center">
              <Grid item sm={8}>
                <Typography variant="h6">{t("Data Quality Checks")} :</Typography>
              </Grid>
              <Grid item sm={4}>
                <Box className="createBtn" sx={{ p: "0 !important" }}>
                  {selected?.length > 0 && (
                    <Typography color="inherit" variant="subtitle1" component="div">
                      {selected?.length} {t("selected")}
                      <Tooltip title="Delete">
                        <IconButton size="small" onClick={deleteSelected}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {finalValidations?.map((row, irow) => {
                  const isItemSelected = isSelected(irow);
                  const labelId = `validations-table-checkbox-${irow}`;
                  return (
                    <TableRow key={labelId}>
                      <TableCell width={"4%"} align="center">
                        <Checkbox
                          onClick={(e) => deleteFinalvalidation(e, irow)}
                          inputProps={{ "aria-labelledby": labelId }}
                          checked={isItemSelected}
                          sx={{ p: 0 }}
                        />
                      </TableCell>
                      <TableCell width={"48%"}>
                        <Typography>
                          <strong>{t("Table Name")} : </strong>
                          <Tooltip title={row?.tablename} placement="top">
                            {row?.tablename.length > 30 ? row?.tablename.slice(0, 30) + "..." : row?.tablename}
                          </Tooltip>
                        </Typography>
                      </TableCell>
                      <TableCell width={"48%"}>
                        {row?.columns?.map((data1, index) => (
                          <div key={index}>
                            <Typography className="columnName">
                              <strong>{t("Column Name")} : </strong>
                              {data1.ColumnName || data1?.validation?.[0]?.NestedControls?.[0]?.DisplayName || "NA"}
                            </Typography>
                            {data1?.validation?.map((data, i) => {
                              return (
                                <Box key={i} pl="8px">
                                  {data1.ColumnName !== "" && (
                                    <>
                                      <strong>{i + 1}.</strong>
                                      {data.DisplayName}
                                    </>
                                  )}
                                  {data["NestedControls"]?.map((obj1, key) => (
                                    <Box key={key}>
                                      {obj1.ControlType === "CheckBox" && (
                                        <>
                                          ({obj1.Name} -{obj1["ControlProperties"].IsChecked.toString()})
                                        </>
                                      )}
                                      {obj1.ControlType === "Integer" && obj1.SelectedValue}
                                      {obj1.ControlType === "Dropdown" &&
                                        (obj1.SelectedValue
                                          ? obj1.SelectedValue
                                          : obj1["ControlProperties"].SelectedValue)}
                                      {obj1.ControlType === "Text" && obj1.SelectedValue}
                                    </Box>
                                  ))}
                                  {data1.ColumnName === "" && (
                                    <>
                                      <strong>{i + 1}.</strong>
                                      {data.NestedControls[0]?.DisplayName}-{data.NestedControls[0]?.SelectedValue}
                                    </>
                                  )}
                                </Box>
                              );
                            })}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
