import {
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@mui/material";

const CustomRadioGroup = ({
  value,
  onChange,
  options,
  label,
  onClick = () => {},
  disabled = false,
}) => {
  return (
    <FormControl disabled={disabled} className="customRadioGroup">
      <FormLabel sx={{ mr: 2 }}>{label}</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        onClick={onClick}
        disabled={disabled}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size="small" />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;
