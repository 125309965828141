/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import CustomDrawer from "../../CustomComponents/CustomDrawer";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";

const CreateCollection = ({
  onClose,
  selectedEditData,
  onSuccessCreateCollection,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { t } = useTranslation();

  const { setSnack } = useContext(SnackbarContext);
  const [selectedValue, setSelectedValue] = useState([]);
  const [dropDown, setDropDown] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const saveCollection = async (data) => {
    if (selectedValue.length === 0) {
      setSnack({
        message: "Select at least one Rule",
        open: true,
        colour: "error",
      });
      return;
    }
    setLoadingSubmit(true);
    const res = {
      title: data.title,
      description: data.description,
      ruleIds: selectedValue, // extracting ids
    };
    try {
      if (selectedEditData?._id) {
        await ApiService.UpdateCollection(selectedEditData?._id, res);
      } else {
        await ApiService.CreateCollection(res);
      }
      onSuccessCreateCollection();
      setLoadingSubmit(false);

      setSnack({
        message: "Rule Collection saved",
        open: true,
        colour: "success",
      });
    } catch (e) {
      setLoadingSubmit(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  const fetchData = async () => {
    const reqData = selectedEditData?._id
      ? { type: "editCollection", collectionId: selectedEditData?._id }
      : {};
    try {
      const response = await ApiService.getRulesList(reqData);
      const defaultDropDownList = response.data?.validationTests?.filter(
        (item) => selectedEditData?.ruleIds?.includes(item?._id)
      );
      setDropDown(response.data?.validationTests);
      setSelectedValue(defaultDropDownList);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CustomDrawer
      onClose={onClose}
      title={`${selectedEditData?._id ? t("Edit") : t("Create")} ${t(
        "Rule Collection"
      )}`}
    >
      <Grid container spacing={2}>
        <Grid sm={12} item>
          <Controller
            control={control}
            name="title"
            defaultValue={selectedEditData?.title || ""} // Ensure a default value
            rules={{
              required: true,
              minLength: {
                value: 4,
                message: t("Minimum length 4 char"),
              },
              maxLength: {
                value: 32,
                message: t("Maximum length 32 char"),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                label={`${t("Rule Collection")} ${t("name")}`}
                variant="outlined"
                value={field.value || ""}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
                  field.onChange(cleanedValue); // Update form state with cleaned value
                }}
                helperText={errors?.title?.message || ""}
                error={!!errors.title}
              />
            )}
          />
        </Grid>
        <Grid sm={12} item>
          <Controller
            control={control}
            name="description"
            defaultValue={selectedEditData?.description || ""}
            rules={{
              required: true,
              minLength: {
                value: 4,
                message: t("Minimum length 4 char"),
              },
              maxLength: {
                value: 250,
                message: t("Maximum length 250 char"),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                name="description"
                label={t("description")}
                minRows={2}
                maxRows={4}
                multiline
                variant="outlined"
                value={field.value || ""}
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s{2,}/g, " ");
                  field.onChange(cleanedValue);
                }}
                helperText={errors?.description?.message || ""}
                error={!!errors.description}
              />
            )}
          />
        </Grid>
        <Grid sm={12} item>
          <CustomAutoComplete
            multiple
            limitTags={1}
            options={dropDown}
            label={t("Select Rules")}
            placeholder={t("Select Rules")}
            value={selectedValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.TestName}
            onChange={(event, newValue) => setSelectedValue(newValue)}
            // loading={loadingTables}
            shouldDisableCheckbox={(option) =>
              selectedValue.length >= 5 && !selectedValue.includes(option)
            }
            isOptionEqualToValue={(option, value) => option === value}
          />
        </Grid>
        <Grid sm={12} item sx={{ mt: 4 }} textAlign="right">
          <Button
            variant="outlined"
            onClick={onClose}
            color="error"
            size="small"
            sx={{ mr: 2 }}
          >
            {t("Cancel")}
          </Button>
          <CustomLoadingButton
            disabled={loadingSubmit}
            onClick={handleSubmit(saveCollection)}
          >
            {t("save")}
          </CustomLoadingButton>
        </Grid>
      </Grid>
    </CustomDrawer>
  );
};

export default CreateCollection;
