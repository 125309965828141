import React, { useContext, useEffect, useState } from "react";
import { Paper, Box, Grid, IconButton, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import PlaylistRemoveOutlinedIcon from "@mui/icons-material/PlaylistRemoveOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EastIcon from "@mui/icons-material/East";
import { useTranslation } from "react-i18next";
import { SnackbarContext } from "../../../App";
import SkeletonLoader from "../../SkeletonLoader";
import APIServices from "../../../services/app.service";
import CustomFolderIcon from "../../CustomComponents/Icons/CustomFolderIcon";
import { formatDateLocale } from "../../../_helpers/utils";
const iconColors = {
  "Processed Location": "#e1e7ff",
  "Passed Record Location": "#dcfce7",
  "Failed Records Location": "#fae6e7",
  "Error Records Location": "#fef3c6",
};

const icons = {
  "Processed Location": <ChangeCircleOutlinedIcon sx={{ color: "#6466ef" }} />,
  "Passed Record Location": <PlaylistAddCheckOutlinedIcon sx={{ color: "#23c45d" }} />,
  "Failed Records Location": <PlaylistRemoveOutlinedIcon sx={{ color: "#ff2b2a" }} />,
  "Error Records Location": <ErrorOutlineOutlinedIcon sx={{ color: "#f6a925" }} />,
};
const scheduleFrequencies = {
  1: { unit: "minutes", label: "Minute(s)" },
  2: { unit: "hours", label: "Hour(s)" },
  3: { unit: "days", label: "Day(s)" },
  4: { unit: "weeks", label: "Week(s)" },
  5: { unit: "months", label: "Month(s)" },
};

const PreviewHeader = ({ testDetails, onClose }) => {
  const { t } = useTranslation();
  return (
    <Box mb={0.5}>
      <Grid container alignItems="center">
        <Grid item xs={6} pl={2}>
          <Typography variant="h6" className="upper-case" sx={{ color: "#403C6C" }}>
            {t("Preview")}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right" pr={1}>
          <Box sx={{ float: "right" }} className="v-center">
            <ScheduleIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
            {testDetails?.isRepeat ? (
              <Typography mr={2} className="bold-text italic-text word-spacing">
                Start on {formatDateLocale(testDetails?.startDate)}, repeats every {testDetails?.frequencyUnit}{" "}
                {scheduleFrequencies[testDetails?.frequencyValue].label}, and ends on{" "}
                {formatDateLocale(testDetails?.endDate)}.
              </Typography>
            ) : (
              <Typography mr={2} className="bold-text italic-text word-spacing">
                Start on {formatDateLocale(testDetails?.startDate)}
              </Typography>
            )}
            <IconButton onClick={onClose} size="small" color="error">
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const LocationDetails = ({ title, connectionType = "localdrive", connectionName = "localdrive", folderName = "" }) => (
  <Grid item sm={3}>
    <Box className="v-center" py={1}>
      <Box className="center" sx={{ backgroundColor: iconColors[title], padding: 1, borderRadius: "50%" }}>
        {icons[title]}
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <Typography variant="h6">{title}</Typography>
        <Box className="v-center">
          <Box className="v-center" sx={{ gap: 1 }}>
            <img width={25} src={`/assets/DataInsights/${connectionType.toLowerCase()}.png`} alt="" />
            <Typography>{connectionName}</Typography>

            <EastIcon color="primary" />
            <CustomFolderIcon height="16" width="16" />
            <Typography className="ellipsis" sx={{ maxWidth: "100px !important" }}>
              {folderName === "" ? "/" : folderName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Grid>
);

const TestDetailsInfo = ({ testDetails }) => {
  const { t } = useTranslation();

  const detailRow = (label, value) => (
    <Grid item sm={3}>
      <Typography>
        <span className="upper-case bold-text" style={{ color: "#495057" }}>
          {t(label)} :
        </span>{" "}
        {value}
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid container py={1} className="Previewbar v-center">
        {detailRow("Test Name", testDetails?.TestName)}
        {detailRow("Source Folder Name", testDetails?.sourceFolderName || "/")}
        {detailRow("Connection Name", testDetails?.ConnectionName)}
        {detailRow("File Name", testDetails?.Tables?.[0]?.tablename)}
      </Grid>
      <Grid px={1} borderBottom="1px solid #ccc" component={Paper} container>
        <LocationDetails
          title="Processed Location"
          connectionType={testDetails?.ConnectionDetails?.processed?.connectionType}
          connectionName={testDetails?.ConnectionDetails?.processed?.connectionName}
          folderName={testDetails?.processedFolderName}
        />
        <LocationDetails
          title="Passed Record Location"
          connectionType={testDetails?.ConnectionDetails?.passed?.connectionType}
          connectionName={testDetails?.ConnectionDetails?.passed?.connectionName}
          folderName={testDetails?.passedRecordsFolderName}
        />
        <LocationDetails
          title="Failed Records Location"
          connectionType={testDetails?.ConnectionDetails?.failed?.connectionType}
          connectionName={testDetails?.ConnectionDetails?.failed?.connectionName}
          folderName={testDetails?.failedRecordsFolderName}
        />
        <LocationDetails
          title="Error Records Location"
          connectionType={testDetails?.ConnectionDetails?.error?.connectionType}
          connectionName={testDetails?.ConnectionDetails?.error?.connectionName}
          folderName={testDetails?.errorFileFolderName}
        />
      </Grid>
    </>
  );
};

const ColumnValidationDetails = ({ testDetails }) => {
  const { t } = useTranslation();

  return (
    <Box px={2} py={1.2}>
      <Grid item container columnGap={2}>
        {testDetails?.Tables?.map((table, tableIndex) => (
          <Grid key={tableIndex} item md={4} lg={3} component={Paper} className="DQRulePreviewcard">
            <Typography>
              <span className="bold-text" style={{ color: "#495057", padding: "3px" }}>
                {t("Column Name")} :
              </span>{" "}
              {table.columns.map((col) => (col.ColumnName === "" ? "NA" : col.ColumnName)).join(", ")}
            </Typography>

            {table.columns[0].validation.map((validation, valIndex) => (
              <div key={valIndex}>
                <span style={{ padding: "4px" }}>
                  {valIndex + 1}. {validation.DisplayName}
                </span>
                {validation.NestedControls?.map((control, controlIndex) => (
                  <React.Fragment key={controlIndex}>
                    {control.ControlType === "CheckBox" && (
                      <Box key={controlIndex} ml={2}>
                        {`${control.Name} - ${control.ControlProperties.IsChecked}`}
                      </Box>
                    )}
                    {control.ControlType === "Integer" && `: ${control.SelectedValue}`}
                    {control.ControlType === "Dropdown" && `: ${control.SelectedValue}`}
                    {control.ControlType === "Text" && `: ${control.SelectedValue}`}{" "}
                  </React.Fragment>
                ))}
                {table.columns?.[valIndex]?.validation.ColumnName === "" && table.NestedControls && (
                  <>
                    <strong>{tableIndex + 1}.</strong>
                    {validation.NestedControls && validation.NestedControls[0]?.DisplayName} -{" "}
                    {validation.NestedControls && validation.NestedControls[0]?.SelectedValue}
                  </>
                )}
                <Typography sx={{ ml: 2 }}>Operator : {validation?.Operator}</Typography>
                <br />
              </div>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const PreviewFileWatcher = ({ autoScroll, connection, returnVal }) => {
  const { setSnack } = useContext(SnackbarContext);

  const [testDetails, setTestDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const { data } = await APIServices.GetDataValidationByID(connection);
        const modifiedData = {
          ...data,
          ConnectionId: data?.ConnectionId || "localdrive",
          sourceConnectionId: data?.sourceConnectionId || "localdrive",
          ConnectionName: data?.ConnectionName || "NA",
          ConnectionType: data?.ConnectionName || "localdrive",
        };
        setTestDetails(modifiedData);
        autoScroll();
      } catch (error) {
        setSnack({
          message: error?.response?.data?.message || error?.message,
          open: true,
          colour: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTestDetails();
  }, [connection]);

  return (
    <>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <Paper className="DQRulePreviewContainer">
          <PreviewHeader testDetails={testDetails} onClose={() => returnVal(false)} />
          <TestDetailsInfo testDetails={testDetails} />
          <ColumnValidationDetails testDetails={testDetails} />
        </Paper>
      )}
    </>
  );
};

export default PreviewFileWatcher;
