/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ApiService from "../../services/app.service";
import { SnackbarContext } from "../../App";
import InnerHeader from "../Layout/InnerHeader";
import CreateCollection from "./CreateCollection/index";
import PreviewCollection from "./PreviewCollection";
import CollectionResults from "./CollectionResults";
import SkeletonLoader from "../SkeletonLoader";
import { collectionListHeadCells } from "./collectionsListHeadCells";
import { dateRangeObj, formatDateString, totalExecutionsPerPage } from "../../_helpers/utils";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import {
  CustomEditIcon,
  CustomExecuteIcon,
  CustomPreviewIcon,
  CustomResultIcon,
} from "../CustomComponents/IconButtons";
import { useSearchParams } from "react-router-dom";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";

const CollectionListActions = ({ data, context, ...rest }) => {
  const { t } = useTranslation();
  const { checkAccess, userDetails } = useContext(UserDetailsContext);
  const [executionRunning, setExecutionRunning] = useState(false);
  const isCollectionExecutionRunning = data?.isExecutionRunning > 0;

  const onClickExecuteCollection = async () => {
    setExecutionRunning(true);
    rest.node.setRowSelectable(false);
    await context.executeCollection(data);
    rest.node.setRowSelectable(true);
    setExecutionRunning(false);
  };

  return (
    <>
      <CustomEditIcon
        disabled={executionRunning || isCollectionExecutionRunning || userDetails?.UserId !== data?.UserId}
        title="Edit"
        onClick={() => context.onClickEditScheduledTest(data)}
      />

      {checkAccess("RuleCollection", "Preview") && (
        <CustomPreviewIcon title={t("Preview")} onClick={() => context.previewTests(data)} />
      )}
      {checkAccess("RuleCollection", "Execute") && (
        <CustomExecuteIcon
          title={t("Execute")}
          onClick={onClickExecuteCollection}
          disabled={executionRunning || isCollectionExecutionRunning}
        />
      )}

      <CustomResultIcon title={t("Results")} onClick={() => context.handleOnClickResults(data)} />
    </>
  );
};

const CollectionList = () => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const scrollRef = useRef();
  const { setSnack } = useContext(SnackbarContext);
  const { checkAccess } = useContext(UserDetailsContext);

  let [searchParams, setSearchParams] = useSearchParams({
    sortBy: "DESC",
    currentPage: 1,
    startDate: dateRangeObj.startDate,
    endDate: dateRangeObj.endDate,
  });
  const sortBy = searchParams.get("sortBy");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const currentPage = searchParams.get("currentPage");
  const collectionId = searchParams.get("collectionId");
  const testName = searchParams.get("testName");
  const previewId = searchParams.get("previewId");

  const [openDialog, setOpenDialog] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [selectedEditData, setSelectedEditData] = useState({});
  const [selectedRuleId, setSelectedRuleId] = useState("");
  const [totalExecutionsCount, setTotalExecutionCount] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const selectedCollection = gridRef?.current?.api.getRowNode(collectionId)?.data;
  const clearAll = () => {
    setPreviewData([]);
  };
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 600);
  };

  const refreshData = () => {
    if (gridRef) gridRef?.current?.api?.refreshServerSide({ purge: true });
  };

  const executeCollection = async (row) => {
    gridRef?.current?.api?.deselectAll();
    let data = { collectionId: row?._id };
    setSnack({
      message: "Execution started",
      open: true,
      colour: "success",
    });

    try {
      await ApiService.executeCollection(data);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
  };

  const deleteCollection = async () => {
    setDeleteLoading(true);
    try {
      await ApiService.DeleteCollection({ ids: selected });
      refreshData();
      setSelected([]);
      setSnack({
        message: "Successfully deleted",
        open: true,
        colour: "success",
      });
      clearAll();
      setOpenDialog(false);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setDeleteLoading(false);
  };

  const previewTests = async (obj) => {
    gridRef?.current?.api?.deselectAll();
    setLoadingPreview(true);
    setSearchParams({ previewId: obj._id, testName: obj?.title });
    try {
      let response = await ApiService.GetDataValidations({
        collectionId: obj._id,
      });
      setPreviewData(response?.data?.data);
      autoScroll();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingPreview(false);
  };

  const onClickEditScheduledTest = (row) => {
    setSelectedEditData(row);
    clearAll();
  };
  const onClickCreateCollection = () => {
    setShowCreateCollection(true);
    clearAll();
  };
  const getResultsData = async (rowId) => {
    setResultsLoading(true);
    try {
      let response = await ApiService.getTestExecutions({
        ruleId: rowId?.ruleIds || selectedRuleId?.ruleIds,
        sort: sortBy,
        pageSize: totalExecutionsPerPage,
        page: currentPage,
        startDate: formatDateString(startDate),
        endDate: formatDateString(endDate),
        isScheduler: false,
      });

      const data = response?.data;
      setExecutions(data?.results);
      setTotalExecutionCount(data?.recordsCount);
      gridRef.current.api.deselectAll();
      autoScroll();
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setResultsLoading(false);
    rowId && setSelectedRuleId(rowId);
  };
  const handleOnClickResults = (rowId) => {
    setSearchParams({
      collectionId: rowId?._id || rowId?.data?._id,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "list",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    setPreviewData([]);
    getResultsData(rowId);
  };

  const onSuccessCreateCollection = () => {
    refreshData();
    onClose();
  };

  const isEditData = Object.keys(selectedEditData).length !== 0;
  const onClose = () => {
    if (isEditData) {
      setSelectedEditData({});
    } else {
      setShowCreateCollection(false);
    }
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const action = useMemo(
    () => ({
      headerName: "Actions",
      sortable: false,
      cellRenderer: CollectionListActions,
      cellClass: "locked-col",
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
      width: 300,
    }),
    [],
  );

  collectionListHeadCells[collectionListHeadCells.length - 1] = action;

  useEffect(() => {
    if (collectionId && selectedCollection) {
      getResultsData(selectedCollection);
    }
    if (previewId) {
      previewTests({ title: testName, _id: previewId });
    }
  }, [selectedCollection]);

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Typography variant="h6" className="upper-case">
            {t("Rule Collection")}
          </Typography>
          {checkAccess("RuleCollection", "Create") && (
            <CustomAddButton
              sx={{ borderRadius: 0.4 }}
              onClick={onClickCreateCollection}
              label={t("Create New Rule Collection")}
            />
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74" ref={scrollRef}>
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box component="form" noValidate autoComplete="off">
            <Box className="createBtn">
              {checkAccess("RuleCollection", "Delete") && selected.length !== 0 && (
                <Box className="v-center">
                  <Typography sx={{ flex: "1 1 100%" }} color="inherit">
                    {selected.length} {t("Selected")}
                  </Typography>
                  <Tooltip title={t("Delete")}>
                    <IconButton size="small" onClick={handleClickOpenDialog}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
            <AgGridSSRM
              gridRef={gridRef}
              closePreview={clearAll}
              setSelected={setSelected}
              apiMethod={"RuleCollectionsList"}
              headCells={collectionListHeadCells}
              context={{
                previewTests: previewTests,
                executeCollection: executeCollection,
                handleOnClickResults: handleOnClickResults,
                onClickEditScheduledTest: onClickEditScheduledTest,
              }}
            />
          </Box>
        </Box>
        {loadingPreview ? (
          <SkeletonLoader />
        ) : (
          previewData.length !== 0 && (
            <PreviewCollection scrollRef={scrollRef} closePreview={clearAll} data={previewData} />
          )
        )}

        {selectedCollection?.ValidatoinDetails?.length > 0 && (
          <CollectionResults
            testType={"RuleCollection"}
            totalexecutions={totalExecutionsCount}
            selectedCollection={selectedCollection}
            executions={executions}
            resultsLoading={resultsLoading}
            setExecutions={setExecutions}
            getResultsData={getResultsData}
            fetchList={refreshData}
          />
        )}
        {(showCreateCollection || isEditData) && (
          <CreateCollection
            selectedEditData={selectedEditData}
            onClose={onClose}
            onSuccessCreateCollection={onSuccessCreateCollection}
          />
        )}

        {openDialog && (
          <CustomDeleteModal
            loading={deleteLoading}
            disabled={deleteLoading}
            onClickDelete={deleteCollection}
            handleClose={handleCloseDialog}
          />
        )}
      </Box>
    </>
  );
};

export default CollectionList;
