import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import {
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from "@mui/icons-material";

const CustomAgGridPagination = ({ gridRef, currentPage, totalPages }) => {
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    if (totalPages < 4) {
      setPagination(
        Array.from({ length: totalPages }, (_, index) => index + 1)
      );
    } else {
      if (currentPage < 4) setPagination([1, 2, 3, "....", totalPages]);
      else if (currentPage >= totalPages - 2)
        setPagination([1, "....", totalPages - 2, totalPages - 1, totalPages]);
      else setPagination([1, "....", currentPage, "....", totalPages]);
    }
  }, [currentPage, totalPages, gridRef]);

  const onBtNext = useCallback(() => {
    gridRef.current.api.paginationGoToNextPage();
  }, [gridRef]);

  const onBtPrevious = useCallback(() => {
    gridRef.current.api.paginationGoToPreviousPage();
  }, [gridRef]);

  const onBtGoToPage = useCallback(
    (pageNumber) => {
      const actualPageNumber = pageNumber - 1;
      gridRef.current.api.paginationGoToPage(actualPageNumber);
    },
    [gridRef]
  );

  return (
    <Box className="paging-panel">
      <Button
        disabled={currentPage === 1}
        size="small"
        variant="outlined"
        sx={{ marginRight: "8px" }}
        onClick={onBtPrevious}
      >
        <KeyboardDoubleArrowLeftIcon />
      </Button>
      {pagination?.map((val, i) => {
        return val === "...." ? (
          <Typography key={i} sx={{ marginLeft: "10px", marginRight: "18px" }}>
            {val}
          </Typography>
        ) : (
          <Button
            key={i}
            value={val}
            size="small"
            variant={val === currentPage ? "contained" : "outlined"}
            sx={{ marginRight: "8px" }}
            onClick={(e) => {
              onBtGoToPage(e.target.value);
            }}
          >
            {val}
          </Button>
        );
      })}

      <Button
        disabled={currentPage === totalPages}
        size="small"
        variant="outlined"
        sx={{ marginRight: "8px" }}
        onClick={onBtNext}
      >
        <KeyboardDoubleArrowRightIcon />
      </Button>
    </Box>
  );
};

export default CustomAgGridPagination;
