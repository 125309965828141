import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const CustomDialog = ({
  open,
  fullWidth = false,
  maxWidth = "lg",
  title,
  onClose,
  children,
  dialogClassName = "",
  titleClassName = "",
  contentClassName = "",
  closeIconClassName = "",
  minWidth = null,
}) => (
  <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    onClose={onClose}
    open={open}
    className={`customDialog ${dialogClassName}`}>
    <DialogTitle className={`dialogTitle ${titleClassName}`} component="div">
      <IconButton onClick={onClose} size="small" color="error" className={`dialogCloseIcon ${closeIconClassName}`}>
        <CancelOutlinedIcon />
      </IconButton>
      {title}
    </DialogTitle>
    <DialogContent sx={{ ...(minWidth && {width: minWidth}) }} className={`dialogContent ${contentClassName}`}>
      {children}
    </DialogContent>
  </Dialog>
);

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  dialogClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  closeIconClassName: PropTypes.string,
};

export default CustomDialog;
