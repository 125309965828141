import { Box, Card, Modal } from "@mui/material";

export default function ComparativeResultModel({ setDisplay, children }) {
  const handleClose = () => {
    setDisplay(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",

    boxShadow: 24,

    borderRadius: "4px",
    maxHeight: "85vh",
    overflowY: "auto",
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <Card>
        <Box sx={style}>{children} </Box>
      </Card>
    </Modal>
  );
}
