/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import APIServices from "../../../services/app.service";
import { SnackbarContext } from "../../../App";
import { useSearchParams } from "react-router-dom";
import ConnectionDetails from "./ConnectionDetails";
import SelectTable from "./SelectTable";
import SkeletonLoader from "../../SkeletonLoader";
import TableDetails from "./TableDetails";
import ValidationFormInputs from "./ValidationFormInputs";
import FinalValidationsDetails from "./FinalValidationsDetails";

const DatabaseValidation = ({
  finalValidations,
  setFinalValidations,
  autoScroll,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const connectionId = searchParams.get("connectionId");
  const ruleId = searchParams.get("ruleId");
  const { setSnack } = useContext(SnackbarContext);
  const [tablesList, setTablesList] = useState([]);
  const [loadingTables, setLoadingTables] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState({});

  const [tableData, setTableData] = useState([]);
  const [validationsFormData, setValidationsFormData] = useState([]);
  const [loadingTableDetails, setLoadingTableDetails] = useState(false);
  const [validationsLoading, setValidationsLoading] = useState(false);
  const [originalValFormData, setOriginalValFormData] = useState([]);

  const [tableColumns, setTableColumns] = useState([]);
  const [queryInputData, setQueryInputData] = useState("");
  //   const [rowsColumns, setrowsColumns] = useState([]);
  const getListOfTables = async () => {
    setLoadingTables(true);
    try {
      let response = await APIServices.ConnectionDetails(connectionId);
      setTablesList(response?.data?.tables);
      setConnectionDetails(response?.data?.ConnectionDetails);
    } catch (e) {
      setLoadingTables(false);
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingTables(false);
  };

  const handleSelectTable = async (queryInput) => {
    reset();
    queryInput && setQueryInputData(queryInput);
    const tableName = searchParams.get("tableName");
    setLoadingTableDetails(true);
    const payload = {
      connectionId: connectionId,
      tableName: tableName ? [tableName] : [],
      CustomQuery: queryInput || "",
      IsCustomQuery: queryInput ? true : false,
    };

    try {
      const response = await APIServices.ConnectionDetailsDataValidation(
        payload
      );
      setValidationsFormData(response?.data?.ValidationsForm);
      setOriginalValFormData(
        JSON.parse(JSON.stringify(response?.data?.ValidationsForm))
      );
      setTableData(response?.data?.tablesData);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingTableDetails(false);
  };

  const selectTableColumns = (event, tablename, column_name) => {
    const updateTableColumns = (prevState) => {
      const isChecked = event.target.checked;
      const index = prevState.findIndex(
        (each) => each?.tablename === tablename
      );

      if (index !== -1) {
        const updatedColumns = isChecked
          ? [...prevState[index].columns, column_name]
          : prevState[index].columns.filter((column) => column !== column_name);
        prevState[index].columns = Array.from(new Set(updatedColumns));
        return [...prevState]?.filter((each) => each?.columns.length);
      } else {
        return [
          ...prevState,
          {
            tablename: tablename,
            columns: [column_name],
          },
        ];
      }
    };

    setTableColumns((prevState) => updateTableColumns([...prevState]));
  };

  const addValidationFun = () => {
    const hasRequiredTextInputs = validationsFormData?.some(
      (each) =>
        each?.ControlProperties?.IsChecked &&
        ["ValueCheck", "Min Length", "Max Length", "CustomPattern"].includes(
          each?.Name
        ) &&
        each?.NestedControls?.length &&
        !each?.NestedControls?.[0]?.SelectedValue
    );

    if (hasRequiredTextInputs) {
      setSnack({
        message: "Fill all required text inputs",
        open: true,
        colour: "warning",
      });
      return;
    }

    const filteredValidationData = validationsFormData?.filter(
      (data) => data["ControlProperties"]["IsChecked"]
    );

    const validationData = tableColumns.map((each) => {
      const columnsData = each?.columns?.map((columnName) => ({
        ColumnName: columnName,
        validation: filteredValidationData,
      }));
      autoScroll();
      return {
        tablename: each?.tablename,
        CustomQuery: queryInputData,
        IsCustomQuery: queryInputData ? true : false,
        columns: columnsData,
        // _id: "65afc59587f434f3c3c5d088",
        ValidationProperties: [],
      };
    });

    const findTable = (tablName, validations) =>
      validations.find((val) => val.tablename === tablName);
    const findColumn = (columns, columnName) =>
      columns.find((col) => col.ColumnName === columnName);

    if (finalValidations.length === 0) {
      setFinalValidations(validationData);
    } else {
      tableColumns.forEach((table) => {
        table.columns.forEach((column) => {
          setFinalValidations((prevState) =>
            prevState.map((each) => {
              const existingTable = findTable(
                table.tablename,
                finalValidations
              );

              const existingColumn = findColumn(existingTable.columns, column);

              if (each.tablename === existingTable?.tablename) {
                if (existingColumn) {
                  return {
                    ...each,
                    columns: each.columns?.map((item2) => {
                      if (item2.ColumnName === existingColumn?.ColumnName) {
                        return {
                          ...item2,
                          validation: [
                            ...item2.validation,
                            ...filteredValidationData,
                          ],
                        };
                      } else {
                        return item2;
                      }
                    }),
                  };
                } else {
                  return {
                    ...each,
                    columns: [
                      ...each.columns,
                      {
                        ColumnName: column,
                        validation: filteredValidationData,
                      },
                    ],
                  };
                }
              } else {
                return each;
              }
            })
          );
        });
      });
    }

    setValidationsFormData(JSON.parse(JSON.stringify(originalValFormData)));
    setTableColumns([]);
  };

  const getEditValidationData = async () => {
    setValidationsLoading(true);
    try {
      let response = await APIServices.editValidation(ruleId);
      setSearchParams((params) => {
        params.set(
          "tableName",
          response?.data?.Tables?.[0]?.IsCustomQuery
            ? ""
            : response?.data?.Tables?.[0]?.tablename
        );
        params.set("isCustomQuery", response?.data?.Tables?.[0]?.IsCustomQuery);
        params.set("isFullScan", response?.data?.isFullScan);
        params.set("isPrivate", response?.data?.isPrivate);
        return params;
      });
      setFinalValidations(response?.data?.Tables);

      setValidationsLoading(false);
      return response?.data;
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setValidationsLoading(false);
  };

  // const addExpectedColRows = (e, type, tablename) => {
  //   let previous_validation = [...rowsColumns];
  //   var index = previous_validation?.findIndex(
  //     (obj) => obj?.tablename === tablename
  //   );
  //   if (previous_validation?.length === 0 || index === -1) {
  //     var validation_obj = JSON?.parse(JSON.stringify(ExpectedRowColumn[0]));
  //     validation_obj["NestedControls"][0]["SelectedValue"] = e.target.value;
  //     var validation = {
  //       tablename: tablename,
  //   CustomQuery: isSqlQuery ? queryInput : "",
  //  IsCustomQuery: isSqlQuery,
  //       columns: [
  //         {
  //           ColumnName: "",
  //           validation: [validation_obj],
  //         },
  //       ],
  //     };
  //     previous_validation.push(validation);
  //     setrowsColumns(previous_validation);
  //   } else {
  //     previous_validation[
  //       index
  //     ].columns[0].validation[0].NestedControls[0].SelectedValue =
  //       e.target.value?.length > 0 ? e.target.value : 0;
  //     setrowsColumns(previous_validation);
  //   }
  // };

  // const addFinalRow = (tablename) => {
  //   setTablesData([]);
  //   let final_validation = [...finalValidations];
  //   var list1 = final_validation.filter((obj) => obj.tablename === tablename);
  //   var prev = rowsColumns.filter((obj2) => obj2.tablename === tablename);
  //   if (list1?.length > 0) {
  //     var list2 = list1[0].columns.filter((obj1) => obj1.ColumnName === "");
  //     if (list2?.length > 0) {
  //       var validation = list2[0].validation.filter(
  //         (obj3) => obj3.StateName === "expected_rows_columns"
  //       );
  //       validation[0].NestedControls[0].SelectedValue =
  //         prev[0].columns[0].validation[0].NestedControls[0].SelectedValue;
  //     } else {
  //       final_validation.push(prev[0]);
  //     }
  //   } else {
  //     final_validation.push(prev[0]);
  //   }
  //   setfinalValidations(final_validation);
  //   setrowsColumns([]);
  // };

  const reset = () => {
    setValidationsFormData(JSON.parse(JSON.stringify(originalValFormData)));
    setTableColumns([]);
    setValidationsFormData([]);
    setTableData([]);
    setQueryInputData("");
  };
  const isDisabledButton = () => {
    let list = validationsFormData.filter(
      (obj) => obj.ControlProperties.IsChecked === true
    );
    return !(tableColumns?.length > 0 && list?.length > 0);
  };

  useEffect(() => {
    (async () => {
      await getListOfTables();
      const res = ruleId && (await getEditValidationData());
      const tableNameData = searchParams.get("tableName");
      (tableNameData || res?.Tables?.[0]?.CustomQuery) &&
        handleSelectTable(res?.Tables?.[0]?.CustomQuery);
    })();
  }, []);

  if (loadingTables || validationsLoading) {
    return <SkeletonLoader />;
  }
  return (
    <Box>
      <Box className="ConnectionDataContainer">
        <ConnectionDetails connectionDetails={connectionDetails} />
        <SelectTable
          queryInputData={queryInputData}
          tableColumns={tableColumns}
          finalValidations={finalValidations}
          connectionDetails={connectionDetails}
          tablesList={tablesList}
          loadingTableDetails={loadingTableDetails}
          handleSelectTable={handleSelectTable}
          setQueryInputData={setQueryInputData}
          reset={reset}
        />
      </Box>

      {loadingTableDetails ? (
        <SkeletonLoader />
      ) : (
        tableData?.length > 0 && (
          <>
            <TableDetails
              tableColumns={tableColumns}
              tableData={tableData}
              selectTableColumns={selectTableColumns}
            />
            <ValidationFormInputs
              validationsFormData={validationsFormData}
              setValidationsFormData={setValidationsFormData}
              reset={reset}
              isDisabledButton={isDisabledButton}
              addValidationFun={addValidationFun}
            />
          </>
        )
      )}
      {finalValidations?.length > 0 && (
        <FinalValidationsDetails
          finalValidations={finalValidations}
          setFinalValidations={setFinalValidations}
        />
      )}
    </Box>
  );
};

export default DatabaseValidation;
