import { Box } from "@mui/material";
import PandasReport from "./PandasReport";
import PlotlyReport from "./PlotlyReport";

const ProfillingReports = ({ response, reportType, scrollRef }) => {
  return (
    response && (
      <Box ref={scrollRef}>
        {reportType === "Pandas" && response.analysis ? (
          <PandasReport data={response} />
        ) : (
          <>{response.data && <PlotlyReport data={response} />}</>
        )}
      </Box>
    )
  );
};
export default ProfillingReports;
