import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const OneDrive = ({ defaultValues, register, errors }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label={t("Description")}
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description")}
          defaultValue={defaultValues?.Description}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          defaultValue={defaultValues?.clientId}
          {...register("clientId", { required: true })}
          label={t("Client Id")}
          fullWidth
          error={!!errors.clientId}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          defaultValue={defaultValues?.clientSecret}
          {...register("clientSecret", { required: true })}
          label={t("Client Secret")}
          fullWidth
          error={!!errors.clientSecret}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          defaultValue={defaultValues.tenentId}
          fullWidth
          {...register("tenentId", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={!!errors.tenentId}
          helperText={errors?.tenentId?.message || null}
          label={t("Tenent Id")}
          variant="outlined"
          required
          size="small"
          onChange={(e) =>
            (e.target.value = e.target.value.replace(/\s{2,}/g, " "))
          }
        />
      </Grid>

      <Grid item sm={12}>
        <TextField
          defaultValue={defaultValues.userEmail}
          fullWidth
          {...register("userEmail", {
            required: true,
            minLength: {
              value: 4,
              message: t("Minimum length 4 char"),
            },
          })}
          error={!!errors.userEmail}
          helperText={errors?.userEmail?.message || null}
          label={t("User Email")}
          variant="outlined"
          required
          size="small"
          onChange={(e) =>
            (e.target.value = e.target.value.replace(/\s{2,}/g, " "))
          }
        />
      </Grid>
    </>
  );
};

export default OneDrive;
