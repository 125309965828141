import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { ImagePreview } from "./ImagePreview";
import { formatDate } from "../../_helpers/utils";
import { useTranslation } from "react-i18next";

export default function TestPreview(props) {
  const { t } = useTranslation();
  const { previewObj, closePreview, ScrollRef } = props;

  const [open, setOpen] = useState(false);

  return (
    <Paper sx={{ maxWidth: "100%" }} ref={ScrollRef}>
      <Box>
        <Box className="innerSubHead">
          <Grid container alignItems="center" justify="center">
            <Grid item sm={2}>
              <Typography variant="h6">{t("Preview")} : </Typography>
            </Grid>
            <Grid align="center" item sm={8}></Grid>
            <Grid item sm={2}>
              <IconButton
                onClick={() => closePreview()}
                size="small"
                color="error"
                sx={{ ml: "auto", display: "flex" }}
                aria-label="add to shopping cart">
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="VTPreview">
        <Grid container alignItems={"center"} justifyContent={"center"} spacing={2}>
          {previewObj.BaseImage && (
            <Grid sm={4} item className="VT-Left">
              <Box>
                <Typography
                  onClick={() => {
                    setOpen(true);
                  }}>
                  <ZoomOutMapOutlinedIcon />
                </Typography>
                <img width="100%" src={previewObj.BaseImage.location} alt="" />
              </Box>
            </Grid>
          )}
          <Grid sm={8} item className="VT-Right">
            <Grid container spacing={1}>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold"> {t("Test Name")} : </Typography>
                  <Typography>{previewObj.TestName}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Application Name")} :</Typography>
                  <Typography>{previewObj.ApplicationName}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Application Url")} :</Typography>
                  <Typography>{previewObj.ApplicationUrl}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Max Differences")} :</Typography>
                  <Typography>{previewObj.MaxDifferences}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold"> {t("Threshold")} : </Typography>
                  <Typography>{previewObj.Threshold}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Last Executed On")}:</Typography>
                  <Typography>N/A</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Created By")} :</Typography>
                  <Typography>{previewObj?.UserEmail || "--"}</Typography>
                </Box>
              </Grid>
              <Grid sm={3} item>
                <Box>
                  <Typography variant="bold">{t("Created Date")} :</Typography>
                  <Typography>{formatDate(previewObj.CreatedDate)}</Typography>
                </Box>
              </Grid>
              <Grid sm={12} item>
                <Box>
                  <Typography variant="bold">{t("Ignored Areas")} :</Typography>
                  {previewObj.IgnoredAreas.length === 0 ? (
                    <Typography>0</Typography>
                  ) : (
                    previewObj.IgnoredAreas.map((obj, i) => {
                      return (
                        <Typography key={i}>
                          <span>
                            {t("X")} : {obj.x.toFixed(2)}
                          </span>
                          <span>
                            {t("Y")} : {obj.y.toFixed(2)}
                          </span>
                          <span>
                            {t("Width")} : {obj.width.toFixed(2)}
                          </span>
                          <span>
                            {t("Height")} : {obj.height.toFixed(2)}
                          </span>
                          <span>
                            {t("Unit")} : {obj.unit}
                          </span>
                        </Typography>
                      );
                    })
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {previewObj.BaseImage && (
        <ImagePreview open={open} setOpen={setOpen} url={previewObj.BaseImage.location} ImagePreview={() => null} />
      )}
    </Paper>
  );
}
