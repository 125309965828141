import { Box, Button, Grid, FormHelperText } from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../../App";
import ApiService from "../../../services/app.service";
import CreateOrUpdateDQRule from "../../Validations/CreateOrUpdateDQRule";
import ValidateResultModal from "../../Validations/ValidateResultModal";
import DataValidations from "./DataValidations";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../SkeletonLoader";
import { LoadingButton } from "@mui/lab";

export default function EditConnectionDataValidations() {
  const { t } = useTranslation();
  const ScrollRef = useRef();
  const [finalValidation, setfinalValidation] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);
  // const [connectionData, setConnectionData] = useState({});
  const [defaultRuleName, setDefaultRuleName] = useState("");
  const [isPrivate, setIsPrivate] = useState("private");
  //loaders
  const [updateLoading, setUpdateLoading] = useState(false);
  const [loadValidate, setloadValidate] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [loadingEditValidation, setLoadingEditValidation] = useState(false);
  const [loadingFetchData, setLoadingFetchData] = useState(false);
  const ValidationCheck = async () => {
    setloadValidate(true);
    let data = {
      TestType: "Single Database",
      Tables: finalValidation,
      ConnectionId: params.connectionId,
    };
    try {
      let response = await ApiService.checkValidation(data);
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setloadValidate(false);
    }
  };
  // const GetConnectionDetails = async () => {
  //   try {
  //     let results = await ApiService.ConnectionDetails(params.connectionId);
  //     setConnectionData(results?.data?.ConnectionDetails);
  //   } catch (e) {
  //     setSnack({
  //       message: e?.response?.data?.message ?? e.message,
  //       open: true,
  //       colour: "error",
  //     });
  //   }
  // };

  const getEditValidationData = async () => {
    setLoadingFetchData(true);
    try {
      let response = await ApiService.editValidation(params.testId);
      setfinalValidation(response?.data?.Tables);
      setDefaultRuleName(response?.data?.TestName);
      setIsPrivate(response?.data?.isPrivate ? "private" : "public");
      setLoadingEditValidation(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setLoadingFetchData(false);
  };

  useEffect(() => {
    // GetConnectionDetails()
    getEditValidationData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createValidation = async (ruleName) => {
    setCreateLoading(true);
    let data = {
      TestName: ruleName,
      TestType: "Single Database",
      Tables: finalValidation,
      ConnectionId: params.connectionId,
      isPrivate: isPrivate === "private",
    };
    try {
      await ApiService.createValidation(data);
      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const updateValidation = async (ruleName) => {
    setUpdateLoading(true);
    let data = {
      TestName: ruleName,
      TestType: "Single Database",
      Tables: finalValidation,
      ConnectionId: params.connectionId,
    };
    try {
      await ApiService.updateValidation(data, params.testId);
      setSnack({ message: "DQ Rule Updated", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message,
        open: true,
        colour: "error",
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };
  if (loadingFetchData) return <SkeletonLoader />;

  return (
    <Box sx={{ width: "100%" }} ref={ScrollRef}>
      {loadingEditValidation && (
        <DataValidations
          connectionId={params?.connectionId}
          finalValidations={finalValidation}
          setfinalValidations={setfinalValidation}
          autoScroll={autoScroll}
        />
      )}

      {finalValidation?.length > 0 && (
        <Box sx={{ mt: 1 }}>
          <Grid container>
            <Grid item sm={6} sx={{ mt: 1 }}>
              <Button
                color="error"
                size="small"
                variant="outlined"
                onClick={() => {
                  navigate("/DataQualityRule");
                }}>
                {t("Cancel")}
              </Button>
            </Grid>
            <Grid item sm={6} sx={{ textAlign: "right", mt: 1 }}>
              {validationsResultShow && (
                <ValidateResultModal
                  Validations={validationsResult}
                  model={true}
                  returnValue={(value) => {
                    setValidationsResultShow(value);
                    setValidationsResult([]);
                  }}
                  // connectionDetails={connectionData}
                />
              )}
              <LoadingButton
                onClick={() => ValidationCheck()}
                sx={{ mr: 1 }}
                size="small"
                color="success"
                variant="contained"
                disabled={loadValidate || finalValidation.length === 0}
                className="loader-button"
                loading={loadValidate}
                startIcon={<></>}
                loadingPosition="start">
                {t("Preview")}
              </LoadingButton>

              <CreateOrUpdateDQRule
                defaultRuleName={defaultRuleName}
                isPrivate={isPrivate}
                createLoading={updateLoading || createLoading}
                validateLoading={loadValidate}
                setIsPrivate={setIsPrivate}
                createValidation={createValidation}
                updateValidation={updateValidation}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid item xs={12} mt="16px">
        <FormHelperText>{t("Note")} : </FormHelperText>
        <FormHelperText>{t("note5")}</FormHelperText>
      </Grid>
    </Box>
  );
}
