import { Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
const CustomArrowIndicator = ({ value }) => {
  const color = value === 0 ? "#ccc" : value > 0 ? "success.main" : "error";

  const icon =
    value === 0 ? (
      <></>
    ) : value > 0 ? (
      <ArrowUpwardOutlinedIcon fontSize="small" />
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    );

  return (
    <Typography
      variant="bold"
      color={color}
      ml="auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {value} {icon}
    </Typography>
  );
};

export default CustomArrowIndicator;
