import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CreateDQRule = ({ createLoading, createValidation, onClickCancel }) => {
  const { t } = useTranslation();

  const [ruleNameInput, setRuleNameInput] = useState("");

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={true}
      onClose={onClickCancel}
    >
      <DialogTitle sx={{ pb: 2 }}>{`${t("Provide")} ${t(
        "Data Quality Rule Name"
      )}`}</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          name="rulename"
          required
          label={t("DQ Rule Name")}
          placeholder={t("Data Quality Rule Name")}
          value={ruleNameInput}
          onChange={(e) => setRuleNameInput(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            color="error"
            size="small"
            variant="outlined"
            onClick={() => onClickCancel()}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            disabled={ruleNameInput?.length < 5 || createLoading}
            ssize="small"
            variant="contained"
            onClick={() => createValidation(ruleNameInput)}
            className="loader-button"
            loading={createLoading}
            startIcon={<></>}
            loadingPosition="start"
          >
            {t("Create")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDQRule;
