/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import { formatDateWithTime } from "../../_helpers/utils";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import appService from "../../services/app.service";
import { CustomAgGrid } from "../AgGrid";
import CustomAutoComplete from "../CustomComponents/CustomAutoComplete";
import { ListItemUser } from "./ListItemUser";
import InnerHeader from "../Layout/InnerHeader";
import SkeletonLoader from "../SkeletonLoader";
import { roleAttrs } from "./DQGUser_Constants";
import { DQGUserLogHeadcells } from "./DQGUsersHeadcells";
import CustomBackButton from "../CustomComponents/CustomsButtons/CustomBackButton";

export default function UserLogsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const gridRef = useRef();

  const { id } = useParams();
  const { userDetails } = useContext(UserDetailsContext);
  const [selecteduser, setSelectedUser] = useState("");

  const [userLogs, setUserLogs] = useState([]);
  const [loadinglogs, setLoadinglogs] = useState(false);

  const [userslist, setUsersList] = useState([]);
  const [loadinguserslist, setLoadingUsersList] = useState(false);
  const fetchUsersList = async () => {
    setLoadingUsersList(true);
    const userInfo = {
      UserId: userDetails?.UserId,
      OrganisationId: userDetails?.OrganisationId,
      UserEmail: userDetails?.Email,
    };
    try {
      const users = await appService.fetchExistingDQGUsers(userInfo);
      setUsersList(users);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadingUsersList(false);
    }
  };

  const fetchLogs = async () => {
    setLoadinglogs(true);
    const data = {
      id: Number(id),
    };
    try {
      const response = await appService.getDQGUserLogs(data);
      setUserLogs(response?.ResponseObject);
      setSelectedUser(response?.ResponseObject?.UserDetailLog);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setLoadinglogs(false);
    }
  };

  const onChangeUser = (e, val) => {
    if (val !== null) {
      val?.Id && navigate(`/dqgusers/userlogs/${val?.Id}`);
    }
  };
  useEffect(() => {
    fetchLogs();
  }, [id]);
  if (id && userDetails?.RoleName && userDetails?.RoleName !== "Admin" && Number(id) !== Number(userDetails?.UserId)) {
    return <Navigate to="/dqgusers" />;
  }
  return (
    <React.Fragment>
      <InnerHeader>
        <Grid
          container
          item
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <Grid sm={12} md={6} className="v-center" gap={2}>
            <Link to="/dqgusers" className="custom-link">
              <CustomBackButton>{t("Back")}</CustomBackButton>
            </Link>
            <Typography variant="h6">{t("User Log Data")}</Typography>
          </Grid>
          <Grid sm={12} md={3}>
            {userDetails?.RoleName === "Admin" && (
              <>
                {Object.keys(selecteduser).includes("FirstName", "LastName") && (
                  <CustomAutoComplete
                    loading={loadinguserslist}
                    options={userslist}
                    placeholder={t("Select User")}
                    value={selecteduser}
                    getOptionLabel={(opt) => `${opt?.FirstName}" "${opt?.LastName}`}
                    onChange={onChangeUser}
                    isOptionEqualToValue={(option, value) => option === value}
                    onOpen={() => (userslist?.length > 0 ? null : fetchUsersList())}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </InnerHeader>
      <Box className="pt74">
        {loadinglogs ? (
          <SkeletonLoader />
        ) : (
          <Box>
            {userLogs?.UserDetailLog?.FirstName && (
              <Grid container className="container" p={3}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}>
                    <ListItemUser
                      title="User Name"
                      value={`${userLogs?.UserDetailLog?.FirstName}" "${userLogs?.UserDetailLog?.LastName}`}
                    />
                    <ListItemUser title="Email" value={userLogs?.UserDetailLog?.Email} />
                    <ListItemUser
                      color={roleAttrs[userLogs?.UserDetailLog?.RoleName]?.color}
                      title="Role"
                      value={userLogs?.UserDetailLog?.RoleName}
                    />
                    <ListItemUser
                      color={userLogs?.UserDetailLog?.IsOnline ? "#52BE80" : "#DC7633"}
                      title="Status"
                      value={userLogs?.UserDetailLog?.IsOnline ? "Online" : "Offline"}
                    />
                  </Box>
                </Grid>
                <Grid gap={1} item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}>
                    <ListItemUser title="Created" value={formatDateWithTime(userLogs?.UserDetailLog?.CreatedAt)} />
                    <ListItemUser
                      title="Last Logged In"
                      value={formatDateWithTime(userLogs?.UserDetailLog?.LastLogin)}
                    />
                    {/* <ListItemUser
                        title="Logged In IP Address"
                        value={"--"}
                      />
                      <ListItemUser
                        title="Last Logged In Session Duration"
                        value={"--"}
                      /> */}
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {userLogs?.userActivities?.length !== 0 ? (
                <CustomAgGrid gridRef={gridRef} headCells={DQGUserLogHeadcells} rows={userLogs?.userActivities} />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    height: "60vh",
                  }}
                  className="v-center container">
                  <Typography variant="h5" sx={{ color: "#64748B", m: "auto" }}>
                    {selecteduser?.Id
                      ? "The Selected user does not have any Logs recorded."
                      : "Select any User from Dropdown to View the recorded Logs."}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
