import { Box, Grid, Button, Typography, LinearProgress } from "@mui/material";
import { dateRangeObj, getTimeDuration } from "../../../../_helpers/utils";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const LinearProgressWithLabel = ({ value }) => (
  <Box sx={{ width: "100%", position: "relative" }}>
    <LinearProgress variant="determinate" value={value || 0} />
    <Typography className="percentage" variant="body2" color="text.secondary">
      {`${Math.round(value || 0)}%`}
    </Typography>
  </Box>
);

const ExecutionStatus = ({
  row,
  setAbortModalData,
  t,
  handleToggleExecutions,
}) => {
  const [, setSearchParams] = useSearchParams();

  const onClickViewResult = (rowId) => {
    setSearchParams({
      ruleId: rowId,
      sortBy: "DESC",
      currentPage: 1,
      viewType: "list",
      startDate: dateRangeObj.startDate,
      endDate: dateRangeObj.endDate,
    });
    handleToggleExecutions();
  };
  const getStatusButton = () => {
    switch (row?.status) {
      case 1:
        return (
          <Button
            onClick={() => setAbortModalData(row)}
            variant="outlined"
            size="small"
            color="warning"
          >
            {t("Abort")}
          </Button>
        );
      case 2:
        return (
          <Button
            onClick={() => onClickViewResult(row?.testValidationId)}
            variant="outlined"
            size="small"
            color="success"
          >
            {t("View Result")}
          </Button>
        );
      case 3:
        return <Typography>{t("Aborted")}</Typography>;
      case 4:
        return <Typography>{t("Suspended")}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Grid item sm ml="auto" textAlign="right">
      {getStatusButton()}
    </Grid>
  );
};

const Progress = ({
  value = 0,
  totalbatches,
  completed = 0,
  row,
  setAbortModalData,
  handleToggleExecutions,
}) => {
  const { t } = useTranslation();
  const { validationDetails, ExecutedBy, finishDateTime } = row || {};

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container alignItems="center" mb="8px">
        <Grid item sm>
          <Typography>
            <b>{validationDetails?.TestName}</b> | {t("User")} :{" "}
            <b>{ExecutedBy}</b>
          </Typography>
        </Grid>
        <ExecutionStatus
          row={row}
          setAbortModalData={setAbortModalData}
          t={t}
          handleToggleExecutions={handleToggleExecutions}
        />
      </Grid>
      <LinearProgressWithLabel value={value} />
      <Grid container alignItems="center" mt="8px">
        <Grid item sm>
          <Typography>
            {t("Batches Completed")} :{" "}
            <Box component="span" color="#1565c0" fontWeight="600">
              {completed} {t("of")}{" "}
              <Box component="span" color="#ed6c02" fontWeight="600">
                {totalbatches}
              </Box>
            </Box>
          </Typography>
        </Grid>
        <Grid item sm ml="auto" textAlign="right">
          <Typography color="#ccc">
            {getTimeDuration(finishDateTime, new Date(), t)} {t("ago")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Progress;
