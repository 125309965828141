import { useContext, useEffect, useState } from "react";
// import { UserDetailsContext } from "../../services/UserDetailsContext";
import appService from "../../services/app.service";

import { Box, Grid, IconButton, Tooltip, useTheme, Divider } from "@mui/material";
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorModeContext } from "../../theme/theme";
import AccountSettings from "./AccountSettings";
import ServiceStatusPopup from "./ServiceStatus/ServiceStatusPopup";
// import io from "socket.io-client";
import Notifications from "./Notifications";

const Header = ({ openSideMenu, onClickMenuIcon }) => {
  const theme = useTheme();
  const ClientLogo = process.env.REACT_APP_CLIENT_LOGO;
  const colorMode = useContext(ColorModeContext);
  // const { userDetails } = useContext(UserDetailsContext);
  // const socket = io.connect(process.env.REACT_APP_SOCKET_HOST, {
  //   path: process.env.REACT_APP_SOCKET_PATH,
  //   auth: {
  //     token: `Bearer ${userDetails.Token}`,
  //   },
  // });
  const [notifications, setNotifications] = useState({ unReadCount: 0, allNotifications: [] });
  const notificationMarkAsRead = async (data) => {
    try {
      await appService.NotificationMarkAsRead(data);
      setNotifications((prevState) => ({
        ...prevState,
        unReadCount: 0,
      }));
    } catch (e) {
      console.log(e.message);
    }
  };
  const notificationList = async () => {
    try {
      const result = await appService.getNotifications();
      setNotifications(result);
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    notificationList();
  }, []);

  // useEffect(() => {
  //   socket?.emit("join_room", userDetails?.UserId);
  //   socket?.on("receive_message", (data) => {
  //     setNotifications((prevState) => ({
  //       ...prevState,
  //       unReadCount: data.body.Count,
  //     }));
  //   });
  // }, [socket]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row" justifyContent="left" alignItems="center">
        <Grid item sm={4}>
          <Box className="v-center">
            <Box
              sx={{
                width: openSideMenu ? "calc(200px - 24px)" : "50px",
                transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                display: "inline-block",
              }}>
              <IconButton sx={{ mr: 2 }} size="small" onClick={onClickMenuIcon} edge="start">
                {openSideMenu ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
            <Box>{ClientLogo && <img src={ClientLogo} height="26px" alt="logo-notavailable" />}</Box>
          </Box>
        </Grid>

        <Grid item sm={4} textAlign="center"></Grid>
        <Grid item sm={4} className="headerIcons">
          <Notifications
            notifications={notifications}
            notificationList={notificationList}
            notificationMarkAsRead={notificationMarkAsRead}
          />
          <ServiceStatusPopup />
          <Divider orientation="vertical" variant="string" flexItem style={{ backgroundColor: "#ccc", width: 2 }} />

          <Tooltip arrow placement="top" title={`${theme?.palette?.mode} mode`}>
            <IconButton
              size="small"
              sx={{
                background: "linear-gradient(45deg, #611EB6, #66A2EE)",
                color: "#fff",
              }}
              onClick={colorMode.toggleColorMode}>
              {theme?.palette?.mode === "dark" ? (
                <DarkModeOutlinedIcon fontSize="small" />
              ) : (
                <LightModeOutlinedIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>

          <AccountSettings />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
