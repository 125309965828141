import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  InputBase,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import ECAddColumnsContainer from "../../EC/ECAddColumnsContainer";
import ECRulesList from "../../EC/ECRulesList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import validationRulesData from "./validationRulesData.json";
const CreateRules = ({ setColumnValidationData, handleValidation, columnValidationData, setStates }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const columnsCount = parseInt(searchParams.get("columnsCount"));

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const [noOfColumns, setNoOfColumns] = useState(columnsCount || 5);
  const onChangeColumnsCount = (event) => {
    const parsedValue = parseInt(event.target.value, 10);
    (parsedValue <= 100 || event.target.value === "") && setNoOfColumns(parsedValue);
  };

  const handleSubmit = () => {
    setSearchParams((params) => {
      params.set("columnsCount", noOfColumns);
      return params;
    });
  };

  const isValidColumnCount = typeof noOfColumns === "number" && noOfColumns >= 1;

  const isDisabled = !isValidColumnCount;
  const renderColumnCount = () => {
    return (
      <Grid item md={10}>
        <Box className="center column" gap={1} p={2}>
          <Typography>Before selecting the rules you have to enter the columns</Typography>

          <Typography>No. of Columns : </Typography>
          <Paper
            className="v-center"
            sx={{
              borderRadius: "4px",
              border: "0.5px solid #ccc",
              paddingLeft: "8px",
              paddingRight: "8px",
              color: "#4F4F4F",
              fontWeight: 500,
              "& input": {
                padding: "3px 0px",
              },
            }}>
            <InputBase
              type="number"
              inputProps={{ min: 1, max: 100 }}
              value={noOfColumns}
              onChange={onChangeColumnsCount}
              onInput={(e) => {
                const value = e.target.value;
                e.target.value = value.replace(/[^0-9]/g, "");
              }}
            />
          </Paper>

          <Button size="small" disabled={isDisabled} onClick={handleSubmit} variant="contained" color="success">
            Submit
          </Button>
        </Box>
      </Grid>
    );
  };

  const handleAccordionChange = (_, isExpanded) => {
    setIsAccordionExpanded(isExpanded);
  };

  const onClickNext = async () => {
    if (!(await handleValidation())) {
      return;
    }
    setIsAccordionExpanded(false);
    setStates((prevState) => ({ ...prevState, showScheduled: true }));
  };

  const noMargin = { margin: "0 !important", padding: 0 };
  const safeColumnsCount = Number.isInteger(columnsCount) && columnsCount > 0 ? columnsCount : 0;
  const columnsList = Array.from({ length: safeColumnsCount }, (_, i) => ({
    COLUMN_NAME: `column${i + 1}`,
  }));
  return (
    <Box
      sx={{
        "& .MuiAccordion-root": {
          border: "1px solid #ccc",
          px: 2,
          py: 1,
          "& .MuiAccordionSummary-root": {
            minHeight: "auto",
            "& .MuiAccordionSummary-content": { my: 1 },
          },
        },
      }}>
      <Accordion expanded={isAccordionExpanded} onChange={handleAccordionChange}>
        <AccordionSummary sx={{ ...noMargin, "& .Mui-expanded": { ...noMargin } }} expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6"> Rules</Typography>
        </AccordionSummary>
        <AccordionDetails className="flex column" sx={{ ...noMargin }}>
          <Box className="ecContainer">
            {validationRulesData.length > 0 && (
              <Grid container className="ecRulesColumns">
                <ECRulesList validationTemplate={validationRulesData} />

                {!columnsCount ? (
                  renderColumnCount()
                ) : (
                  <ECAddColumnsContainer
                    tableData={[]}
                    loadingCreateOrPreview={false}
                    columnsData={columnsList}
                    columnValidationData={columnValidationData}
                    setColumnValidationData={setColumnValidationData}
                  />
                )}
              </Grid>
            )}
          </Box>
          <Button sx={{ ml: "auto", mt: 1.5 }} variant="contained" onClick={onClickNext}>
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CreateRules;
