import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

export default function OrderbyDropdown(props) {
  const { t } = useTranslation();
  const { isDisabled, options, fieldName, setSelectedValue, showError } = props;
  return (
    <Autocomplete
      disabled={isDisabled}
      size="small"
      // disablePortal
      options={options}
      value={fieldName} // Set the value prop to the selectedValue
      onChange={(event, newValue, reason) => {
        if (reason === "selectOption") {
          setSelectedValue(newValue);
        } else if (reason === "removeOption") {
          setSelectedValue("");
        } else if (reason === "clear") {
          setSelectedValue("");
        }
      }}
      sx={{ width: 230 }}
      renderInput={(params) => (
        <TextField
          error={showError}
          {...params}
          label={t("Order By")}
          // autoFocus={true} // Add this line to enable autofocus
        />
      )}
    />
  );
}
