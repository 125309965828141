import { useEffect, useRef, useState, useContext } from "react";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import CustomStepper from "./FileWatcherStepper/CustomStepper";
import { SnackbarContext } from "../../../App";
import InnerHeader from "../../Layout/InnerHeader";
import CreateRules from "./CreateRules";
import ResultsLocations from "./ResultsLocation";
import Schedule from "./Schedule";
import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";
import APIServices from "../../../services/app.service";
import CustomBackButton from "../../CustomComponents/CustomsButtons/CustomBackButton";
import SkeletonLoader from "../../SkeletonLoader";
import { isAzure, isLocalDrive } from "../../../_helpers/Constant";

// Helper function for error handling
const handleApiError = (error, setSnack) => {
  const message = error?.response?.data?.message || error?.response?.data?.TestName?.message || error.message;
  setSnack({ message, open: true, colour: "error" });
};

// Default location data
const defaultLocationData = {
  processedConnectionId: "",
  processedFolderName: "",
  errorFileConnectionId: "",
  errorFileFolderName: "",
  passedRecordsConnectionId: "",
  passedRecordsFolderName: "",
  failedRecordsConnectionId: "",
  failedRecordsFolderName: "",
  processedFolderList: [],
  errorFolderList: [],
  passedFolderList: [],
  failedFolderList: [],
};

// Default scheduled data
const defaultScheduledData = {
  frequencyValue: 2,
  frequencyUnit: 1,
  startDate: moment().format("YYYY-MM-DDTHH:mm"),
  endDate: moment().format("YYYY-MM-DDTHH:mm"),
  isRepeat: false,
};

const FormInputField = ({ label, required = false, value, error = false, onChange }) => {
  const { t } = useTranslation();
  const helperText =
    value?.length < 4
      ? "Minimum 4 characters required"
      : "Validation name already existed, Please provide another name";

  return (
    <TextField
      fullWidth
      autoFocus
      size="small"
      required={required}
      label={t(label)}
      placeholder={t(label)}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      helperText={error && helperText}
    />
  );
};

const CreateFileWatcher = () => {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const connectionId = searchParams.get("connectionId");
  const folderName = searchParams.get("folderName");
  const fileType = searchParams.get("fileType") || "csv";
  const activeStep = parseInt(searchParams.get("activeStep"), 10) || 0;
  const fileName = searchParams.get("fileName") || "";
  const columnsCount = parseInt(searchParams.get("columnsCount"), 10);
  const ruleId = searchParams.get("ruleId") || "";
  const saveToSource = JSON.parse(searchParams.get("saveToSource"));

  const [ruleName, setRuleName] = useState("");
  const [description, setDescription] = useState("");
  const [connectionsList, setConnectionsList] = useState([]);
  const [foldersList, setFoldersList] = useState([]);
  const [locationData, setLocationData] = useState(defaultLocationData);
  const [scheduledData, setScheduledData] = useState(defaultScheduledData);
  const [columnValidationData, setColumnValidationData] = useState([{ columns: [], rules: [] }]);

  const [isRuleNameError, setIsRuleNameError] = useState(false);
  const [loadingConList, setLoadingConList] = useState(false);
  const [loadingFoldersList, setLoadingFoldersList] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingEditData, setLoadingEditData] = useState(false);
  const [loadingUpdateRule, setLoadingUpdateRule] = useState(false);

  const [states, setStates] = useState({ showScheduled: false, showCreateRule: false });

  const scrollToTop = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 400);
  };

  const onChangeRuleName = (value) => {
    setRuleName(value);
    isRuleNameError && setIsRuleNameError(false);
  };

  const handleSave = async (editRuleId) => {
    try {
      const isValid = await handleValidation();
      if (!isValid) return;

      if (!fileName) {
        setSnack({
          message: "Enter File Name",
          open: true,
          colour: "warning",
        });
        scrollToTop();
        return;
      }

      if (!ruleName) {
        setIsRuleNameError(true);
        scrollToTop();
        return;
      }

      editRuleId ? setLoadingUpdateRule(true) : setLoadingCreate(true);

      const formattedStartDate = moment(scheduledData.startDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      const formattedEndDate = moment(scheduledData.endDate).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

      const payload = {
        TestName: ruleName,
        description: description,
        TestType: "Single File",
        sourceConnectionId: connectionId !== "localdrive" ? connectionId : null,
        ConnectionId: connectionId !== "localdrive" ? connectionId : null,
        sourceFolderName: folderName !== "/" ? folderName : "",
        fileName: `${fileName}.${fileType}`,
        processedConnectionId:
          locationData?.processedConnectionId !== "localdrive" ? locationData?.processedConnectionId : null,
        processedFolderName: locationData?.processedFolderName !== "/" ? locationData?.processedFolderName : "",
        errorFileConnectionId:
          locationData?.errorFileConnectionId !== "localdrive" ? locationData?.errorFileConnectionId : null,
        errorFileFolderName: locationData?.errorFileFolderName !== "/" ? locationData?.errorFileFolderName : "",
        passedRecordsConnectionId:
          locationData?.passedRecordsConnectionId !== "localdrive" ? locationData?.passedRecordsConnectionId : null,
        passedRecordsFolderName:
          locationData?.passedRecordsFolderName !== "/" ? locationData?.passedRecordsFolderName : "",
        failedRecordsConnectionId:
          locationData?.failedRecordsConnectionId !== "localdrive" ? locationData?.failedRecordsConnectionId : null,
        failedRecordsFolderName:
          locationData?.failedRecordsFolderName !== "/" ? locationData?.failedRecordsFolderName : "",
        noColumns: columnsCount,
        isFirstRowHeader: true,
        frequencyValue: scheduledData.frequencyValue,
        frequencyUnit: scheduledData.frequencyUnit,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        Tables: transformData(columnValidationData),
        isRepeat: scheduledData?.isRepeat,
        isPrivate: true,
        sourcePath: saveToSource,
      };

      if (editRuleId) {
        await APIServices.updateValidation(payload, editRuleId);
        setSnack({
          message: "Test Updated Successfully",
          open: true,
          colour: "success",
        });
      } else {
        await APIServices.createValidation(payload);
        setSnack({
          message: "Test Created Successfully",
          open: true,
          colour: "success",
        });
      }

      navigate("/file-watcher");
    } catch (error) {
      handleApiError(error, setSnack);
      if (error?.response?.data?.TestName?.message) {
        setIsRuleNameError(true);
        scrollToTop();
      }
    } finally {
      editRuleId ? setLoadingUpdateRule(false) : setLoadingCreate(false);
    }
  };

  const handleValidation = async () => {
    const isAddedColAndRules = columnValidationData?.some(
      (each) => each?.columns?.length === 0 || each?.rules?.length === 0,
    );
    const isCheckValidate = columnValidationData?.some((each) =>
      each?.rules?.some((eachRule) =>
        eachRule?.NestedControls?.length &&
        eachRule?.NestedControls?.some((eachnes) => ["Text", "Integer"]?.includes(eachnes?.ControlType))
          ? !eachRule?.NestedControls?.[0]?.SelectedValue
          : false,
      ),
    );
    if (isCheckValidate) {
      setSnack({
        message: "Enter Value For Checks",
        open: true,
        colour: "warning",
      });
      setSearchParams((params) => {
        params.set("validateCheck", true);
        return params;
      });
      return false;
    }
    if (isAddedColAndRules) {
      setSnack({
        message: "Add Missing Columns Or Rule Checks",
        open: true,
        colour: "warning",
      });
      return false;
    }
    return true;
  };

  const transformData = (data) => {
    return data.map((item) => ({
      tablename: `${fileName}.${fileType}`,
      IsCustomQuery: false,
      columns: item.columns.map((column) => ({
        ColumnName: column,
        validation: item.rules,
      })),
    }));
  };

  const fetchConnectionList = async (type = "file") => {
    setLoadingConList(true);

    try {
      const response = await APIServices.getConnectionsByType({ type });
      const azureConnections = response.data.data?.filter((each) => isAzure(each?.connectionType)) || [];
      const localDriveConnection = { id: "localdrive", connectionName: "Local Drive", connectionType: "localdrive" };
      setConnectionsList(isLocalDrive ? [...azureConnections, localDriveConnection] : azureConnections);
    } catch (error) {
      handleApiError(error, setSnack);
    } finally {
      setLoadingConList(false);
    }
  };

  const handleSelectConnection = async (connection) => {
    if (connection?.id === "localdrive") return;
    setLoadingFoldersList(true);
    foldersList?.length && setFoldersList([]);
    try {
      const response = await APIServices.getFoldersList({
        connectionId: connection.id,
      });
      setFoldersList(["/", ...response.data]);
    } catch (error) {
      handleApiError(error, setSnack);
    } finally {
      setLoadingFoldersList(false);
    }
  };

  const reset = () => {
    // setColumnValidationData([{ columns: [], rules: [] }]);
    setLocationData(defaultLocationData);
    // setStates((prevState) => ({ ...prevState, showScheduled: false, showCreateRule: false }));
  };

  const getEditValidationData = async () => {
    setLoadingEditData(true);
    try {
      const { data } = await APIServices.editValidation(ruleId);

      const formattedEditData = data?.Tables?.map((each) => ({
        columns: each?.columns?.map((column) => column?.ColumnName),
        rules: each?.columns?.[0]?.validation,
      }));

      setSearchParams((params) => {
        params.set("folderName", data?.sourceFolderName || "/");
        return params;
      });
      setRuleName(data?.TestName);
      setDescription(data?.description);
      setLocationData({
        ...defaultLocationData,
        processedConnectionId: data?.processedConnectionId || "localdrive",
        processedFolderName: data?.processedFolderName || "/",
        errorFileConnectionId: data?.errorFileConnectionId || "localdrive",
        errorFileFolderName: data?.errorFileFolderName || "/",
        passedRecordsConnectionId: data?.passedRecordsConnectionId || "localdrive",
        passedRecordsFolderName: data?.passedRecordsFolderName || "/",
        failedRecordsConnectionId: data?.failedRecordsConnectionId || "localdrive",
        failedRecordsFolderName: data?.failedRecordsFolderName || "/",
      });

      setColumnValidationData(formattedEditData);

      setScheduledData({
        frequencyValue: data?.frequencyValue ?? 2,
        frequencyUnit: data?.frequencyUnit ?? "",
        startDate: moment(data.startDate).format("YYYY-MM-DDTHH:mm"),
        endDate: moment(data.endDate).format("YYYY-MM-DDTHH:mm"),
        isRepeat: data?.isRepeat ?? false,
      });
      setStates((prevState) => ({ ...prevState, showScheduled: true, showCreateRule: true }));
    } catch (error) {
      handleApiError(error, setSnack);
    } finally {
      setLoadingEditData(false);
    }
  };

  useEffect(() => {
    if (connectionId && connectionId !== "localdrive") {
      handleSelectConnection({ id: connectionId });
    }
    fetchConnectionList();
    if (ruleId) {
      getEditValidationData();
    }
  }, []);

  // const isSaveDisabled = columnValidationData?.every((each) => each?.columns?.length && each?.rules?.length);

  const isSaveDisabled =
    !scheduledData || !states.showScheduled || !states.showCreateRule || !ruleName || isRuleNameError;

  return (
    <>
      <InnerHeader>
        <Box className="v-center" sx={{ width: "100%", gap: 1 }}>
          <CustomBackButton onClick={() => navigate("/file-watcher")}>{t("Back")}</CustomBackButton>
          <Typography variant="h6">{ruleId ? "Edit File Watcher" : t("Create File Watcher")}</Typography>
        </Box>
      </InnerHeader>

      <Box ref={scrollRef} className="pt74 fileWatcherContainer">
        <Box className="container">
          <Box className="titleContainer space-between" sx={{ p: "0.5rem" }}>
            <Typography variant="h6">{ruleId ? "Edit File Watcher" : t("Create New Configuration")}</Typography>
          </Box>

          <Paper className="flex column" sx={{ p: 2, borderRadius: "12px", gap: 2 }}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <FormInputField
                  required
                  label="Rule Name"
                  value={ruleName}
                  onChange={onChangeRuleName}
                  error={isRuleNameError}
                />
              </Grid>
              <Grid item md={6}>
                <FormInputField label="Description" value={description} onChange={setDescription} />
              </Grid>
            </Grid>

            <CustomStepper
              connectionsList={connectionsList}
              loadingConList={loadingConList}
              foldersList={foldersList}
              loadingFoldersList={loadingFoldersList}
              onSelectConnection={(connection) => {
                reset();
                handleSelectConnection(connection);
              }}
            />

            {loadingEditData ? (
              <SkeletonLoader />
            ) : (
              <>
                {activeStep === 3 && (
                  <ResultsLocations
                    foldersList={foldersList}
                    connectionsList={connectionsList}
                    locationData={locationData}
                    setLocationData={setLocationData}
                    setStates={setStates}
                  />
                )}
                {states.showCreateRule && (
                  <CreateRules
                    columnValidationData={columnValidationData}
                    handleValidation={handleValidation}
                    setColumnValidationData={setColumnValidationData}
                    setStates={setStates}
                  />
                )}
                {states.showScheduled && <Schedule scheduledData={scheduledData} setScheduledData={setScheduledData} />}
              </>
            )}
          </Paper>
        </Box>
      </Box>

      <Box className="space-between" mt={2} mb={10}>
        <Button size="small" variant="outlined" color="error" onClick={() => navigate("/file-watcher")}>
          {t("Cancel")}
        </Button>

        <Box className="v-center" sx={{ gap: 1 }}>
          {ruleId && (
            <CustomLoadingButton
              loading={loadingUpdateRule}
              disabled={loadingCreate || !states.showScheduled || isSaveDisabled || loadingUpdateRule}
              onClick={() => handleSave(ruleId)}>
              {t("Update")}
            </CustomLoadingButton>
          )}
          <CustomLoadingButton
            loading={loadingCreate}
            disabled={loadingCreate || !states.showScheduled || isSaveDisabled || loadingUpdateRule}
            onClick={() => handleSave(null)}>
            {t(ruleId ? "Save As New" : "Save")}
          </CustomLoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default CreateFileWatcher;
