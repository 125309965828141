import { FormHelperText, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../App";
import ApiService from "../../services/app.service";
import InnerHeader from "../Layout/InnerHeader";
import Comparative from "./RowComparison/Comparative";
import CreateOrUpdateDQRule from "./CreateOrUpdateDQRule";
import DatabaseToFile from "./RowComparison/DatabaseToFile";
import { CreateValidationSchema } from "./test.schema";
import ValidateResultModal from "./ValidateResultModal";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { LoadingButton } from "@mui/lab";
import CustomBackButton from "../CustomComponents/CustomsButtons/CustomBackButton";
const steps = ["Select Data Sources", "Validate"];
const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "50vw",
  },
});
export default function Validations() {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const ScrollRef = useRef();

  const navigate = useNavigate();

  const [source1, setSource1] = useState({});
  const [source2, setSource2] = useState({});

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [source1Sql, setsource1Sql] = useState("No");

  const [createValidationSchema] = useState(CreateValidationSchema);
  const [finalValidation, setFinalValidation] = useState([]);
  const [validationsResult, setValidationsResult] = useState([]);
  const [validationsResultShow, setValidationsResultShow] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);

  const [isPrivate, setIsPrivate] = useState("private");
  const [firstSourceOrderBy, setFirstSourceOrderBy] = useState("");
  const [secondSourceOrderBy, setSecondSourceOrderBy] = useState("");
  const [isFullScan, setIsFullScan] = useState(false);
  const autoScroll = () => {
    setTimeout(() => {
      ScrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    activeStep === 0 ? navigate("/DataQualityRule") : setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const dataFormat = (ruleName) => {
    const data = { ...createValidationSchema };
    data.comparissonValidations = [];
    data["TestName"] = ruleName;
    data["firstSourceOrderBy"] = firstSourceOrderBy ? firstSourceOrderBy : "";
    data["secondSourceOrderBy"] = secondSourceOrderBy ? secondSourceOrderBy : "";
    data["FirstDatasourceId"] = source1.id;
    data["FirstDatasourceName"] = source1?.dataBase ? source1?.dataBase : source1?.fileName;
    data["FirstDatasourceType"] = source1?.fileName ? "File" : "Database";
    data["SecondDatasourceId"] = source2.id;
    data["SecondDatasourceName"] = source2?.dataBase ? source2?.dataBase : source2?.fileName;
    data["SecondDatasourceType"] = source2?.fileName ? "File" : "Database";
    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          const data__ = {};
          data__["ValidationName"] = "RowCount";
          data__["ValidationId"] = "21";
          data__["ValidationDisplayName"] = "Row Count Match";
          data__["FirstDataSource"] = [];
          data__["SecondDataSource"] = [];
          const a = {
            Table: obj1.Table ? obj1.Table : obj1.filename,
            Column: obj1.Column,
            IsKey: obj1.IsKey,
          };
          const b = {
            Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
            Column: obj.source2[index].Column,
            IsKey: obj.source2[index].IsKey,
          };
          data__["FirstDataSource"].push(a);
          data__["SecondDataSource"].push(b);
          data["comparissonValidations"].push(data__);
        });
      }
      if (obj.validation.row_data_matching === true) {
        obj.source1 &&
          obj.source1.forEach((obj1, index) => {
            const data__ = {};
            data__["ValidationName"] = "RowComparison";
            data__["ValidationId"] = "22";
            data__["ValidationDisplayName"] = "Row Comparison";
            data__["FirstDataSource"] = [];
            data__["SecondDataSource"] = [];

            const a = {
              Table: obj1.Table ? obj1.Table : obj1.filename,
              Column: obj1.Column,
              IsKey: obj1.IsKey,
            };

            const b = {
              Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
              Column: obj.source2[index].Column,
              IsKey: obj.source2[index].IsKey,
            };
            data__["FirstDataSource"].push(a);
            data__["SecondDataSource"].push(b);
            data["comparissonValidations"].push(data__);
          });
      }
    });
    return data;
  };

  const sqlDataFormate = (ruleName) => {
    const data = { ...createValidationSchema };
    data.comparissonValidations = [];
    data["TestName"] = ruleName;
    data["FirstDatasourceId"] = source1?.connectionName ? source1.id : source1._id;
    data["FirstDatasourceName"] = source1?.dataBase ? source1?.dataBase : source1?.fileName;
    data["FirstDatasourceType"] = source1?.fileName ? "File" : "Database";

    data["SecondDatasourceId"] = source2.id;
    data["SecondDatasourceName"] = source2?.dataBase ? source2?.dataBase : source2?.fileName;
    data["SecondDatasourceType"] = source2?.fileName ? "File" : "Database";

    finalValidation.forEach((obj) => {
      if (obj.validation.row_count_matching === true) {
        obj.source1.forEach((obj1, index) => {
          const data__ = {};
          data__["SqlQuery"] = {
            FirstDataSource: obj1.sqlQuery,
            SecondDataSource: obj.source2[index].sqlQuery,
          };
          data__["ValidationName"] = "RowCount";
          data__["ValidationId"] = "21";
          data__["ValidationDisplayName"] = "Row Count Match";
          data__["FirstDataSource"] = [];
          data__["SecondDataSource"] = [];
          const a = {
            Table: obj1.Table ? obj1.Table : obj1?.filename,
            Column: obj1.Column,
            IsKey: obj1.IsKey,
          };
          const b = {
            Table: obj.source2[index]?.Table ? obj.source2[index].Table : obj.source2[index]?.filename,
            Column: obj.source2[index].Column,
            IsKey: obj.source2[index].IsKey,
          };
          data__["FirstDataSource"].push(a);
          data__["SecondDataSource"].push(b);
          data["comparissonValidations"].push(data__);
        });
      }
      if (obj.validation.row_data_matching === true) {
        obj.source1 &&
          obj.source1.forEach((obj1, index) => {
            const data__ = {};
            data__["SqlQuery"] = {
              FirstDataSource: obj1.sqlQuery,
              SecondDataSource: obj.source2[index].sqlQuery,
            };
            data__["ValidationName"] = "RowComparison";
            data__["ValidationId"] = "22";
            data__["ValidationDisplayName"] = "Row Comparison";
            data__["FirstDataSource"] = [];
            data__["SecondDataSource"] = [];

            const a = {
              Table: obj1.Table ? obj1.Table : obj1.filename,
              Column: obj1.Column,
              IsKey: obj1.IsKey,
            };

            const b = {
              Table: obj.source2[index].Table ? obj.source2[index].Table : obj.source2[index].filename,
              Column: obj.source2[index].Column,
              IsKey: obj.source2[index].IsKey,
            };
            data__["FirstDataSource"].push(a);
            data__["SecondDataSource"].push(b);
            data["comparissonValidations"].push(data__);
          });
      }
    });

    return data;
  };

  const createValidation = async (ruleName) => {
    setCreateLoading(true);
    let data;
    if (source1Sql === "Yes") {
      data = sqlDataFormate(ruleName);
    } else {
      data = dataFormat(ruleName);
    }
    try {
      await ApiService.createValidation({
        ...data,
        isPrivate: isPrivate === "private",
        isFullScan: isFullScan,
      });
      setSnack({ message: "DQ Rule Created", open: true, colour: "success" });
      navigate("/DataQualityRule");
    } catch (error) {
      setSnack({
        message: error?.response?.data?.TestName?.message || error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const checkValidation = async () => {
    setValidateLoading(true);
    let data;
    if (source1Sql === "Yes") {
      data = sqlDataFormate("");
    } else {
      data = dataFormat("");
    }
    try {
      const response = await ApiService.checkValidation({
        ...data,
        isFullScan: isFullScan,
      });
      setValidationsResult(response?.data?.response?.ResponseObject?.Validations);
      setValidationsResultShow(true);
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setValidateLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} ref={ScrollRef}>
      <InnerHeader name={t("createComparisionHeading")} />
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}>
            <CustomBackButton sx={{ mb: 1 }} onClick={handleBack}>
              {t("Back")}
            </CustomBackButton>
            <IconButton>
              <NoMaxWidthTooltip width={"100%"} title={t("note9")} placement="left-start" arrow>
                <InfoOutlinedIcon />
              </NoMaxWidthTooltip>
            </IconButton>
          </Box>

          <Box sx={{ mt: 2, mb: 1 }}>
            <div style={{ display: activeStep === 0 ? "block" : "none" }}>
              <Comparative setSource1={setSource1} setSource2={setSource2} />
            </div>

            {activeStep === 1 && (
              <DatabaseToFile
                source1={source1}
                source2={source2}
                finalValidation={finalValidation}
                setFinalValidation={setFinalValidation}
                source1Sql={source1Sql}
                setsource1Sql={setsource1Sql}
                sqlQuery={true}
                autoScroll={autoScroll}
                setFirstSourceOrderBy={setFirstSourceOrderBy}
                firstSourceOrderBy={firstSourceOrderBy}
                secondSourceOrderBy={secondSourceOrderBy}
                setSecondSourceOrderBy={setSecondSourceOrderBy}
                isFullScan={isFullScan}
                setIsFullScan={setIsFullScan}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <>
                {finalValidation.length > 0 && (
                  <Grid container>
                    <Grid item sm={6} sx={{ textAlign: "left" }}>
                      <Button
                        color="error"
                        size="small"
                        variant="outlined"
                        onClick={() => navigate("/DataQualityRule")}>
                        {t("Cancel")}
                      </Button>
                    </Grid>
                    <Grid item sm={6} sx={{ textAlign: "right" }}>
                      {validationsResultShow && (
                        <ValidateResultModal
                          Validations={validationsResult}
                          model={true}
                          Comparative={true}
                          returnValue={(value) => {
                            setValidationsResultShow(value);
                            setValidationsResult([]);
                          }}
                          validationDetailsRowComparison={dataFormat}
                        />
                      )}
                      <LoadingButton
                        onClick={() => checkValidation()}
                        sx={{ mr: 1 }}
                        size="small"
                        color="success"
                        variant="contained"
                        disabled={validateLoading || createLoading}
                        className="loader-button"
                        loading={validateLoading}
                        startIcon={<></>}
                        loadingPosition="start">
                        {t("Preview")}
                      </LoadingButton>

                      <CreateOrUpdateDQRule
                        isPrivate={isPrivate}
                        createLoading={createLoading}
                        validateLoading={validateLoading}
                        setIsPrivate={setIsPrivate}
                        createValidation={createValidation}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormHelperText>{t("Note")} : </FormHelperText>
                      <FormHelperText>{t("note5")}</FormHelperText>
                      <FormHelperText>
                        <FormHelperText>{t("note6")}</FormHelperText>
                      </FormHelperText>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Button disabled={!(source1 && source2)} variant="contained" onClick={handleNext}>
                {t("Next")}
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
