import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Box, Grid, Typography } from "@mui/material";
import { formatNumber } from "../../_helpers/utils";
import { useStyles } from "../Styles";

const DQRulesOverview = ({ tests }) => {
  return (
    <Box className="container">
      <Typography className="DQhead" variant="h6">
        Data Quality
      </Typography>

      <Box className="compSec">
        <Grid my="8px" container spacing={2}>
          <Grid item sm={4}>
            <Box className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid item sm={12} md={8}>
                  <Box sx={useStyles.TimeCir}>
                    <StorageOutlinedIcon /> <Typography>DB</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="wBox">
                <Typography>
                  Rules <b>{tests?.singleDatabase?.ValidatedRecords?.Tests}</b>
                </Typography>
                <Typography>
                  DQ Checks{" "}
                  <b>
                    {tests?.singleDatabase?.ValidatedRecords?.TotalValidations}
                  </b>
                </Typography>
                <Typography>
                  Records Validated{" "}
                  <b>
                    {formatNumber(
                      tests?.singleDatabase?.ValidatedRecords.ValidatedRecords
                    )}
                  </b>
                </Typography>
                <Typography>
                  Data Sources <b> {tests.singleDatabase?.dataSource} </b>
                </Typography>
              </Box>

              <Grid container className="PassFail" mt="0!important">
                <Grid item sm={6}>
                  <Box className="passed">
                    <CheckCircleRoundedIcon sx={{ color: "#50cd89" }} />
                    <Typography>
                      {formatNumber(
                        tests?.singleDatabase?.ValidatedRecords.Passed
                      )}
                      {" Passed"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Box className="failed">
                    <CancelRoundedIcon color="error" />
                    <Typography>
                      {formatNumber(
                        tests?.singleDatabase?.ValidatedRecords.Failed
                      )}
                      {" Failed"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid item sm={12} md={8}>
                  <Box sx={useStyles.TimeCir}>
                    <ArticleOutlinedIcon /> <Typography>File</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box className="wBox">
                <Typography>
                  Rules <b>{tests?.singleFile?.ValidatedRecords?.Tests}</b>
                </Typography>
                <Typography>
                  DQ Checks{" "}
                  <b>{tests?.singleFile?.ValidatedRecords?.TotalValidations}</b>
                </Typography>
                <Typography>
                  Records Validated{" "}
                  <b>
                    {formatNumber(
                      tests?.singleFile?.ValidatedRecords?.ValidatedRecords
                    )}
                  </b>
                </Typography>
                <Typography>
                  Connections <b> {tests?.singleFile?.dataSource}</b>
                </Typography>
              </Box>

              <Box>
                <Grid container className="PassFail" mt="0!important">
                  <Grid item sm={6}>
                    <Box className="passed">
                      <CheckCircleRoundedIcon sx={{ color: "#50cd89" }} />
                      <Typography>
                        {formatNumber(
                          tests?.singleFile?.ValidatedRecords?.Passed
                        )}
                        Passed
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <Box className="failed">
                      <CancelRoundedIcon color="error" />
                      <Typography>
                        {formatNumber(
                          tests?.singleFile?.ValidatedRecords?.Failed
                        )}{" "}
                        Failed
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box height={"90%"} className="DBsec">
              <Grid container spacing={1} alignItems="center">
                <Grid item sm={12} md={8}>
                  <Box sx={useStyles.TimeCir}>
                    <CompareArrowsIcon /> <Typography>Comparative</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box className="wBox">
                <Typography>
                  Total row-comparison rules{" "}
                  <b>{tests?.comparisonRules?.totalComparisonRules}</b>
                </Typography>
                <Typography>
                  Positional row-comparison rules{" "}
                  <b>{tests?.comparisonRules?.fullScanComparisonRules}</b>
                </Typography>
                <Typography>
                  Full scan row-comparison rules{" "}
                  <b>{tests?.comparisonRules?.positionalComparisonRules}</b>
                </Typography>
                <Typography>
                  Total row compared till date{" "}
                  <b>
                    {formatNumber(tests?.comparisonRules?.totalRowsCompared)}
                  </b>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DQRulesOverview;
