import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

export default function SingleSelect({
  loader = false,
  options,
  metaData,
  selectedValue = () => {},
  value,
  setValue,
  onOpen = null,
}) {
  const { t } = useTranslation();
  const handleChange = (e, val) => {
    selectedValue(val);
    setValue(val);
  };

  return (
    <Autocomplete
      disablePortal
      disableClearable={!value}
      loading={loader}
      options={options}
      value={value}
      getOptionLabel={(option) => {
        if (option.connectionName) {
          return (
            option.connectionName + " -- ( " + option.connectionType + " )"
          );
        } else if (option.fileName) {
          return option.fileName;
        } else if (option.tableName) {
          return option.tableName;
        } else if (option.COLUMN_NAME) {
          return option.COLUMN_NAME;
        } else if (Object.keys(option).includes("FirstName", "LastName")) {
          return option?.FirstName + " " + option?.LastName;
        } else {
          return option;
        }
      }}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(e, value) => {
        handleChange(e, value);
      }}
      onOpen={() => (onOpen ? onOpen() : null)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          placeholder={t(metaData.placeholder)}
          sx={{
            "& .MuiChip-root": {
              my: "1px",
              fontSize: "10px",
              height: "18px",
              "& .MuiChip-deleteIcon": {
                fontSize: "12px",
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loader && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
