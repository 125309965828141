import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import InnerHeader from "../Layout/InnerHeader";
import CustomAddButton from "../CustomComponents/CustomsButtons/CustomAddButton";
import FileWatcherTestList from "./FileWatcherTestList";

const FileWatcher = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateNew = () => {
    navigate("create");
  };

  return (
    <>
      <InnerHeader>
        <Box width="100%" className="space-between">
          <Typography variant="h6">{t("File Watcher")}</Typography>
          <CustomAddButton onClick={handleCreateNew} label={t("Create New")} />
        </Box>
      </InnerHeader>

      <FileWatcherTestList />
    </>
  );
};

export default FileWatcher;
