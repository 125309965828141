import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Button, Typography } from "@mui/material";

import CustomSwitchButton from "../../../CustomComponents/CustomSwitchButton/index.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationItem from "./LocationItem.js";
import { useSearchParams } from "react-router-dom";

const ResultsLocations = ({ foldersList, locationData, connectionsList, setLocationData, setStates }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const saveToSource = JSON.parse(searchParams.get("saveToSource"));
  const folderName = searchParams.get("folderName");

  const connectionId = searchParams.get("connectionId");

  const [isExpanded, setIsExpanded] = useState(true);

  const handleAccordionChange = (event, isExpanded) => {
    setIsExpanded(isExpanded);
  };

  const handleSwitchChange = (event) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("saveToSource", event.target.checked);
      return newState;
    });
    if (event.target.checked) {
      setLocationData({
        processedConnectionId: connectionId,
        processedFolderName: folderName,
        errorFileConnectionId: connectionId,
        errorFileFolderName: folderName,
        passedRecordsConnectionId: connectionId,
        passedRecordsFolderName: folderName,
        failedRecordsConnectionId: connectionId,
        failedRecordsFolderName: folderName,
        processedFolderList: foldersList,
        errorFolderList: foldersList,
        passedFolderList: foldersList,
        failedFolderList: foldersList,
      });
    }
  };

  const onClickNext = () => {
    setIsExpanded(false);
    setStates((prevState) => ({ ...prevState, showCreateRule: true }));
  };
  const isDisabled = Object.values({ ...locationData })?.some((each) => !each);
  const noMargin = { m: "0px !important", p: 0 };

  return (
    <Box
      sx={{
        "& .MuiAccordion-root": {
          border: "1px solid #ccc",
          px: 2,
          py: 1,
          "& .MuiAccordionSummary-root": {
            minHeight: "auto",
            "& .MuiAccordionSummary-content": { my: 1 },
          },
        },
      }}>
      <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
        <AccordionSummary sx={{ ...noMargin, "& .Mui-expanded": { ...noMargin } }} expandIcon={<ExpandMoreIcon />}>
          <Box className="space-between" sx={{ width: "100%" }}>
            <Typography variant="h6"> Add Results Stored Locations</Typography>
            {isExpanded && (
              <Box sx={{ mr: 3 }} onClick={(e) => e.stopPropagation()}>
                <CustomSwitchButton
                  checked={saveToSource || false}
                  labels={["", "Save to Source"]}
                  disabled={false}
                  onChange={handleSwitchChange}
                />
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ ...noMargin }}>
          <Grid container spacing={2}>
            <LocationItem
              title="Processed Location"
              description="This file will be moved to this location after processing"
              data={{
                folderKeyName: "processedFolderName",
                connectionKeyName: "processedConnectionId",
                folderListName: "processedFolderList",
              }}
              connectionsList={connectionsList}
              setLocationDetails={setLocationData}
              locationData={locationData}
            />

            <LocationItem
              title="Passed Record Location"
              description="Passed records will be moved to this location"
              data={{
                folderKeyName: "passedRecordsFolderName",
                connectionKeyName: "passedRecordsConnectionId",
                folderListName: "passedFolderList",
              }}
              connectionsList={connectionsList}
              setLocationDetails={setLocationData}
              locationData={locationData}
            />
            <LocationItem
              title="Failed Records Location"
              description="Failed records will be moved to this location"
              data={{
                folderKeyName: "failedRecordsFolderName",
                connectionKeyName: "failedRecordsConnectionId",
                folderListName: "failedFolderList",
              }}
              locationData={locationData}
              connectionsList={connectionsList}
              setLocationDetails={setLocationData}
            />

            <LocationItem
              title="Error Records Location"
              description="If unable to processed the file, It will be moved to this location"
              data={{
                folderKeyName: "errorFileFolderName",
                connectionKeyName: "errorFileConnectionId",
                folderListName: "errorFolderList",
              }}
              locationData={locationData}
              connectionsList={connectionsList}
              setLocationDetails={setLocationData}
            />

            <Button sx={{ ml: "auto", mt: 2 }} onClick={onClickNext} variant="contained" disabled={isDisabled}>
              Next
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ResultsLocations;
